import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { Mention } from "primereact/mention";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import AnswerIcon from "../../assets/images/Momentum Logo M.png";
import MomentumGPTLogo from "../../assets/images/MomentumGPTLogo.svg";
import fileUpload from "../../assets/images/Support-Bot/Upload-File.svg";
import CommunicationIcon from "../../assets/images/Support-Bot/communication-icon.svg";
import FileUploadSuccess from "../../assets/images/file-upload-success.svg";
import RemoveFileUpload from "../../assets/images/remove-file.svg";
import ToolboxHeader from "../../common/Header";
import SupportLoader from "../../common/SupportLoader";
import CommonValues from "../../common/utils";
import MomentumGPTAnswer from "./MomentumGPTAnswer";
import NCChatBotConversationHistory from "./MomentumGPTHistory";
import HomeSidebar from "./MomentumGPTSidebar";

interface ChatResponse {
  conversationId: string;
  companyId: number;
  response: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
  ai_tool: string;
  insured_id: string;
  policy_id: string;
}

class ChatRequest {
  ConversationId: string = "";
  ChatHistory: { Question: string; Answer: string }[] = [];
  Question: string = "";
}

export default function MomentumGPT() {
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [recordingAudio, setRecordingAudio] = useState(false);
  const [transcribingAudio, setTranscribingAudio] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [showConversationHistory, setShowConversationHistory] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [successMessage, setSuccessMessage] = useState(false);
  const location = useLocation();
  const [chatBotEmbeded, setChatBotEmbeded] = useState(
    location.state?.embeded || window.location.pathname.endsWith("embeded")
  );
  const [suggestions, setSuggestions] = useState([
    { name: "Rater Agent", nickname: "RaterAgent" },
    { name: "Talk To Ams Agent", nickname: "TalkToAmsAgent" },
  ]);

  let aiWidgets = [
    {
      title: "Driving License Scan",
      description:
        "Captures data from PDF/IMAGE and converts it into smart forms.",
    },
    {
      title: "Invoice Scan",
      description:
        "Captures data from invoices and create endorsements in AMS.",
    },
    {
      title: "Quote Proposal",
      description:
        "Captures data from PDF and converts it into quote proposal.",
    },
    {
      title: "Commission Scan",
      description: "Captures data from PDF and extracts data.",
    },
    {
      title: "Policy Summarize",
      description: "Upload your policy document and summarize it.",
    },
    {
      title: "Policy Compare",
      description: "Upload your policy documents and compare them.",
    },
  ];

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.setItem("Embeded", String(chatBotEmbeded));

    setChatBotEmbeded(localStorage.getItem("Embeded") === "true");

    getConversationHistory(window.location.href.split("/")[4]);
    setUploadFile([]);
    adjustTextareaHeight();
    setAvatarName(localStorage.getItem("AvatarName"));
  }, [window.location.href.split("/")[4]]);

  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);
    }
  }, [chatResponses.length]);

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks: any[] = [];

      setRecordingAudio(true);
      setAudioRecorder(recorder);

      recorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        transcribeAudio(audioBlob);
      };

      recorder.start();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopRecordingAudio = () => {
    if (audioRecorder) {
      audioRecorder.stop();
    }

    setRecordingAudio(false);
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      setTranscribingAudio(true);
      const data = new FormData();
      var audioFile = new File([audioBlob], "recording.wav", {
        type: audioBlob.type,
      });
      data.append("audioFile", audioFile);

      const token = CommonValues.GetToken();

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_PolicyAgent_Url}/process_audio`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setTranscribingAudio(false);
          setQuestion(response.data.result);
        })
        .catch((error: any) => {
          setTranscribingAudio(false);
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 500) {
            toast.current.show({
              severity: "info",
              detail:
                "Unknown error occured while processing audio. Please try again.",
              life: 3000,
            });
          }
        });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const onSearch = (event: { query: string }) => {
    const query = event.query.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.nickname.toLowerCase().startsWith(query)
    );
    setSuggestions(filteredSuggestions);
  };

  const itemTemplate = (suggestion: { name: string; nickname: string }) => {
    return (
      <div className="flex align-items-center py-0">
        <span className="flex flex-column ml-2 text-10">
          {suggestion.name}
          <small
            style={{ fontSize: ".75rem", color: "var(--text-color-secondary)" }}
          >
            @{suggestion.nickname}
          </small>
        </span>
      </div>
    );
  };

  const getConversationHistory = (conversationId: string | null) => {
    if (
      conversationId === undefined ||
      conversationId === null ||
      conversationId === ""
    ) {
      return;
    }
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncmomentumgpt/nc-momentum-gpt-history/${conversationId}`,
      // url: `https://localhost:55723/api/ncmomentumgpt/nc-momentum-gpt-history/${conversationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const data = response.data.ncMomentumGPTHistoryConversation;
        const fileUrl = response.data.pdfUrl;
        let conversationHistory: ChatResponse[] = [];
        data.forEach((conversation: any) => {
          conversationHistory.push({
            conversationId: conversation.conversationId,
            companyId: conversation.companyId,
            response: conversation.response,
            sources: [],
            imageURLs: [fileUrl],
            videoURLs: [],
            imageSidebar: false,
            question: conversation.question,
            chatId: conversation.chatId,
            like: conversation.like === null ? false : conversation.like,
            disLike:
              conversation.disLike === null ? false : conversation.disLike,
            feedback: "",
            showFeedback: false,
            feedbackLoading: false,
            plotlyData: { data: "", layout: "" },
            textToAction: "",
            responseStreaming: false,
            ai_tool: conversation.aI_Tool,
            insured_id: conversation.insuredId,
            policy_id: conversation.policyId,
          });
        });
        setChatResponses(conversationHistory);
        setTimeout(() => {
          scrollToBottomForQuestion(false);
          scrollToBottomForAnswer(false);
        }, 100);

        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    adjustTextareaHeight();
  };

  const onFeedbackChange = (chatId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };

  const scrollToBottomForQuestion = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(
        ".last-question-block-nc-chat-bot"
      );
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop : 0;
      }
    }
  };

  const scrollToBottomForAnswer = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(
        ".last-answer-block-nc-chat-bot"
      );
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom
          ? offsetTop + 5000
          : 0;
      }
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const onThumbsUpClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onThumbsDownClick = (
    chatId: string,
    like: boolean,
    disLike: boolean
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onFeedbackSubmit = (chatId: string, feedBack: string) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedbackLoading: true }
          : response
      )
    );
    updateFeedback(chatId, feedBack);
  };

  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      sendQuestion(question);
    }
  };

  const toggleFeedbackVisibility = (chatId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };

  const sendQuestion = async (que: string) => {
    try {
      if (que === "") {
        return;
      }
      const newQuestion: ChatResponse = {
        conversationId: window.location.href.split("/")[4],
        companyId: 0,
        question: que,
        response: "",
        sources: [],
        imageURLs: [],
        videoURLs: [],
        imageSidebar: false,
        chatId: "",
        like: false,
        disLike: false,
        feedback: "",
        showFeedback: false,
        feedbackLoading: false,
        plotlyData: { data: "", layout: "" },
        textToAction: "",
        responseStreaming: false,
        ai_tool: "",
        insured_id: "",
        policy_id: "",
      };

      var FormData = require("form-data");
      var formData = new FormData();
      formData.append("human_prompt", que);
      if (uploadFile.length !== 0) {
        formData.append("file", uploadFile[0].file);
        if (uploadFile.length === 2) {
          formData.append("second_file", uploadFile[1].file);
        }
      }
      formData.append("nc_token", localStorage.getItem("NCToken"));
      formData.append("conversationId", window.location.href.split("/")[4]);
      if (chatResponses.length === 0) {
        formData.append(
          "conversation_history",
          JSON.stringify([
            {
              Question: "Scan provided document",
              Answer: "",
            },
          ])
        );
      } else {
        formData.append(
          "conversation_history",
          JSON.stringify([
            {
              Question: chatResponses[0].question,
              Answer: chatResponses[0].response,
            },
          ])
        );
      }
      let updatedChatResponses = [...chatResponses, { ...newQuestion }];
      setChatResponses(updatedChatResponses);
      setQuestion("");
      scrollToBottomForQuestion(true);
      setQuestionLoading(true);
      const token = CommonValues.GetToken();

      try {
        const ctrl = new AbortController();
        await fetchEventSource(
          // `http://127.0.0.1:8000/chat`,
          `${process.env.REACT_APP_MomentumGPT}/chat`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
            signal: ctrl.signal,
            openWhenHidden: true,
            onopen(response: Response): Promise<void> {
              if (response.status === 401) {
                CommonValues.Logout(navigate);
              } else if (response.status !== 200) {
                setQuestionLoading(false);
                toast.current.show({
                  severity: "error",
                  detail: "Unknown error while sending question",
                  life: 3000,
                });
              }
              return Promise.resolve();
            },
            onmessage(event: any) {
              let responseObject = JSON.parse(event.data);
              if (responseObject.response !== "") {
                setQuestionLoading(false);
              }

              updatedChatResponses = updatedChatResponses.map(
                (chatResponse, index) => {
                  if (index === updatedChatResponses.length - 1) {
                    return {
                      ...chatResponse,
                      response:
                        (chatResponse.response || "") + responseObject.response,
                      chatId: responseObject.chatId,
                      imageURLs: responseObject.file_url
                        ? [responseObject.file_url]
                        : [],
                      ai_tool: responseObject.ai_tool,
                      insured_id: responseObject.insuredClientId,
                      policy_id: responseObject.policyId,
                      responseStreaming: responseObject.responseStreaming,
                    };
                  }
                  return chatResponse;
                }
              );

              setChatResponses(updatedChatResponses);

              // scrollToBottomForAnswer(true);
            },
            onclose() {
              setQuestionLoading(false);
            },
            onerror(error: any) {
              setQuestionLoading(false);
            },
          }
        );
      } catch (error: any) {}

      // const config = {
      //   method: "post",
      //   // url: `http://127.0.0.1:8000/chat`,
      //   url: `${process.env.REACT_APP_MomentumGPT}/chat`,
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      //   data: formData,
      // };

      // axios(config)
      //   .then((response: any) => {
      //     setQuestionLoading(false);
      //     updatedChatResponses = updatedChatResponses.map(
      //       (chatResponse, index) => {
      //         if (index === updatedChatResponses.length - 1) {
      //           return {
      //             ...chatResponse,
      //             response:
      //               (chatResponse?.response || "") + response.data.response,
      //             chatId: response.data.chatId,
      //             sources: [],
      //             imageURLs:
      //               response.data.file_url === ""
      //                 ? []
      //                 : [response.data.file_url],
      //             videoURLs: [],
      //             textToAction: "",
      //             responseStreaming: false,
      //             ai_tool: response.data.ai_tool,
      //             insured_id: response.data.insuredClientId,
      //             policy_id: response.data.policyId,
      //           };
      //         }
      //         return chatResponse;
      //       }
      //     );

      //     setChatResponses(updatedChatResponses);
      //   })
      //   .catch((error: any) => {
      //     setQuestionLoading(false);
      //     if (error.response.status == 401) {
      //       CommonValues.Logout(navigate);
      //     } else if (error.response.status == 500) {
      //       toast.current.show({
      //         severity: "error",
      //         detail:
      //           "Unknown error occured while getting response. Please try again.",
      //         life: 3000,
      //       });
      //     }
      //   });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const updateReaction = (chatId: string, like: boolean, disLike: boolean) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Like: like,
      DisLike: disLike,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-reaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating reaction",
            life: 3000,
          });
        }
      });
  };

  const updateFeedback = (chatId: string, feedBack: string) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Feedback: feedBack,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.chatId === chatId
                ? {
                    ...response,
                    feedback: "",
                    showFeedback: false,
                    feedbackLoading: false,
                  }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.chatId === chatId
              ? { ...response, feedbackLoading: false }
              : response
          )
        );
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating feedback",
            life: 3000,
          });
        }
      });
  };

  const onCopyResponseClick = (copiedData: string) => {
    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Copied  Successfully!",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          detail: "Unknown error occurred while coping data",
          life: 3000,
        });
      });
  };

  const showImageSidebar = (index: number, isHidden: boolean) => {
    const updatedChatResponses = [...chatResponses];
    updatedChatResponses[index].imageSidebar = isHidden;
    setChatResponses(updatedChatResponses);
  };

  const startScan = (title: string) => {
    let conversationId = window.location.href.split("/")[4];
    const newResponse: ChatResponse = {
      conversationId: conversationId,
      companyId: 0,
      question: `Start ${title}`,
      response: `Great, Now can you please upload your ${
        title === "Driving License Scan"
          ? "driving license"
          : title === "Invoice Scan"
          ? "invoice statement"
          : title === "Quote Proposal"
          ? "quote application"
          : title === "Commission Scan"
          ? "commission pdf"
          : ""
      } here?`,
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      textToAction: "",
      responseStreaming: false,
      ai_tool: "",
      insured_id: "",
      policy_id: "",
    };
    setChatResponses([newResponse]);
  };
  const onFileSelectHandler = (files: FileList | null) => {
    if (files === null) {
      return;
    }
    if (files.length > 2) {
      toast.current.show({
        severity: "info",
        detail: `Maximum of 2 files can be uploaded for Momentum GPT`,
        life: 3000,
      });
      return;
    }
    let fileName = "";
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      setErrorMessage("Please select PDF / Image files only");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return false;
    }
    let fileList = [];
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      fileName += files[i].name + "\n";
    }
    setFileNameTitle(fileName);

    if (fileList.length > 0) {
      setUploadFile(fileList);
      toast.current.show({
        severity: "success",
        detail: `${fileName} is uploaded successfully`,
        life: 3000,
      });
    }
  };
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (
        fileExtension?.[1].toLowerCase().includes("pdf") ||
        fileExtension?.[1].toLowerCase().includes("png") ||
        fileExtension?.[1].toLowerCase().includes("jpeg") ||
        fileExtension?.[1].toLowerCase().includes("jpg")
      ) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const removeFileFromList = () => {
    console.log(uploadFile);
    setUploadFile([]);
    toast.current.show({
      severity: "info",
      detail: `${fileNameTitle} is removed.`,
      life: 3000,
    });
  };
  const handleFileAlreadyUploaded = () => {
    toast.current.show({
      severity: "info",
      detail: `${fileNameTitle} is already uploaded`,
      life: 3000,
    });
  };
  return (
    <div className="landing-page">
      <Toast ref={toast} />
      <div style={{ minHeight: `${chatBotEmbeded ? "6.4vh" : "5.7vh"}` }}>
        {/* <Header
          setShowConversationHistory={setShowConversationHistory}
          chatBotEmbeded={chatBotEmbeded}
        /> */}
        <ToolboxHeader
          pagename="commission-scan"
          NavigatedFromChatBot={false}
        />
      </div>
      <div style={{ minHeight: `${chatBotEmbeded ? "93.6vh" : "81.7vh"}` }}>
        <div
          className={`row mx-auto ${
            chatBotEmbeded
              ? "nc-chat-bot-main-div-embeded"
              : "nc-chat-bot-main-div"
          }`}
        >
          {!chatBotEmbeded && (
            <div
              className={`${
                showConversationHistory
                  ? "col-lg-3 col-md-3 col-sm-3 col-3"
                  : "col-lg-1 col-md-1 col-sm-1 col-1"
              } d-flex px-0`}
            >
              <div
                className={
                  showConversationHistory
                    ? "chat-bot-sidebar-main-div-on-show-history"
                    : "chat-bot-sidebar-main-div"
                }
              >
                <HomeSidebar
                  setShowConversationHistory={setShowConversationHistory}
                  chatBotEmbeded={chatBotEmbeded}
                />
              </div>
              {showConversationHistory && (
                <div className="col conversation-history">
                  <NCChatBotConversationHistory
                    setShowConversationHistory={setShowConversationHistory}
                    chatBotEmbeded={chatBotEmbeded}
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={`${
              chatBotEmbeded
                ? "col-lg-11 col-md-11 col-sm-11 col-11 chat-bot-response-area-embeded"
                : `${
                    showConversationHistory
                      ? "col-lg-9 col-md-9 col-sm-9 col-9"
                      : "col-lg-11 col-md-11 col-sm-11 col-11"
                  } chat-bot-response-area`
            } d-flex px-0`}
            ref={chatContainerRef}
          >
            {!chatBotEmbeded && (
              <>
                <div
                  className={`col-lg-2 col-md-2 col-sm-2 col-2
                  `}
                ></div>
                <div
                  className={`col-lg-8 col-md-8 col-sm-8 col-8
                   p-4`}
                >
                  {!conversationLoading &&
                    chatResponses.length > 0 &&
                    chatResponses.map((response, index) => {
                      const isLastAnswer = index === chatResponses.length - 1;
                      const showUpload =
                        index === 0 && response.companyId === 0;

                      return (
                        <div
                          id="last-answer"
                          key={response.chatId}
                          className={`col-12 ${
                            isLastAnswer && !questionLoading
                              ? "last-answer-block-nc-chat-bot"
                              : "question-answer-block"
                          }`}
                        >
                          <div className="d-flex flex-direction-row align-items-center">
                            {!chatBotEmbeded && (
                              <span className="me-1 avtar border-rounded">
                                {avatarName}
                              </span>
                            )}
                            <div className="mb-1 text-14 nc-chat-bot-question">
                              <b>{response.question}</b>
                            </div>
                          </div>
                          <div className="d-flex flex-direction-row">
                            {!chatBotEmbeded && (
                              <span className="me-1">
                                <img
                                  alt="NowCerts Logo"
                                  src={AnswerIcon}
                                  className="me-2"
                                  height={27}
                                  width={27}
                                />
                              </span>
                            )}
                            {isLastAnswer && questionLoading ? (
                              <div
                                id="last-question"
                                className={`row w-100 ${
                                  isLastAnswer && questionLoading
                                    ? "last-question-block-nc-chat-bot"
                                    : "question-answer-block"
                                }`}
                              >
                                <div className="col-12">
                                  <Skeleton className="mb-2"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton
                                    width="70%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>

                                <div className="col-12">
                                  <Skeleton
                                    width="40%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton
                                    width="20%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>
                              </div>
                            ) : (
                              <MomentumGPTAnswer
                                chatResponse={response}
                                index={index}
                                chatBotEmbeded={chatBotEmbeded}
                                // sendQuestion={sendQuestion}
                                onFeedbackSubmit={onFeedbackSubmit}
                                onFeedbackChange={onFeedbackChange}
                                toggleFeedbackVisibility={
                                  toggleFeedbackVisibility
                                }
                                onCopyResponseClick={onCopyResponseClick}
                                onThumbsUpClick={onThumbsUpClick}
                                onThumbsDownClick={onThumbsDownClick}
                                showImageSidebar={showImageSidebar}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {conversationLoading ? (
                    <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <SupportLoader />
                      </div>
                    </div>
                  ) : (
                    chatResponses.length === 0 && (
                      <div className="row chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                        {chatBotEmbeded ? (
                          <span
                            className="text-16 "
                            style={{ color: "#381576" }}
                          >
                            How may I assist you today?
                          </span>
                        ) : (
                          <>
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 text-16 text-center mb-4">
                              <img src={MomentumGPTLogo} />
                            </div>
                            {aiWidgets.map(({ title, description }) => {
                              return (
                                <>
                                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                    <div
                                      className="ai-widgets-box me-3 "
                                      onClick={() => startScan(title)}
                                    >
                                      <span className="source-wrap d-flex">
                                        <span className="namebox">
                                          <p className="mb-0">
                                            <b>{title}</b>
                                          </p>
                                          <p>{description}</p>{" "}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    )
                  )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2"></div>
              </>
            )}
          </div>
        </div>
      </div>
      {!chatBotEmbeded && (
        <div style={{ minHeight: "12.6vh" }}>
          <div className="question-text">
            <div className="position-relative question-text-div">
              <div className="row">
                {
                  <div
                    className={`${
                      showConversationHistory
                        ? "col-lg-4 col-md-4 col-sm-4 col-4"
                        : "col-lg-3 col-md-3 col-sm-3 col-3"
                    }`}
                  ></div>
                }

                <div
                  className={`${
                    chatBotEmbeded
                      ? "col-lg-10 col-md-10 col-sm-10 col-10"
                      : "col-lg-7 col-md-7 col-sm-7 col-7"
                  }`}
                >
                  <div className="row">
                    {showConversationHistory && (
                      <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
                    )}
                    <div
                      className={`${
                        showConversationHistory
                          ? "col-lg-10 col-md-10 col-sm-10 col-10"
                          : "col-lg-12 col-md-12 col-sm-12 col-12"
                      }`}
                    >
                      {/* <img
                        className="upload-file-momentum-gpt"
                        src={fileUpload}
                        height={32}
                        width={23}
                      > */}
                      {/* </img> */}

                      {transcribingAudio ? (
                        <span
                          className={`${
                            showConversationHistory
                              ? "audio-icon-on-show-conversation-history"
                              : "audio-icon-nc-chat-bot"
                          }`}
                        >
                          <ThreeDots height="35" width="45" color="#2dacc2" />
                        </span>
                      ) : recordingAudio ? (
                        <i
                          className={`fa fa-stop-circle ${
                            showConversationHistory
                              ? "audio-icon-on-show-conversation-history"
                              : "audio-icon-nc-chat-bot"
                          }`}
                          onClick={stopRecordingAudio}
                          style={{
                            fontSize: "xx-large",
                            left: `${chatBotEmbeded ? "7%" : ""}`,
                          }}
                        ></i>
                      ) : (
                        <i
                          title="Speech to text"
                          className={`fa fa-microphone ${
                            showConversationHistory
                              ? "audio-icon-on-show-conversation-history"
                              : "audio-icon-nc-chat-bot"
                          }`}
                          onClick={startRecordingAudio}
                          style={{
                            fontSize: "xx-large",
                            left: `${chatBotEmbeded ? "7%" : ""}`,
                          }}
                        ></i>
                      )}
                      <Mention
                        value={question}
                        onChange={onQuestionChange}
                        suggestions={suggestions}
                        onSearch={onSearch}
                        field="nickname"
                        placeholder="Ask me anything about NowCerts..."
                        rows={2}
                        itemTemplate={itemTemplate}
                        name="question"
                        className="nc-chat-bot-mention-textarea"
                        maxLength={2000}
                        style={{
                          minHeight: `${Math.max(2, question.length / 150)}em`,
                        }}
                        onKeyDown={onEnterClick}
                      />
                      <span className="info-msg mb-2">
                        Support Bot can make mistakes. Check important info.
                      </span>
                      <>
                        {uploadFile.length == 0 ? (
                          <>
                            <input
                              className="upload-file-momentum-gpt custom-file-input"
                              type="file"
                              id="firstFileInput"
                              name="filefield"
                              accept="application/pdf, image/png, image/jpeg"
                              onChange={(e) => {
                                onFileSelectHandler(e.target.files);
                              }}
                              multiple
                            />
                            <label
                              className="upload-file-momentum-gpt"
                              htmlFor="firstFileInput"
                            >
                              <img src={fileUpload} className=""></img>
                            </label>
                          </>
                        ) : (
                          <>
                            <label
                              className="uploaded-file-momentum-gpt"
                              // htmlFor="firstFileInput"
                              onClick={handleFileAlreadyUploaded}
                              title={fileNameTitle}
                            >
                              <img src={FileUploadSuccess} className=""></img>
                            </label>
                            <label
                              className="upload-file-momentum-gpt"
                              // htmlFor="firstFileInput"
                              onClick={removeFileFromList}
                              title="Remove uploaded file"
                            >
                              <img src={RemoveFileUpload} className=""></img>
                            </label>
                          </>
                        )}
                      </>
                      <img
                        src={CommunicationIcon}
                        onClick={() => sendQuestion(question)}
                        className={`${
                          showConversationHistory
                            ? "communication-icon-on-show-conversation-history"
                            : "communication-icon-nc-chat-bot"
                        }`}
                        style={{ right: `${chatBotEmbeded ? "7%" : ""}` }}
                      />
                    </div>
                  </div>
                  {showConversationHistory && (
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
                  )}
                </div>

                <div
                  className={`col-lg-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "2"
                  } col-md-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "2"
                  } col-sm-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "2"
                  } col-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "2"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
