import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { Mention } from "primereact/mention";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import CommunicationIcon from "../../assets/images/Support-Bot/communication-icon.svg";
import AnswerIcon from "../../assets/images/Momentum Logo M.png";
import SupportLoader from "../../common/SupportLoader";
import CommonValues from "../../common/utils";
import Answer from "./Answer";
// import NCChatBotConversationHistory from "./NCChatBotConversationHistory";
import BackgroundVideo from "../../common/BackgroundVideo";
import Header from "../../common/Header";
import HistorySidebar from "./HistorySidebar";
import MiddleHeader from "./MiddleHeader";

interface ChatResponse {
  conversationId: string;
  answer: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
}

class ChatRequest {
  ConversationId: string = "";
  ChatHistory: { Question: string; Answer: string }[] = [];
  Question: string = "";
}

export default function NewConversation() {
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [recordingAudio, setRecordingAudio] = useState(false);
  const [transcribingAudio, setTranscribingAudio] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [showConversationHistory, setShowConversationHistory] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const suggestedQuestions = [
    "How to send a Certificate of Insurance",
    "How to manage user access and permissions?",
    "How to generate reports and export data?",
    "How to edit, delete, or merge policies and accounts?",
    "How to handle commissions and payments?",
  ];

  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(
    null
  );

  const location = useLocation();
  const [chatBotEmbeded, setChatBotEmbeded] = useState(true);
  const [suggestions, setSuggestions] = useState([
    { name: "Rater Agent", nickname: "RaterAgent" },
    { name: "Talk To Ams Agent", nickname: "TalkToAmsAgent" },
  ]);
  const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.setItem("Embeded", String(chatBotEmbeded));

    setChatBotEmbeded(localStorage.getItem("Embeded") === "true");

    getConversationHistory(window.location.href.split("/")[4]);

    adjustTextareaHeight();
    setAvatarName(localStorage.getItem("AvatarName"));
  }, [window.location.href.split("/")[4]]);

  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);
    }
  }, [chatResponses.length]);

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks: any[] = [];

      setRecordingAudio(true);
      setAudioRecorder(recorder);

      recorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        transcribeAudio(audioBlob);
      };

      recorder.start();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopRecordingAudio = () => {
    if (audioRecorder) {
      audioRecorder.stop();
    }

    setRecordingAudio(false);
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      setTranscribingAudio(true);
      const data = new FormData();
      var audioFile = new File([audioBlob], "recording.wav", {
        type: audioBlob.type,
      });
      data.append("audioFile", audioFile);

      const token = CommonValues.GetToken();

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_PolicyAgent_Url}/process_audio`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setTranscribingAudio(false);
          setQuestion(response.data.result);
        })
        .catch((error: any) => {
          setTranscribingAudio(false);
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 500) {
            toast.current.show({
              severity: "info",
              detail:
                "Unknown error occured while processing audio. Please try again.",
              life: 3000,
            });
          }
        });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const onSearch = (event: { query: string }) => {
    const query = event.query.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.nickname.toLowerCase().startsWith(query)
    );
    setSuggestions(filteredSuggestions);
  };

  const itemTemplate = (suggestion: { name: string; nickname: string }) => {
    return (
      <div className="flex align-items-center py-0">
        <span className="flex flex-column ml-2 text-10">
          {suggestion.name}
          <small
            style={{ fontSize: ".75rem", color: "var(--text-color-secondary)" }}
          >
            @{suggestion.nickname}
          </small>
        </span>
      </div>
    );
  };

  const getConversationHistory = (conversationId: string | null) => {
    if (
      conversationId === undefined ||
      conversationId === null ||
      conversationId === ""
    ) {
      return;
    }
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-conversation-history/${conversationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const data = response.data;
        let conversationHistory: ChatResponse[] = [];
        data.forEach((conversation: any) => {
          conversationHistory.push({
            conversationId: conversation.conversationId,
            answer: conversation.response,
            sources: conversation.pageURL,
            imageURLs: conversation.imageURL,
            videoURLs: conversation.videoURL,
            imageSidebar: false,
            question: conversation.question,
            chatId: conversation.chatId,
            like: conversation.like === null ? false : conversation.like,
            disLike:
              conversation.disLike === null ? false : conversation.disLike,
            feedback: "",
            showFeedback: false,
            feedbackLoading: false,
            plotlyData: { data: "", layout: "" },
            textToAction: "",
            responseStreaming: false,
          });
        });
        setChatResponses(conversationHistory);
        setTimeout(() => {
          scrollToBottomForQuestion(false);
          scrollToBottomForAnswer(false);
        }, 100);

        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    adjustTextareaHeight();
  };

  const onFeedbackChange = (chatId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };

  const scrollToBottomForQuestion = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(
        ".last-question-block-nc-chat-bot"
      );
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop : 0;
      }
    }
  };

  const scrollToBottomForAnswer = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(
        ".last-answer-block-nc-chat-bot"
      );
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom
          ? offsetTop + 5000
          : 0;
      }
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const onThumbsUpClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onThumbsDownClick = (
    chatId: string,
    like: boolean,
    disLike: boolean
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onFeedbackSubmit = (chatId: string, feedBack: string) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedbackLoading: true }
          : response
      )
    );
    updateFeedback(chatId, feedBack);
  };

  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      sendQuestion(question);
    }
  };

  const toggleFeedbackVisibility = (chatId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };

  const sendQuestion = async (que: any) => {
    if (que === "") {
      return;
    }
    let body: ChatRequest = {
      ConversationId: window.location.href.split("/")[4],
      ChatHistory: [],
      Question: que,
    };
    chatResponses.forEach((response: ChatResponse) => {
      body.ChatHistory.push({
        Question: response.question,
        Answer: response.answer,
      });
    });
    body.ChatHistory.push({ Question: que, Answer: "" });
    const newQuestion: ChatResponse = {
      conversationId: body.ConversationId,
      question: que,
      answer: "",
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      textToAction: "",
      responseStreaming: false,
    };
    // setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    let updatedChatResponses = [...chatResponses, { ...newQuestion }];
    setChatResponses(updatedChatResponses);
    setQuestion("");
    scrollToBottomForQuestion(true);
    setQuestionLoading(true);
    const index = chatResponses.length;
    const token = CommonValues.GetToken();
    try {
      const ctrl = new AbortController();
      await fetchEventSource(
        `${process.env.REACT_APP_LangchainSupportBot_Url}/chat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          signal: ctrl.signal,
          openWhenHidden: true,
          onopen(response: Response): Promise<void> {
            if (response.status === 401) {
              CommonValues.Logout(navigate);
            } else if (response.status !== 200) {
              setQuestionLoading(false);
              toast.current.show({
                severity: "error",
                detail: "Unknown error while sending question",
                life: 3000,
              });
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            let responseObject = JSON.parse(event.data);
            if (responseObject.Answer !== "") {
              setQuestionLoading(false);
            }

            updatedChatResponses = updatedChatResponses.map(
              (chatResponse, index) => {
                if (index === updatedChatResponses.length - 1) {
                  return {
                    ...chatResponse,
                    answer:
                      (chatResponse?.answer || "") + responseObject.Answer,
                    chatId: responseObject.ChatId,
                    sources: responseObject.Sources,
                    imageURLs: responseObject.Images,
                    videoURLs: responseObject.Videos,
                    textToAction: responseObject.ParentDOMId,
                    responseStreaming: responseObject.ResponseLoading,
                  };
                }
                return chatResponse;
              }
            );

            setChatResponses(updatedChatResponses);

            // scrollToBottomForAnswer(true);
          },
          onclose() {
            setQuestionLoading(false);
          },
          onerror(error: any) {
            setQuestionLoading(false);
          },
        }
      );
    } catch (error: any) {}
  };

  const updateReaction = (chatId: string, like: boolean, disLike: boolean) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Like: like,
      DisLike: disLike,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-reaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating reaction",
            life: 3000,
          });
        }
      });
  };

  const updateFeedback = (chatId: string, feedBack: string) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Feedback: feedBack,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.chatId === chatId
                ? {
                    ...response,
                    feedback: "",
                    showFeedback: false,
                    feedbackLoading: false,
                  }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.chatId === chatId
              ? { ...response, feedbackLoading: false }
              : response
          )
        );
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating feedback",
            life: 3000,
          });
        }
      });
  };

  const onCopyResponseClick = (copiedData: string) => {
    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Copied  Successfully!",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          detail: "Unknown error occurred while coping data",
          life: 3000,
        });
      });
  };

  const showImageSidebar = (index: number, isHidden: boolean) => {
    const updatedChatResponses = [...chatResponses];
    updatedChatResponses[index].imageSidebar = isHidden;
    setChatResponses(updatedChatResponses);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="support-bot-2-new-conversation">
        <Header
          pagename="commission-scan"
          NavigatedFromChatBot={navigatedFromChatBot}
        />
        <div className="content-wrapper">
          <BackgroundVideo />
          <div className="row wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <MiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <HistorySidebar pagename="commission-scan" />
              </div>
              <div
                className="col-lg-10 col-md-12 col-sm-12 main-content-col"
                style={{ minHeight: `${chatBotEmbeded ? "79vh" : "81.7vh"}` }}
              >
                <div
                  className={`row mx-auto ${
                    chatBotEmbeded
                      ? "chat-bot-response-area-embeded mt-2"
                      : "nc-chat-bot-main-div"
                  }`}
                >
                  <div
                    className={`${
                      chatBotEmbeded
                        ? "col-lg-12 col-md-12 col-sm-12 col-12 chat-bot-response-area-embeded"
                        : `${
                            showConversationHistory
                              ? "col-lg-9 col-md-9 col-sm-9 col-9"
                              : "col-lg-11 col-md-11 col-sm-11 col-11"
                          } chat-bot-response-area`
                    } d-flex px-0`}
                    ref={chatContainerRef}
                  >
                    {!showConversationHistory && chatBotEmbeded && (
                      <>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4">
                          {!conversationLoading &&
                            chatResponses.length > 0 &&
                            chatResponses.map((response, index) => {
                              const isLastAnswer =
                                index === chatResponses.length - 1;

                              return (
                                <div
                                  id="last-answer"
                                  key={response.chatId}
                                  className={`col-12 ${
                                    isLastAnswer && !questionLoading
                                      ? "last-answer-block-nc-chat-bot"
                                      : "question-answer-block"
                                  }`}
                                >
                                  <div className="d-flex flex-direction-row align-items-center">
                                    {chatBotEmbeded && (
                                      <span className="me-1 avtar border-rounded">
                                        {avatarName}
                                      </span>
                                    )}
                                    <div className="mb-1 text-14 nc-chat-bot-question">
                                      <b>{response.question}</b>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-direction-row">
                                    {chatBotEmbeded && (
                                      <span className="me-1">
                                        <img
                                          alt="NowCerts Logo"
                                          src={AnswerIcon}
                                          className="me-2"
                                          height={27}
                                          width={27}
                                        />
                                      </span>
                                    )}
                                    {isLastAnswer && questionLoading ? (
                                      <div
                                        id="last-question"
                                        className={`row w-100 ${
                                          isLastAnswer && questionLoading
                                            ? "last-question-block-nc-chat-bot"
                                            : "question-answer-block"
                                        }`}
                                      >
                                        <div className="col-12">
                                          <Skeleton className="mb-2"></Skeleton>
                                        </div>
                                        <div className="col-12">
                                          <Skeleton
                                            width="70%"
                                            className="mb-2 d-block"
                                          ></Skeleton>
                                        </div>

                                        <div className="col-12">
                                          <Skeleton
                                            width="40%"
                                            className="mb-2 d-block"
                                          ></Skeleton>
                                        </div>
                                        <div className="col-12">
                                          <Skeleton
                                            width="20%"
                                            className="mb-2 d-block"
                                          ></Skeleton>
                                        </div>
                                      </div>
                                    ) : (
                                      <Answer
                                        chatResponse={response}
                                        index={index}
                                        chatBotEmbeded={chatBotEmbeded}
                                        sendQuestion={sendQuestion}
                                        onFeedbackSubmit={onFeedbackSubmit}
                                        onFeedbackChange={onFeedbackChange}
                                        toggleFeedbackVisibility={
                                          toggleFeedbackVisibility
                                        }
                                        onCopyResponseClick={
                                          onCopyResponseClick
                                        }
                                        onThumbsUpClick={onThumbsUpClick}
                                        onThumbsDownClick={onThumbsDownClick}
                                        showImageSidebar={showImageSidebar}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          {conversationLoading ? (
                            <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                              <div className="d-flex justify-content-center">
                                <SupportLoader />
                              </div>
                            </div>
                          ) : (
                            chatResponses.length === 0 && (
                              <div className="row chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                                {chatBotEmbeded ? (
                                  <>
                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 text-16 text-center mb-4" style={{color:"#381576"}}>
                                      How may I assist you today?
                                    </div>
                                    {suggestedQuestions.map(
                                      (
                                        suggestedQuestion: string,
                                        index: number
                                      ) => {
                                        let isLast =
                                          index ===
                                          suggestedQuestions.length - 1;
                                        return (
                                          <>
                                            {!isLast && index % 2 === 0 && (
                                              <>
                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2"></div>
                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 text-end">
                                                  <div
                                                    className="sources-box me-3 "
                                                    onClick={() =>
                                                      sendQuestion(
                                                        suggestedQuestion
                                                      )
                                                    }
                                                  >
                                                    <span className="source-wrap d-flex">
                                                      <span className="namebox">
                                                        {suggestedQuestion}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </>
                                            )}

                                            {!isLast && index % 2 != 0 && (
                                              <>
                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                                  <div
                                                    className="sources-box me-3 "
                                                    onClick={() =>
                                                      sendQuestion(
                                                        suggestedQuestion
                                                      )
                                                    }
                                                  >
                                                    <span className="source-wrap d-flex">
                                                      <span className="namebox">
                                                        {suggestedQuestion}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2"></div>
                                              </>
                                            )}
                                            {isLast && (
                                              <div className="col-12 col-sm-12 col-md-2 col-lg-12 text-center">
                                                <div
                                                  className="sources-box me-3 "
                                                  onClick={() =>
                                                    sendQuestion(
                                                      suggestedQuestion
                                                    )
                                                  }
                                                >
                                                  <span className="source-wrap d-flex">
                                                    <span className="namebox">
                                                      {suggestedQuestion}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <h4 className="" style={{color:"#381576"}}>
                                    How may I assist you today?
                                  </h4>
                                )}
                              </div>
                            )
                          )}
                        </div>

                        <div className="question-text-embeded">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-4 py-1">
                              {transcribingAudio ? (
                                <span
                                  className={`${
                                    showConversationHistory
                                      ? "audio-icon-on-show-conversation-history"
                                      : "audio-icon-nc-chat-bot"
                                  }`}
                                  style={{ left: "10px" }}
                                >
                                  <ThreeDots
                                    height="35"
                                    width="45"
                                    color="#2dacc2"
                                  />
                                </span>
                              ) : recordingAudio ? (
                                <i
                                  className={`fa fa-stop-circle ${
                                    showConversationHistory
                                      ? "audio-icon-on-show-conversation-history"
                                      : "audio-icon-nc-chat-bot"
                                  }`}
                                  onClick={stopRecordingAudio}
                                  style={{
                                    fontSize: "xx-large",
                                    left: `${chatBotEmbeded ? "1%" : ""}`,
                                  }}
                                ></i>
                              ) : (
                                <i
                                  title="Speech to text"
                                  className={`fa fa-microphone ${
                                    showConversationHistory
                                      ? "audio-icon-on-show-conversation-history"
                                      : "audio-icon-nc-chat-bot"
                                  }`}
                                  onClick={startRecordingAudio}
                                  style={{
                                    fontSize: "xx-large",
                                    left: `${chatBotEmbeded ? "1%" : ""}`,
                                  }}
                                ></i>
                              )}
                              <Mention
                                value={question}
                                onChange={onQuestionChange}
                                suggestions={suggestions}
                                onSearch={onSearch}
                                field="nickname"
                                placeholder="Ask me anything about NowCerts..."
                                rows={2}
                                itemTemplate={itemTemplate}
                                name="question"
                                className="nc-chat-bot-mention-textarea-embeded px-5"
                                maxLength={2000}
                                style={{
                                  minHeight: `${Math.max(
                                    2,
                                    question.length / 150
                                  )}em`,
                                }}
                                onKeyDown={onEnterClick}
                              />

                              <span className="info-msg">
                                Support Bot can make mistakes. Check important
                                info.
                              </span>
                              <img
                                src={CommunicationIcon}
                                onClick={() => sendQuestion(question)}
                                className={`${
                                  showConversationHistory
                                    ? "communication-icon-on-show-conversation-history"
                                    : "communication-icon"
                                }`}
                                style={{
                                  right: "12%",
                                  bottom: "28%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
