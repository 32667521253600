import { useNavigate } from "react-router-dom";
import HistoryIcon from "../../assets/images/History_B.svg";
import NewIcon from "../../assets/images/newquote.png";

export default function QuoteProposalHistorySidebar(props: any) {
  const navigate = useNavigate();

  const onQuoteProposalClick = () => {
    navigate("/quoteproposal");
  };
  const onQuoteProposalHistoryClick = () => {
    navigate("/quoteproposalhistory");
  };

  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={
            props.pagename == "commission-scan"
              ? "pf-sidebar-menu active "
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onQuoteProposalClick}
        >
          <img
            src={NewIcon}
            className={
              props.pagename == "commission-scan"
                ? "pf-sidebar-img pf-sidebar-img-active"
                : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">New Quote Proposal Scan</span>{" "}
        </span>

        <span
          className={
            window.location.pathname == "/smartformscanhistory"
              ? "pf-sidebar-menu active "
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onQuoteProposalHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={
              window.location.pathname == "/smartformscanhistory"
                ? "pf-sidebar-img pf-sidebar-img-active"
                : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
