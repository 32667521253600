import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Header from "../common/Header";
import AppetiteMiddleHeader from "./AppetiteMiddleHeader";
import AppetiteSidebar from "./AppetiteSidebar";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Dropdown } from "primereact/dropdown";
import AtkButton from "../AtlaskitControls/AtkButton";
import CommonValues from "../common/utils";
import axios from "axios";
import { useCallback } from "react";
import _ from "lodash";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import BackgroundVideo from "../common/BackgroundVideo";

export default function AppetiteLandingPage() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [policyTypeError, setPolicyTypeError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [naicsCode, setNaicsCode] = useState("");
  const [naicsCodeLabel, setNaicsCodeLabel] = useState("");
  const [naicsCodeError, setNaicsCodeError] = useState("");
  const [appetiteResponse, setAppetiteResponse] = useState([]);
  const [naicsCodeOptions, setNaicsCodeOptions] = useState<any>([]);
  const [naicsCodeLoading, setNaicsCodeLoading] = useState(false);
  const [naicsCodeSearch, setNaicsCodeSearch] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);
  const options = [
    { label: "General Liability", value: "GL" },
    { label: "Workers Compensation", value: "WC" },
    { label: "Business Owners Policy", value: "BOP" },
    { label: "Cyber Policy", value: "CYBER" },
  ];
  const Allstate = [
    { label: "Alaska", value: "AK" },
    { label: "Alabama", value: "AL" },
    { label: "Arkansas", value: "AR" },
    { label: "Arizona", value: "AZ" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "District of Columbia", value: "DC" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Iowa", value: "IA" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Massachusetts", value: "MA" },
    { label: "Maryland", value: "MD" },
    { label: "Maine", value: "ME" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Missouri", value: "MO" },
    { label: "Mississippi", value: "MS" },
    { label: "Montana", value: "MT" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Nebraska", value: "NE" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "Nevada", value: "NV" },
    { label: "New York", value: "NY" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Virginia", value: "VA" },
    { label: "Vermont", value: "VT" },
    { label: "Washington", value: "WA" },
    { label: "Wisconsin", value: "WI" },
    { label: "West Virginia", value: "WV" },
    { label: "Wyoming", value: "WY" },
  ];

  const debouncedFetchResults = useCallback(
    _.debounce((searchQuery: any) => {
      getNaicsCodeList(searchQuery);
    }, 500),
    []
  );
  const hostname = window.location.hostname;
  useEffect(() => {
     if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (naicsCodeSearch.replaceAll(" ", "") == "") {
      return;
    }
    setNaicsCodeLoading(true);
    if (naicsCodeSearch) {
      debouncedFetchResults(naicsCodeSearch);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [naicsCodeSearch, debouncedFetchResults]);
  const policyTypeDropdownClicked = (e: any) => {
    setPolicyType(e.target.value);
    setPolicyTypeError("");
  };
  const stateDropdownClicked = (e: any) => {
    setState(e.value);
    setStateError("");
  };
  const NaicsCodeDropdownClicked = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setNaicsCodeSearch(value);
  };
  const getNaicsCodeList = (code: any) => {
    setNaicsCodeLoading(true);
    var token = CommonValues.GetToken();
    var data = {
      searchtext: code,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_RATEAPI}/api/rater/external/naics`,
      headers: {
        SecretKey: "uGw1VAngyoJlX0fxfvoHGS/Ned6dS15jH9Oqgfu2xjSOtDvMBZOjwA==",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        const naicsCodeOptions = response.data.codeInfo.map((item: any) => ({
          label: `${item.naicsCode} - ${item.description}`,
          value: item.naicsCode,
        }));
        setNaicsCodeOptions(naicsCodeOptions);
        setNaicsCodeLoading(false);
      })
      .catch((error: any) => {
        setNaicsCodeLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting naics code list",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting naics code list",
            life: 3000,
          });
        }
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    if (state === "" || state === null) {
      formIsValid = false;
      setStateError("Please select state");
    } else {
      setStateError("");
    }
    if (policyType === "" || policyType === null) {
      formIsValid = false;
      setPolicyTypeError("Please select policy type");
    } else {
      setPolicyTypeError("");
    }
    if (naicsCode === "" || naicsCode === null) {
      formIsValid = false;
      setNaicsCodeError("Please select naics code");
    } else {
      setNaicsCodeError("");
    }
    return formIsValid;
  };
  const onNextButtonClick = () => {
    if (checkValidation()) {
      setLoading(true);
      var token = CommonValues.GetToken();
      var data = {
        policyType: policyType,
        state: state,
        naicsCode: naicsCode,
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_RATEAPI}/api/rater/external/appetite`,
        headers: {
          SecretKey: "uGw1VAngyoJlX0fxfvoHGS/Ned6dS15jH9Oqgfu2xjSOtDvMBZOjwA==",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          setLoading(false);
          setSuccessStatus(response.data.success);
          setAppetiteResponse(response.data.providerInfo.primaryPreferrefProvider);
          InsertAppetiteDetailsInIGDB(response.data);
          if (response.data.success === false) {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: response.data.errorMessage,
              life: 3000,
            });
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response !== null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({
                severity: "error", summary: 'Error',
                detail: "Unknown error while getting rate risk appetite response",
                life: 3000,
              });
            }
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting rate risk appetite response",
              life: 3000,
            });
          }
        });
    }
  };
  const onContinueWithRateClick = () => {
    window.open(process.env.REACT_APP_Rate_UI_Url, "_blank");
  };
  const onTryAgainClick = () => {
    setPolicyType("");
    setState("");
    setNaicsCode("");
    setNaicsCodeLabel("");
    setSuccessStatus(false);
    setAppetiteResponse([]);
  };
  const InsertAppetiteDetailsInIGDB = (AppetiteResponse: any) => {
    setLoading(true);
    let selectedPolicyType;
    let selectedState;
    let selectedNaicsCode;

    if (options.find((option) => option.value === policyType)) {
      selectedPolicyType = options.find((option) => option.value === policyType)?.label;
    }
    if (Allstate.find((option) => option.value === state)) {
      selectedState = Allstate.find((option) => option.value === state)?.label;
    }
    if (naicsCodeOptions.find((option: any) => option.value === naicsCode)) {
      selectedNaicsCode = naicsCodeOptions.find((option: any) => option.value === naicsCode)?.label;
    }

    var token = CommonValues.GetToken();
    var data = {
      policyType: selectedPolicyType,
      state: selectedState,
      naicsCode: selectedNaicsCode,
      appetiteResponse: JSON.stringify(AppetiteResponse),
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/RateAppetite/availableappetite`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while inserting  rate risk appetite response ",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while inserting  rate risk appetite response",
            life: 3000,
          });
        }
      });
  };

  const onNaicsCodeSelect = (code: any) => {
    setNaicsCode(code.value);
    setNaicsCodeLabel(code.label);
    setNaicsCodeSearch("");
    setNaicsCodeError("");
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="appetite-main-page">
        <Header pagename="appetite-landing-page" />
        <div className="content-wrapper ">
          <BackgroundVideo/>
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <AppetiteMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <AppetiteSidebar pagename="appetite-landing-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row history-search-row d-flex  align-items-center text-12 ">
                  <div className={`col-3 ${policyTypeError.length > 0 ? " validation-div " : " "}`}>
                    <p className="text-14 fw-bold mb-1 ">
                      Policy Type <span className="text-danger "> *</span>
                    </p>
                    <Dropdown
                      type="text"
                      id="text"
                      className="finance-company-dropdown input-field"
                      placeholder="Select policy type"
                      value={policyType}
                      onChange={(e: any) => policyTypeDropdownClicked(e)}
                      options={options}
                    />
                  </div>
                  <div className={`col-3 ${stateError.length > 0 ? " validation-div " : " "}`}>
                    <p className="text-14 fw-bold mb-1 ">
                      State <span className="text-danger "> *</span>
                    </p>
                    <Dropdown
                      type="text"
                      id="text"
                      className="finance-company-dropdown input-field"
                      placeholder="Select state"
                      value={state}
                      onChange={(e: any) => stateDropdownClicked(e)}
                      options={Allstate}
                    />
                  </div>

                  <div className="col-3 position-relative  p-0">
                    <p className=" fw-bold mb-1 ">
                      NAICS Code <span className="text-danger "> *</span>
                    </p>

                    <div className={`search-bar flex-column  ${naicsCodeError.length > 0 ? " validation-div " : " "}`}>
                      <AtkTextField
                        type="text"
                        id="client-name"
                        className={` ${naicsCode.length > 0 ? "clientname-placeholder" : ""} input-field search-area w-100 `}
                        name="naicsCodeSearch"
                        onChange={(e) => {
                          NaicsCodeDropdownClicked(e.target.value);
                        }}
                        value={naicsCodeSearch}
                        placeholder={naicsCode.length > 0 ? naicsCodeLabel : "Search and select the NAICS Code"}
                      />
                    </div>
                    {naicsCodeSearch.replaceAll(" ", "").length > 0 && (
                      <div id="client-search" className=" position-absolute  ">
                        {naicsCodeLoading ? (
                          <div className="d-flex justify-content-center my-2 client-loader-div">
                            <AtkLoader />
                          </div>
                        ) : naicsCodeOptions.length == 0 ? (
                          <div style={{ padding: "5px", background: "white" }}>
                            <span style={{ color: "#ddd", marginLeft: "10px" }}>No naics code found</span>
                          </div>
                        ) : (
                          naicsCodeOptions.map((item: any) => (
                            <div
                              style={{ cursor: "pointer", width: "100%" }}
                              onClick={() => {
                                onNaicsCodeSelect(item);
                              }}
                            >
                              <a
                                href="#"
                                style={{
                                  color: "black",
                                  width: "100%",
                                  fontSize: "12px",
                                }}
                              >
                                {item.label}
                              </a>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                    {/* <span className="text-danger text-12">{naicsCodeError}</span> */}
                  </div>

                  <div className="col-3 d-flex justify-content-end">
                    <div className="col-2 "></div>
                    <div className="col-10 text-center">
                      {loading ? (
                        <AtkLoader />
                      ) : (
                        <AtkButton label="Get Risk Appetite" onClick={onNextButtonClick} className="pf-secondary-btn w-100 mt-3" />
                      )}
                    </div>
                  </div>
                  <div className="col-3 mt-1">
                    <span className="text-12 text-danger">{policyTypeError}</span>
                  </div>
                  <div className="col-3 mt-1">
                    <span className="text-12 text-danger">{stateError}</span>
                  </div>
                  <div className="col-3 p-0 mt-1">
                    <span className="text-12 text-danger">{naicsCodeError}</span>
                  </div>
                </div>
                {appetiteResponse.length > 0 ? (
                  <>
                    <div className="card mt-4 mx-3">
                      <div className="card-header">
                        <span className="text-18" style={{ color: "#8854BC" }}>
                          Available Risk Appetite
                        </span>
                      </div>
                      <div className="card-body">
                        <div className="row ms-1">
                          {appetiteResponse.map((response: any) => (
                            <div className="col-3 mb-3">
                              <img src={response.carrierLogoURL} className="appetite-img"/>
                              <span className="d-block mt-1 " style={{ color: "#00966E" }}>
                                {response.carrierName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 text-end mb-3">
                      <AtkButton label="Try Again" onClick={onTryAgainClick} className="pf-secondary-btn w-auto mt-5 mx-3" />

                      <AtkButton label="Start Quoting" onClick={onContinueWithRateClick} className="pf-secondary-btn w-auto mt-5 mx-3" />
                    </div>
                  </>
                ) : successStatus ? (
                  <div className="col-12 mt-5 w-50 mx-auto">
                    <AtkMessage messageText="No Risk Appetite is available" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
