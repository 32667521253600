import React from 'react'
import PolicySummaryIcon from "../../assets/images/PolicySummaryReport.svg";

export default function InvoiceScanMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="coi-scan-middle-header-col active ms-2">
          <img src={PolicySummaryIcon} className="landing-page-img" />
          <span className="middle-header-title">Invoice Scan</span>
        </div>
      </div>{" "}
    </div>
  )
} 
