import React, { FC, useEffect, useState, useRef } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import SelectClearIcon from "@atlaskit/icon/glyph/select-clear";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Logout } from "../common/utils";
import { Navigate, useNavigate } from "react-router-dom";

export default function Gigcode() {
  const [couponCode, setCouponCode] = useState("");
  const [ErrorMessageCouponCode, setErrorMessageCouponCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [continueloading, setcontinueloading] = useState(false);
  const [couponCodeLoading, setCouponCodeLoading] = useState(false);
  const toast: any = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getGigCode();
  }, []);

  const getGigCode = () => {
    let token = CommonValues.GetToken();
    setCouponCodeLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/couponcodebyuserid`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        setCouponCodeLoading(false);
        setCouponCode(response.data);
      })
      .catch((error: any) => {
        setCouponCodeLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating pfServiceId setup state";
          }
        } else {
          errorMessage = "Unknown error while updating pfServiceId setup state";
        }
        setErrorMessage(errorMessage);
      });
  };
  const checkValidation = (code: any) => {
    let valid = true;
    if (couponCode == "" || couponCode == null) {
      valid = false;
    }
    return valid;
  };

  const updateCouponCodeId = (code: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "Get",
      url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/code?gigCode=${code}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setcontinueloading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "GigCode Saved Successfully!", life: 3000 });
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while Get CouponDetails ByCode";
          }
        } else {
          errorMessage = "Unknown error while Get CouponDetails ByCode";
        }
        setErrorMessage(errorMessage);
        setcontinueloading(false);
      });
  };

  const onChangeGigCode = (e: any) => {
    setErrorMessageCouponCode("");
    if (e.target.value.length > 255) {
      return;
    }
    setCouponCode(e.target.value);
  };

  const handleClearClick = () => {
    setCouponCode("");
    setErrorMessageCouponCode("");
    setErrorMessage("");
  };

  const onContinueClick = (code: any) => {
    setErrorMessage("");
    setErrorMessageCouponCode("");
    setcontinueloading(true);
    if (code != null && code != "") {
      const regex = /[^a-zA-Z0-9]/;
      if (regex.test(code) === false) {
        if (checkValidation(code)) {
          var token = CommonValues.GetToken();

          var config = {
            method: "Get",
            url: `${process.env.REACT_APP_CompanyService_Url}/api/Coupon/code?gigCode=${code}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then((response: any) => {
              if (response.data === 1) {
                setcontinueloading(false);
                setErrorMessageCouponCode("");

                toast.current.show({ severity: "success", summary: 'Success', detail: "GigCode Saved Successfully!", life: 3000 });
              }
            })
            .catch((error: any) => {
              setcontinueloading(false);
              console.log(error);
              let errorMessage = "";
              if (error.response != null) {
                if (error.response.status == 401) {
                  CommonValues.Logout(navigate);
                } else if (error.response.status == 404) {
                  setErrorMessageCouponCode("Please enter valid gigcode");
                } else {
                  errorMessage = "Unknown error while Get CouponDetails ByCode";
                }
              }
              setErrorMessage(errorMessage);
            });
        }
      } else {
        setErrorMessageCouponCode("Please enter valid gigcode");
        setcontinueloading(false);
      }
    } else {
      updateCouponCodeId(code);
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onContinueClick(couponCode);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="row ams-header mx-auto ">
        <div className="col-12">
          <h4 className="text-20 fw-normal ">GIGCODE</h4>
        </div>
      </div>
      <div className="row mx-auto text-12 p-1  mb-2 ">
        {couponCodeLoading ? (
          <div className="d-flex justify-content-center align-items-center mb-2">
            <AtkLoader />
          </div>
        ) : (
          <>
            <div
              className={
                ErrorMessageCouponCode.length > 0
                  ? "col-8 form-group validation-div mt-2 d-flex align-items-center  "
                  : "col-8 form-group  d-flex align-items-center  mt-2"
              }
            >
              <AtkTextField
                type="text"
                id="myField"
                name="myField"
                className="input-field"
                onChange={onChangeGigCode}
                value={couponCode}
                elemAfterInput={
                  <span className="show-cursor" onClick={handleClearClick}>
                    {couponCode == "" ? null : <SelectClearIcon label="Pause" />}
                  </span>
                }
                onKeyDown={onEnterClick}
                placeholder="Enter gigcode"
              />
            </div>

            <div className="col-4 mt-2  d-flex align-items-center justify-content-center">
              {continueloading ? (
                <AtkLoader />
              ) : (
                <AtkButton label="Save" onClick={() => onContinueClick(couponCode)} className="pf-secondary-btn w-auto my-3" />
              )}
            </div>
            <span className="text-danger text-12 d-block ">{ErrorMessageCouponCode}</span>

            {errorMessage != "" && errorMessage != null ? (
              <div className="col-12  my-1">
                <AtkMessage appearance="error" messageText={errorMessage} />
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}
