import { useEffect, useRef, useState } from "react";

import NaicsSidebar from "./NaicsSidebar";
import NaicsMiddleHeader from "./NaicsMiddleHeader";
import { Toast } from "primereact/toast";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import feedbackImg from "../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../AtlaskitControls/AtkButton";
import PreviewIcon from "../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import BackgroundVideo from "../common/BackgroundVideo";

export default function NaicsHistory() {
  const navigate = useNavigate();
  const feedbackRef = useRef<OverlayPanel>(null);
  const toast: any = useRef("");
  const [naicsHistoryLoading, setNaicsHistoryLoading] = useState(true);
  const [naicsHistoryData, setNaicsHistoryData] = useState<any>([]);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [previewData, setPreviewData] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    getNaicsHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getNaicsHistory = () => {
    setNaicsHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/naics/getexistingdetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setNaicsHistoryData(response.data);
        setNaicsHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting naics history",
              life: 3000,
            });
          }
        }
        setNaicsHistoryLoading(false);
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };
  const actionTemplate = (rowData: any) => {
    return (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    );
  };
  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const previewStatusTemplate = (rowData: any) => {
    return rowData.Success === true ? (
      <b className="text-success"> Success</b>
    ) : rowData.ErrorMessege === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };
  const updatedTemplate = (rowData: any) => {
    return rowData.updatedInAms === true ? <b className="text-success"> Updated In AMS</b> : null;
  };
  const onPreviewClick = (rowData: any) => {
    setShowPopUp(true);
    if (rowData.response.length > 0) {
      let parsedResponse: any = "";
      try {
        const parsedResponse = JSON.parse(rowData.response);

        const enrichedCodeInfo = parsedResponse
          ? parsedResponse.CodeInfo.map((codeItem: any, index: number) => ({
              ...codeItem,
              Success: parsedResponse.Success,
              ErrorMessege: parsedResponse.ErrorMessege,
              SearchedText: rowData.searchText,
              updatedInAms: rowData.naicsIndex === index && rowData.updatedInAMS === true ? true : false,
            }))
          : null;
        setPreviewData(enrichedCodeInfo);
      } catch (error) {
        console.error("Error:", error);
        const enrichedCodeInfo = [
          {
            Success: false,
            ErrorMessege: "Failed",
            SearchedText: rowData.searchText,
          },
        ];
        setPreviewData(enrichedCodeInfo);
      }
    } else {
      const enrichedCodeInfo = [
        {
          Success: false,
          ErrorMessege: "Failed",
          SearchedText: rowData.searchText,
        },
      ];
      setPreviewData(enrichedCodeInfo);
    }
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length === 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setNaicsHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.id === rowData.id ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setNaicsHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.id === rowData.id ? { ...response, isLiked: liked } : response))
    );
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback === null || rowData.feedback === "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };
  const onSaveRatings = (rowData: any) => {
    const token = CommonValues.GetToken();
    let data = {
      Id: rowData.id,
      IsLiked: rowData.isLiked,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/naics/naicsratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback === null ? "" : rowData.feedback;
    let data = {
      Id: rowData.id,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/naics/naicsfeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="naics-landing-main-page">
        <Header pagename="naics-classification-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <NaicsMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <NaicsSidebar pagename="naics-classification-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-3 d-flex  align-items-end text-12 position-relative">
                  <div className="col-6 mb-2">
                    <span className="p-input-icon-left w-50">
                      <i className="fa fa-search grid-search-icon" />
                      <AtkTextField
                        type="text"
                        id="txtfirstName"
                        name="globalFilter"
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        value={globalFilter}
                        className="input-field"
                        placeholder="Search..."
                      />
                    </span>
                  </div>
                  <div className="col-6 text-end mb-2">
                    <AtkButton label="Refresh" onClick={getNaicsHistory} className=" pf-primary-btn w-auto" />
                  </div>
                  {naicsHistoryLoading ? (
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <div className="col-12  history-table">
                      <DataTable
                        value={naicsHistoryData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        dataKey="id"
                        globalFilter={globalFilter}
                      >
                        <Column header="Insured Name" field="insuredName" sortable></Column>
                        <Column header="Searched Text" field="searchText" sortable></Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          sortable
                        ></Column>
                        <Column header="Status" field="status" body={statusTemplate} sortable></Column>
                        <Column header="Action" body={actionTemplate} className="text-center" style={{ width: "10%" }}></Column>
                        <Column header="Feedback" body={feedbackTemplate} className="text-center"></Column>
                      </DataTable>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked === true ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked === false ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
          </div>
          <div className="col-4 text-end p-2  ">
            {/* {submissionId == feedBackData.submissionId && feedbackLoading ? ( */}
            {feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
            )}
          </div>
        </div>
      </OverlayPanel>
      <Dialog header="" visible={showPopUp} style={{ width: "80vw" }} onHide={onHidePopupClicked} draggable={false}>
        <div className="row">
          <div className="col-12 history-table">
            <b>Extracted Data</b>

            <div className="mt-2">
              <DataTable value={previewData} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={10}>
                <Column header="Searched Text" field="SearchedText" sortable></Column>
                <Column header="NAICS Code" field="NaicsCode" sortable></Column>
                <Column header="Description" field="Description" sortable></Column>
                <Column header="Status" body={previewStatusTemplate} field="Success" sortable></Column>
                <Column header="Action" body={updatedTemplate} sortable></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
