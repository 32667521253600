import React, { FC, useEffect, useState, useRef } from "react";
import Header from "../../../common/Header";
import MiddleHeader from "../../../common/MiddleHeader";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PfCompanies from "./PfCompanies";
import IpfsSetting from "./IpfsSetting";
import PeoplesSetting from "./PeoplesSetting";
import GotoSetting from "./GotoSetting";
import CapitalSetting from "./CapitalSetting";
import CommonValues, { Logout } from "../../../common/utils";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/images/Back Button.svg";
import USPFSettings from "./USPFSettings";
import FirstInsuranceSettings from "./FirstInsuranceSettings";
import { Toast } from "primereact/toast";
import { useLocation } from "react-router-dom";
import BackgroundVideo from "../../../common/BackgroundVideo";
const hostname = window.location.hostname;

interface IProps {}
export const PFSettings: React.FC<IProps> = () => {
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [setupGotoPFSetting, setSetupGotoPFSetting] = useState(false);
  const [setupCapitalPFSetting, setSetupCapitalPFSetting] = useState(false);
  const [setupIpfsPFSetting, setSetupIpfsPFSetting] = useState(false);
  const [setupPeoplesPFSetting, setSetupPeoplesPFSetting] = useState(false);
  const [setupFirstInsurancePFSetting, setSetupFirstInsurancePFSetting] = useState(false);
  const [setupUSPfSetting, setSetupUSPfSetting] = useState(false);
  const toast: any = useRef("");
  const [errorMessage, setErrorMessage] = useState("");
  const [preferredVendorList, setPreferredVendorList] = useState([]);
  const [gotoPremiumFinanceVendor, setGotoPremiumFinanceVendor] = useState(false);
  const [capitalPremiumFinanceVendor, setCapitalPremiumFinanceVendor] = useState(false);
  const [ipfsVendor, setIpfsVendor] = useState(false);
  const [peoplesPremiumFinanceVendor, setPeoplesPremiumFinanceVendor] = useState(false);
  const [usPremiumFinanceVendor, setUsPremiumFinanceVendor] = useState(false);
  const [firstInsurancePremiumFinanceVendor, setFirstInsurancePremiumFinanceVendor] = useState(false);
  const location = useLocation();
  const momentumEmbeded = location.state?.embeded || "";
  const navigate = useNavigate();
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getPremiumFinanceSettingsById();
    if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2 || hostname == process.env.REACT_APP_Momentum_Url3 || hostname == process.env.REACT_APP_Momentum_Url4) {
      getPreferredVendorListById();
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getPremiumFinanceSettingsById = () => {
    setPremiumLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const gotoPremiumFinance = response.data.find((item: any) => item.pfServiceId === 1);
        const capitalPremiumFinancing = response.data.find((item: any) => item.pfServiceId === 2);
        const ipfs = response.data.find((item: any) => item.pfServiceId === 4);
        const peoples = response.data.find((item: any) => item.pfServiceId === 5);
        const firstInsurance = response.data.find((item: any) => item.pfServiceId === 6);
        const USPfStting = response.data.find((item: any) => item.pfServiceId === 7);
        setSetupGotoPFSetting(!!gotoPremiumFinance);
        setSetupCapitalPFSetting(!!capitalPremiumFinancing);
        setSetupIpfsPFSetting(!!ipfs);
        setSetupPeoplesPFSetting(!!peoples);
        setSetupFirstInsurancePFSetting(!!firstInsurance);
        setSetupUSPfSetting(!!USPfStting);
        setPremiumLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            setErrorMessage("Unknown error while loading the Premium finance Setting details by id");
          }
        }
        setPremiumLoading(false);
      });
  };
  const getPreferredVendorListById = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setPreferredVendorList(response.data);
        let checkGotoPremiumFinance = false;
        let checkCapitalPremiumFinance = false;
        let checkIpfs = false;
        let checkPeoplesPremiumFinance = false;
        let checkFirstInsurancePremiumFinance = false;
        let checkUsPremiumFinance = false;

        response.data.forEach((vendor: any) => {
          switch (vendor.providerId) {
            case 1:
              checkGotoPremiumFinance = true;
              break;
            case 2:
              checkCapitalPremiumFinance = true;
              break;
            case 4:
              checkIpfs = true;
              break;
            case 5:
              checkPeoplesPremiumFinance = true;
              break;
            case 6:
              checkFirstInsurancePremiumFinance = true;
              break;
            case 7:
              checkUsPremiumFinance = true;
              break;
            default:
              break;
          }
        });

        setGotoPremiumFinanceVendor(checkGotoPremiumFinance);
        setCapitalPremiumFinanceVendor(checkCapitalPremiumFinance);
        setIpfsVendor(checkIpfs);
        setPeoplesPremiumFinanceVendor(checkPeoplesPremiumFinance);
        setFirstInsurancePremiumFinanceVendor(checkFirstInsurancePremiumFinance);
        setUsPremiumFinanceVendor(checkUsPremiumFinance);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the preferred vendor list by id" });
          }
        }
      });
  };

  const parentFunction = () => {
    getPremiumFinanceSettingsById();
  };
  const getVendorListFunction = () => {
    getPreferredVendorListById();
  };
  return (
    <div className="pf-settings-main-page">
      {momentumEmbeded == "true" ? null : <Header  pagename="PfSettingpage" />}
      <div className="content-wrapper ">
        <BackgroundVideo/>
        <div className="row  wrapper-main-row mx-auto wrapper-below-div ">
          <div className="col-12">
            <MiddleHeader />
          </div>
          <div className="row main-content-row mx-auto">
            {momentumEmbeded == "true" ? null : (
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <Sidebar pagename="PfSettingpage" />
              </div>
            )}
            <div
              className={ 
                momentumEmbeded == "true" ? "col-lg-12 col-md-12 col-sm-12 main-content-col" : "col-lg-10 col-md-12 col-sm-12 main-content-col"
              }
            >
              <div className="row history-search-row d-flex  align-items-center text-12 position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex  justify-content-center " style={{ height: "20px" }}>
                  {premiumLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <AtkLoader></AtkLoader>{" "}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mx-auto mt-2">
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 d-flex">
                    <div className=" pf-company-col mt-3">
                      <PfCompanies parentFunction={parentFunction} />
                    </div>
                  </div>
                </>

                <div className="col-lg-9 col-md-6 col-sm-12 p-0">
                  <div className="row">
                    {setupGotoPFSetting ? (
                      <div className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media">
                        <GotoSetting gotoPremiumFinanceVendor={gotoPremiumFinanceVendor} getVendorListFunction={getVendorListFunction} />
                      </div>
                    ) : null}

                    {setupIpfsPFSetting ? (
                      <div className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media">
                        {" "}
                        <IpfsSetting ipfsVendor={ipfsVendor} getVendorListFunction={getVendorListFunction} />{" "}
                      </div>
                    ) : null}
                    {setupPeoplesPFSetting ? (
                      <div className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media">
                        {" "}
                        <PeoplesSetting
                          peoplesPremiumFinanceVendor={peoplesPremiumFinanceVendor}
                          getVendorListFunction={getVendorListFunction}
                        />{" "}
                      </div>
                    ) : null}
                    {setupCapitalPFSetting ? (
                      <div className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media">
                        {" "}
                        <CapitalSetting
                          capitalPremiumFinanceVendor={capitalPremiumFinanceVendor}
                          getVendorListFunction={getVendorListFunction}
                        />{" "}
                      </div>
                    ) : null}
                    {setupUSPfSetting ? (
                      <div className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media">
                        {" "}
                        <USPFSettings usPremiumFinanceVendor={usPremiumFinanceVendor} getVendorListFunction={getVendorListFunction} />{" "}
                      </div>
                    ) : null}

                    {setupFirstInsurancePFSetting ? (
                      <div className="col-lg-4 col-md-12 col-sm-12 mt-3 setting-width-for-media">
                        {" "}
                        <FirstInsuranceSettings
                          firstInsurancePremiumFinanceVendor={firstInsurancePremiumFinanceVendor}
                          getVendorListFunction={getVendorListFunction}
                        />{" "}
                      </div>
                    ) : null}

                    <div className="col-12  mt-2 ">
                      {errorMessage != "" && errorMessage != null ? <AtkMessage appearance="error" messageText={errorMessage} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {momentumEmbeded == "true" ? null : <Footer />} */}
    </div>
  );
};
export default PFSettings;
