import React from "react";
import { useNavigate } from "react-router-dom";
import { Guid } from "js-guid";

export default function AIMenuFooter(props: any) {
  const navigate = useNavigate();

  const onSupportAssistantClick = () => {
    navigate(`/ai-support-assistant/${Guid.newGuid()}`);
  };
  const onAiPoweredScansClick = () => {
    navigate(`/ai-scans/${Guid.newGuid()}`);
  };
  const onDataDiscoveryClick = () => {
    navigate("/datadiscovery");
  };
  const onReportAssistantClick = () => {
    //navigate("/report-assistant");
    window.open(`${window.location.ancestorOrigins[0]}/AMSINS/Integrations/Informer/Assistant`, "_blank");
  };
  const onCallAssistantClick = () => {
    navigate(`/ai-call-assistant/${Guid.newGuid()}`);
  };
  const onCOIAssistantClick = () => {
    navigate(`/ai-coi-assistant/${Guid.newGuid()}`);
  };
  return (
    <>
    <span className="info-msg">AI can make mistakes. Check important info.</span>

    <div className="d-flex justify-content-center mt-2">
      <div className="p-1 d-inline-flex" style={{ width: "auto" }}>
        <button
          className={`btn fw-bold text-dark ai-border-radius px-3 py-2 btn-outline-secondary text-12 ai-new-conversion-btn me-2 ${
            props.pagename == "ai-support-assistant" ? "active" : ""
          }`}
          onClick={onSupportAssistantClick}
        >
          <span className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-headphones me-2"
            >
              <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
            </svg>
            Support Assistant
          </span>
        </button>
        <button
          className={`btn fw-bold text-dark ai-border-radius px-3 py-2 btn-outline-secondary text-12 ai-new-conversion-btn me-2 ${
            props.pagename == "ai-scans" ? "active" : ""
          }`}
          onClick={onAiPoweredScansClick}
        >
          <span className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-search me-2"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <path d="m21 21-4.3-4.3"></path>
            </svg>{" "}
            AI-Powered Scans
          </span>
        </button>
        <button
          className={`btn fw-bold text-dark ai-border-radius px-3 py-2 btn-outline-secondary text-12 ai-new-conversion-btn me-2 ${
            props.pagename == "ai-call-assistant" ? "active" : ""
          }`}
          onClick={onCallAssistantClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-search me-2"
          >
            <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z"></path>
          </svg>{" "}
          Calls by AI Agent
        </button>
        <button
          className={`btn fw-bold text-dark ai-border-radius px-3 py-2 btn-outline-secondary text-12 ai-new-conversion-btn me-2 ${
            props.pagename == "ai-coi-assistant" ? "active" : ""
          }`}
          onClick={onCOIAssistantClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-search me-2"
          >
            <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
          </svg>{" "}
          COI Assistance
        </button>
        {/* <button
          className={`btn rounded-pill px-3 py-2 btn-outline-secondary text-12 ai-new-conversion-btn me-2 ${
            props.pagename == "ai-data-discovery" ? "active" : ""
          }`}
          onClick={onDataDiscoveryClick}
        >
          <i className="fa fa-database  me-1"></i> Data Discovery
        </button> */}
        <button
          className={`btn fw-bold text-dark ai-border-radius px-3 py-2 btn-outline-secondary text-12 ai-new-conversion-btn me-2 ${
            props.pagename == "ai-report-assistant" ? "active" : ""
          }`}
          onClick={onReportAssistantClick}
        >
          <span className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-shield me-2"
            >
              <line x1="12" x2="12" y1="20" y2="10" />
              <line x1="18" x2="18" y1="20" y2="4" />
              <line x1="6" x2="6" y1="20" y2="16" />
            </svg>{" "}
            Report Assistant
          </span>
        </button>
      </div>
    </div>
    </>
  );
}