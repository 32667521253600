import { useNavigate } from "react-router-dom";
import HistoryIcon from "../../assets/images/History_B.svg";
import NewIcon from "../../assets/images/newquote.png";
import { Guid } from "js-guid";

export default function HistorySidebar(props: any) {
  const navigate = useNavigate();

  const onQuoteProposalClick = () => {
    navigate(`/talk-to-ams/${Guid.newGuid()}`);
  };
  const onQuoteProposalHistoryClick = () => {
    navigate("/talk-to-ams-history");
  };

  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "talk-to-ams" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onQuoteProposalClick}
        >
          <img src={NewIcon} className={props.pagename == "talk-to-ams" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">Start New Conversation</span>{" "}
        </span>

        <span
          className={props.pagename == "talk-to-ams-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onQuoteProposalHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename == "talk-to-ams-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
