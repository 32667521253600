import { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import AtkTextField from "../AtlaskitControls/AtkTextField";

import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Toast } from "primereact/toast";
import groupImg from ".././assets/images/Group.png";
import axios from "axios";
import BackgroundVideo from "../common/BackgroundVideo";
import { useNavigate } from "react-router-dom";

export default function RequestDemo() {
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [workEmailError, setWorkEmailError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showRequestDemoSuccessMessage, setShowRequestDemoSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const toast: any = useRef("");
  const hostname = window.location.hostname;
  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  const onCompanyNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }

    setCompanyName(value);

    if (!value.trim()) {
      setCompanyNameError("Please enter company name");
    } else {
      setCompanyNameError("");
    }
  };
  const onFirstNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }
    setFirstName(value);
    if (!value.trim()) {
      setFirstNameError("Please enter first name");
    } else {
      setFirstNameError("");
    }
  };
  const onLastNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }
    setLastName(value);
    if (!value.trim()) {
      setLastNameError("Please enter last name");
    } else {
      setLastNameError("");
    }
  };
  const onWorkemailChange = (e: any) => {
    const value = e.target.value;
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (value.length > 255) {
      return;
    }

    setWorkEmail(value);
    if (!value.trim()) {
      setWorkEmailError("Please enter work email");
    } else if (regex.test(value) === false) {
      setWorkEmailError("Please enter a valid work email");
    } else {
      setWorkEmailError("");
    }
  };
  const onContactNumberChange = (e: any) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (re.test(value)) {
      if (value.length > 10) {
        return;
      }
      setContactNumber(e.target.value);
    }
    if (value != null && value != "" && re.test(value)) {
      if (value.length < 10 || value.length > 10) {
        setContactNumberError("Please enter valid contact number");
      } else {
        setContactNumberError("");
      }
    } else {
      setContactNumberError("");
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode === 13) {
      //   onRegisterClick();
    }
  };
  const checkValidation = () => {
    let returnValue = true;
    setCompanyNameError("");
    setFirstNameError("");
    setLastNameError("");
    setWorkEmailError("");
    setContactNumberError("");

    if (companyName === null || companyName === "") {
      setCompanyNameError("Please enter company name");
      returnValue = false;
    }
    if (firstName === null || firstName === "") {
      setFirstNameError("Please enter first name");
      returnValue = false;
    }

    if (lastName === null || lastName === "") {
      setLastNameError("Please enter last name");
      returnValue = false;
    }
    if (workEmail === null || workEmail === "") {
      setWorkEmailError("Please enter work email");
      returnValue = false;
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(workEmail) === false) {
        setWorkEmailError("Please enter a valid work email");
        returnValue = false;
      }
    }
    if (contactNumber != null && contactNumber != "") {
      if (contactNumber.length < 10 || contactNumber.length > 10) {
        setContactNumberError("Please enter valid contact number");
        returnValue = false;
      }
    }

    return returnValue;
  };
  const onRequestDemoSubmitClick = () => {
    if (checkValidation()) {
      setSubmitLoading(true);
      var data = JSON.stringify({
        FirstName: firstName,
        LastName: lastName,
        WorkEmail: workEmail,
        PhoneNumber: contactNumber,
        CompanyName: companyName,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_CompanyService_Url}/api/company/insertrequestinfo`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          setSubmitLoading(false);
          setShowRequestDemoSuccessMessage(true);
          toast.current.show({ severity: "success", summary: "Success", detail: "Request demo details submitted successfully...!!!", life: 3000 });
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
          toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting the request demo details.", life: 3000 });
        });
    }
  };
  return (
    <div className="request-demo-page">
      <Toast ref={toast} />

      <Header pagename="request-demo" />

      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <BackgroundVideo />
        <div className="register-box">
          <div className="row register-header mx-auto p-3">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-12   d-flex  align-items-center p-0">
                  <img src={groupImg} className="me-2" />
                  <span className="text-16">
                    <b>Request Demo</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {showRequestDemoSuccessMessage ? (
            <div className="row">
              <div className="col-12">
                <div className="text-center p-4">
                  <h3>Thank you for submitting!</h3>
                  <p className="text-16">We will be in touch with you shortly to set up a time for the demo.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mx-auto d-flex align-items-center px-3 py-4">
              <div className=" col-4 text-start">
                <label>
                  Company Name<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={companyNameError.length > 0 ? "col-8  validation-div" : "col-8 "}>
                <AtkTextField
                  type="text"
                  id="txtCompanyName"
                  name="companyName"
                  onChange={onCompanyNameChange}
                  value={companyName}
                  className="input-field"
                  placeholder="Enter company name"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{companyNameError}</span>
              </div>

              <div className=" col-4 text-start mt-3">
                <label>
                  First Name<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={firstNameError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtfirstName"
                  name="firstName"
                  onChange={onFirstNameChange}
                  value={firstName}
                  className="input-field"
                  placeholder="Enter first name"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{firstNameError}</span>
              </div>
              <div className=" col-4 text-start mt-3">
                <label>
                  Last Name<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={lastNameError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtLastName"
                  name="lastName"
                  onChange={onLastNameChange}
                  value={lastName}
                  className="input-field"
                  placeholder="Enter last name"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{lastNameError}</span>
              </div>
              <div className=" col-4 text-start mt-3">
                <label>
                  Work Email<span className="text-danger "> *</span>{" "}
                </label>
              </div>
              <div className={workEmailError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtWorkEmail"
                  name="workEmail"
                  onChange={onWorkemailChange}
                  value={workEmail}
                  className="input-field"
                  placeholder="Enter work email"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{workEmailError}</span>
              </div>
              <div className=" col-4 text-start mt-3 ">
                <label>Contact Number</label>
              </div>
              <div className={contactNumberError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                <AtkTextField
                  type="text"
                  id="txtContact"
                  name="contactNumber"
                  onChange={onContactNumberChange}
                  value={contactNumber}
                  className="input-field"
                  placeholder="Enter contact number"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className=" col-4 text-start "></div>
              <div className="col-8 ">
                <span className="text-danger text-12 ">{contactNumberError}</span>
              </div>

              <div className="col-12 text-center my-3">
                {submitLoading ? (
                  <div className="p-1">
                    <AtkLoader />{" "}
                  </div>
                ) : (
                  <AtkButton label="Submit" onClick={onRequestDemoSubmitClick} className="pf-primary-btn w-auto" />
                )}
              </div>
            </div>
          )}
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
}
