import React, { FC, useEffect, useState } from "react";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import CommonValues from "../../common/utils";
import ResponseButton from "./ResponseButton";
import ResponseTable from "./ResponseTable";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FinalQuotes from "./FinalQuotes";
import { Skeleton } from 'primereact/skeleton';
import axios from "axios";

export default function UsPremiumFinance(props: any) {
  const [usPfLoading, setUsPfLoading] = useState(false);
  const [usPfShowResponse, setUsPfShowResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [saveQuoteErrorMessage, setSaveQuoteErrorMessage] = useState("");
  const [saveQuoteSuccessMessage, setSaveQuoteSuccessMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [rowData, setRowData] = useState({});
  const [usPfPremiumFinanceResponse, setUsPfPremiumFinanceResponse] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }

    usPfSubmitClick();
  }, []);

  const usPfSubmitClick = () => {
    let subPolicies: any = [];
    // let term = "";
    // term = props.term.toString();
    // if (term.includes("M")) {
    //   term = term.replace("M", "");
    // }
    Array.from(props.coverageCode).forEach((code: any, index: any) => {
      subPolicies.push({
        number: props.policyNumber[index],
        coverageCode: code.uspfCode == null ? "*" : code.uspfCode,
        policyId: props.nCPolicyId,
        effectiveDate: moment(props.effectiveDate[index]).format("YYYY-MM-DD"),
        expirationDate: "",
        term: props.term[index],
        BrokerFee: props.brokerFee[index] == null || props.brokerFee[index] === "" ? "0" : props.brokerFee[index],
        premium: props.currentPremiumsArray[index] ? props.currentPremiumsArray[index].toString() : 0,
        earnedTaxesFees: "0",
        financedTaxesFees: "0",
        otherFees:
          props.fees[index] != undefined ? props.fees[index].toString() : "0",
        otherTaxes:
          props.taxes[index] != undefined ? props.taxes[index].toString() : "0",
          minimumEarnedPercent: props.minimumEarned[index] != undefined ? props.minimumEarned[index] : "0",
        policyType: "commercial",
        index: props.selectedPolicyIndex ? props.selectedPolicyIndex : 0,
        carrier: {
          id: props.carrier[index].uspfCode,
          name: props.carrier[index].description,
          address: {
            address1: props.carrier[index].address1,
            address2: "",
            city: props.carrier[index].city,
            state: props.carrier[index].state,
            zip: props.carrier[index].zipCode,
            country: props.carrierCountry,
            unit: "",
          },
        },
        generalAgency:{
          name: props.gaName[index],
          searchCode:props.gaSearchCode[index],
          address: {
            address1: props.gaAddress[index],
            address2: "",
            city: props.gaCity[index],
            state: props.gaState[index],
            zip: props.gaZip[index],
            country: props.gaCountry[index],
            unit: "",
          },
        }
      });
    });

    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      target: "USPF",
      data: {
        ClientName: props.firstName + " " + props.lastName,
        ClientId: props.clientId.toString(),
        IsIndicative: localStorage.getItem("IsIndicative"),        
        insured: {
          insuredId: props.nCInsuredId,
          name: props.businessProfile,
          dbaName: "",
          naics: "",
          phoneNumber:
            props.businessPhoneNumber != null ? props.businessPhoneNumber : "",
          email: props.businessEmail,
          address: {
            address1: props.addLine1,
            address2: props.addLine2,
            city: props.city,
            state: props.sstate,
            zip: props.zipCode,
            country: props.country,
            unit: "",
          },
        },
        agent: {
          id: "",
          firstName: props.agentName,
          lastName: "",
          email: "",
          phoneNumber: props.agentPhoneNumber,
          address: {
            address1: props.agentAddress,
            address2: "",
            city: props.agentCity,
            state: props.agentState,
            zip: props.agentZip,
            country: props.agentCountry,
            unit: "",
          },
        },
        policy: subPolicies,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/quote`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setUsPfLoading(true);
    axios(config)
      .then((response: any) => {
        window.scroll(0, 0);
        setUsPfPremiumFinanceResponse(response.data);
        if (
          response.data.quoteNumber == null ||
          response.data.quoteNumber == "" ||
          response.data.quoteNumber == "0" ||
          response.status == 204
        ) {

            setUsPfShowResponse(true);
          setResponseMessage(response.data.message);
        } else {
            setUsPfShowResponse(false);
        }
        let responseData = {
            transactionId: response.data.transactionId,
            url: "",
            provider: "US Premium Finance",
            targetPartner: "US Premium Finance",
            isSaved: false,
            quoteNumber: response.data.quoteNumber,
            ncPolicyNumber: props.ncPolicyNumber,
            policyId: props.nCPolicyId,
            insuredId: props.nCInsuredId,
            policyNumber: props.policyNumber,
            clientId: props.clientId,
            selectedPolicyIndex: props.selectedPolicyIndex,
            responseMessage: response.data.message,
            premiumresponse: response.data,
            tenantId:localStorage.getItem("tenantId")
          };
        setRowData(responseData);
        setUsPfLoading(false);
        setSuccessMessage("Details submitted successfully..");
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage =
              "Unknown error while submitting the details for US Premium Finance";
          }
        } else {
          errorMessage =
            "Unknown error while submitting  details for US Premium Finance";
        }
        setUsPfShowResponse(true);
        setUsPfLoading(false);
        setErrorMessage(errorMessage);
        setResponseMessage(errorMessage);
      });
  };
  return  <>
  <div className=" quote-response-block">
  <h6 className="quote-heading mb-0">
      <img src={props.usPfLogoUrl} width="25" height="25" /> US Premium Finance Quote
    </h6>
    <div className="quote-middle-block">
      {usPfLoading ? (
       
        <Skeleton width="100%" height="15rem"></Skeleton>
      ) : (
        <>
          {usPfShowResponse ? (
            <div className="my-2 p-2">
              <AtkMessage appearance="warning" messageText={responseMessage} />
            </div>
          ) : (
            <>
              {/* <div className=""> */}
              <FinalQuotes premiumresponse={usPfPremiumFinanceResponse} />
              <div className="response-btn-div  ">
                <ResponseButton rowData={rowData} />
              </div>
              {/* </div> */}
            </>
          )}
        </>
      )}

      {saveQuoteErrorMessage.length > 0 ? (
        <div className="my-2 p-2">
          <AtkMessage appearance="error" messageText={saveQuoteErrorMessage} />
        </div>
      ) : null}
      {saveQuoteSuccessMessage.length > 0 ? (
        <div className="my-2 p-2">
          <AtkMessage appearance="success" messageText={saveQuoteSuccessMessage} />
        </div>
      ) : null}
    </div>
  </div>
</>
}
