import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import PreviewIcon from "../assets/images/ViewDetailsIcon.png";
import feedbackImg from "../assets/images/feedback.svg";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import PolicyComparisonMiddleHeader from "./PolicyComparisonMiddleHeader";
import PolicyComparisonSidebar from "./PolicyComparisonSidebar";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import BackgroundVideo from "../common/BackgroundVideo";
import { marked } from "marked";

export default function PolicyComparisonHistory() {
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [historyloading, setHistoryLoading] = useState(false);
  const [historyData, setHistoryData] = useState<any>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [previewResponse, setPreviewResponse] = useState("");
  const navigate = useNavigate();
  const [headerName, setHeaderName] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    getHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (showPopUp) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [showPopUp]);
  const getHistory = () => {
    const token = CommonValues.GetToken();
    setHistoryLoading(true);

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/policycomparisonhistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        setHistoryLoading(false);
        setHistoryData(response.data);
      })
      .catch((error) => {
        setHistoryLoading(false);

        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting history details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting history details",
            life: 3000,
          });
        }
      });
  };
  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const previewTemplate = (rowData: any) => {
    return (
      <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData.response, rowData.action)} title="Preview" className="show-cursor" />
    );
  };

  const onPreviewClick = (data: any, action: any) => {
    let name = action == null ? "Results" : action + " Results";
    setHeaderName(name);
    setShowPopUp(true);
    setPreviewResponse(data);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPreviewResponse("");
    setHeaderName("");
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };
  const onSaveRatings = (rowData: any) => {
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      IsLiked: rowData.isLiked,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/policycomparisonratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Feedback submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting ratings",
              life: 3000,
            });
          }
        }
      });
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/policycomparisonfeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Feedback Submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting feedback",
              life: 3000,
            });
          }
        }
      });
  };

  const CopyToClipBoardClicked = async () => {
    const htmlContent = await marked(previewResponse); // Ensure it resolves to a string
    const styledHtmlContent = applyInlineStylesToTable(htmlContent); // Apply styles to table
    const plainTextContent = htmlToPlainTextWithFormatting(htmlContent);

    const htmlBlob = new Blob([styledHtmlContent], { type: "text/html" });
    const textBlob = new Blob([plainTextContent], { type: "text/plain" });

    const clipboardItem = new ClipboardItem({
      "text/plain": textBlob,
      "text/html": htmlBlob,
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Copied Successfully!",
          life: 3000,
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error occurred while copying data",
          life: 3000,
        });
      });
  };

  const applyInlineStylesToTable = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const tables = tempDiv.querySelectorAll("table");
    tables.forEach((table) => {
      table.setAttribute("style", "border-collapse: collapse; width: 100%; font-family: Arial, sans-serif;");

      const theads = table.querySelectorAll("thead");
      theads.forEach((thead) => {
        thead.setAttribute("style", "background-color: #e5e4e2;");
      });

      const ths = table.querySelectorAll("th");
      ths.forEach((th) => {
        th.setAttribute("style", "border: 2px solid #e3e3e3 !important; padding-left: 9px !important; padding-right: 9px !important;");
      });

      const tds = table.querySelectorAll("td");
      tds.forEach((td) => {
        td.setAttribute("style", "border: 1px solid #e3e3e3 !important; padding-left: 10px !important; padding-right: 10px !important;");
      });
    });

    return tempDiv.innerHTML;
  };

  const htmlToPlainTextWithFormatting = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const elementsToReplace = tempDiv.querySelectorAll("br, p, h1, h2, h3, h4, h5, h6");
    elementsToReplace.forEach((element: any) => {
      const lineBreak = document.createTextNode("\n");
      element.parentNode.insertBefore(lineBreak, element);
    });

    return tempDiv.textContent || tempDiv.innerText || "";
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="naics-landing-main-page">
        <Header pagename="naics-landing-page" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <PolicyComparisonMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <PolicyComparisonSidebar pagename="policycompare-page-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    <div className="row mb-3">
                      <div className="col-6">
                        <span className="p-input-icon-left w-50">
                          <i className="fa fa-search grid-search-icon" />
                          <AtkTextField
                            type="text"
                            id="txtfirstName"
                            name="globalFilter"
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            value={globalFilter}
                            className="input-field"
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                      <div className="col-6 text-end">
                        <AtkButton label="Refresh" onClick={getHistory} className=" pf-primary-btn w-auto" />
                      </div>
                    </div>
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable
                        value={historyData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        globalFilter={globalFilter}
                        emptyMessage="No data available."
                      >
                        <Column header="First File Name" field="firstFileName" sortable></Column>
                        <Column header="Second File Name" field="secondFileName" sortable>
                          {" "}
                        </Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          sortable
                        ></Column>
                        <Column header="Answer" body={previewTemplate} className="text-center"></Column>

                        <Column header="Action" field="action" style={{ width: "10%" }}></Column>
                        <Column header="Feedback" body={feedbackTemplate} className="text-center"></Column>
                      </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>{headerName}</span>
            <i className="fa fa-copy show-cursor me-2" aria-hidden="true" title="Copy" onClick={CopyToClipBoardClicked}></i>
          </div>
        }
        visible={showPopUp}
        className=""
        style={{ width: "80vw" }}
        onHide={onHidePopupClicked}
        draggable={false}
      >
        <div className="row ">
          <div className="col-12 card mt-1 p-3 text-12 dialog-content">
            <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} className="answer-block ">
              {previewResponse}
            </ReactMarkdown>
          </div>
        </div>
      </Dialog>
      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
          </div>
          <div className="col-4 text-end p-2  ">
            {/* {submissionId == feedBackData.submissionId && feedbackLoading ? ( */}
            {feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
            )}
          </div>
        </div>
      </OverlayPanel>
      {/* <Footer /> */}
    </>
  );
}
