import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import Header from '../common/Header';
import BackgroundVideo from "../common/BackgroundVideo";
import { useNavigate } from "react-router-dom";
import CommonValues from "../common/utils";
import axios from "axios";

export default function FinchRedirectPage() {
    const toast: any = useRef("");
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const codeParam = queryParams.get("code");
        if (codeParam) {
            createAccessToken(codeParam);
        }
    }, []);

    const createAccessToken = (code : any) =>{
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_DataFabric_Url}/api/finch/accesstoken?code=${code}`,
          headers: {
          },
        };
    
        axios(config)
          .then((response: any) => {
          })
          .catch((error: any) => {
            if (error.response !== null) {
              if (error.response.status === 401) {
                CommonValues.Logout(navigate);
              } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while getting finch details", life: 3000 });
              }
            }
          });
    }
    
    return (
        <>
            <Toast ref={toast} />
            <div className="commission-scan-main-page">
                <Header pagename="finch-redirect" />
                <div className="content-wrapper">
                    <BackgroundVideo />
                    <div className="login-box d-flex justify-content-center" style={{ minHeight: "50vh" }}>
                        <div className="row ">
                            <div className="col-12 text-center ">
                                <div className="">
                                    <img src="https://mintlify.s3.us-west-1.amazonaws.com/finch/logo/logo-black.svg" className="" width="35%" />
                                </div>
                                <div className="mt-5">
                                <i className="fa fa-check finch-success-icon"/>
                                </div>
                                <div className="mt-3">
                                    <span className="text-22">Success!</span>
                                </div>
                                <div className="">
                                    <span className="text-16">Your account has been successfully linked to momentumamp</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
