import { useRef, useState } from "react";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import SectionMessage from "@atlaskit/section-message";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import CommonValues from "../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function GotoPfSettings() {
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [input1Loading, setInput1Loading] = useState(false);
  const [gotoSetupPassword, setGotoSetupPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const [portfolioCode, setPortfolioCode] = useState("");
  const [deleteInput1Loading, setDeleteInput1Loading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [preferredVendorLoading, setPreferredVendorLoading] = useState(false);
  const [gotoPremiumFinanceVendor, setGotoPremiumFinanceVendor] = useState(false);
  const hostname = window.location.hostname;

  const getPreferredVendorListById = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let checkGotoPremiumFinance = false;

        response.data.forEach((vendor: any) => {
          switch (vendor.providerId) {
            case 1:
              checkGotoPremiumFinance = true;
              break;

            default:
              break;
          }
        });

        setGotoPremiumFinanceVendor(checkGotoPremiumFinance);
        setPreferredVendorLoading(false);
      })
      .catch((error: any) => {
        setPreferredVendorLoading(false);

        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the preferred vendor list by id" });
          }
        }
      });
  };

  const onKeyUpdateInput1Click = (e: any) => {
    if (e.keyCode === 13) {
      onUpdateInput1Click();
    }
  };
  const onShowPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setGotoSetupPassword(e.target.value);
  };
  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUsername(e.target.value);
  };

  const onRegisterClick = () => {
    window.open(
      "https://www.gotopremiumfinance.com/Portals/0/Documents/gotoPremiumFinance-Broker-Application.pdf?ver=2020-10-21-132653-357",
      "_blank"
    );
  };
  const onLoginClick = () => {
    getGotopfDetailsForLogin();
  };
  const getGotopfDetailsForLogin = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/gotopfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        if (error.response !== null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
        }

        setLoginLoading(false);
      });
  };
  const input1CheckValidation = () => {
    let formIsValid = true;
    setErrorMessagePassword("");
    setErrorMessageUsername("");

    if (gotoSetupPassword === "" || gotoSetupPassword === null) {
      formIsValid = false;
      setErrorMessagePassword("Please enter password");
    }

    if (username === "" || username === null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter user name");
    }

    return formIsValid;
  };
  const getInput1Creds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status === 204) {
          setGotoSetupPassword("*********");
          setUsername("*********");
          setPortfolioCode("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setLoading(false);
          setGotoSetupPassword(response.data.password);
          setUsername(response.data.username);
          setPortfolioCode(response.data.portfolioCode);
          if (
            response.data.username !== null &&
            response.data.username !== "" &&
            response.data.userType !== null &&
            response.data.userType !== "" &&
            response.data.portfolioCode !== null &&
            response.data.portfolioCode !== "" &&
            response.data.accountType !== null &&
            response.data.accountType !== "" &&
            response.data.password === ""
          ) {
            setGotoSetupPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response !== null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the gotoPremiumFinance Setting details" });
        }

        setLoading(false);
      });
  };
  const onDeleteInput1Click = () => {
    setDeleteInput1Loading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        toast.current.show({ severity: "success", summary: 'Success', detail: "gotoPremiumFinance Settings deleted successfully", life: 3000 });
        setGotoSetupPassword("");
        setUsername("");
        setDeleteInput1Loading(false);
        getInput1Creds();
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response !== null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the gotoPremiumFinance Settings" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the gotoPremiumFinance Settings" });
        }
        setDeleteInput1Loading(false);
      });
  };
  const onUpdateInput1Click = () => {
    if (input1CheckValidation()) {
      setInput1Loading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Password: gotoSetupPassword,
        Username: username,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/input1creds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          toast.current.show({ severity: "success", summary: 'Success', detail: "gotoPremiumFinance Settings updated successfully", life: 3000 });
          setInput1Loading(false);
          getInput1Creds();
        })
        .catch((error: any) => {
          console.log(error);
          if (error.response !== null) {
            if (error.response.status === 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status === 409) {
              toast.current.show({
                severity: "error", summary: 'Error',
                detail: "It look likes you are not registered with gotoPremiumFinance, Please register first.",
              });
            } else {
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the gotoPremiumFinance Settings" });
            }
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the gotoPremiumFinance Settings" });
          }
          setInput1Loading(false);
        });
    }
  };
  const insertPreferredVendor = (vendorId: any, isChecked: any) => {
    setPreferredVendorLoading(true);
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      ProviderId: vendorId,
      IsChecked: !isChecked,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        // toast.current.show({ severity: "success", summary: 'Success', detail: "Capital Settings updated successfully", life: 3000 });
        getPreferredVendorListById();
      })
      .catch((error: any) => {
        console.log(error);
        setPreferredVendorLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the preferred vendor" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the preferred vendor" });
        }
      });
  };
  return (
    <div className="row mx-auto mt-3 d-flex align-items-center setting-setup-width">
      <Toast ref={toast} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <AtkLoader />
        </div>
      ) : (
        <>
          <div className="form-group col-4 mb-2 ">
            <label className="form-label">
              User Name
              <span className="text-danger"> *</span>
            </label>
          </div>
          <div className={errorMessageUsername.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              type="text"
              className="input-field"
              id="username"
              name="username"
              onChange={onUsernameChange}
              value={username}
              onKeyDown={onKeyUpdateInput1Click}
              placeholder="Enter user name"
            />
            <span className="text-danger text-12">{errorMessageUsername}</span>
          </div>
          <div className="form-group col-4 ">
            <label className="form-label">
              Password
              <span className="text-danger"> *</span>
            </label>
          </div>
          <div className={errorMessagePassword.length > 0 ? " col-8   validation-div  position-relative" : " col-8  position-relative"}>
            <AtkTextField
              className="input-field"
              type={passwordType}
              id="password"
              name="gotoSetupPassword"
              onChange={onPasswordChange}
              value={gotoSetupPassword}
              onKeyDown={onKeyUpdateInput1Click}
              placeholder="Enter password"
              autoComplete="new-password"
            />
            <span className="eye-icon" onClick={onShowPassword}>
              {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
            </span>
          </div>
          <div className="form-group col-4 mb-2"></div>
          <div className=" col-8 mb-2">
            <span className="text-danger text-12">{errorMessagePassword}</span>
          </div>
          {hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2 || hostname == process.env.REACT_APP_Momentum_Url3 || hostname == process.env.REACT_APP_Momentum_Url4 ? (
            <div className="col-12 mb-2 p-2">
              <label className="d-flex align-items-center">
                {preferredVendorLoading ? (
                  <AtkLoader />
                ) : (
                  <input
                    type="checkbox"
                    value=""
                    checked={gotoPremiumFinanceVendor}
                    onChange={() => insertPreferredVendor(1, gotoPremiumFinanceVendor)}
                    className="show-cursor bundle-checkbox "
                  />
                )}
                <span className="ms-2">Select preferred vendor</span>
              </label>
            </div>
          ) : null}
          <>
            <div className="col-12  text-end mb-2 mt-2">
              {deleteInput1Loading ? (
                <AtkLoader></AtkLoader>
              ) : (
                <AtkButton label="Reset" onClick={onDeleteInput1Click} className="pf-secondary-btn w-auto me-2 mb-2" />
              )}

              {input1Loading ? (
                <AtkLoader></AtkLoader>
              ) : (
                <AtkButton label="Save" onClick={onUpdateInput1Click} className="pf-secondary-btn w-auto me-2 mb-2" />
              )}

              <AtkButton label="Register" onClick={onRegisterClick} className="pf-secondary-btn w-auto me-2 mb-2" />

              {loginLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Login" onClick={onLoginClick} className="pf-primary-btn w-auto mb-2" />}
            </div>
          </>

          <div className="col-12 mb-2">
            <SectionMessage appearance="information">
              <p>
                You will still able to get quotes but we recommend you to register with them{" "}
                <a href="#" onClick={onRegisterClick} className="text-14">
                  here
                </a>
                .{" "}
              </p>
            </SectionMessage>
          </div>
        </>
      )}
      {showIGCredsMsg ? (
        <div className="col-md-12 mb-2">
          <AtkMessage appearance="information" messageText="Using Momentum Credentials" />
        </div>
      ) : null}
    </div>
  );
}