import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/NewQuote_B 1.svg";

export default function AppetiteSidebar(props: any) {
  const navigate = useNavigate();

  const onAppetiteClick = () => {
    navigate("/appetite");
  };
  const onAppetiteHistoryClick = () => {
    navigate("/appetitehistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename === "appetite-landing-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onAppetiteClick}
        >
          <img src={NewIcon} className={props.pagename === "appetite-landing-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt="" />
          <span className="sidebar-menu-title">New Rate Risk Appetite</span>{" "}
        </span>
        <span
          className={props.pagename === "appetite-history-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onAppetiteHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename === "appetite-history-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt=""
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
