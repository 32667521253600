import SendIcon from "@atlaskit/icon/glyph/send";
import SectionMessage from "@atlaskit/section-message";
import axios from "axios";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import feedbackImg from "../../assets/images/feedback.svg";
import NewIcon from "../../assets/images/newquote.png";
import BackgroundVideo from "../../common/BackgroundVideo";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import SmartFormScanMiddleHeader from "../SmartFormScan/SmartFormScanMiddleHeader";
import SmartFormScanSidebar from "../SmartFormScan/SmartFormScanSidebar";

interface InsuredDetails {
  databaseId: string;
  commercialName: string;
  firstName: any;
  lastName: any;
  type: number;
  addressLine1: string;
  addressLine2: any;
  stateNameOrAbbreviation: string;
  city: string;
  zipCode: string;
  eMail: any;
  eMail2: any;
  eMail3: any;
  fax: string;
  phone: string;
  cellPhone: string;
  smsPhone: any;
  description: any;
  active: boolean;
  website: any;
  fein: any;
  customerId: any;
  insuredId: string;
  id: any;
  dba: any;
  insuredType: any;
  prospectType: any;
  acquisitionDate: any;
}

export default function SmartFormScanHistory() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [historyData, setHistoryData] = useState<any>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [csvFileData, setCsvFileData] = useState<any>([]);
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [htmlUrl, setHTMLUrl] = useState("");
  const [selectInsuredPopUp, setSelectInsuredPopUp] = useState(false);
  const [search, setSearch] = useState("");
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [selectedSmartFormHtml, setSelectedSmartFormHtml] = useState("");
  const [htmlUrlForInsured, setHtmlUrlForInsured] = useState("");
  const [createSmartFormForInsuredLoading, setCreateSmartFormForInsuredLoading] = useState(false);
  const [insuredDetails, setInsuredDetails] = useState<InsuredDetails[]>([]);
  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    getHistoryDetails();
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const debouncedFetchResults = useCallback(
    _.debounce((searchQuery: any) => {
      getNowCertsClients(searchQuery);
    }, 500),
    []
  );
  useEffect(() => {
    if (search.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (search) {
      debouncedFetchResults(search);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [search, debouncedFetchResults]);

  useEffect(() => {
    if (clientId !== "") {
      getNowCertsClientsById(clientId);
    }
  }, [clientId]);

  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        setSearchClientLoading(false);
      });
  };
  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    // setNowcertsClientLoading(true);
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          console.log("Insured details received.");
          let data: InsuredDetails[] = [];
          data.push({
            databaseId: insuredInfo[0].databaseId ?? "",
            commercialName: insuredInfo[0].commercialName ?? "",
            firstName: insuredInfo[0].firstName ?? "",
            lastName: insuredInfo[0].lastName ?? "",
            type: insuredInfo[0].type ?? "",
            addressLine1: insuredInfo[0].addressLine1 ?? "",
            addressLine2: insuredInfo[0].addressLine2 ?? "",
            stateNameOrAbbreviation: insuredInfo[0].stateNameOrAbbreviation ?? "",
            city: insuredInfo[0].city ?? "",
            zipCode: insuredInfo[0].zipCode ?? "",
            eMail: insuredInfo[0].eMail ?? "",
            eMail2: insuredInfo[0].eMail2 ?? "",
            eMail3: insuredInfo[0].eMail3 ?? "",
            fax: insuredInfo[0].fax ?? "",
            phone: insuredInfo[0].phone ?? "",
            cellPhone: insuredInfo[0].cellPhone ?? "",
            smsPhone: insuredInfo[0].smsPhone ?? "",
            description: insuredInfo[0].description ?? "",
            active: insuredInfo[0].active ?? "",
            website: insuredInfo[0].website ?? "",
            fein: insuredInfo[0].fein ?? "",
            customerId: insuredInfo[0].customerId ?? "",
            insuredId: insuredInfo[0].insuredId ?? "",
            id: insuredInfo[0].id ?? "",
            dba: insuredInfo[0].dba ?? "",
            insuredType: insuredInfo[0].insuredType ?? "",
            prospectType: insuredInfo[0].prospectType ?? "",
            acquisitionDate: insuredInfo[0].acquisitionDate ?? "",
          });
          setInsuredDetails(data);
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the insureds data. Please try again later.",
              life: 3000,
            });
          }
        }
      });
  };
  const getHistoryDetails = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCSmartFormScan/nc-smart-form-scan-history`,
      // url: `https://localhost:55723/api/NCSmartFormScan/nc-smart-form-scan-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting  history details",
              life: 3000,
            });
          }
        }
        setHistoryLoading(false);
      });
  };
  // const statusTemplate = (rowData: any) => {
  //   return rowData.status === 0 ? (
  //     <b className="text-warning"> Submitted</b>
  //   ) : rowData.status === 1 ? (
  //     <b className="text-info"> In Progress</b>
  //   ) : rowData.status === 2 ? (
  //     <b className="text-success"> Done</b>
  //   ) : rowData.status === 3 ? (
  //     <b className="text-danger"> Failed</b>
  //   ) : null;
  // };
  const actionTemplate = (rowData: any) => {
    return rowData.status === 2 ? (
      <>
        <div className="history-action-column">
          {/* <img src={DownloadCSVIcon} onClick={() => onDownloadClick("csv", rowData)} title="Download CSV" className="me-2 show-cursor" />
          <img src={DownloadXMLIcon} onClick={() => onDownloadClick("xml", rowData)} title="Download XML" className="me-2 show-cursor" /> */}
          <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="me-2 show-cursor" />
          <img
            src={NewIcon}
            height={20}
            onClick={() => onSelectInsuredClick(rowData)}
            title="Select insured for sending template"
            className="me-2 show-cursor"
          />

          {/* <span onClick={() => openExtractedHTML(rowData)} title="Open smart form in new tab" className="show-cursor p-1">
            {previewFileLoading && rowData.transactionId == transactionId ? <AtkLoader /> : <SendIcon label="Open smart form in new tab" />}
          </span> */}
        </div>
      </>
    ) : null;
  };
  const onSelectInsuredClick = (rowData: any) => {
    setSelectInsuredPopUp(true);
    setTransactionId(rowData.transactionId);
    setHtmlUrlForInsured("");
    setSelectedSmartFormHtml(rowData.smartFormHtml);
  };
  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
  };
  const onDownloadClick = (documentType: string, rowData: any) => {
    const transactionId = rowData.transactionId == undefined ? rowData : rowData.transactionId;
    setTransactionId(transactionId);
    const token = CommonValues.GetToken();
    axios({
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/document/${documentType}/${transactionId}`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        let filename = rowData.fileName == undefined ? fileName : rowData.fileName;
        let fileExtension = filename.substring(filename.lastIndexOf("."), filename.length);
        // filename = filename.replace(".PDF", ".pdf");
        if (documentType === "csv") {
          saveAs(response.data, filename.replace(fileExtension, ".csv"));
        } else if (documentType === "xml") {
          saveAs(response.data, filename.replace(fileExtension, ".xml"));
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting  history details",
              life: 3000,
            });
          }
        }
        setHistoryLoading(false);
      });
  };
  const onPreviewClick = (rowData: any) => {
    const transactionId = rowData.transactionId;
    const fileName = rowData.smartFormHtml;
    setFileName(fileName);
    setTransactionId(transactionId);
    setShowPopUp(true);
    setPreviewFileLoading(true);
    const token = CommonValues.GetToken();
    var data = {
      transactionId: rowData.transactionId,
      smartFormPdf: rowData.smartFormPdf,
      smartFormHtml: rowData.transactionId + ".html",
      isHTMLLinkForInsured: false,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCSmartFormScan/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.pdfUrl);
        setHTMLUrl(response.data.htmlUrl);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting  history details",
              life: 3000,
            });
          }
        }
        setPreviewFileLoading(false);
      });
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
    setHTMLUrl("");
    setCsvFileData([]);
  };
  const onHideSelectInsuredPopupClicked = () => {
    setSelectInsuredPopUp(false);
    setPdfUrl("");
    setHTMLUrl("");
    setSelectedSmartFormHtml("");
    setHtmlUrlForInsured("");
    setCsvFileData([]);
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setTransactionId(rowData.transactionId);
      onSaveFeedback(rowData);
    }
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionfeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Feedback Submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting feedback",
              life: 3000,
            });
          }
        }
      });
  };
  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Feedback submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting ratings",
              life: 3000,
            });
          }
        }
      });
  };
  const openExtractedHTML = (rowData: any) => {
    const transactionId = rowData.transactionId;
    const fileName = rowData.smartFormHtml;
    setFileName(fileName);
    setTransactionId(transactionId);
    setPreviewFileLoading(true);
    const token = CommonValues.GetToken();
    var data = {
      transactionId: rowData.transactionId,
      smartFormPdf: "",
      smartFormHtml: rowData.transactionId + ".html",
      isHTMLLinkForInsured: false,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCSmartFormScan/previewdocument`,
      // url: `https://localhost:55723/api/NCSmartFormScan/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        window.open(response.data.htmlUrl, "_blank");

        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting html details",
              life: 3000,
            });
          }
        }
        setPreviewFileLoading(false);
      });
  };
  const statusTemplate = (rowData: any) => {
    return rowData.status === 0 ? (
      <b className="text-warning"> Submitted</b>
    ) : rowData.status === 1 ? (
      <b className="text-info"> In Progress</b>
    ) : rowData.status === 2 ? (
      <b className="text-success"> Done</b>
    ) : rowData.status === 3 ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };
  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
  };
  const onClientSelect = (client: any) => {
    setHtmlUrlForInsured("");
    window.history.replaceState(null, "", window.location.pathname);
    // setclientSelect(true);
    setSearch("");
    // setInputValue(client.name);
    let clientList = {
      clientName: client.name,
      clientId: client.databaseId,
    };
    localStorage.setItem("client", JSON.stringify(clientList));

    setClientName(client.name);
    setClientId(client.databaseId);
  };
  const getSmartFormLinkForInsured = (transactionId: string, smartFormHtml: string) => {
    setCreateSmartFormForInsuredLoading(true);
    const fileName = smartFormHtml;
    setFileName(fileName);
    setTransactionId(transactionId);
    setPreviewFileLoading(true);
    const token = CommonValues.GetToken();
    var data = {
      SmartFormTransactionId: transactionId,
      InsuredDetails: insuredDetails.length > 0 ? insuredDetails[0] : null,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCSmartFormScan/prefilled-html`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setCreateSmartFormForInsuredLoading(false);
        setHtmlUrlForInsured("submitted successfully");
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          setCreateSmartFormForInsuredLoading(false);
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting html details",
              life: 3000,
            });
          }
        }
        setPreviewFileLoading(false);
      });
  };
  const onHistoryClick = () => {
    navigate("/smartforminsuredhistory");
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <SmartFormScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SmartFormScanSidebar pagename="commission-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    <div className="row mb-3">
                      <div className="col-6">
                        <span className="p-input-icon-left w-50">
                          <i className="fa fa-search grid-search-icon" />
                          <AtkTextField
                            type="text"
                            id="txtfirstName"
                            name="globalFilter"
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            value={globalFilter}
                            className="input-field"
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                      <div className="col-6 text-end">
                        <AtkButton label="Refresh" onClick={getHistoryDetails} className=" pf-primary-btn w-auto" />
                      </div>
                    </div>
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable
                        value={historyData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        globalFilter={globalFilter}
                        emptyMessage="No data available."
                      >
                        <Column header="File Name" field="smartFormPdf" sortable></Column>
                        <Column
                          header="Submitted Date"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="justify-">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          field="created"
                          sortable
                          style={{ width: "12%" }}
                        ></Column>
                        <Column header="Status" field="status" body={statusTemplate} style={{ width: "10%" }} sortable></Column>
                        <Column header="Action" body={actionTemplate} style={{ width: "12%" }}></Column>
                      </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
        {previewFileLoading ? (
          <div className="d-flex align-items-center justify-content-center overflow-hidden">
            <AtkLoader />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 border-end">
              <b>Uploaded PDF</b>
              <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
            </div>
            <div className="col-md-6">
              <b>Smart Form Template</b>
              <iframe src={htmlUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        header=""
        visible={selectInsuredPopUp}
        style={{ width: "60vw", height: "70vh" }}
        onHide={onHideSelectInsuredPopupClicked}
        draggable={false}
      >
        <div className="row">
          <div className="col-md-12 p-0 my-1 client-search-input-col position-relative mx-auto">
            <b>Select Insured</b>
            <div className="search-bar ">
              <AtkTextField
                type="text"
                id="text"
                className="search-area "
                name="clientName"
                onChange={(e) => {
                  onClientSearchChange(e.target.value);
                }}
                value={search}
                // onKeyDown={onKeyClick}
                placeholder={"Enter client name"}
              />
            </div>
            {search.replaceAll(" ", "").length > 0 && (
              <div id="client-search" className=" position-absolute  ">
                {searchClientLoading ? (
                  <div className="d-flex justify-content-center my-2 client-loader-div">
                    <AtkLoader />
                  </div>
                ) : searchClientList.length == 0 ? (
                  <div style={{ padding: "5px", background: "white" }}>
                    <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                  </div>
                ) : (
                  searchClientList.map((item: any) => (
                    <div
                      key={item.databaseId}
                      style={{ cursor: "pointer", width: "100%" }}
                      onClick={() => {
                        onClientSelect(item);
                      }}
                    >
                      <a
                        href="#"
                        style={{
                          color: "black",
                          width: "100%",
                          fontSize: "12px",
                        }}
                      >
                        {item.name}
                      </a>
                    </div>
                  ))
                )}
              </div>
            )}
            {clientName !== "" && (
              <>
                <div className="col-md-12 mt-5">
                  <span className="policy-type-heading me-2 text-center">Selected Insured</span>

                  <span className="client-name-span me-2 text-center">
                    {" "}
                    <b className="">{clientName}</b>
                  </span>
                </div>
                <div className="col-md-12 mt-3">
                  {!createSmartFormForInsuredLoading ? (
                    htmlUrlForInsured === "" ? (
                      <AtkButton
                        label="Create smart form link using insured details"
                        onClick={() => getSmartFormLinkForInsured(transactionId, selectedSmartFormHtml)}
                        className=" pf-primary-btn w-auto"
                      />
                    ) : (
                      <div className=" my-3">
                        <SectionMessage appearance="success">
                          <h3>Smart form requested successfully!</h3>
                          <p className="text-12">
                            We are processing smart form template. Please check the{" "}
                            <b className="show-cursor" style={{ color: "#2dabc1" }} onClick={onHistoryClick}>
                              Insured Smart Form History
                            </b>{" "}
                            tab to find out smart form's current progress status
                          </p>
                        </SectionMessage>
                      </div>
                    )
                  ) : (
                    <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                      <AtkLoader />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>
      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
          </div>
          <div className="col-4 text-end p-2  ">
            {transactionId == feedBackData.transactionId && feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
            )}
          </div>
        </div>
      </OverlayPanel>
    </>
  );
}
