import React from 'react'
import VehicleLogo from "../assets/images/vehicle2_TH.svg";


export default function VehicleMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
    <div className="col-12">
      <div className="middle-header-col active ms-2">
        <img src={VehicleLogo} className="" width={30} alt=''/>
        <span className="middle-header-title">Vehicle Prefill</span>
      </div>
    </div>{" "}
  </div>
  )
}
