import React from 'react'
import DLLog from "../../assets/images/DLLogo.svg";

export default function DriverLicenseScanMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
    <div className="col-12">
      <div className="middle-header-col active ms-2">
        <img src={DLLog} className="" width={30}/>
        <span className="middle-header-title">Driver License Scan</span>
      </div>
    </div>{" "}
  </div>
  )
}
