import Header from "../../common/Header";
import { useEffect } from "react";
import MTMScanSidebar from "./MTMScanSidebar";
import MTMScanMiddleHeader from "./MTMScanMiddleHeader";
import MTMScanFileUploadPage from "./MTMScanFileUploadPage";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
import BackgroundVideo from "../../common/BackgroundVideo";

export default function MTMScanLadingPage() {
  const navigate = useNavigate();

  const hostname = window.location.hostname;
  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="smartscan-main-page">
        <Header pagename="smart-scan" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <MTMScanMiddleHeader pagename="mtm-scan" />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <MTMScanSidebar pagename="mtm-scan" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col">
                <MTMScanFileUploadPage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
