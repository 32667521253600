import NewIcon from "../assets/images/NewQuote_B 1.svg";
import HistoryIcon from "../assets/images/History_B.svg";
import SaveIcon from "../assets/images/Saved Quotes_B.svg";
import SettingIcon from "../assets/images/Settings_B.svg";
import { useNavigate } from "react-router-dom";

export default function Sidebar(props: any) {
  const navigate = useNavigate();

  const onNewQuoteClick = () => {
    navigate("/premiumfinance");
    //  window.location.reload();
  };

  const onHistoryClick = () => {
    navigate("/pfhistory");
  };

  const onSavedQuotesClick = () => {
    navigate("/pfsavedquotes");
  };

  const onSettingClick = () => {
    navigate("/pfsettings");
  };

  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={
            props.pagename === "PfNewQuotepage" || props.pagename === "Quotespage"
              ? "pf-sidebar-menu active "
              : "pf-sidebar-menu pf-hover-effect show-cursor"
          }
          onClick={onNewQuoteClick}
        >
          <img
            src={NewIcon}
            alt=""
            className={
              props.pagename === "PfNewQuotepage" || props.pagename === "Quotespage" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "
            }
          />
          <span className="sidebar-menu-title">New Quote</span>{" "}
        </span>
        <span
          className={props.pagename === "PfHistorypage" ? "pf-sidebar-menu active" : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onHistoryClick}
        >
          <img src={HistoryIcon} className={props.pagename === "PfHistorypage" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt="" />
          <span className="sidebar-menu-title">History </span>
        </span>
        <span
          className={props.pagename === "PfSavedQuotespage" ? "pf-sidebar-menu active" : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onSavedQuotesClick}
        >
          <img src={SaveIcon} className={props.pagename === "PfSavedQuotespage" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt="" />
          <span className="sidebar-menu-title">Saved Quotes</span>{" "}
        </span>
        <span
          className={props.pagename === "PfSettingpage" ? "pf-sidebar-menu active" : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onSettingClick}
        >
          <img src={SettingIcon} className={props.pagename === "PfSettingpage" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt="" />
          <span className="sidebar-menu-title">PF Settings</span>{" "}
        </span>
      </>
    </div>
  );
}
