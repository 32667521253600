import React, { useEffect, useState } from "react";
import MomentumAISidebar from "./MomentumAISidebar";
import AIMenuFooter from "./AIMenuFooter";
import CommonValues from "../common/utils";
import { useNavigate } from "react-router-dom";

export default function ReportAssistantLandingPage() {

  const navigate = useNavigate();

  const [chatBotEmbeded, setChatBotEmbeded] = useState(true);

  useEffect(() => {
      let token = CommonValues.GetToken();
      if (token == "" || token == null) {
        CommonValues.Logout(navigate);
      }
      localStorage.setItem("Embeded", String(chatBotEmbeded));
  
      setChatBotEmbeded(localStorage.getItem("Embeded") === "true");
    },[]);
  return (
    <div className="ai-support-main-page">
      <div className="ai-wrapper min-vh-100 ">
        <div className="ai-wrapper-container position-relative z-1">
          <div className="row g-4 mx-auto">
            <div className="col-2 px-0 " style={{ paddingTop: "2%" }}>
              <MomentumAISidebar pagename="ai-report-assistant" />
            </div>
            <div className="col-10 px-0" style={{ paddingTop: "2%" }}>
              <div className="container p-3 d-flex flex-column h-100">
                <div className="ai-main-content-wrapper" >
                  {/* <iframe src={`${window.location.ancestorOrigins[0]}/AMSINS/Integrations/Informer/Assistant`} height="100%" width="100%" /> */}
                </div>
                <div className="p-3 mt-auto">
                  <AIMenuFooter pagename="ai-report-assistant" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}