import React from "react";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import TextField from "@atlaskit/textfield";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkButton from "../../AtlaskitControls/AtkButton";
import SectionMessage from "@atlaskit/section-message";
import moment from "moment";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import { Dialog } from "primereact/dialog";

export default function SmartScanDataExtractPage(props: any) {
  const feedbackRef = useRef<OverlayPanel>(null);
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [toggleSelectErrorMsg, setToggleSelectErrorMsg] = useState("");
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [submissionId, setSubmissionId] = useState("");
  const [showUploadAgain, setShowUploadAgain] = useState(false);
  const [ncURL, setNcURL] = useState("");

  useEffect(() => {
    if (props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14")) {
      setHideSubmitButton(false);
    }
  }, []);

  let options1 = [
    { value: "Commercial", label: "Commercial" },
    { value: "Personal", label: "Personal" },
  ];

  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback, submissionId: props.submissionId };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: props.submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: props.submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      onSaveFeedback(rowData);
      setFeedbackLoading(true);
    }
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const checkValidation = () => {
    let formIsValid = true;
    props.setInsuredFirstNameError("");
    props.setInsuredLastNameError("");
    props.setInsuredEmailError("");
    props.setBusinessNameError("");
    props.setmailingAddressLine1Error("");
    props.setMailingCityError("");
    props.setMailingStateError("");
    props.setMailingZipCodeError("");
    props.setPolicyNumberError("");
    props.setPolicyPremiumError("");
    props.setCarrierNameError("");
    props.setLineOfBusinessError("");
    props.setEffectiveDateError("");
    props.setExpiryDateError("");
    props.setQuoteNumberError("");

    if (props.createQuote == true) {
      if (props.quoteNumber == "" || props.quoteNumber == null) {
        formIsValid = false;
        props.setQuoteNumberError("Quote number is required");
      }
    }

    if (props.createNewPolicy == true) {
      if (props.policyNumber == "" || props.policyNumber == null) {
        formIsValid = false;
        props.setPolicyNumberError("Policy number is required");
      }
      if (props.policyPremium == "" || props.policyPremium == null) {
        formIsValid = false;
        props.setPolicyPremiumError("Policy Premium is required");
      }
    }
    if (props.documentType == "DecPage" || props.documentType == "QuoteLatter") {
      if (props.createInsured == true) {
        if (props.insuredType == "Commercial") {
          if (props.businessName == "" || props.businessName == null) {
            formIsValid = false;
            props.setBusinessNameError("Business name is required");
          }
        }
        else {
          if (props.firstName == "" || props.firstName == null) {
            formIsValid = false;
            props.setInsuredFirstNameError("First name is required");
          }
          if (props.lastName == "" || props.lastName == null) {
            formIsValid = false;
            props.setInsuredLastNameError("Last name is required");
          }
        }
      }
      else {
        if (props.businessName == "" || props.businessName == null) {
          formIsValid = false;
          props.setBusinessNameError("Business name is required");
        }
      }
    }

    if (props.documentType == "Acord125" || props.documentType == "Acord130" || props.documentType == "Acord126") {
      if (props.businessName == "" || props.businessName == null) {
        formIsValid = false;
        props.setBusinessNameError("Business name is required");
      }
    }
    // if (props.createInsured == true || props.createProspect == true) {
    //   if (props.documentType == "DecPage" || props.documentType == "QuoteLetter") {
    //     if (props.insuredType == "Commercial") {
    //       if (props.businessName == "" || props.businessName == null) {
    //         formIsValid = false;
    //         props.setBusinessNameError("Business name is required");
    //       }
    //     } else {
    //       if (props.firstName == "" || props.firstName == null) {
    //         formIsValid = false;
    //         props.setInsuredFirstNameError("First name is required");
    //       }
    //       if (props.lastName == "" || props.lastName == null) {
    //         formIsValid = false;
    //         props.setInsuredLastNameError("Last name is required");
    //       }
    //     }
    //   }
    //   else {
    //     if (props.businessName == "" || props.businessName == null) {
    //       formIsValid = false;
    //       props.setBusinessNameError("Business name is required");
    //     }
    //   }
    // }
    return formIsValid;
  };

  const OnSubmit = () => {
    if (checkValidation()) {
      setShowConfirmBox(true)
    }
  };

  const onOkClick = () => {
    onSubmitClick();
  };
  const onCancelClick = () => {
    setShowConfirmBox(false);
  };
  const onSubmitClick = async () => {
    let createPolicyStatus: any = "";
    let createQuoteStatus: any = "";

    setOnSubmitLoader(true);
    const executeSmartScanUpdate = async () => {
      updateSmartScan();
    };

    const handleCreateInsured = async () => {
      const insuredDatabaseId: any = await createNewInsured();

      if (props.createQuoteForInsured) {
        createQuoteStatus = await insertQuote(insuredDatabaseId);
      }

      if (props.createNewPolicyForInsured) {
        createPolicyStatus = await createNewPolicy(insuredDatabaseId);
      }

      if(insuredDatabaseId !="" || createQuoteStatus == true || createPolicyStatus ==true){
        await executeSmartScanUpdate();
      }
    };

    const handleExistingInsured = async () => {
      if (props.createQuote) {
        createQuoteStatus = await insertQuote(props.insuredDatabaseId);
      }

      if (props.createNewPolicyForInsured) {
        createPolicyStatus = await createNewPolicy(props.insuredDatabaseId);
      }

      if (createPolicyStatus == true || createQuoteStatus == true) {
        await executeSmartScanUpdate();
      }
    };

    const handleCreateProspect = async () => {
      const insuredDatabaseId: any = await createNewProspect();

      if (props.createQuote) {
        createQuoteStatus = await insertQuote(insuredDatabaseId);
      }

      if ( insuredDatabaseId !="" || createQuoteStatus == true) {
        await executeSmartScanUpdate();
      }
    };

    const handleExistingProspect = async () => {
      if (props.createQuote) {
        createQuoteStatus= await insertQuote(props.insuredDatabaseId);
      }
      if (createQuoteStatus == true) {
        await executeSmartScanUpdate();
      }
    };

    if (props.createInsured) {
      await handleCreateInsured();
    } else if (props.insuredDatabaseId) {
      await handleExistingInsured();
    } else if (props.createProspect) {
      await handleCreateProspect();
    } else if (!props.createProspect && props.createQuote) {
      createQuoteStatus=await insertQuote(props.insuredDatabaseId);
      if (createQuoteStatus == true) {
        await executeSmartScanUpdate();
      }
    } else if (props.prospectDatabaseId && props.createQuote) {
      await handleExistingProspect();
    } else {
      setToggleSelectErrorMsg("Select any one of the process Create Client / Create Quote");
      setTimeout(() => setToggleSelectErrorMsg(""), 6000);
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
    }
  };

  const updateSmartScan = () => {
    let token = CommonValues.GetToken();
    const requestJson = {
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      phoneNumber: props.phoneNumber,
      policyNumber: props.policyNumber,
      policyPremium: props.policyPremium,
      carrierName: props.carrierName,
      lineOfBusiness: props.lineOfBusiness,
      quoteNumber: props.quoteNumber,
      effectiveDate: props.effectiveDate,
      expirationDate: props.expirationDate,
      businessName: props.businessName,
      addressLine1: props.addressLine1,
      city: props.city,
      state: props.state,
      zipCode: props.zipCode,
      fees: props.fees,
      taxes: props.taxes,
      coverageLimitDetails: props.coverageLimitDetails,
      insuredType: props.insuredType,
    };
    var data = {
      submissionId: props.submissionId,
      createProspect: props.createProspect,
      createQuote: props.createQuote,
      requestJson: JSON.stringify(requestJson),
      isUpdateInNowCerts: true,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/updatesmartscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => { })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while smart scan updating the request.",
            life: 3000,
          });
        }
      });
  };

  const insertQuote = async (insuredDatabaseId: any) => {
    try {
      let token = CommonValues.GetToken();
      let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
      var data = JSON.stringify({
        insuredDatabaseId: insuredDatabaseId,
        insuredFirstName: props.firstName,
        insuredLastName: props.lastName,
        insuredEmail: props.email,
        effectiveDate: props.effectiveDate == "" || props.effectiveDate == null ? "" : moment(props.effectiveDate).format("MM/DD/YYYY"),
        expirationDate: props.expirationDate == "" || props.expirationDate == null ? "" : moment(props.expirationDate).format("MM/DD/YYYY"),
        bindDate: "",
        insuredCommercialName: props.businessName,
        lineOfBusinessName: props.lineOfBusiness,
        carrierName: props.carrierName,
        premium: props.policyPremium == "" || props.policyPremium == null ? "0" : props.policyPremium.replace("$", "").replace(",", ""),
        quoteNumber: props.quoteNumber,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/insertquote?&ncToken=${nowCertsToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Quote Created Successfully.",
        life: 3000,
      });
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      setShowUploadAgain(true);
      setHideSubmitButton(false);
      return true
    }
    catch (error: any) {
      console.log(error);
      setOnSubmitLoader(false);
      localStorage.removeItem("currentPage");
      if (error.response != null && error.response.status == 401) {
        navigate("/");
        CommonValues.Logout(navigate);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while quote creating the request.",
          life: 3000,
        });
      }
    }
    return false
  };

  const createNewPolicy = async (insuredDataBaseId: any) => {
    try {
      let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
      var data = {
        insuredDatabaseId: insuredDataBaseId,
        firstName: props.firstName,
        lastName: props.lastName,
        number: props.policyNumber,
        effectiveDate: props.effectiveDate == "" || props.effectiveDate == null ? "" : moment(props.effectiveDate).format("MM/DD/YYYY"),
        expirationDate: props.expirationDate == "" || props.expirationDate == null ? "" : moment(props.expirationDate).format("MM/DD/YYYY"),
        bindDate: "",
        businessType: 0,
        billingType: 0,
        mgaName: "",
        carrierName: props.carrierName,
        premium: props.policyPremium == "" || props.policyPremium == null ? "0" : props.policyPremium.replace("$", "").replace(",", ""),
        lineOfBusinessName: props.lineOfBusiness.replace(",", ""),
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addpolicy?nowCertsToken=${nowCertsToken}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      let policyId = response.data.policiesOrQuotes[0].policyOrQuoteId;
      if (props.documentType == "DecPage" || props.documentType == "QuoteLetter") {
        insertPolicyEndorsementFee(policyId)
        getLobList(policyId);
      }
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Policy has been created successfully in NowCerts",
        life: 3000,
      });
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      setShowUploadAgain(true);
      setHideSubmitButton(false);
      return true
    }
    catch (error: any) {
      let errorMessage = "";
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      if (error.response != null) {
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while adding new policy",
            life: 3000,
          });
        }
      }
    }
    return false
  };

  const insertPolicyEndorsementFee = async (policyId: any) => {
    let policyEndorsementFee: any = [];
    const formattedOtherFees = parseFloat(props.fees.replace("$", "").replace(",", ""));
    const formattedOtherTaxes = parseFloat(props.taxes.replace("$", "").replace(",", ""));

    policyEndorsementFee.push({
      order: 1,
      policyId: policyId,
      amount: formattedOtherFees,
      date: new Date().toJSON(),
      premiumType: 1,
      type: 3,
      followUpDate: new Date().toJSON(),
    });

    policyEndorsementFee.push({
      order: 1,
      policyId: policyId,
      amount: formattedOtherTaxes,
      date: new Date().toJSON(),
      premiumType: 1,
      type: 4,
      followUpDate: new Date().toJSON(),
    });

    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    const data = JSON.stringify(policyEndorsementFee);
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertpolicyendorsementfee?ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {

      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while adding policy endorsement fee.",
              life: 3000,
            });
          }
        }
      });
  }

  const createNewInsured = async () => {
    setNcURL("");
    try {
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: props.insuredType == "Commercial" ? props.businessName : "",
        firstName: props.insuredType == "Personal" ? props.firstName : "",
        lastName: props.insuredType == "Personal" ? props.lastName : "",
        dba: "",
        addressLine1: props.addressLine1,
        addressLine2: "",
        state: props.state,
        city: props.city,
        zipCode: props.zipCode,
        eMail: props.email,
        cellPhone: props.phoneNumber,
        fein: "",
        type: props.insuredType == "Commercial" ? 0 : 1,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertinsured?nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.database_id;
      setNcURL(response.data.newInsuredLunchUrl);
      setShowUploadAgain(true);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Insured has been created successfully in NowCerts",
        life: 3000,
      });
      setOnSubmitLoader(false);
      setHideSubmitButton(false);
      setShowConfirmBox(false);
      return insuredInfo;
    } catch (error: any) {
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.errors,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Please correct NowCerts username and password.",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error while creating insured inside the NowCerts.",
          life: 3000,
        });
      }
      let insuredInfo = "";
      return insuredInfo;
    }
  };
  const createNewProspect = async () => {
    try {
      setNcURL("");
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: props.insuredType == "Commercial" ? props.businessName : "",
        firstName: props.insuredType == "Personal" ? props.firstName : "",
        lastName: props.insuredType == "Personal" ? props.firstName : "",
        dba: "",
        addressLine1: props.addressLine1,
        addressLine2: "",
        state: props.state,
        city: props.city,
        zipCode: props.zipCode,
        eMail: props.email,
        cellPhone: props.phoneNumber,
        fein: props.fein,
        insuredType: props.insuredType == "Commercial" ? 0 : 1,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/createnewprospect?nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.insuredDatabaseId;
      setNcURL(response.data.nowCertsUrlToLaunch);
      setShowUploadAgain(true);
      window.scrollTo(0, 1500);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Prospect has been created successfully in NowCerts.",
        life: 3000,
      });
      setOnSubmitLoader(false);
      setHideSubmitButton(false);
      setShowConfirmBox(false);
      return insuredInfo;
    } catch (error: any) {
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.errors,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Please correct NowCerts username and password.",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error while creating insured inside the NowCerts.",
          life: 3000,
        });
      }
      setOnSubmitLoader(false);
      setHideSubmitButton(false);
      setShowConfirmBox(false);
      let insuredInfo = "";
      return insuredInfo;
    }
  };

  const uploadAgain = () => {
    if (props.fromHistory == true) {
      props.onHidePopupClicked();
    } else {
      setShowUploadAgain(false);
      props.uploadAgain();
    }
  };

  const getLobList = (policyId: any) => {
    let generalLiability = false;
    let token = CommonValues.GetToken();
    var ncToken = CommonValues.GetNCToken();
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/loblist?policyId=${policyId}&ncToken=${ncToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data.length > 0) {
          insertCoverageAndLimits(response.data, policyId);
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while getting line of business id.", life: 3000 });
          }
        }
      });
  }

  const insertCoverageAndLimits = (lineOfBusinessIdResponse: any, policyId: any) => {
    let coverageLimitDetailsRequest: any = [];
    lineOfBusinessIdResponse.map((item: any) => {
      if (item.lineOfBusinessName.toUpperCase().includes("GENERAL LIABILITY")) {
        props.coverageLimitDetails.map((coverageItem: any) => {
          coverageLimitDetailsRequest.push({
            generalLiability: coverageItem.generalLiability,
            lineOfBusinessDatabaseId: item.lineOfBusinessId
          })
            ;
        });
      }
      else {
        if (item.lineOfBusinessName.toUpperCase().includes("WORKERS COMPENSATION")) {
          props.coverageLimitDetails.map((coverageItem: any) => {
            coverageLimitDetailsRequest.push({
              workerCompensationAndEmployersLiability: coverageItem.WorkerCompensationAndEmployersLiability,
              lineOfBusinessDatabaseId: item.lineOfBusinessId
            });
          });
        }
      }
    });
    var ncToken = CommonValues.GetNCToken();
    let token = CommonValues.GetToken();
    var data = {
      policyDatabaseId: policyId,
      policyCoverages: coverageLimitDetailsRequest
    }

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/policycoverage?ncToken=${ncToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data
    };
    axios(config)
      .then((response: any) => {
        if (response.data.status == 1) {
          toast.current.show({ severity: "success", summary: "Success", detail: "Policy coverage and limits added in NowCerts.", life: 3000 });
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while fetching coverage and limits.", life: 3000 });
          }
        }
      });
  }
  const onOpenClick = () => {
    window.open(ncURL, "_blank");
  };
  const onHideConfirmationBox = () => {
    setShowConfirmBox(false)
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="row  my-2  mx-auto">
        <div className="col-12">
          <span className="text-14 ">
            <b>Insured Information</b>
          </span>
        </div>
      </div>
      <div className="row smartscan-block mx-auto mt-2 text-12">

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2${props.businessNameError.length > 0 ? " validation-div" : ""}`}>
          <span>Business Name</span>
          <TextField
            className="input-field"
            type="text"
            placeholder={props.businessName == "" ? "Enter business name" : props.businessName}
            value={props.businessName}
            onChange={(e: any) => {
              props.onChangeBusinessName(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12">{props.businessNameError}</span>
        </div>

        {props.documentType == "DecPage" && props.insuredType == "Personal" ?
          <>
            {props.createInsured == true || props.createProspect == true ?
              <>
                <div
                  className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.insuredFirstNameError.length > 0 ? " validation-div" : ""}`}
                >
                  <label>First Name</label>
                  <AtkTextField
                    className="input-field"
                    type="text"
                    id="text"
                    value={props.firstName}
                    placeholder={props.firstName == "" ? "Enter first name" : props.firstName}
                    onChange={(e: any) => {
                      props.onChangeInsuredFirstName(e.target.value);
                    }}
                    isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
                  />
                  <span className="text-danger text-12">{props.insuredFirstNameError}</span>
                </div>

                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.insuredLastNameError.length > 0 ? " validation-div" : ""}`}>
                  <label>Last Name</label>
                  <TextField
                    placeholder={props.lastName == "" ? "Enter last name" : props.lastName}
                    className="input-field"
                    type="text"
                    value={props.lastName}
                    onChange={(e: any) => {
                      props.onChangeInsuredLastName(e.target.value);
                    }}
                    isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
                  />
                  <span className="text-danger text-12">{props.insuredLastNameError}</span>
                </div>
              </> : null}
          </>
          : null}
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.insuredEmailError.length > 0 ? " validation-div" : ""}`}>
          <label>Email</label>
          <TextField
            className="input-field"
            type="text"
            value={props.email}
            placeholder={props.email == "" ? "Enter email" : props.email}
            onChange={(e: any) => {
              props.onChangeEmail(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12">{props.insuredEmailError}</span>
        </div>
        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Phone Number</label>
          <TextField
            className="input-field"
            type="text"
            value={props.phoneNumber}
            placeholder={props.phoneNumber == "" ? "Enter phone number" : props.phoneNumber}
            onChange={(e: any) => {
              props.onChangePhoneNumber(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.policyNumberError.length > 0 ? " validation-div" : ""}`}>
          <span>Policy Number</span>
          <TextField
            className="input-field"
            type="text"
            value={props.policyNumber}
            placeholder={props.policyNumber == "" ? "Enter policy number" : ""}
            onChange={(e: any) => {
              props.onChangePolicyNumber(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12">{props.policyNumberError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.policyPremiumError.length > 0 ? " validation-div" : ""}`}>
          <span>Policy Premium ($)</span>
          <TextField
            className="input-field"
            type="text"
            value={props.policyPremium}
            placeholder={props.policyPremium == "" ? "Enter policy premium" : props.policyPremium}
            onChange={(e: any) => {
              props.onChangePolicyPremium(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12">{props.policyPremiumError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.carrierNameError.length > 0 ? " validation-div" : ""}`}>
          <span>Carrier Name</span>

          {props.fromHistory == true ? (
            <TextField
              className="input-field"
              type="text"
              value={props.carrierName}
              placeholder={props.carrierName == "" ? "Enter carrier name" : props.carrierName}
              onChange={(e: any) => {
                props.onChangeCarrierName(e.target.value);
              }}
              isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
            />
          ) : (
            <>
              {props.carrierNameLoading ? (
                <div className="d-flex justify-content-center align-items-center p-1">
                  <AtkLoader></AtkLoader>{" "}
                </div>
              ) : (
                <select
                  style={{
                    width: "102%",
                    color: "#5c5a5a",
                    fontWeight: "500",
                    height: "32px",
                  }}
                  className="input-field"
                  value={props.carrierName}
                  onChange={(e: any) => {
                    props.onChangeCarrierName(e.target.value);
                  }}
                >
                  <option value="" selected>
                    Select an option
                  </option>

                  {props.allCarrierNames.map((name: any) => (
                    <option key={name} value={name} title={name}>
                      {name.length > 16 ? name.substring(0, 16) + "..." : name}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
          <span className="text-danger text-12">{props.carrierNameError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2  ${props.lineOfBusinessError.length > 0 ? " validation-div" : ""}`}>
          <span>Line of Business</span>
          <TextField
            className="input-field"
            type="text"
            value={props.lineOfBusiness}
            placeholder={props.lineOfBusiness == "" ? "Enter line of business" : props.lineOfBusiness}
            onChange={(e: any) => {
              props.onChangeLineOfBusiness(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12">{props.lineOfBusinessError}</span>
        </div>

        {props.createQuote ? (
          <>
            <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.quoteNumberError.length > 0 ? " validation-div" : ""}`}>
              <span>Quote Number</span>
              <TextField
                className="input-field"
                type="text"
                value={props.quoteNumber}
                placeholder={props.quoteNumber == "" ? "Enter quote number" : ""}
                onChange={(e: any) => {
                  props.onChangeQuoteNumber(e.target.value);
                }}
                isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
              />
              <span className="text-danger text-12">{props.quoteNumberError}</span>
            </div>
          </>
        ) : null}

        {props.documentType == "DecPage" || props.documentType == "QuoteLetter" ?
          <>

            {props.createInsured == true ? (

              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                <span>Insured Type</span>
                <>
                  <select
                    style={{
                      width: "102%",
                      color: "#5c5a5a",
                      fontWeight: "500",
                      height: "32px",
                    }}
                    className="input-field"
                    value={props.insuredType}
                    onChange={(e: any) => {
                      props.onChangeInsuredType(e.target.value);
                    }}
                    disabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
                  >
                    {options1.map((item: any) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </>
              </div>
            ) : null}

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <span>Fees($)</span>
              <TextField
                className="input-field"
                type="text"
                value={props.fees}
                placeholder={props.fees == "" ? "Enter fees" : props.fees}
                onChange={(e: any) => {
                  props.onChangeFees(e.target.value);
                }}
                isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <span>Taxes($)</span>
              <TextField
                className="input-field"
                type="text"
                value={props.taxes}
                placeholder={props.taxes == "" ? "Enter fees" : props.taxes}
                onChange={(e: any) => {
                  props.onChangeTaxes(e.target.value);
                }}
                isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
              />
            </div>
          </> : null}

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.effectiveDateError.length > 0 ? " validation-div" : ""}`}>
          <span>Effective Date</span>

          <AtkTextField
            className="input-field"
            type="date"
            id="datepicker"
            value={moment(props.effectiveDate).format("YYYY-MM-DD")}
            onChange={(e: any) => {
              props.onChangeEffectiveDate(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12 btisErrorText">{props.effectiveDateError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.expiryDateError.length > 0 ? " validation-div" : ""}`}>
          <span>Expiration Date</span>

          <AtkTextField
            className="input-field"
            type="date"
            id="datepicker"
            value={moment(props.expirationDate).format("YYYY-MM-DD")}
            onChange={(e: any) => {
              props.onChangeExpiryDate(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12 btisErrorText">{props.expiryDateError}</span>
        </div>

      </div>
      <div className="row  my-2  mx-auto">
        <div className="col-12">
          <span className="text-14 ">
            <b>Address Information</b>
          </span>
        </div>
      </div>
      <div className="row smartscan-block  mx-auto text-12">
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingAddressLine1Error.length > 0 ? " validation-div" : ""}`}>
          <span>Address</span>
          <TextField
            className="input-field"
            type="text"
            value={props.addressLine1}
            placeholder={props.addressLine1 == "" ? "Enter address" : props.addressLine1}
            onChange={(e: any) => {
              props.onChangeAgencyMailingAddress1(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12">{props.mailingAddressLine1Error}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingCityError.length > 0 ? " validation-div" : ""}`}>
          <span>City</span>
          <TextField
            className="input-field"
            type="text"
            value={props.city}
            placeholder={props.city == "" ? "Enter city" : props.city}
            onChange={(e: any) => {
              props.onChangeAgencyMailingCity(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12 btisErrorText">{props.mailingCityError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingStateError.length > 0 ? " validation-div" : ""}`}>
          <span>State</span>
          {props.fromHistory == true ? (
            <TextField className="input-field" type="text" value={props.state} isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false} />
          ) : (
            <select
              style={{
                width: "102%",
                color: "#5c5a5a",
                fontWeight: "500",
                height: "34px",
              }}
              className="input-field"
              value={props.state}
              onChange={(e: any) => {
                props.onChangeAgencyMailingState(e.target.value);
              }}
            >
              <option value="" selected>
                Select an option
              </option>

              {props.Allstate.map((state: any) => (
                <option key={state.code} value={state.code}>
                  {state.code + "-" + state.name}
                </option>
              ))}
            </select>
          )}
          <span className="text-danger text-12 btisErrorText">{props.mailingStateError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.mailingZipCodeError.length > 0 ? " validation-div" : ""}`}>
          <span>Zip Code</span>
          <TextField
            className="input-field"
            type="text"
            value={props.zipCode}
            placeholder={props.zipCode == "" ? "Enter zip code" : props.zipCode}
            onChange={(e: any) => {
              props.onChangeAgencyMailingZip(e.target.value);
            }}
            isDisabled={props.isUpdateInNowCerts == true || new Date(props.createdDate) <= new Date("2025-02-14") ? true : false}
          />
          <span className="text-danger text-12 btisErrorText">{props.mailingZipCodeError}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex align-items-center  justify-content-end my-2">
          {hideSubmitButton ? <AtkButtonIconBefore label="Submit" onClick={OnSubmit} className="pf-secondary-btn w-auto me-2" /> : null}

          {ncURL != "" && ncURL != null ? (
            <AtkButtonIconBefore
              label={props.createInsured == true ? "Open Insured" : "Open Prospect"}
              onClick={onOpenClick}
              className="pf-secondary-btn w-auto me-2 "
              style={{ marginTop: "7px" }}
            />
          ) : null}
          {showUploadAgain ? (
            <>
              {props.fromHistory ? null : (
                <>
                  <AtkButtonIconBefore
                    label="Upload Again"
                    onClick={uploadAgain}
                    className="pf-secondary-btn w-auto me-2 "
                    style={{ marginTop: "7px" }}
                  />
                  <AtkButtonIconBefore
                    label="Feedback"
                    onClick={(e: any) => {
                      onFeedbackClick(e);
                    }}
                    className="pf-secondary-btn w-auto me-2 "
                    style={{ marginTop: "7px" }}
                  />
                </>
              )}
            </>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col-3"></div>
        <div className="col-6">
          {showConfirmBox ? (
            // <SectionMessage appearance="warning">
            //   <div className="row">
            //     <div className="col-12 text-center">
            //       <p>
            //         <span className="d-block text-13">
            //           By clicking{" "}
            //           <span className="text-danger">
            //             <b>Submit</b>
            //           </span>
            //           , a new client/quote will be created in NowCerts. Are you sure you want to proceed?
            //         </span>
            //       </p>
            //       <div className="text-center mt-1">
            //         {onSubmitLoader ? (
            //           <div className="d-inline-block me-2 ">
            //             <AtkLoader />
            //           </div>
            //         ) : (
            //           <AtkButton
            //             label="OK"
            //             onClick={() => {
            //               onOkClick();
            //             }}
            //             className="pf-secondary-btn w-auto me-2"
            //           />
            //         )}
            //         <AtkButton
            //           label="Cancel"
            //           onClick={() => {
            //             onCancelClick();
            //           }}
            //           className="pf-primary-btn w-auto"
            //         />
            //       </div>
            //     </div>
            //   </div>
            // </SectionMessage>
            <Dialog header="" visible={showConfirmBox} onHide={onHideConfirmationBox} draggable={false}>
              <div className="row">
                <div className="col-12 text-center">
                  <p>
                    <span className="d-block text-13">
                      By clicking{" "}
                      <span className="text-danger">
                        <b>Submit</b>
                      </span>
                      , a new client/quote will be created in NowCerts. Are you sure you want to proceed?
                    </span>
                  </p>
                  <div className="text-center mt-1">
                    {onSubmitLoader ? (
                      <div className="d-inline-block me-2 ">
                        <AtkLoader />
                      </div>
                    ) : (
                      <AtkButton
                        label="OK"
                        onClick={() => {
                          onOkClick();
                        }}
                        className="pf-secondary-btn w-auto me-2"
                      />
                    )}
                    <AtkButton
                      label="Cancel"
                      onClick={() => {
                        onCancelClick();
                      }}
                      className="pf-primary-btn w-auto"
                    />
                  </div>
                </div>
              </div>
            </Dialog>

          ) : null}
        </div>
        <div className="col-3"></div>
      </div>
      {toggleSelectErrorMsg.length > 0 ? (
        <div className="d-flex justify-content-center upload-file-error" style={{ width: "100%", padding: "20px 20px 0px" }}>
          <AtkMessage appearance="error" messageText={toggleSelectErrorMsg} />
        </div>
      ) : null}

      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedBackData.isLiked == true ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedBackData.isLiked == false ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
          </div>
          <div className="col-4 text-end p-2  ">
            {feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
            )}
          </div>
        </div>
      </OverlayPanel>


    </>
  );
}
