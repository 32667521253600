import React from "react";
import SmartScanSidebar from "./SmartScanSidebar";
import SmartScanMiddleHeader from "./SmartScanMiddleHeader";
import Header from "../../common/Header";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import CommonValues from "../../common/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import moment from "moment";
import AtkButton from "../../AtlaskitControls/AtkButton";
import feedbackImg from "../../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackgroundVideo from "../../common/BackgroundVideo";
import SmartScanDataExtractPage from "./SmartScanDataExtractPage";
import Toggle from "@atlaskit/toggle";
import { RadioButton } from "primereact/radiobutton";

export default function SmartScanHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [smartscanhistorydata, setSmartScanHistoryData] = useState<any>([]);
  const [submissionId, setSubmissionId] = useState();
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [extractedData, setExtractedData] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("Insured");

  const [globalFilter, setGlobalFilter] = useState("");

  const [carrierName, setCarrierName] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [fein, setFein] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyPremium, setPolicyPremium] = useState("");
  const [lineOfBusiness, setLineOfBusiness] = useState("");

  const [insuredFirstNameError, setInsuredFirstNameError] = useState("");
  const [insuredLastNameError, setInsuredLastNameError] = useState("");
  const [insuredEmailError, setInsuredEmailError] = useState("");
  const [insuredPhoneNumberError, setInsuredPhoneNumberError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessDbaError, setBusinessDbaError] = useState("");
  const [businessFeinError, setBusinessFeinError] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState("");
  const [mailingAddressLine1Error, setmailingAddressLine1Error] = useState("");
  const [mailingCityError, setMailingCityError] = useState("");
  const [mailingStateError, setMailingStateError] = useState("");
  const [mailingZipCodeError, setMailingZipCodeError] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");
  const [policyPremiumError, setPolicyPremiumError] = useState("");
  const [carrierNameError, setCarrierNameError] = useState("");
  const [lineOfBusinessError, setLineOfBusinessError] = useState("");
  const [quoteNumberError, setQuoteNumberError] = useState("");
  const [effectiveDateError, setEffectiveDateError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [createQuote, setCreateQuote] = useState(false);
  const [createInsured, setCreateInsured] = useState(false);
  const [insuredDatabaseId, setInsuredDatabaseId] = useState("");
  const [createProspect, setCreateProspect] = useState(false);
  const [createNewPolicy, setCreateNewPolicy] = useState(false);
  const [fromHistory, setFromHistory] = useState(true);
  const [selectionOptionsList, setSelectionOptionsList] = useState<any>([]);
  const [createdDate, setCreatedDate] = useState<Date>();
  const [fees, setFees] = useState("");
  const [taxes, setTaxes] = useState("");
  const [insuredType, setInsuredType] = useState("Commercial");
  const [isBusinessEmail, setIsBusinessEmail] = useState(false);
  const [isUpdateInNowCerts, setIsUpdateInNowCerts] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [coverageLimitDetails, setCoverageLimitDetails] = useState([]);
  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getSmartScanHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getSmartScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/getsmartscanhistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSmartScanHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while getting SmartScan history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setSmartScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setSmartScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const numericValue = phoneNumber.replace(/\D/g, "");
    const phoneNumberLength = numericValue.length;
    if (phoneNumberLength <= 3) {
      return numericValue;
    } else if (phoneNumberLength <= 6) {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
    }
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };

  const updateInAMSStatus = (rowData: any) => {
    return rowData.isUpdateInNowCerts === true ? (
      <span className="">Yes</span>
    ) : rowData.isUpdateInNowCerts === false ? (
      <span className="">No</span>
    ) : (
      <span className="">No</span>
    );
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const actionTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );
  };

  const onPreviewClick = (rowData: any) => {
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setBusinessNameError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setQuoteNumberError("");
    setShowPopUp(true);
    setPreviewFileLoading(true);
    setSubmissionId(rowData.submissionId);
    setIsUpdateInNowCerts(rowData.isUpdateInNowCerts);
    setDocumentType(rowData.documentType);
    setExtractedData(JSON.parse(rowData.responseJson));
    setSelectionOptionsList(JSON.parse(rowData.selectedOptions));
    setCreatedDate(rowData.created);
    const responseJson = JSON.parse(rowData.responseJson);
    const requestJson = JSON.parse(rowData.requestJson);
    const selectedOptions = JSON.parse(rowData.selectedOptions);
    if (rowData.created && new Date(rowData.created) <= new Date("2025-02-14")) {
      setFirstName(responseJson.firstName == null ? "" : responseJson.firstName);
      setLastName(responseJson.lastName == null ? "" : responseJson.lastName);
      setEmail(responseJson.email == null ? "" : responseJson.email);
      setFein(responseJson.fein);
      setPhoneNumber(responseJson.phoneNumber);
      setAddressLine1(responseJson.addressLine1 == null ? "" : responseJson.addressLine1);
      setCity(responseJson.city == null ? "" : responseJson.city);
      setState(responseJson.state == null ? "" : responseJson.state);
      setZipCode(responseJson.zipCode == null ? "" : responseJson.zipCode);
      setPolicyNumber(responseJson.policyNumber == null ? "" : responseJson.policyNumber);
      setQuoteNumber(responseJson.quoteNumber == null ? "" : responseJson.quoteNumber);
      setPolicyPremium(responseJson.policyPremium == null || responseJson.policyPremium == "" ? "0" : responseJson.policyPremium);
      setCarrierName(responseJson.carrierName == null ? "" : responseJson.carrierName);
      setEffectiveDate(responseJson.effectiveDate == null ? "" : responseJson.effectiveDate);
      setExpirationDate(responseJson.proposedExpDate == null ? "" : responseJson.proposedExpDate);
      setFees("0");
      setTaxes("0");
      setBusinessName(responseJson.businessName == null ? "" : responseJson.businessName);
      setLineOfBusiness(responseJson.lineOfBusinessName == null ? "" : responseJson.lineOfBusinessName);
    } else if (rowData.isUpdateInNowCerts == false || isUpdateInNowCerts == null) {
      setBusinessName(responseJson.businessName == null ? "" : responseJson.businessName);
      setFirstName(responseJson.firstName == null ? "" : responseJson.firstName);
      setLastName(responseJson.lastName == null ? "" : responseJson.lastName);
      setEmail(responseJson.email == null ? "" : responseJson.email);
      setFein(responseJson.fein);
      setPhoneNumber(responseJson.phoneNumber);
      setAddressLine1(responseJson.address == null ? "" : responseJson.address);
      setCity(responseJson.city == null ? "" : responseJson.city);
      setState(responseJson.state == null ? "" : responseJson.state);
      setZipCode(responseJson.zipCode == null ? "" : responseJson.zipCode);
      setPolicyNumber(responseJson.policyNumber == null ? "" : responseJson.policyNumber);
      setQuoteNumber(responseJson.quoteNumber == null ? "" : responseJson.quoteNumber);
      setPolicyPremium(responseJson.policyPremium == null || responseJson.policyPremium == "" ? "0" : responseJson.policyPremium.replace(/[^0-9.]/g, "").replace("$", "").replace(",", ""));
      setCarrierName(responseJson.carrierName == null ? "" : responseJson.carrierName);
      setEffectiveDate(responseJson.effectiveDate == null ? "" : responseJson.effectiveDate.replace(/[^0-9/]/g, ""));
      setExpirationDate(responseJson.expirationDate == null ? "" : responseJson.expirationDate.replace(/[^0-9/]/g, ""));
      setFees(responseJson.fees == null || responseJson.fees == "" ? "0" : responseJson.fees.replace(/[^0-9.]/g, "").replace("$", "").replace(",", ""));
      setTaxes(responseJson.taxes == null || responseJson.taxes == "" ? "0" : responseJson.taxes.replace(/[^0-9.]/g, "").replace("$", "").replace(",", ""));
      setLineOfBusiness(responseJson.linesOfBusiness == null ? "" : responseJson.linesOfBusiness);
      setCoverageLimitDetails(responseJson.coverageLimitDetails);
    } else {
      setBusinessName(requestJson.businessName);
      setFirstName(requestJson.firstName);
      setLastName(requestJson.lastName);
      setEmail(requestJson.email);
      setFein(requestJson.fein);
      setPhoneNumber(requestJson.phoneNumber);
      setAddressLine1(requestJson.addressLine1);
      setCity(requestJson.city);
      setState(requestJson.state);
      setZipCode(requestJson.zipCode);
      setPolicyNumber(requestJson.policyNumber);
      setQuoteNumber(requestJson.quoteNumber);
      setPolicyPremium(requestJson.policyPremium);
      setCarrierName(requestJson.carrierName);
      setEffectiveDate(requestJson.effectiveDate);
      setExpirationDate(requestJson.expirationDate);
      setLineOfBusiness(requestJson.lineOfBusiness);
      setFees(requestJson.fees);
      setTaxes(requestJson.taxes);
      setCoverageLimitDetails(requestJson.coverageLimitDetails);
      setInsuredType(requestJson.insuredType);
    }

    if (selectedOptions != null) {
      setCreateQuote(selectedOptions.CreateQuote);
      setCreateProspect(selectedOptions.CreateNewProspect);
      setCreateInsured(selectedOptions.CreateNewClient);
      setInsuredDatabaseId(selectedOptions.InsuredId);
      setCreateNewPolicy(selectedOptions.CreateNewPolicy);
    }


    const token = CommonValues.GetToken();
    var data = {
      TransactionId: rowData.submissionId,
      FileName: rowData.fileName,
      Target: "SmartScan",
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.fileUrl);
        setTimeout(() => { setPreviewFileLoading(false) }, 2000)

      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
    setPreviewFileLoading(false);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
    getSmartScanHistory();
    setActiveTab("Insured");
  };
  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };

  const onChangeInsuredFirstName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setFirstName(Value);
    setInsuredFirstNameError("");
  };
  const onChangeInsuredLastName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setLastName(Value);
    setInsuredLastNameError("");
  };
  const onChangeInsuredEmail = (value: any) => {
    const Value = value.slice(0, 255);
    setEmail(Value);
    setInsuredEmailError("");
  };

  const onChangeMailingAddress1 = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setAddressLine1(Value);
    setmailingAddressLine1Error("");
  };
  const onChangeAgencyMailingCity = (value: any) => {
    const Value = value.slice(0, 255);
    setCity(Value);
    setMailingCityError("");
  };
  const onChangeAgencyMailingState = (value: any) => {
    setState(value);
    setMailingStateError("");
  };
  const onChangeAgencyMailingZip = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length > 5) {
        return;
      }
      setZipCode(value);
      setMailingZipCodeError("");
    }
  };
  const onChangePolicyNumber = (value: any) => {
    const Value = value.slice(0, 255);
    setPolicyNumber(Value);
    setPolicyNumberError("");
  };
  const onChangePolicyPremium = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setPolicyPremium(value);
      setPolicyPremiumError("");
    }
  };
  const onChangeFees = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setFees(value);
    }
  };
  const onChangeTaxes = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setTaxes(value);
    }
  };

  const onChangeCarrierName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    if (value == "") {
      setCarrierName("");
    } else {
      setCarrierName(Value);
      setCarrierNameError("");
    }
  };
  const onChangeInsuredType = (value: any) => {
    setInsuredType(value);
  };
  const onChangeLineOfBusiness = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setLineOfBusiness(Value);
    setLineOfBusinessError("");
  };
  const onChangeQuoteNumber = (value: any) => {
    const Value = value.slice(0, 255);
    setQuoteNumber(Value);
    setQuoteNumberError("");
  };
  const onChangeEffectiveDate = (value: any) => {
    setEffectiveDate(value);
    setEffectiveDateError("");
    createExpiryDate(value);
  };

  const createExpiryDate = (value: string) => {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
      console.error("Invalid date format. Expected 'MM/dd/yyyy'. Got:", value);
      return "Invalid date format";
    }

    const [month, day, year] = value.split("/").map((val) => parseInt(val, 10));

    const result = new Date(year, month - 1, day);

    if (isNaN(result.getTime())) {
      console.error("Invalid Date Object:", result);
      return "Invalid date";
    }

    return result.toISOString().split("T")[0];
  };

  const onChangeExpiryDate = (value: any) => {
    setExpirationDate(value);
    setExpiryDateError("");
  };
  const onChangeBusinessName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setBusinessName(Value);
    setBusinessNameError("");
  };

  const onChangeEmail = (value: any) => {
    const limitedValue = value.slice(0, 64);
    setEmail(limitedValue);
    setBusinessEmailError("");
  };
  const onChangePhoneNumber = (value: any) => {
    const inputValue = value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setPhoneNumber(limitedValue);
    setInsuredPhoneNumberError("");
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div ">
            <div className="col-12">
              <SmartScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SmartScanSidebar pagename="smart-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  {historyloading ? (
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <div className="col-12  history-table">
                      <div className="row mb-3 mt-2">
                        <div className="col-6">
                          <span className="p-input-icon-left w-50">
                            <i className="fa fa-search grid-search-icon" />
                            <AtkTextField
                              type="text"
                              id="txtfirstName"
                              name="globalFilter"
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              value={globalFilter}
                              className="input-field"
                              placeholder="Search..."
                            />
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <AtkButton label="Refresh" onClick={getSmartScanHistory} className=" pf-primary-btn w-auto" />
                        </div>
                      </div>
                      <DataTable
                        value={smartscanhistorydata}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        selectionMode="single"
                        globalFilter={globalFilter}
                        emptyMessage="No data available."
                      >
                        <Column header="File Name" field="fileName" sortable></Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          sortable
                        ></Column>
                        <Column header="Document Type" field="documentType" sortable></Column>
                        <Column header="Update In AMS" body={updateInAMSStatus} sortable></Column>
                        <Column header="Status" field="status" body={statusTemplate} sortable></Column>
                        <Column header="Action" body={actionTemplate}></Column>
                        <Column header="Feedback" body={feedbackTemplate} className="text-center"></Column>
                      </DataTable>
                    </div>
                  )}

                  <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                    {previewFileLoading ? (
                      <div className="d-flex align-items-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                          <div className="col-2"></div>
                          <div className="col-10 d-flex justify-content-end">
                            {createdDate && new Date(createdDate) >= new Date("2025-02-14") && isUpdateInNowCerts == false ? (
                              <>
                                {fromHistory == true ? (
                                  <>

                                    <div className="d-flex align-items-center">
                                      <RadioButton
                                        inputId="prospect"
                                        name="prospect"
                                        value="Prospect"
                                        checked={selectionOptionsList.SelectProspect == true || selectionOptionsList.SelectInsured == true}
                                        disabled={true}
                                      />
                                      <label htmlFor="prospect" className="mx-2">
                                        <b> {selectionOptionsList.SelectProspect == true ? "Select Prospect" : "Select Insured"}</b>
                                      </label>
                                      {selectionOptionsList.SelectProspect == true ? (
                                        <>
                                          {selectionOptionsList.CreateNewProspect == true ? (
                                            <div className="p-field-checkbox d-flex align-items-center ">
                                              <label htmlFor="createNew">Create New Prospect</label>
                                              <Toggle id="createNew" isChecked={true} isDisabled={true} />
                                            </div>
                                          ) : (
                                            <>
                                              <label htmlFor="createNew">Existing Prospect</label>
                                              <Toggle id="createNew" isChecked={true} isDisabled={true} />
                                            </>
                                          )}
                                        </>
                                      ) : null}

                                      {selectionOptionsList.SelectInsured == true ? (
                                        <>
                                          {selectionOptionsList.CreateNewClient == true ? (
                                            <div className="p-field-checkbox d-flex align-items-center ">
                                              <label htmlFor="createNew">Create New Insured</label>
                                              <Toggle id="createNew" isChecked={true} isDisabled={true} />
                                            </div>
                                          ) : (
                                            <>
                                              <label htmlFor="createNew">Existing Insured</label>
                                              <Toggle id="createNew" isChecked={true} isDisabled={true} />
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                      {selectionOptionsList.CreateQuote == true ? (
                                        <>
                                          <label htmlFor="createNew">Create Quote</label>
                                          <Toggle id="createNew" isChecked={true} isDisabled={true} />
                                        </>
                                      ) : null}
                                      {selectionOptionsList.CreateNewPolicy == true ? (
                                        <>
                                          <label htmlFor="createNew">New Policy</label>
                                          <Toggle id="createNew" isChecked={true} isDisabled={true} />
                                        </>
                                      ) : null}
                                      {selectionOptionsList.ClientName != "" ? (
                                        <div className="search-bar flex-column">
                                          <AtkTextField type="text" id="client-name" value={selectionOptionsList.ClientName} isDisabled={true} />
                                        </div>
                                      ) : null}
                                    </div>

                                  </>
                                ) : null}
                              </>
                            ) : null}
                            {isUpdateInNowCerts == true ? (
                              <>
                                <div className="col-4">
                                  <span className="text-16 fw-bold">Update In AMS</span>
                                  <AtkTextField type="text" id="text" className="" name="clientName" value="Yes" isDisabled={true} />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-6 border-end mt-2">
                          <b>Uploaded PDF</b>
                          <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                        </div>
                        <div
                          className="col-6 p-2 mt-2"
                          style={{
                            border: "1px solid darkgrey",
                            borderRadius: "30px",
                            minHeight: "66vh",
                          }}
                        >
                          <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                            <div className="col-12 d-flex align-items-center justify-content-start">
                              <span
                                className={activeTab === "Insured" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                onClick={() => {
                                  handleTabClick("Insured");
                                }}
                              >
                                Insured Details
                              </span>
                              {documentType == "DecPage" || documentType == "QuoteLetter" ?
                                <>
                                  {createdDate && new Date(createdDate) >= new Date("2025-02-14") ?
                                    <span
                                      className={activeTab === "CoverageLimit" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                      onClick={() => {
                                        handleTabClick("CoverageLimit");
                                      }}
                                    >
                                      Coverage Limit Details
                                    </span>
                                    : null}
                                </>
                                : null}
                            </div>
                          </div>
                          {activeTab === "Insured" ? (
                            <SmartScanDataExtractPage
                              firstName={firstName}
                              lastName={lastName}
                              email={email}
                              policyNumber={policyNumber}
                              policyPremium={policyPremium}
                              carrierName={carrierName}
                              lineOfBusiness={lineOfBusiness}
                              quoteNumber={quoteNumber}
                              effectiveDate={effectiveDate}
                              expirationDate={expirationDate}
                              businessName={businessName}
                              addressLine1={addressLine1}
                              city={city}
                              state={state}
                              phoneNumber={phoneNumber}
                              onChangePhoneNumber={onChangePhoneNumber}
                              submissionId={submissionId}
                              zipCode={zipCode}
                              createQuote={createQuote}
                              createInsured={createInsured}
                              insuredDatabaseId={insuredDatabaseId}
                              insuredFirstNameError={insuredFirstNameError}
                              setInsuredFirstNameError={setInsuredFirstNameError}
                              insuredLastNameError={insuredLastNameError}
                              setInsuredLastNameError={setInsuredLastNameError}
                              insuredEmailError={insuredEmailError}
                              setInsuredEmailError={setInsuredEmailError}
                              policyNumberError={policyNumberError}
                              setPolicyNumberError={setPolicyNumberError}
                              policyPremiumError={policyPremiumError}
                              setPolicyPremiumError={setPolicyPremiumError}
                              carrierNameError={carrierNameError}
                              setCarrierNameError={setCarrierNameError}
                              lineOfBusinessError={lineOfBusinessError}
                              setLineOfBusinessError={setLineOfBusinessError}
                              effectiveDateError={effectiveDateError}
                              setEffectiveDateError={setEffectiveDateError}
                              expiryDateError={expiryDateError}
                              setExpiryDateError={setExpiryDateError}
                              businessNameError={businessNameError}
                              setBusinessNameError={setBusinessNameError}
                              quoteNumberError={quoteNumberError}
                              setQuoteNumberError={setQuoteNumberError}
                              mailingAddressLine1Error={mailingAddressLine1Error}
                              setmailingAddressLine1Error={setmailingAddressLine1Error}
                              mailingCityError={mailingCityError}
                              setMailingCityError={setMailingCityError}
                              mailingStateError={mailingStateError}
                              setMailingStateError={setMailingStateError}
                              mailingZipCodeError={mailingZipCodeError}
                              setMailingZipCodeError={setMailingZipCodeError}
                              fromHistory={fromHistory}
                              selectionOptionsList={selectionOptionsList}
                              createdDate={createdDate}
                              fees={fees}
                              taxes={taxes}
                              onChangeInsuredFirstName={onChangeInsuredFirstName}
                              onChangeInsuredLastName={onChangeInsuredLastName}
                              onChangeInsuredEmail={onChangeInsuredEmail}
                              onChangePolicyNumber={onChangePolicyNumber}
                              onChangePolicyPremium={onChangePolicyPremium}
                              onChangeCarrierName={onChangeCarrierName}
                              onChangeLineOfBusiness={onChangeLineOfBusiness}
                              onChangeFees={onChangeFees}
                              onChangeTaxes={onChangeTaxes}
                              onChangeEffectiveDate={onChangeEffectiveDate}
                              onChangeExpiryDate={onChangeExpiryDate}
                              onChangeAgencyMailingAddress1={onChangeMailingAddress1}
                              onChangeAgencyMailingCity={onChangeAgencyMailingCity}
                              onChangeAgencyMailingState={onChangeAgencyMailingState}
                              onChangeAgencyMailingZip={onChangeAgencyMailingZip}
                              onChangeQuoteNumber={onChangeQuoteNumber}
                              onChangeBusinessName={onChangeBusinessName}
                              onChangeEmail={onChangeEmail}
                              createQuoteForInsured={createQuote}
                              createNewPolicyForInsured={createNewPolicy}
                              createNewPolicy={createNewPolicy}
                              createExistingPolicyForInsured={insuredDatabaseId}
                              createProspect={createProspect}
                              prospectDatabaseId={insuredDatabaseId}
                              onHidePopupClicked={onHidePopupClicked}
                              isUpdateInNowCerts={isUpdateInNowCerts}
                              documentType={documentType}
                              onChangeInsuredType={onChangeInsuredType}
                              insuredType={insuredType}
                              coverageLimitDetails={coverageLimitDetails}
                            />
                          ) : null}
                          {documentType == "DecPage" || documentType == "QuoteLetter" ?
                            <>
                              {createdDate && new Date(createdDate) >= new Date("2025-02-14") ?
                                <>
                                  {activeTab === "CoverageLimit" ? (
                                    <div className="card mt-2">
                                      <div className="col-12  history-table p-3 rounded-bottom">
                                        {coverageLimitDetails.map((item: any, index: number) => (
                                          <div key={index}>
                                            {item.generalLiability == "" || item.generalLiability == undefined ?
                                              null
                                              : <>
                                                <span className="text-12 fw-bold">General liability</span>
                                                <div className="col-12   w-100 p-0">
                                                  <div className="history-expanded-block ">
                                                    <div className="row mx-auto">
                                                      <div className="row ">
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit damage to rented premises</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.generalLiability.limitDamageToRentedPremises == undefined ? "" : item.generalLiability.limitDamageToRentedPremises}
                                                          </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit each occurrence</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.generalLiability.limitEachOccurrence}
                                                          </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit general aggregate</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.generalLiability.limitGeneralAggregate}
                                                          </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit med exp</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.generalLiability.limitMedExp}
                                                          </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-ite</div>ms-center fw-bold ">
                                                          <span>Limit personal and injury</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.generalLiability.limitPersonalAndAdvInjury}
                                                          </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit products comp aggregate</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.generalLiability.limitProductsCompOpAggregate}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>}

                                            {/* {item.autoMobileLiability == "" || item.autoMobileLiability == undefined ?
                                              null
                                              : <>
                                              <span className="text-12 fw-bold">Auto Mobile Liability </span>
                                              <div className="col-12   w-100 p-0">
                                                <div className="history-expanded-block ">
                                                  <div className="row mx-auto">
                                                    <div className="row ">
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit Combined Single</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitCombinedSingle}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit bodily injury per person</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitBodilyInjuryPerPerson}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit bodily injury per accident</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitBodilyInjuryPerAccident}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit property damage</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitPropertyDamage}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>} */}

                                            {item.WorkerCompensationAndEmployersLiability == "" || item.WorkerCompensationAndEmployersLiability == undefined ?
                                              null
                                              : <>
                                                <span className="text-12 fw-bold">Worker Compensation Liability </span>
                                                <div className="col-12   w-100 p-0">
                                                  <div className="history-expanded-block ">
                                                    <div className="row mx-auto">
                                                      <div className="row ">
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit each accident</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.WorkerCompensationAndEmployersLiability.limitEachAccident}
                                                          </span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Limit EA accident</span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.WorkerCompensationAndEmployersLiability.limitEAEmployee}
                                                          </span>
                                                        </div>

                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                          <span>Policy limit </span>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                          <span>
                                                            {item.WorkerCompensationAndEmployersLiability.limitPolicy}
                                                          </span>
                                                        </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                                : null}
                            </>
                            : null}
                        </div>
                      </div>
                    )}
                  </Dialog>

                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedBackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {submissionId == feedBackData.submissionId && feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
