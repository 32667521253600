import TextField from "@atlaskit/textfield";
import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import CommonValues from "../../common/utils";

export default function InvoiceScanDataExtractPage(props: any) {
  const feedbackRef = useRef<OverlayPanel>(null);
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("PolicyInfo");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [isUploadAgain, setIsUploadAgain] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  let options1 = [
    { value: "Commercial", label: "Commercial" },
    { value: "Personal", label: "Personal" },
  ];

  const billingTypeData = [
    { name: "Initial_Base_Premium", value: 0 },
    { name: "Positive_Endorsement", value: 1 },
    { name: "Negative_Endorsement", value: 2 },
    { name: "Cancel", value: 5 },
    { name: "Renew", value: 6 },
    { name: "Reinstate", value: 7 },
    { name: "Govt_Discount", value: 9 },
    { name: "Premium_Audit", value: 13 },
    { name: "General_Discount", value: 14 },
    { name: "Credit", value: 15 },
    { name: "Installment", value: 200 },
    { name: "New_Policy_Premium", value: 210 },
    { name: "No_Premium_Impact", value: 230 },
    { name: "Fees", value: 3 },
    { name: "Agency_Fee", value: 10 },
    { name: "Risk_Management_Fee", value: 101 },
    { name: "Carrier_Fee", value: 11 },
    { name: "Policy_Fee", value: 111 },
    { name: "MGA_Fee", value: 12 },
    { name: "Inspection_Fee", value: 20 },
    { name: "Al_Endorsements_Fee", value: 21 },
    { name: "Subscription_Fee", value: 16 },
    { name: "Program_Fee", value: 19 },
    { name: "Taxes", value: 4 },
    { name: "State_Tax", value: 17 },
    { name: "Surplus_Lines_Tax", value: 22 },
    { name: "Miscellaneous_Agency_Revenue", value: 23 },
    { name: "Other_Tax", value: 18 },
    { name: "Fire_Marshall_Tax", value: 181 },
    { name: "Processing_Fee", value: 35 },
    { name: "Filing_Fee", value: 351 },
    { name: "APTC_Tax_Credit", value: 45 },
    { name: "Expense_Constant", value: 100 },
    { name: "Surplus_Contribution", value: 150 },
    { name: "AB_TPA_Fee", value: 160 },
    { name: "Independent_Agent_Broker_Fee", value: 170 },
    { name: "Payment_Processing_Fee", value: 180 },
    { name: "Collateral_Fee", value: 190 },
    { name: "MVR_Fee", value: 240 },
    { name: "CC_Fee", value: 250 },
    { name: "Admin_Fee", value: 350 },
    { name: "Other", value: 8 },
  ];

  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = {
      ...feedBackData,
      feedback: newFeedback,
      submissionId: props.submissionId,
    };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      onSaveFeedback(rowData);
      setFeedbackLoading(true);
    }
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: props.transactionId,
      Feedback: feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/ratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Feedback submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting ratings",
              life: 3000,
            });
          }
        }
      });
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: props.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Feedback Submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting feedback",
              life: 3000,
            });
          }
        }
      });
  };

  const onSubmitClick = () => {
    setSubmitLoading(true);
    const policyEndorsementFee = getPolicyEndorsementFeeMappingData();
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    const data = JSON.stringify(policyEndorsementFee);
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertpolicyendorsementfee?ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        setShowSubmitButton(true);
        toast.current.show({
          summary: "Success",
          severity: "success",
          detail: "Policy endorsement fee added successfully",
          life: 3000,
        });
        setIsUploadAgain(true);
        updateInvoiceHistoryScanHistory();
      })
      .catch((error) => {
        let errorMessage = "";
        setSubmitLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while adding policy endorsement fee.",
              life: 3000,
            });
          }
        }
      });
  };

  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };
  const getPolicyEndorsementFeeMappingData = () => {
    let policyEndorsementFee: any = [];
    props.invoiceLineItems.map((item: any, index: any) => {
      {
        let formattedPremium =
          item.grossAmount == "" || item.grossAmount == null
            ? item.lineAmount == null || item.lineAmount == ""
              ? 0
              : item.lineAmount.replace("$", "").replace(",", "")
            : item.grossAmount.replace("$", "").replace(",", "");
        if (item.transactionCodeAfterMapped == "Premium") {
          policyEndorsementFee.push({
            order: index,
            policyId: props.selectedPolicyId,
            amount: parseFloat(formattedPremium),
            date: new Date().toJSON(),
            premiumType: 0,
            type: 0,
            followUpDate: new Date().toJSON(),
          });
        }
      }
    });
    props.invoiceLineItems.map((item: any, index: any) => {
      let formattedValue =
        item.grossAmount == "" || item.grossAmount == null
          ? item.lineAmount == null || item.lineAmount == ""
            ? 0
            : item.lineAmount.replace("$", "").replace(",", "")
          : item.grossAmount.replace("$", "").replace(",", "");
      billingTypeData.map((billingType: any) => {
        if (item.transactionCodeAfterMapped === billingType.name) {
          policyEndorsementFee.push({
            order: index,
            policyId: props.selectedPolicyId,
            amount: parseFloat(formattedValue),
            date: new Date().toJSON(),
            premiumType: 1,
            type: billingType.value,
            followUpDate: new Date().toJSON(),
          });
        }
      });
    });

    return policyEndorsementFee;
  };

  const updateInvoiceHistoryScanHistory = () => {
    let token = CommonValues.GetToken();
    const submittedRequestJson = {
      invoiceNumber: props.invoiceNumber,
      invoiceDate: props.invoiceDate,
      invoiceDueDate: props.invoiceDueDate,
      totalAmountDue: props.totalAmountDue,
      totalGrossAmount: props.totalGrossAmount,
      insuredName: props.insuredName,
      policyNumber: props.policyNumber,
      invoiceLineItems: props.invoiceLineItems,
    };
    const selectionOptionsJson = {
      useExistingInsured: true,
      insuredClientId: props.insuredClientId,
      clientName: props.clientName,
      policyNumber: props.policyNumber,
      selectedPolicyId: props.selectedPolicyId,
    };
    var data = {
      TransactionId: props.transactionId,
      submittedRequestJson: JSON.stringify(submittedRequestJson),
      selectionOptionsJson: JSON.stringify(selectionOptionsJson),
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/updatehistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while invoice scan updating the request.",
            life: 3000,
          });
        }
      });
  };

  const billingTypeBodyTemplate = (rowData: any) => {
    if (rowData.transactionCodeAfterMapped == "Policy_Fee") {
      return "Policy Fee";
    }
    if (rowData.transactionCodeAfterMapped == "Premium_Tax") {
      return "Taxes";
    }
    if (rowData.transactionCodeAfterMapped == "Other_Tax") {
      return "Other Taxes";
    }
    if (rowData.transactionCodeAfterMapped == "State_Tax") {
      return "State Tax";
    }
    return rowData.transactionCodeAfterMapped;
  };
  return (
    <>
      <div
        className="col p-2 ms-2 mt-2"
        style={{
          border: "1px solid darkgrey",
          borderRadius: "30px",
          minHeight: "66vh",
        }}
      >
        <Toast ref={toast} />
        <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
          <div className="col-6 d-flex align-items-center justify-content-start">
            <span className="policy-name-span active">Invoice Details</span>
          </div>
          <div className="col-6 "></div>
        </div>
        {props.isUpdateInNowCerts == true ? (
          <div className="row smartscan-block  mx-auto mt-2 text-12 ">
            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Invoice Number</label>
              <TextField
                className="input-field"
                type="text"
                value={props.invoiceNumber}
                isDisabled={true}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Invoice Date</label>
              <TextField
                className="input-field"
                type="text"
                value={props.invoiceDate}
                isDisabled={true}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Invoice Due Date</label>
              <TextField
                className="input-field"
                type="text"
                value={props.invoiceDueDate}
                isDisabled={true}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Total Amount Due</label>
              <TextField
                className="input-field"
                type="text"
                value={props.totalAmountDue}
                isDisabled={true}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Total Gross Amount</label>

              <TextField
                className="input-field"
                type="text"
                value={props.totalGrossAmount}
                isDisabled={true}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Insured Name</label>

              <TextField
                className="input-field"
                type="text"
                value={props.insuredName}
                isDisabled={true}
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Policy Number</label>

              <TextField
                className="input-field"
                type="text"
                value={props.policyNumber}
                isDisabled={true}
              />
            </div>

            <div className="col-12 divider"></div>

            <div className="col-12  history-table p-3 rounded-bottom">
              <DataTable
                value={props.invoiceLineItems}
                paginator
                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                rows={8}
                selectionMode="single"
                emptyMessage="No data available."
              >
                <Column header="Line Code" field="lineCode" sortable></Column>
                <Column
                  header="Transaction Code"
                  field="transactionCodeBeforeMapped"
                  sortable
                ></Column>
                <Column
                  header="Billing Type"
                  body={billingTypeBodyTemplate}
                  sortable
                ></Column>
                <Column
                  header="EffectiveDate"
                  field="effectiveDate"
                  sortable
                ></Column>
                <Column
                  header="Gross Amount"
                  field="grossAmount"
                  sortable
                ></Column>
                <Column
                  header="Broker Commission"
                  field="brokerCommission"
                  sortable
                ></Column>
                <Column
                  header="Line Amount"
                  field="lineAmount"
                  sortable
                ></Column>
              </DataTable>
            </div>
          </div>
        ) : (
          <div className="row smartscan-block  mx-auto mt-2 ">
            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Invoice Number</label>
              <TextField
                className="input-field"
                type="text"
                value={props.invoiceNumber}
                onChange={(e: any) =>
                  props.onChangeInvoiceNumber(e.target.value)
                }
                placeholder="Enter invoice number"
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Invoice Date</label>
              <AtkTextField
                className="input-field "
                type="date"
                id="text"
                value={
                  props.invoiceDate != undefined && props.invoiceDate != null
                    ? moment(props.invoiceDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e: any) => props.onChangeInvoiceDate(e.target.value)}
                placeholder="Select invoice date"
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Invoice Due Date</label>
              {/* <TextField
                            className="input-field"
                            type="text"
                            value={props.invoiceDueDate}
                            onChange={(e: any) => props.onInvoiceDueDateChange(e.target.value)}
                            placeholder="Enter invoice due date"
                        /> */}
              <AtkTextField
                className="input-field "
                type="date"
                id="text"
                value={
                  props.invoiceDueDate != undefined &&
                  props.invoiceDueDate != null
                    ? moment(props.invoiceDueDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e: any) =>
                  props.onChangeInvoiceDueDate(e.target.value)
                }
                placeholder="Select invoice due date"
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Total Amount Due</label>
              <TextField
                className="input-field"
                type="text"
                value={props.totalAmountDue}
                onChange={(e: any) =>
                  props.onChangeTotalDueAmount(e.target.value)
                }
                placeholder="Enter total amount due"
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Total Gross Amount</label>

              <TextField
                className="input-field"
                type="text"
                value={props.totalGrossAmount}
                onChange={(e: any) =>
                  props.onChangeTotalGrossAmount(e.target.value)
                }
                placeholder="Enter total gross amount"
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Insured Name</label>

              <TextField
                className="input-field"
                type="text"
                value={props.insuredName}
                onChange={(e: any) => props.onChangeInsuredName(e.target.value)}
                placeholder="Enter insured name"
              />
            </div>

            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
              <label>Policy Number</label>

              <TextField
                className="input-field"
                type="text"
                value={props.policyNumber}
                onChange={(e: any) =>
                  props.onChangePolicyNumber(e.target.value)
                }
                placeholder="Enter policy number"
              />
            </div>

            <div className="col-12 divider"></div>

            <div className="col-12  history-table p-3 rounded-bottom">
              <DataTable
                value={props.invoiceLineItems}
                paginator
                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                rows={8}
                selectionMode="single"
                emptyMessage="No data available."
              >
                <Column header="Line Code" field="lineCode" sortable></Column>
                <Column
                  header="Transaction Code"
                  field="transactionCodeBeforeMapped"
                  sortable
                ></Column>
                <Column
                  header="Billing Type"
                  body={billingTypeBodyTemplate}
                  sortable
                ></Column>
                <Column
                  header="Effective Date"
                  field="effectiveDate"
                  sortable
                ></Column>
                <Column
                  header="Gross Amount"
                  field="grossAmount"
                  sortable
                ></Column>
                <Column
                  header="Broker Commission"
                  field="brokerCommission"
                  sortable
                ></Column>
                <Column
                  header="Line Amount"
                  field="lineAmount"
                  sortable
                ></Column>
              </DataTable>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 d-flex align-items-center  justify-content-end my-2">
            {props.isUpdateInNowCerts == true ? null : (
              <>
                {submitLoading ? (
                  <div className="d-inline-block me-2 ">
                    <AtkLoader />
                  </div>
                ) : (
                  <>
                    {isUploadAgain ? (
                      <>
                        {props.fromHistoryPage == true ? null : (
                          <>
                            <AtkButton
                              label="Upload Again"
                              onClick={props.onUploadAgain}
                              className="pf-secondary-btn w-auto me-2"
                            />
                            <AtkButtonIconBefore
                              label="Feedback"
                              onClick={(e: any) => {
                                onFeedbackClick(e);
                              }}
                              className="pf-secondary-btn w-auto me-2 "
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <AtkButton
                        label="Add Endorsement Fees"
                        onClick={onSubmitClick}
                        className="pf-secondary-btn w-auto me-2"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {props.formHistoryPage == true ? null : (
            <OverlayPanel
              ref={feedbackRef}
              className="commission-feedback-panel feedback-panel"
            >
              <div className="row mx-auto d-flex align-items-center mb-2">
                <div className="col-12 feedback-header">
                  <h4>Feedback</h4>
                </div>
                <div className={"form-group col-12 mb-2 text-12"}>
                  <textarea
                    name="feedback"
                    onChange={(e) => onFeedbackChange(e.target.value)}
                    value={feedBackData.feedback}
                    className={`form-control comment-text ${
                      feedBackError.length > 0 ? " validation-div " : ""
                    }`}
                    rows={4}
                    placeholder="Enter your feedback here"
                  />
                  <span className="text-danger  text-12 mt-1">
                    {feedBackError}
                  </span>
                </div>
                <div className="col-4 d-flex justify-content-evenly">
                  <i
                    className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                                                                              ${
                                                                                feedBackData.isLiked ==
                                                                                true
                                                                                  ? " active"
                                                                                  : " "
                                                                              }`}
                    aria-hidden="true"
                    onClick={(e) => onThumbsUpClick(feedBackData)}
                  ></i>
                  <i
                    className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                                                                ${
                                                                                  feedBackData.isLiked ==
                                                                                  false
                                                                                    ? " active"
                                                                                    : " "
                                                                                }`}
                    aria-hidden="true"
                    onClick={(e) => onThumbDownClick(feedBackData)}
                  ></i>
                </div>
                <div className="col-4 text-end p-2 pe-0  ">
                  <AtkButton
                    label="Cancel"
                    onClick={onHideFeedbackPopup}
                    className="pf-secondary-btn w-auto"
                  />
                </div>
                <div className="col-4 text-end p-2  ">
                  {feedbackLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <AtkLoader />
                    </div>
                  ) : (
                    <AtkButton
                      label="Submit"
                      onClick={() => onSubmitFeedbackClick(feedBackData)}
                      className="pf-secondary-btn w-auto "
                    />
                  )}
                </div>
              </div>
            </OverlayPanel>
          )}
        </div>
      </div>
    </>
  );
}
