import React from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import { useEffect, useState, useRef } from "react";
import AtkButtonIconBefore from "../AtlaskitControls/AtkButtonIconBefore";
import StarIcon from "@atlaskit/icon/glyph/star";
import ChevronRightLargeIcon from "@atlaskit/icon/glyph/chevron-right-large";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import EditIcon from "@atlaskit/icon/glyph/edit";
import ShareIcon from "@atlaskit/icon/glyph/share";
import CheckCircleOutlineIcon from "@atlaskit/icon/glyph/check-circle-outline";
import MoreBtn from "../assets/images/continue.png";
import { useNavigate } from "react-router-dom";

export default function Easy2QuoteQuotesPage() {
  const [capitalLogoUrl, setCapitalLogoUrl] = useState("https://d1u42a3y69tca1.cloudfront.net/companydata/765/logo/CapitalPremiunFinanceLogo.jpg");
  const [heightFull, setHeightFull] = useState(false);
  const navigate = useNavigate();

  const onMoreButtonClick = () => {
    setHeightFull(!heightFull);
  };
  const onBackButtonClick = () => {
    navigate("/easy2quote");
  };
  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-end">
        <AtkButton label="Back" className="show-direct-btn w-auto me-3" onClick={onBackButtonClick} />

        <AtkButton label="Advance Options" className="show-direct-btn w-auto" />
      </div>
      <div className="card shadow mt-3">
        <div className="card-header d-flex justify-content-between align-items-center bg-light">
          <h5 className="mb-0">
            <img src={capitalLogoUrl} width="25" height="25" /> Capital Premium Financing Quote
          </h5>
          <AtkButtonIconBefore
            label="Preferred Partner Choice "
            //   onClick={saveQuote}
            iconBefore={<StarIcon label="" />}
            className="pf-primary-btn w-auto me-1   my-1 "
          />
        </div>
        <div className="card-body">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th>Quote Number</th>
                <td>
                  7557395 <i className="bi bi-link-45deg"></i>
                </td>
              </tr>
              <tr>
                <th>Total Premium</th>
                <td>$1,500.00</td>
              </tr>
              <tr>
                <th>Downpayment</th>
                <td>$375.00</td>
              </tr>
              <tr>
                <th>Installment Amount</th>
                <td>$134.01</td>
              </tr>
              <tr>
                <th>Effective APR(%)</th>
                <td>16.980%</td>
              </tr>
              {heightFull ? (
                <>
                  <tr>
                    <td className="fw-bold">Financed Amount</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Finance Charge</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Document Stamp Tax</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Non Refundable Fee</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Number of Installments</td>
                    <td> </td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Broker Fee</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Other Taxes</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Other Fees</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Quote Date</td>
                    <td></td>
                  </tr>
                </>
              ) : null}
              <tr>
                <td></td>
                <td className="text-end ">
                  <img src={MoreBtn} onClick={onMoreButtonClick} className="show-cursor" title={heightFull ? "Show less" : "Show more"} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-footer text-center">
          <div className="row g-2">
            <div className="col-6 ">
              <AtkButtonIconBefore
                label="Save "
                //   onClick={saveQuote}
                iconBefore={<StarIcon label="" />}
                className="pf-primary-btn w-75 me-1   my-1 "
              />
            </div>
            <div className="col-6 ">
              <AtkButtonIconBefore
                label="Contact"
                //   onClick={saveQuote}
                iconBefore={<ChevronRightLargeIcon label="" />}
                className="pf-primary-btn w-75 me-1   my-1 "
              />
            </div>
            <div className="col-6 ">
              <AtkButtonIconBefore
                label="Download"
                //   onClick={saveQuote}
                iconBefore={<DownloadIcon label="" />}
                className="pf-primary-btn w-75 me-1   my-1 "
              />
            </div>
            <div className="col-6 ">
              <AtkButtonIconBefore
                label="Save to AMS Files"
                //   onClick={saveQuote}
                iconBefore={<ShareIcon label="" />}
                className="pf-primary-btn w-75 me-1   my-1 "
              />
            </div>
            <div className="col-6 ">
              <AtkButtonIconBefore
                label="Mark as Bound"
                //   onClick={saveQuote}
                iconBefore={<CheckCircleOutlineIcon label="" />}
                className="pf-primary-btn w-75 me-1   my-1 "
              />
            </div>
            <div className="col-6 ">
              <AtkButtonIconBefore
                label="Show Direct Bill (Easy2Quote"
                //   onClick={saveQuote}
                className="pf-primary-btn w-75  me-1   my-1 "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-3 mb-3">
        <button className="btn btn-lg btn-primary pf-primary-btn">
          <i className="fa fa-list me-2"></i> Load all Integration Premium Finance Companies options
        </button>
      </div>
    </div>
  );
}
