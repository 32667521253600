import SectionMessage from "@atlaskit/section-message";

export default function AtkMessage(props: any) {
  return (
    <SectionMessage 
      appearance={props.appearance} 
      title={props.title}>
      <p className="text-12 mb-0">{props.messageText}</p>
    </SectionMessage>
  );
}
