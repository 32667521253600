import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import Header from "../common/Header";
import BackgroundVideo from "../common/BackgroundVideo";
import SonantMiddleHeader from "./SonantMiddleHeader";
import SonantSidebar from "./SonantSidebar";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonValues from "../common/utils";
import axios from "axios";
import { Skeleton } from "primereact/skeleton";
import { InputSwitch } from "primereact/inputswitch";

export default function SonantLandingPage() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isSonantSubscribed, setIsSonantSubscribed] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    getSonantDetails();
  }, []);
  const features = [
    {
      icon: "fa-clock",
      title: "24/7 Availability",
      description: "Enable 24/7 personalized and consistent service with zero waiting times for your clients.",
    },
    {
      icon: "fa-random",
      title: "Transfer Calls",
      description: "Direct to human agents as needed. Instantly connects customers to your team or third parties.",
    },
    {
      icon: "fa-file-alt",
      title: "Quote Intaking",
      description: "Make quote intaking an automatic process, from a call to quote details in your AMS or rater.",
    },
    {
      icon: "fa-question-circle",
      title: "Servicing Inquiries",
      description: "Collect information relative with requests from customers and third parties.",
    },
    {
      icon: "fa-calendar-check",
      title: "Appointment Scheduling",
      description: "Automatically schedules appointments, fully integrated with your calendar.",
    },
    {
      icon: "fa-sticky-note",
      title: "Post Call Notes",
      description: "Summarizes calls and extracts relevant data and automatically updates your systems.",
    },
    { icon: "fa-language", title: "Multilingual", description: "Speaks and understands multiple languages, even in the same call." },
    {
      icon: "fa-magic",
      title: "Tailored to your Agency",
      description: "Consistent client experience across calls, tailored to your agency guidelines.",
    },
  ];
  const getSonantDetails = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/CallNotification/sonantdetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCustomerId(response.data.data.sonantCustomerId);
          setApiKey(response.data.data.apiKey);
          if (
            response.data.data.sonantCustomerId !== "" &&
            response.data.data.sonantCustomerId !== null &&
            response.data.data.apiKey !== "" &&
            response.data.data.apiKey !== null
          ) {
            setIsSonantSubscribed(true);
          } else {
            setIsSonantSubscribed(false);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting sonant details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting sonant details",
            life: 3000,
          });
        }
      });
  };

  const onRequestDemoClick = () => {
    window.open("https://tally.so/r/npvP1B", "_blank");
  };
  const onSubscribeClick = () => {
    var token = CommonValues.GetToken();
    setSubmitLoading(true);
    var data = {
      AgentId: localStorage.getItem("NowcertsAgentId"),
      AgentEmail: localStorage.getItem("userName"),
      ServiceId: 13,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/request-subscription`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setSubmitLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Subscription request submitted successfully",
          life: 3000,
        });
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while subscribing sonant",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while subscribing sonant",
            life: 3000,
          });
        }
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="sonant-main-page">
        <Header pagename="sonant" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <SonantMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SonantSidebar pagename="sonant" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                {/* <div className="row  d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  p-0">
                    <div className="row d-flex   justify-content-end scan-search-row mx-auto ">
                      <div className="col-7"></div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="balance-span">
                          <label>Current Balance</label>
                          {currentBalanceLoading ? (
                            <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                              <AtkLoader />
                            </span>
                          ) : (
                            <span className=" d-block w-auto ">${currentBalance}</span>
                          )}
                          <small className="d-block w-auto text-danger mt-0">1 dollar will be charged per usage</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row ">
                  <div className="col-12">
                    <div className="card  mt-3">
                      <div className="card-body">
                        <div className="row  d-flex  align-items-center ">
                          <div className="col-6 ">
                            <b className="fs-5">Request Demo</b>
                          </div>
                          <div className="col-6 d-flex  justify-content-end ">
                            {loading ? (
                              <div className="d-flex align-items-center justify-content-center my-2">
                                <AtkLoader />
                              </div>
                            ) : (
                              <InputSwitch className="d-flex align-items-center sonant-switch" checked={isSonantSubscribed} />
                            )}
                          </div>
                          <div className="col-12">
                            <span className="text-20 ">The AI Receptionist for Insurance Agencies & Brokers</span>
                          </div>
                          <div className="col-12 text-14 mt-1" style={{ textAlign: "justify" }}>
                            <span className="">
                              Sonant AI offers an AI-powered receptionist solution tailored for insurance agencies. Their technology automates routine
                              phone calls, including servicing requests, quote intakes, lead qualification, and appointment scheduling, allowing
                              agents to focus on higher-value tasks.
                            </span>
                          </div>
                          <div className="col-12 mt-2">
                            <AtkButton label="Request a Demo" onClick={onRequestDemoClick} className="pf-primary-btn w-auto m" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card  mt-3">
                      <div className="card-body">
                        <div className="row g-2">
                          <div className="col-12 ">
                            <b className="fs-5">Services</b>
                          </div>

                          {features.map((feature, index) => (
                            <div className="col-4 mb-2" key={index}>
                              <div className="d-flex align-items-start">
                                <i className={`fa ${feature.icon} fs-4 me-3`}></i>
                                <div>
                                  <h6 className="fw-bold">{feature.title}</h6>
                                  <p className=" mb-0 text-14">{feature.description}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row  d-flex  ">
                          <div className="col-6 ">
                            <b className="fs-5">Configuration</b>
                          </div>
                          <div className="col-6 text-end">
                            {submitLoading || loading ? (
                              <div className="p-1">
                                <AtkLoader />{" "}
                              </div>
                            ) : isSonantSubscribed ? null : (
                              <AtkButton label="Subscribe" onClick={onSubscribeClick} className="pf-primary-btn w-auto" />
                            )}
                          </div>
                          <div className="form-group col-lg-6 col-md-6 col-sm-12 mb-2">
                            <label className="text-14">Customer Id <span className="text-danger">*</span></label>
                            {loading ? (
                              <Skeleton width="100%" className="mb-2 d-block"></Skeleton>
                            ) : (
                              <AtkTextField
                                className="input-field"
                                type="text"
                                id="text"
                                value={customerId}
                                placeholder="Please enter customer id"
                                isDisabled
                              />
                            )}
                          </div>
                          <div className="form-group col-lg-6 col-md-6 col-sm-12 mb-2">
                            <label className="text-14">API Key <span className="text-danger">*</span></label>
                            {loading ? (
                              <Skeleton width="100%" className="mb-2 d-block"></Skeleton>
                            ) : (
                              <AtkTextField
                                className="input-field"
                                type="text"
                                id="text"
                                value={apiKey}
                                placeholder="Please enter api key"
                                isDisabled
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
