import React from "react";
import MomentumAIHeader from "./MomentumAIHeader";
import MomentumAISidebar from "./MomentumAISidebar";
import { useEffect, useState, useRef } from "react";
import CommunicationIcon from "../assets/images/Support-Bot/communication-icon.svg";
import CommonValues from "../common/utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import SupportLoader from "../common/SupportLoader";
import AnswerIcon from "../assets/images/Momentum AI Icon-bk.png";
import { Skeleton } from "primereact/skeleton";
import Answer from "../RTG/Support-Bot/Answer";
import axios from "axios";
import { Guid } from "js-guid";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { marked } from "marked";
import AIMenuFooter from "./AIMenuFooter";

interface ChatResponse {
  conversationId: string;
  answer: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
}
class ChatRequest {
  ConversationId: string = "";
  ChatHistory: { Question: string; Answer: string }[] = [];
  Question: string = "";
}
export default function SupportAssistantLandingPage() {
  const [showQuestionAnswer, setShowQuestionAnswer] = useState(false);
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const toast: any = useRef("");
  const navigate = useNavigate();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [showConversationHistory, setShowConversationHistory] = useState(false);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const [chatBotEmbeded, setChatBotEmbeded] = useState(true);
  const [transcribingAudio, setTranscribingAudio] = useState(false);
  const [recordingAudio, setRecordingAudio] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(null);
  const [latestQuestion, setLatestQuestion] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const suggestedQuestions = [
    "How to send a Certificate of Insurance",
    "How to manage user access and permissions?",
    "How to generate reports and export data?",
    "How to edit, delete, or merge policies and accounts?",
    "How to handle commissions and payments?",
  ];
  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };
  const adjustHeight = () => {
    if (textAreaRef.current) {
      const prevScrollY = window.scrollY;

      textAreaRef.current.style.height = "56px";
      textAreaRef.current.style.height = `${Math.min(textAreaRef.current.scrollHeight, 150)}px`; 

      window.scrollTo(0, prevScrollY);
    }
  };

  const onNewConversionlick = () => {
    setChatResponses([]);
    navigate(`/ai-support-assistant/${Guid.newGuid()}`);
  };
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.setItem("Embeded", String(chatBotEmbeded));

    setChatBotEmbeded(localStorage.getItem("Embeded") === "true");
    getConversationHistory(window.location.href.split("/")[4]);
    setQuestion("");

    setAvatarName(localStorage.getItem("AvatarName"));
  }, [window.location.href.split("/")[4]]);
  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);
    }
  }, [chatResponses.length]);
  const getConversationHistory = (conversationId: string | null) => {
    if (conversationId === undefined || conversationId === null || conversationId === "") {
      return;
    }
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-conversation-history/${conversationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const data = response.data;
        let conversationHistory: ChatResponse[] = [];
        data.forEach((conversation: any) => {
          conversationHistory.push({
            conversationId: conversation.conversationId,
            answer: conversation.response,
            sources: conversation.pageURL,
            imageURLs: conversation.imageURL,
            videoURLs: conversation.videoURL,
            imageSidebar: false,
            question: conversation.question,
            chatId: conversation.chatId,
            like: conversation.like === null ? false : conversation.like,
            disLike: conversation.disLike === null ? false : conversation.disLike,
            feedback: "",
            showFeedback: false,
            feedbackLoading: false,
            plotlyData: { data: "", layout: "" },
            textToAction: "",
            responseStreaming: false,
          });
        });
        setChatResponses(conversationHistory);
        setTimeout(() => {
          scrollToBottomForQuestion(false);
          scrollToBottomForAnswer(false);
        }, 100);

        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };

  const onSendQuestionClick = async (que: any) => {
    if (que === "") {
      return;
    }
    let body: ChatRequest = {
      ConversationId: window.location.href.split("/")[4],
      ChatHistory: [],
      Question: que,
    };
    chatResponses.forEach((response: ChatResponse) => {
      body.ChatHistory.push({
        Question: response.question,
        Answer: response.answer,
      });
    });
    let latestQue = {
      conversationId: body.ConversationId,
      question: que,
      type: "ai-support-assistant",
    };
    setLatestQuestion(latestQue);
    body.ChatHistory.push({ Question: que, Answer: "" });
    const newQuestion: ChatResponse = {
      conversationId: body.ConversationId,
      question: que,
      answer: "",
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      textToAction: "",
      responseStreaming: false,
    };
    // setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    let updatedChatResponses = [...chatResponses, { ...newQuestion }];
    setChatResponses(updatedChatResponses);
    setQuestion("");
    // scrollToBottomForQuestion(true);
    scrollToBottomForAnswer(true);

    setQuestionLoading(true);
    const index = chatResponses.length;
    const token = CommonValues.GetToken();
    try {
      const ctrl = new AbortController();
      await fetchEventSource(`${process.env.REACT_APP_LangchainSupportBot_Url}/chat`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        signal: ctrl.signal,
        openWhenHidden: true,
        onopen(response: Response): Promise<void> {
          if (response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (response.status !== 200) {
            setQuestionLoading(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while sending question",
              life: 3000,
            });
          }
          return Promise.resolve();
        },
        onmessage(event: any) {
          let responseObject = JSON.parse(event.data);

          if (responseObject.Answer !== "") {
            setQuestionLoading(false);
          }

          updatedChatResponses = updatedChatResponses.map((chatResponse, index) => {
            if (index === updatedChatResponses.length - 1) {
              return {
                ...chatResponse,
                answer: (chatResponse?.answer || "") + responseObject.Answer,
                chatId: responseObject.ChatId,
                sources: responseObject.Sources,
                imageURLs: responseObject.Images,
                videoURLs: responseObject.Videos,
                textToAction: responseObject.ParentDOMId,
                responseStreaming: responseObject.ResponseLoading,
              };
            }
            return chatResponse;
          });

          setChatResponses(updatedChatResponses);

          //  scrollToBottomForAnswer(true);
        },
        onclose() {
          setQuestionLoading(false);
        },
        onerror(error: any) {
          setQuestionLoading(false);
        },
      });
    } catch (error: any) {}
  };
  const scrollToBottomForAnswer = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(".last-answer-block-nc-chat-bot");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop + 5000 : 0;
      }
    }
  };
  const scrollToBottomForQuestion = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(".last-question-block-nc-chat-bot");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop : 0;
      }
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSendQuestionClick(question);
    }
  };
  const onFeedbackSubmit = (chatId: string, feedBack: string) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) => (response.chatId === chatId ? { ...response, feedbackLoading: true } : response))
    );
    updateFeedback(chatId, feedBack);
  };
  const updateFeedback = (chatId: string, feedBack: string) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Feedback: feedBack,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.chatId === chatId
                ? {
                    ...response,
                    feedback: "",
                    showFeedback: false,
                    feedbackLoading: false,
                  }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) => (response.chatId === chatId ? { ...response, feedbackLoading: false } : response))
        );
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while updating feedback",
            life: 3000,
          });
        }
      });
  };
  const onFeedbackChange = (chatId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) => (response.chatId === chatId ? { ...response, feedback: newFeedback } : response))
    );
  };
  const onCopyResponseClick = async (copiedData: string) => {
    const htmlContent = await marked(copiedData);
    const plainTextContent = htmlToPlainTextWithFormatting(htmlContent);

    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const textBlob = new Blob([plainTextContent], { type: "text/plain" });

    const clipboardItem = new ClipboardItem({
      "text/plain": textBlob,
      "text/html": htmlBlob,
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Copied Successfully!",
          life: 3000,
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error occurred while copying data",
          life: 3000,
        });
      });
  };
  const htmlToPlainTextWithFormatting = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const elementsToReplace = tempDiv.querySelectorAll("br, p, h1, h2, h3, h4, h5, h6");
    elementsToReplace.forEach((element: any) => {
      const lineBreak = document.createTextNode("\n");
      element.parentNode.insertBefore(lineBreak, element);
    });

    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const showImageSidebar = (index: number, isHidden: boolean) => {
    const updatedChatResponses = [...chatResponses];
    updatedChatResponses[index].imageSidebar = isHidden;
    setChatResponses(updatedChatResponses);
  };

  const onThumbsUpClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onThumbsDownClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };
  const updateReaction = (chatId: string, like: boolean, disLike: boolean) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Like: like,
      DisLike: disLike,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-reaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while updating reaction",
            life: 3000,
          });
        }
      });
  };

  const toggleFeedbackVisibility = (chatId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) => (response.chatId === chatId ? { ...response, showFeedback: !response.showFeedback } : response))
    );
  };
  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      setTranscribingAudio(true);
      const data = new FormData();
      var audioFile = new File([audioBlob], "recording.wav", {
        type: audioBlob.type,
      });
      data.append("audioFile", audioFile);

      const token = CommonValues.GetToken();

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_PolicyAgent_Url}/process_audio`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setTranscribingAudio(false);
          setQuestion(response.data.result);
        })
        .catch((error: any) => {
          setTranscribingAudio(false);
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 500) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: "Unknown error occured while processing audio. Please try again.",
              life: 3000,
            });
          }
        });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks: any[] = [];

      setRecordingAudio(true);
      setAudioRecorder(recorder);

      recorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        transcribeAudio(audioBlob);
      };

      recorder.start();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopRecordingAudio = () => {
    if (audioRecorder) {
      audioRecorder.stop();
    }

    setRecordingAudio(false);
  };
  return (
    <div className="ai-support-main-page">
      <Toast ref={toast} />

      <div className="ai-wrapper min-vh-100 ">
        <div className="ai-wrapper-container  position-relative z-1">
          <div className="row g-4 mx-auto">
            <div className="col-2 px-0 border-end" style={{ paddingTop: "2%" }}>
              <MomentumAISidebar pagename="ai-support-assistant" latestQuestion={latestQuestion} />
            </div>
            <div className="col-10 px-0" style={{ paddingTop: "2%" }}>
              <div className="container p-3 d-flex flex-column h-100">
                <div className="ai-main-content-wrapper" ref={chatContainerRef}>
                  {!conversationLoading &&
                    chatResponses.length > 0 &&
                    chatResponses.map((response, index) => {
                      const isLastAnswer = index === chatResponses.length - 1;

                      return (
                        <div
                          id="last-answer"
                          key={response.chatId}
                          className={`col-12 ${isLastAnswer && !questionLoading ? "last-answer-block-nc-chat-bot" : "question-answer-block"}`}
                        >
                          <div className="d-flex flex-direction-row align-items-center">
                            {chatBotEmbeded && <span className="me-1 avtar border-rounded">{avatarName}</span>}
                            <div className="mb-1 text-14 ai-question-block">
                              <b>{response.question}</b>
                            </div>
                          </div>
                          <div className="d-flex flex-direction-row">
                            {chatBotEmbeded && (
                              <span className="me-1">
                                <img alt="NowCerts Logo" src={AnswerIcon} className="me-2" height={30} />
                              </span>
                            )}
                            {isLastAnswer && questionLoading ? (
                              <div
                                id="last-question"
                                className={`row w-100 ${
                                  isLastAnswer && questionLoading ? "last-question-block-nc-chat-bot" : "question-answer-block"
                                }`}
                              >
                                <div className="col-12">
                                  <Skeleton className="mb-2"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton width="70%" className="mb-2 d-block"></Skeleton>
                                </div>

                                <div className="col-12">
                                  <Skeleton width="40%" className="mb-2 d-block"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton width="20%" className="mb-2 d-block"></Skeleton>
                                </div>
                              </div>
                            ) : (
                              <Answer
                                chatResponse={response}
                                index={index}
                                chatBotEmbeded={chatBotEmbeded}
                                sendQuestion={onSendQuestionClick}
                                onFeedbackSubmit={onFeedbackSubmit}
                                onFeedbackChange={onFeedbackChange}
                                toggleFeedbackVisibility={toggleFeedbackVisibility}
                                onCopyResponseClick={onCopyResponseClick}
                                onThumbsUpClick={onThumbsUpClick}
                                onThumbsDownClick={onThumbsDownClick}
                                showImageSidebar={showImageSidebar}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {conversationLoading ? (
                    <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <SupportLoader />
                      </div>
                    </div>
                  ) : (
                    chatResponses.length === 0 && (
                      <div className="row chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                        {chatBotEmbeded ? (
                          <>
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 text-16 text-center mb-4" style={{ color: "#381576" }}>
                              <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column text-center ">
                                <div className="mb-2 rounded p-2 d-flex align-items-center text-start">
                                  <span className="ai-content-heading">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="lucide lucide-headphones"
                                    >
                                      <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
                                    </svg>
                                  </span>
                                  <span style={{ color: "#381574" }}>
                                    <h4 className="mb-0">Support Assistant</h4>
                                    <p className=" small mb-0">Responds to support related queries.</p>
                                  </span>
                                </div>
                              </div>{" "}
                            </div>
                            <div className="d-flex flex-nowrap justify-content-center gap-3 overflow-auto w-100 text-center">
                              {suggestedQuestions.map((suggestedQuestion: string, index: number) => {
                                let isLast = index === suggestedQuestions.length - 1;
                                return (
                                  <>
                                    <span
                                      className="ai-border-radius  p-3 text-muted small bg-momentum-light d-inline  shadow-sm mb-2 me-2"
                                      onClick={() => {
                                        setQuestion(suggestedQuestion);
                                        textAreaRef.current?.focus();
                                      }}
                                    >
                                      {suggestedQuestion}
                                    </span>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column text-center ">
                            <div className="mb-2 rounded p-2 d-flex align-items-center text-start">
                              <span className="ai-content-heading">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="lucide lucide-headphones"
                                >
                                  <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
                                </svg>
                              </span>{" "}
                              <span style={{ color: "#381574" }}>
                                <h4 className="mb-0">Support Assistant</h4>
                                <p className=" small mb-0">Responds to support related queries.</p>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>

                <div className="p-3 mt-auto">
                  <div className="position-relative">
                    <div
                      className={`d-flex align-items-end  ai-border-radius bg-white shadow-sm transition-all overflow-hidden ${
                        isFocused ? "ai-textarea " : "border"
                      }`}
                    >
                      <textarea
                        ref={textAreaRef}
                        placeholder="Message Momentum AI..."
                        name="question"
                        value={question}
                        className="form-control border-0 py-3 px-4 w-100 bg-transparent text-dark resize-none"
                        onChange={(e) => {
                          onQuestionChange(e);
                          adjustHeight();
                        }}
                        rows={1}
                        onKeyDown={onEnterClick}
                        style={{ minHeight: "56px", maxHeight: "150px", overflowY: "auto" }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                      />
                      <div className="d-flex align-items-center gap-2 px-3 mb-3">
                        {transcribingAudio ? (
                          <div className="d-inline-flex align-items-center me-2">
                            <AtkLoader />
                          </div>
                        ) : recordingAudio ? (
                          <span onClick={stopRecordingAudio} className="me-2 show-cursor" title="Microphone">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-mic"
                            >
                              <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" x2="12" y1="19" y2="22"></line>
                            </svg>
                          </span>
                        ) : (
                          <span onClick={startRecordingAudio} className="me-2 show-cursor" title="Microphone">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-mic"
                              style={{ opacity: 0.5 }}
                            >
                              <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" x2="12" y1="19" y2="22"></line>
                            </svg>
                          </span>
                        )}
                        <span className="show-cursor" onClick={() => onSendQuestionClick(question)} title="Send">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke={question.length > 0 ? "#6b44bd" : "currentColor"}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-send"
                            style={{ opacity: question.length > 0 ? 1 : 0.5 }}
                          >
                            <path d="M14.536 21.686a.5.5 0 0 0 .937-.024l6.5-19a.496.496 0 0 0-.635-.635l-19 6.5a.5.5 0 0 0-.024.937l7.93 3.18a2 2 0 0 1 1.112 1.11z"></path>
                            <path d="m21.854 2.147-10.94 10.939"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <AIMenuFooter pagename="ai-support-assistant" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
