import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import PFLogo from ".././assets/images/PF_Logo.svg";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CommonValues from "../common/utils";
import moment from "moment";
import PolicyLoadingSkeleton from "./PolicyLoadingSkeleton";
import CapitalResponse from "../RTG/PremiumFinance/CapitalResponse";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import IpfsResponse from "../RTG/PremiumFinance/IpfsResponse";
import GotoPFResponse from "../RTG/PremiumFinance/GotoPFResponse";
import Peoples from "../RTG/PremiumFinance/Peoples";
import FirstInsuranceFunding from "../RTG/PremiumFinance/FirstInsuranceFunding";
import UsPremiumFinance from "../RTG/PremiumFinance/UsPremiumFinance";

export default function Easy2QuoteLandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [gaName, setGaName] = useState("");
  const [showCarrierDropDown, setShowCarrierDropDown] = useState(true);
  const [carrierSearch, setCarrierSearch] = useState<any>([]);
  const [nowCertsClientsByIdLoading, setNowCertsClientsByIdLoading] = useState(false);
  const [currentPremiumsArray, setCurrentPremiumsArray] = useState<any>([""]);
  const [dataMappingLoading, setDataMappingLoading] = useState<any>([]);
  const [subPolicyLobs, setSubPolicyLobs] = useState<any>([]);
  const [term, setTerm] = useState<any>([""]);
  const [policyNumber, setPolicyNumber] = useState([""]);
  const [effectiveDate, setEffectiveDate] = useState<any>([""]);
  const [clientId, setClientId] = useState("");
  const [nCInsuredId, setNCInsuredId] = useState("");
  const [taxes, setTaxes] = useState<any>([]);
  const [fees, setFees] = useState<any>([]);
  const [coverageCode, setCoverageCode] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>({});
  const [invalidPolicyError, setInvalidPolicyError] = useState([""]);
  const [coverageCodeError, setCoverageCodeError] = useState([""]);
  const [carrierCodeLoading, setCarrierCodeLoading] = useState(false);
  const [carrierName, setCarrierName] = useState([""]);
  const [carrierValue, setCarrierValue] = useState<any>({});
  const [carrier, setCarrier] = useState<any>([]);
  const [carrierAddress, setCarrierAddress] = useState([""]);
  const [carrierCity, setCarrierCity] = useState([""]);
  const [carrierState, setCarrierState] = useState([""]);
  const [carrierZip, setCarrierZip] = useState([""]);
  const [carrierCountry, setCarrierCountry] = useState(["USA"]);
  const [carrierList, setCarrierList] = useState([]);
  const [businessProfile, setBusinessProfile] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [sstate, setSstate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [agentPhone, setAgentPhone] = useState("");
  const [agentAddress, setAgentAddress] = useState("");
  const [agentCity, setAgentCity] = useState("");
  const [agentState, setAgentState] = useState("");
  const [agentZip, setAgentZip] = useState("");
  const [agentCountry, setAgentCountry] = useState("");
  const [selectedSubPolicy, setSelectedSubPolicy] = useState(0);
  const [brokerFee, setBrokerFee] = useState<any>([]);
  const [minimumEarned, setminimumEarned] = useState<any>([]);
  const [carrierNameError, setCarrierNameError] = useState([""]);
  const [capitalShowResponse, setCapitalShowResponse] = useState(false);
  const [nCPolicyId, setNCPolicyId] = useState("");
  const [gaAddress, setGaAddress] = useState([""]);
  const [gaCity, setGaCity] = useState([""]);
  const [gaState, setGaState] = useState([""]);
  const [gaZip, setGaZip] = useState([""]);
  const [gaCountry, setGaCountry] = useState([""]);
  const [gaSearchCode, setGaSearchCode] = useState([""]);
  const [capitalLogoUrl, setCapitalLogoUrl] = useState("https://d1u42a3y69tca1.cloudfront.net/companydata/765/logo/CapitalPremiunFinanceLogo.jpg");
  const [capitalTarget, setCapitalTarget] = useState("CAPITAL");
  const [directBillLoading, setDirectBillLoading] = useState(false);
  const [particularPremiumSettings, setParticularPremiumSettings] = useState<any>([]);
  const [iPFSShowResponse, setIPFSShowResponse] = useState(false);
  const [peoplesShowResponse, setPeoplesShowResponse] = useState(false);
  const [firstInsuranceFundingShowResponse, setFirstInsuranceFundingShowResponse] = useState(false);
  const [usPfShowResponse, setUsPfShowResponse] = useState(false);
  const [gotoShowResponse, setGotoShowResponse] = useState(false);
  const [usPfLogoUrl, setUsPfLogoUrl] = useState("");
  const [iPFSLogoUrl, setIpfsLogoUrl] = useState("");
  const [peoplesLogoUrl, setPeoplesLogoUrl] = useState("");
  const [firstInsuranceFundingLogoUrl, setFirstInsuranceFundingLogoUrl] = useState("");
  const [gotoLogoUrl, setGotoLogoUrl] = useState("");
  const [hideShowAllOptionBtn, setHideShowAllOptionBtn] = useState(false);
  const momentumPolicyId: any = 'ae078049-b745-44fc-be97-591a72b1a9a5'; //location.state?.policyid || "";
  const toast: any = useRef("");

  useEffect(() => {

    getAmsAgencySettings();
    getPremiumFinanceSettingsById();

    let lobArray: any = [];
    let newPolicyNum: any = [];
    let newEffDt: any = [];
    let newPrem: any = [];
    let newCoverageCodes: any = [];
    let newCarrierValues: any = [];
    let newCarrierName: any = [];
    let newCarrierAddress: any = [];
    let newCarrierCity: any = [];
    let newCarrierState: any = [];
    let newCarrierZip: any = [];
    let newTerm: any = [];
    let newTaxes: any = [];
    let newFees: any = [];
    setNCPolicyId(momentumPolicyId);
    if (momentumPolicyId != null && momentumPolicyId != "") {
      getPolicyDetails(
        momentumPolicyId,
        0,
        lobArray,
        newPolicyNum,
        newEffDt,
        newPrem,
        newCoverageCodes,
        newCarrierValues,
        newCarrierName,
        newCarrierAddress,
        newCarrierCity,
        newCarrierState,
        newCarrierZip,
        newTerm,
        newTaxes,
        newFees
      );
    }
  }, []);
  useEffect(() => {
    if (carrierSearch.length == 0 || carrierSearch[selectedSubPolicy] == "") return;
    let newCarrierValues: any = carrierValue != null && carrierValue != undefined ? carrierValue : [];
    let newCarrierName: any = carrierName != null && carrierName != undefined ? carrierName : [];
    let newCarrierAddress: any = [];
    let newCarrierCity: any = [];
    let newCarrierState: any = [];
    let newCarrierZip: any = [];

    const searchTimeout = setTimeout(() => {
      getCarrierCodeMappingForCPF(
        "",
        carrierSearch[selectedSubPolicy],
        0,
        1,
        [],
        newCarrierValues,
        newCarrierName,
        newCarrierAddress,
        newCarrierCity,
        newCarrierState,
        newCarrierZip,
        ""
      );
    }, 10);

    return () => clearTimeout(searchTimeout);
  }, [carrierSearch]);
  const getPolicyDetails = async (
    policyId: any,
    indexx: any,
    lobArray: any,
    newPolicyNum: any,
    newEffDt: any,
    newPrem: any,
    newCoverageCodes: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any,
    newTerm: any,
    newTaxes: any,
    newFees: any
  ) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/policydetails?databaseId=${policyId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setNowCertsClientsByIdLoading(true);
      const response = await axios(config);
      let policyInfo = response.data;
      if (policyInfo != null && policyInfo.length > 0) {
        newPrem.push(parseFloat(response.data[0].totalPremium).toFixed(2));
        setCurrentPremiumsArray(newPrem);
        //  upDateTotalCurrentPremiuim(newPrem, true);
        let invalidPolicyErrorLoop: any = [];
        let policyIdsString = localStorage.getItem("policyIds");
        if (policyIdsString !== null && policyIdsString.trim() !== "[]") {
          lobArray.push({ code: policyInfo[0].lineOfBusinesses[0].lineOfBusinessName });
          newPolicyNum[lobArray.length - 1] = response.data[0].number;
          newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
          // newTerm[lobArray.length - 1] =  policyInfo[0].policyTerm;
          if (policyInfo[0].policyTerm !== "" && policyInfo[0].policyTerm !== null) {
            newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
          } else {
            newTerm[lobArray.length - 1] = "12";
          }

          getPolicyEndorsementDetailList(policyInfo[0].lineOfBusinesses[0].policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
          setTerm(newTerm);
          setSubPolicyLobs(lobArray);
          setPolicyNumber(newPolicyNum);
          setEffectiveDate(newEffDt);
        } else {
          if (policyInfo[0].lineOfBusinesses != null && policyInfo[0].lineOfBusinesses.length > 0) {
            if (policyInfo[0].lineOfBusinesses.length > 1) {
              lobArray.push({ code: "Commercial Package" });
              newPolicyNum[lobArray.length - 1] = response.data[0].number;
              newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
              // newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
              if (policyInfo[0].policyTerm !== "" && policyInfo[0].policyTerm !== null) {
                newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
              } else {
                newTerm[lobArray.length - 1] = "12";
              }
              setTerm(newTerm);
              GetDataMappingForPF(
                "Commercial Package",
                lobArray.length - 1,
                newCoverageCodes,
                [],
                [], //for coverage code mapping
                policyInfo[0].carrierName,
                policyInfo[0].carrierFinanceSearchCoCode,
                "",
                lobArray.length,
                [],
                newCarrierValues,
                newCarrierName,
                newCarrierAddress,
                newCarrierCity,
                newCarrierState,
                newCarrierZip
              );
              getPolicyEndorsementDetailList(policyInfo[0].lineOfBusinesses[0].policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
            } else {
              policyInfo[0].lineOfBusinesses.forEach((item: any, index: any) => {
                if (policyInfo[0].policyTerm !== "" && policyInfo[0].policyTerm !== null) {
                  newTerm[0] = policyInfo[0].policyTerm;
                } else {
                  newTerm[0] = "12";
                }
                lobArray.push({ code: item.lineOfBusinessName });
                newPolicyNum[lobArray.length - 1] = response.data[0].number;
                newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
                // newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;

                GetDataMappingForPF(
                  item.lineOfBusinessName,
                  lobArray.length - 1,
                  newCoverageCodes,
                  [],
                  [],
                  policyInfo[0].carrierName,
                  policyInfo[0].carrierFinanceSearchCoCode,
                  "",
                  lobArray.length,
                  [],
                  newCarrierValues,
                  newCarrierName,
                  newCarrierAddress,
                  newCarrierCity,
                  newCarrierState,
                  newCarrierZip
                );
                getPolicyEndorsementDetailList(item.policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
              });
            }
            setTerm(newTerm);
            setSubPolicyLobs(lobArray);
            setPolicyNumber(newPolicyNum);
            setEffectiveDate(newEffDt);
          }

          if (policyInfo[0].insuredDatabaseId != null || policyInfo[0].insuredDatabaseId != "") {
            let insuredDatabaseId = policyInfo[0].insuredDatabaseId;
            setClientId(insuredDatabaseId);
            getNowCertsClientsById(insuredDatabaseId);
            setNCInsuredId(insuredDatabaseId);
          } else {
            setNowCertsClientsByIdLoading(false);
          }
        }
        // if (momentumPolicyId != null && momentumPolicyId != "" && enableQuickQuote === true) {
        //   setCapitalTarget("CAPITALNC");
        //   setTimeout(() => {
        //     getCapitalQuoteFromPopup();
        //   }, 2500);
        // } else {
        //   setCapitalTarget("CAPITAL");
        // }
      }
    } catch (error: any) {
      setNowCertsClientsByIdLoading(false);

      if (error.response != null) {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        }
      }
    }
  }
  const getPolicyEndorsementDetailList = (policyId: any, index: number, newTaxes: any, newFees: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/getpolicyendorsementdetailslist?policyId=${policyId}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        newFees[index] = parseFloat(response.data[0]).toFixed(2);
        newTaxes[index] = parseFloat(response.data[1]).toFixed(2);
        setTaxes(newTaxes);
        setFees(newFees);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting policyendorsementdetaillist ",
              life: 3000,
            });
          }
        }
      });
  };
  const GetDataMappingForPF = (
    coveragecode: any,
    index: any,
    newValue: any,
    coverageCodeErrorLoop: any,
    invalidPolicyErrorLoop: any,
    carrierName: any,
    carrierSearchCode: any,
    search: any,
    subPolicyCount: any,
    [],
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any
  ) => {
    let newLoadingValue: any = [];
    newLoadingValue[index] = true;
    setDataMappingLoading(newLoadingValue);
    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      HsCode: coveragecode,
      MatchFlag: coveragecode.includes(" ") ? "desc" : "code",
      hscarrier: carrierName,
      generalAgency: "",
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/datamapping`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setTimeout(() => {
          newLoadingValue[index] = false;
          setDataMappingLoading(newLoadingValue);
        }, 500);

        newValue[index] = response.data.coverageCodeMapping;
        if (response.data == null || response.data == "") {
          coverageCodeErrorLoop[index] = "Please select coverage code";
          invalidPolicyErrorLoop[index] = "true";
        }
        setCoverageCode(newValue);

        setInputValue(newValue);
        setInvalidPolicyError(invalidPolicyErrorLoop);
        setCoverageCodeError(coverageCodeErrorLoop);

        if (search == "") {
          if (response.data.carrierCodeMappings != null) {
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierName[i] = response.data.carrierCodeMappings.description;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = response.data.carrierCodeMappings;
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);

                newCarrierAddress[i] = response.data.carrierCodeMappings.address1;
                setCarrierAddress(newCarrierAddress);

                newCarrierCity[i] = response.data.carrierCodeMappings.city;
                setCarrierCity(newCarrierCity);

                newCarrierState[i] = response.data.carrierCodeMappings.state;
                setCarrierState(newCarrierState);

                newCarrierZip[i] = response.data.carrierCodeMappings.zipCode;
                setCarrierZip(newCarrierZip);
              }
            }
          } else {
            invalidPolicyErrorLoop[index] = "";
            let newCarrierCountry = [];
            let newcarrierSearch = [];

            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierCountry[i] = "USA";
                setCarrierCountry(newCarrierCountry);

                newCarrierName[i] = carrierName;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = { description: carrierName };
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);
              }
            }
          }
        }

        // getCarrierCodeMappingForCPF(
        //   carrierSearchCode,
        //   search,
        //   0,
        //   1,
        //   [],
        //   newCarrierValues,
        //   newCarrierName,
        //   newCarrierAddress,
        //   newCarrierCity,
        //   newCarrierState,
        //   newCarrierZip,
        //   response.data.carrierCodeMappings
        // );
      })
      .catch((error: any) => {
        console.log(error);
        setTimeout(() => {
          newLoadingValue[index] = false;
          setDataMappingLoading(newLoadingValue);
        }, 500);
      });
  };
  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    // setNowCertsClientsByIdLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setNowCertsClientsByIdLoading(false);

        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          setBusinessProfile(insuredInfo[0].commercialName);
          setBusinessEmail(insuredInfo[0].eMail);
          setBusinessPhoneNumber(insuredInfo[0].phone);
          setAddLine1(insuredInfo[0].addressLine1);
          setAddLine2(insuredInfo[0].addressLine2);
          setCity(insuredInfo[0].city);
          setSstate(insuredInfo[0].stateNameOrAbbreviation);
          setZipCode(insuredInfo[0].zipCode);
          setClientId(insuredInfo[0].databaseId);
          setFirstName(insuredInfo[0].commercialName);
          setClientId(insuredInfo[0].databaseId);

          let clianttlist = {
            clientName: insuredInfo[0].commercialName,
            clientId: insuredInfo[0].databaseId,
          };
          // props.onClientNameChange(insuredInfo[0].commercialName);
          localStorage.setItem("client", JSON.stringify(clianttlist));
        }
      })
      .catch((error: any) => {
        setNowCertsClientsByIdLoading(false);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
          }
        }
      });
  };
  const getPremiumFinanceSettingsById = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        response.data.forEach((item: any) => {
          if (item.pfServiceId == 2) {
            setCapitalLogoUrl(item.logoUrl);
          } else {
            setCapitalLogoUrl("https://d1u42a3y69tca1.cloudfront.net/companydata/765/logo/CapitalPremiunFinanceLogo.jpg");
          }
        });
        setParticularPremiumSettings(response.data);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while loading the Premium finance Setting details by id",
              life: 3000,
            });
          }
        }
      });
  };
  const getCarrierCodeMappingForCPF = (
    carrierName: any,
    search: any,
    index: any,
    subPolicyCount: any,
    invalidPolicyErrorLoop: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any,
    carrierResponseByName: any
  ) => {
    setCarrierCodeLoading(true);
    let carrierData: any = [];

    var token = CommonValues.GetToken();
    var data = {
      search: search,
      hscarrier: carrierName,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/cpfcarriercodes`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setCarrierCodeLoading(false);

        if (search == "") {
          if (response.data.length > 0) {
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierName[i] = response.data[0].description;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = response.data[0];
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);

                newCarrierAddress[i] = response.data[0].address1;
                setCarrierAddress(newCarrierAddress);

                newCarrierCity[i] = response.data[0].city;
                setCarrierCity(newCarrierCity);

                newCarrierState[i] = response.data[0].state;
                setCarrierState(newCarrierState);

                newCarrierZip[i] = response.data[0].zipCode;
                setCarrierZip(newCarrierZip);
              }
            }
          } else {
            if (carrierResponseByName != null) {
              newCarrierName[index] = carrierResponseByName.description;
              setCarrierName(newCarrierName);
              newCarrierValues[index] = carrierResponseByName;
              setCarrier(newCarrierValues);
              setCarrierValue(newCarrierValues);

              newCarrierAddress[index] = carrierResponseByName.address1;
              setCarrierAddress(newCarrierAddress);

              newCarrierCity[index] = carrierResponseByName.city;
              setCarrierCity(newCarrierCity);

              newCarrierState[index] = carrierResponseByName.state;
              setCarrierState(newCarrierState);

              newCarrierZip[index] = carrierResponseByName.zipCode;
              setCarrierZip(newCarrierZip);
            }
          }
        } else {
          setCarrierList(response.data);
          invalidPolicyErrorLoop[index] = "";
        }
      })
      .catch((error: any) => {
        setCarrierCodeLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while loading the carrier details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while loading the carrier details",
            life: 3000,
          });
        }
      });
  };
  const getAmsAgencySettings = () => {

    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setAgentName(response.data.agencyName);
        setAgentAddress(response.data.address1);
        setAgentCity(response.data.city);
        setAgentState(response.data.state);
        setAgentZip(response.data.zip);
        setAgentPhone(response.data.phone);
        if (response.data.country != null && response.data.country != "") {
          setCountry(response.data.country);
        } else {
          setCountry("USA");
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while loading the AMS Agency settings",
              life: 3000,
            });
          }
        }
      });
  };
  const onCarrierEditIconClick = () => {
    setShowCarrierDropDown(!showCarrierDropDown);
  };
  const onPaymonthlyClick = () => {
    setCapitalShowResponse(true);
  }
  const onCarrierNameChange = (e: any) => {
    let newcarrierSearch = [];
    newcarrierSearch[selectedSubPolicy] = e.target.value;
    setCarrierSearch(newcarrierSearch);
  };
  const onCarrierselect = (item: any, e: any) => {
    setCarrierList([]);
    setCarrierSearch([]);
    if (item) {
      let newCarrierName = carrierName == null ? [] : [...carrierName];
      newCarrierName[selectedSubPolicy] = item.description;
      setCarrierName(newCarrierName);

      const newValues = [...carrier];
      newValues[selectedSubPolicy] = item;
      setCarrier(newValues);
      setCarrierValue(newValues);

      let newCarrierAddress = [...carrierAddress];
      newCarrierAddress[selectedSubPolicy] = item.address1 == undefined ? "" : item.address1;
      setCarrierAddress(newCarrierAddress);

      let newCarrierCity = [...carrierCity];
      newCarrierCity[selectedSubPolicy] = item.city == undefined ? "" : item.city;
      setCarrierCity(newCarrierCity);

      let newCarrierState = [...carrierState];
      newCarrierState[selectedSubPolicy] = item.state == undefined ? "" : item.state;
      setCarrierState(newCarrierState);

      let newCarrierZip = [...carrierZip];
      newCarrierZip[selectedSubPolicy] = item.zipCode == undefined ? "" : item.zipCode;
      setCarrierZip(newCarrierZip);
    }
    setShowCarrierDropDown(true);

    e.preventDefault();
  };
  const onBackButtonClicked = () => {
    setCapitalShowResponse(false);
    setIPFSShowResponse(false);
    setGotoShowResponse(false);
    setFirstInsuranceFundingShowResponse(false);
    setPeoplesShowResponse(false);
    setUsPfShowResponse(false);
    setHideShowAllOptionBtn(false);
  }
  const capitalSubmitClick = () => {
    setDirectBillLoading(true);
    let subPolicies: any = [];

    Array.from(coverageCode).forEach((code: any, index: any) => {
      subPolicies.push({
        number: policyNumber[index],
        coverageCode: code.capitalCode == null ? "*" : code.capitalCode,
        policyId: nCPolicyId,
        effectiveDate: moment(effectiveDate[index]).format("YYYY-MM-DD"),
        expirationDate: "",
        term: term[index],
        BrokerFee: brokerFee[index] == null || brokerFee[index] === "" ? "0" : brokerFee[index],
        premium: currentPremiumsArray[index] ? currentPremiumsArray[index].toString() : 0,
        earnedTaxesFees: "0",
        financedTaxesFees: "0",
        otherFees: fees[index] != undefined ? fees[index].toString() : "0",
        otherTaxes: taxes[index] != undefined ? taxes[index].toString() : "0",
        minimumEarnedPercent: minimumEarned[index] != undefined ? minimumEarned[index] : "0",
        policyType: "commercial",
        index: 0,
        carrier: {
          id: carrier[index] ? carrier[index].capitalCode : "",
          name: carrier[index] ? carrier[index].description : "",
          address: {
            address1: carrier[index] ? carrier[index].address1 : "",
            address2: "",
            city: carrier[index] ? carrier[index].city : "",
            state: carrier[index] ? carrier[index].state : "",
            zip: carrier[index] ? carrier[index].zipCode : "",
            country: carrierCountry[index],
            unit: "",
          },
        },

        generalAgency: {
          name: gaName[index],
          searchCode: gaSearchCode[index],
          address: {
            address1: gaAddress[index],
            address2: "",
            city: gaCity[index],
            state: gaState[index],
            zip: gaZip[index],
            country: gaCountry[index],
            unit: "",
          },
        },
      });
    });
    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      target: "CAPITAL",
      data: {
        ClientName: firstName,
        ClientId: clientId.toString(),
        IsIndicative: localStorage.getItem("IsIndicative"),
        insured: {
          insuredId: nCInsuredId,
          name: businessProfile,
          dbaName: "",
          naics: "",
          phoneNumber: businessPhoneNumber != null ? businessPhoneNumber : "",
          email: businessEmail,
          address: {
            address1: addLine1,
            address2: addLine2,
            city: city,
            state: sstate,
            zip: zipCode,
            country: country,
            unit: "",
          },
        },
        agent: {
          id: "",
          firstName: agentName,
          lastName: "",
          email: "",
          phoneNumber: agentPhone,
          address: {
            address1: agentAddress,
            address2: "",
            city: agentCity,
            state: agentState,
            zip: agentZip,
            country: agentCountry,
            unit: "",
          },
        },
        policy: subPolicies,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/quote`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        onGeneratePaymentLinkClicked(response.data.transactionId)
      })
      .catch((error: any) => {
        let errorMessage = "";
        setDirectBillLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while submitting the details for capital PF";
          }
        } else {
          errorMessage = "Unknown error while submitting  details for capital PF";
        }
      });
  };
  const onGeneratePaymentLinkClicked = (transactionId: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/premiumfinance/gete2qpaymentlink?transactionId=${transactionId}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.url != "" && response.data.url != null) {
          window.open(response.data.url, "_blank");
        }
        setDirectBillLoading(false);
      })
      .catch((error: any) => {
        setDirectBillLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while generating payment link",
              life: 3000,
            });
          }
        }
      });
  }
  const onShowAllOptionsClicked = () => {
    let showButton = !particularPremiumSettings.every((item: any) => item.id === 2);
    setHideShowAllOptionBtn(true);
    if (particularPremiumSettings.length > 0) {
      particularPremiumSettings.forEach((item: any) => {
        switch (item.pfServiceId) {
          case 1:
            setGotoShowResponse(true);
            setGotoLogoUrl(item.logoUrl);
            break;
          case 2:
            setCapitalShowResponse(true);
            setCapitalLogoUrl(item.logoUrl);
            break;
          case 4:
            setIPFSShowResponse(true);
            setIpfsLogoUrl(item.logoUrl);
            break;
          case 5:
            setPeoplesShowResponse(true);
            setPeoplesLogoUrl(item.logoUrl);
            break;
          case 6:
            setFirstInsuranceFundingShowResponse(true);
            setFirstInsuranceFundingLogoUrl(item.logoUrl);
            break;
          case 7:
            setUsPfShowResponse(true);
            setUsPfLogoUrl(item.logoUrl);
            break;
          default:
            break;
        }
      });
    }
  }
  return (
    <div >
      <div className="container-fluid">
        <span className="d-block text-center mt-3 mb-2">
          <span className="h3">  <img src={PFLogo} className="me-2" width={30} />
            PremFi</span>
          {capitalShowResponse ?
            <>
              <AtkButton label="Back" className=" show-direct-btn w-auto me-3 float-end" onClick={onBackButtonClicked} />
              {hideShowAllOptionBtn ? null :
                <AtkButton label="Show All Options" className=" show-direct-btn w-auto me-3 float-end" onClick={onShowAllOptionsClicked} />}
            </>

            : null}
        </span>

        <p className="text-center text-muted mb-1">
          Gain control of premium financing. See payment options from our
          <br /> integrated partners side-by-side and generate agreements instantly.
        </p>
        {capitalShowResponse ? (
          <div className="row d-flex justify-content-center">
            {capitalShowResponse ? (
              <div className="form-group col-lg-4 col-md-6 col-12 mb-3 mt-5 ">
                <CapitalResponse
                  businessProfile={businessProfile}
                  businessEmail={businessEmail}
                  businessPhoneNumber={businessPhoneNumber}
                  addLine1={addLine1}
                  addLine2={addLine2}
                  city={city}
                  sstate={sstate}
                  zipCode={zipCode}
                  country={country}
                  policyNumber={policyNumber}
                  coverageCode={coverageCode}
                  effectiveDate={effectiveDate}
                  term={term}
                  // currentPremium={currentPremium}
                  currentPremiumsArray={currentPremiumsArray}
                  firstName={firstName}
                  lastName=""
                  clientId={clientId}
                  carrier={carrier}
                  carrierName={carrierName}
                  carrierAddress={carrierAddress}
                  carrierCity={carrierCity}
                  carrierState={carrierState}
                  carrierZip={carrierZip}
                  selectedPolicyIndex=""
                  agentName={agentName}
                  agentPhoneNumber={agentPhone}
                  agentAddress={agentAddress}
                  agentCity={agentCity}
                  agentState={agentState}
                  agentZip={agentZip}
                  agentCountry={agentCountry}
                  brokerFee={brokerFee}
                  taxes={taxes}
                  fees={fees}
                  minimumEarned={minimumEarned}
                  nCPolicyId={nCPolicyId}
                  nCInsuredId={nCInsuredId}
                  ncPolicyNumber={policyNumber}
                  gaSearchCode={gaSearchCode}
                  gaName={gaName}
                  gaAddress={gaAddress}
                  gaCity={gaCity}
                  gaState={gaState}
                  gaZip={gaZip}
                  gaCountry={gaCountry}
                  capitalLogoUrl={capitalLogoUrl}
                  onCustomizeClick=""
                  // onMultiPolicyClick={onMultiPolicyClick}
                  target={capitalTarget}
                  showMultipolicyButton={false}
                />
              </div>
            ) : null}
            {iPFSShowResponse ? (
              <div className="form-group col-lg-4 col-md-6 col-12 mb-2 mt-5">
                <IpfsResponse
                  businessProfile={businessProfile}
                  businessEmail={businessEmail}
                  businessPhoneNumber={businessPhoneNumber}
                  addLine1={addLine1}
                  addLine2={addLine2}
                  city={city}
                  sstate={sstate}
                  zipCode={zipCode}
                  country={country}
                  policyNumber={policyNumber}
                  coverageCode={coverageCode}
                  effectiveDate={effectiveDate}
                  term={term}
                  // currentPremium={currentPremium}
                  currentPremiumsArray={currentPremiumsArray}
                  firstName={firstName}
                  lastName=""
                  clientId={clientId}
                  carrierId={carrierValue.inputCode}
                  carrier={carrier}
                  carrierName={carrierName}
                  carrierAddress={carrierAddress}
                  carrierCity={carrierCity}
                  carrierState={carrierState}
                  carrierZip={carrierZip}
                  selectedPolicyIndex=""
                  agentName={agentName}
                  agentPhoneNumber={agentPhone}
                  agentAddress={agentAddress}
                  agentCity={agentCity}
                  agentState={agentState}
                  agentZip={agentZip}
                  agentCountry={agentCountry}
                  brokerFee={brokerFee}
                  taxes={taxes}
                  fees={fees}
                  minimumEarned={minimumEarned}
                  nCPolicyId={nCPolicyId}
                  nCInsuredId={nCInsuredId}
                  ncPolicyNumber={policyNumber}
                  gaSearchCode={gaSearchCode}
                  gaName={gaName}
                  gaAddress={gaAddress}
                  gaCity={gaCity}
                  gaState={gaState}
                  gaZip={gaZip}
                  gaCountry={gaCountry}
                  iPFSLogoUrl={iPFSLogoUrl}
                />
              </div>
            ) : null}
            {gotoShowResponse ? (
              <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                <GotoPFResponse
                  businessProfile={businessProfile}
                  businessEmail={businessEmail}
                  businessPhoneNumber={businessPhoneNumber}
                  addLine1={addLine1}
                  addLine2={addLine2}
                  city={city}
                  sstate={sstate}
                  zipCode={zipCode}
                  country={country}
                  policyNumber={policyNumber}
                  coverageCode={coverageCode}
                  effectiveDate={effectiveDate}
                  term={term}
                  // currentPremium={currentPremium}
                  currentPremiumsArray={currentPremiumsArray}
                  firstName={firstName}
                  lastName=""
                  clientId={clientId}
                  carrierId={carrierValue.inputCode}
                  carrierName={carrierName}
                  carrier={carrier}
                  carrierAddress={carrierAddress}
                  carrierCity={carrierCity}
                  carrierState={carrierState}
                  carrierZip={carrierZip}
                  selectedPolicyIndex=""
                  agentName={agentName}
                  agentPhoneNumber={agentPhone}
                  agentAddress={agentAddress}
                  agentCity={agentCity}
                  agentState={agentState}
                  agentZip={agentZip}
                  agentCountry={agentCountry}
                  brokerFee={brokerFee}
                  taxes={taxes}
                  fees={fees}
                  minimumEarned={minimumEarned}
                  nCPolicyId={nCPolicyId}
                  nCInsuredId={nCInsuredId}
                  ncPolicyNumber={policyNumber}
                  gaSearchCode={gaSearchCode}
                  gaName={gaName}
                  gaAddress={gaAddress}
                  gaCity={gaCity}
                  gaState={gaState}
                  gaZip={gaZip}
                  gaCountry={gaCountry}
                  gotoLogoUrl={gotoLogoUrl}
                />
              </div>
            ) : null}
            {peoplesShowResponse ? (
              <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                <Peoples
                  businessProfile={businessProfile}
                  businessEmail={businessEmail}
                  businessPhoneNumber={businessPhoneNumber}
                  addLine1={addLine1}
                  addLine2={addLine2}
                  city={city}
                  sstate={sstate}
                  zipCode={zipCode}
                  country={country}
                  policyNumber={policyNumber}
                  coverageCode={coverageCode}
                  effectiveDate={effectiveDate}
                  term={term}
                  // currentPremium={currentPremium}
                  currentPremiumsArray={currentPremiumsArray}
                  firstName={firstName}
                  lastName=""
                  clientId={clientId}
                  carrierId={carrierValue.inputCode}
                  carrierName={carrierName}
                  carrier={carrier}
                  carrierAddress={carrierAddress}
                  carrierCity={carrierCity}
                  carrierState={carrierState}
                  carrierZip={carrierZip}
                  selectedPolicyIndex=""
                  agentName={agentName}
                  agentPhoneNumber={agentPhone}
                  agentAddress={agentAddress}
                  agentCity={agentCity}
                  agentState={agentState}
                  agentZip={agentZip}
                  agentCountry={agentCountry}
                  brokerFee={brokerFee}
                  taxes={taxes}
                  fees={fees}
                  minimumEarned={minimumEarned}
                  nCPolicyId={nCPolicyId}
                  nCInsuredId={nCInsuredId}
                  ncPolicyNumber={policyNumber}
                  gaSearchCode={gaSearchCode}
                  gaName={gaName}
                  gaAddress={gaAddress}
                  gaCity={gaCity}
                  gaState={gaState}
                  gaZip={gaZip}
                  gaCountry={gaCountry}
                  peoplesLogoUrl={peoplesLogoUrl}
                />
              </div>
            ) : null}
            {firstInsuranceFundingShowResponse ? (
              <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                <FirstInsuranceFunding
                  businessProfile={businessProfile}
                  businessEmail={businessEmail}
                  businessPhoneNumber={businessPhoneNumber}
                  addLine1={addLine1}
                  addLine2={addLine2}
                  city={city}
                  sstate={sstate}
                  zipCode={zipCode}
                  country={country}
                  policyNumber={policyNumber}
                  coverageCode={coverageCode}
                  effectiveDate={effectiveDate}
                  term={term}
                  // currentPremium={currentPremium}
                  currentPremiumsArray={currentPremiumsArray}
                  firstName={firstName}
                  lastName=""
                  clientId={clientId}
                  carrierId={carrierValue.inputCode}
                  carrierName={carrierName}
                  carrier={carrier}
                  carrierAddress={carrierAddress}
                  carrierCity={carrierCity}
                  carrierState={carrierState}
                  carrierZip={carrierZip}
                  selectedPolicyIndex=""
                  agentName={agentName}
                  agentPhoneNumber={agentPhone}
                  agentAddress={agentAddress}
                  agentCity={agentCity}
                  agentState={agentState}
                  agentZip={agentZip}
                  agentCountry={agentCountry}
                  brokerFee={brokerFee}
                  taxes={taxes}
                  fees={fees}
                  minimumEarned={minimumEarned}
                  nCPolicyId={nCPolicyId}
                  nCInsuredId={nCInsuredId}
                  ncPolicyNumber={policyNumber}
                  gaSearchCode={gaSearchCode}
                  gaName={gaName}
                  gaAddress={gaAddress}
                  gaCity={gaCity}
                  gaState={gaState}
                  gaZip={gaZip}
                  gaCountry={gaCountry}
                  firstInsuranceFundingLogoUrl={firstInsuranceFundingLogoUrl}
                />
              </div>
            ) : null}
            {usPfShowResponse ? (
              <div className="form-group  col-lg-4 col-md-6 col-12 mb-2 mt-5">
                <UsPremiumFinance
                  businessProfile={businessProfile}
                  businessEmail={businessEmail}
                  businessPhoneNumber={businessPhoneNumber}
                  addLine1={addLine1}
                  addLine2={addLine2}
                  city={city}
                  sstate={sstate}
                  zipCode={zipCode}
                  country={country}
                  policyNumber={policyNumber}
                  coverageCode={coverageCode}
                  effectiveDate={effectiveDate}
                  term={term}
                  // currentPremium={currentPremium}
                  currentPremiumsArray={currentPremiumsArray}
                  firstName={firstName}
                  lastName=""
                  clientId={clientId}
                  carrierId={carrierValue.inputCode}
                  carrierName={carrierName}
                  carrier={carrier}
                  carrierAddress={carrierAddress}
                  carrierCity={carrierCity}
                  carrierState={carrierState}
                  carrierZip={carrierZip}
                  selectedPolicyIndex=""
                  agentName={agentName}
                  agentPhoneNumber={agentPhone}
                  agentAddress={agentAddress}
                  agentCity={agentCity}
                  agentState={agentState}
                  agentZip={agentZip}
                  agentCountry={agentCountry}
                  brokerFee={brokerFee}
                  taxes={taxes}
                  fees={fees}
                  minimumEarned={minimumEarned}
                  nCPolicyId={nCPolicyId}
                  nCInsuredId={nCInsuredId}
                  ncPolicyNumber={policyNumber}
                  gaSearchCode={gaSearchCode}
                  gaName={gaName}
                  gaAddress={gaAddress}
                  gaCity={gaCity}
                  gaState={gaState}
                  gaZip={gaZip}
                  gaCountry={gaCountry}
                  usPfLogoUrl={usPfLogoUrl}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <div className="p-3 border rounded bg-light">
              <div className="row g-3 mx-auto">
                <div className="col-6">
                  <h4 className="fw-bold mb-2">Insure Details</h4>
                  {nowCertsClientsByIdLoading ?
                    <PolicyLoadingSkeleton />
                    :
                    <div className="text-12">
                      <div className="">{businessProfile}</div>
                      <div className="">{addLine1}</div>
                      <div className="">{city}, {sstate}, {zipCode}</div>
                      <div className="">{businessEmail}</div>
                      <div className="">{businessPhoneNumber}</div>
                    </div>
                  }
                  <h4 className="fw-bold mb-2 mt-2">Agent Details</h4>
                  {nowCertsClientsByIdLoading ?
                    <PolicyLoadingSkeleton />
                    :
                    <div className="text-12">
                      <div className="">{agentName}</div>
                      <div className="">{agentAddress}</div>
                      <div className="">{agentCity}, {agentState}, {agentZip}</div>
                      <div className="">{agentPhone}</div>
                    </div>
                  }
                </div>
                <div className="col-6">
                  <h4 className="fw-bold mb-2">Policies</h4>
                  {nowCertsClientsByIdLoading ?
                    <PolicyLoadingSkeleton />
                    :
                    <div className="row text-12">
                      <div className="col-6 fw-bold">Policy Number</div>
                      <div className="col-6 ">{policyNumber[selectedSubPolicy]}</div>
                      <div className="col-6 fw-bold">Coverage Code</div>
                      <div className="col-6 ">{inputValue &&
                        Array.isArray(inputValue) &&
                        inputValue[selectedSubPolicy] &&
                        inputValue[selectedSubPolicy].description
                        ? inputValue[selectedSubPolicy].description
                        : "Not Found"}</div>
                      <div className="col-6 fw-bold">Minium Earned %</div>
                      <div className="col-6 ">{minimumEarned[selectedSubPolicy]}</div>
                      <div className="col-6 fw-bold">Current Premium</div>
                      <div className="col-6 ">$ {currentPremiumsArray[selectedSubPolicy]}</div>
                      <div className="col-6 fw-bold">Fees </div>
                      <div className="col-6 ">$ {fees[selectedSubPolicy]}</div>
                      <div className="col-6 fw-bold">Taxes</div>
                      <div className="col-6 ">$ {taxes[selectedSubPolicy]}</div>
                      <div className="col-6 fw-bold">Broker Fee</div>
                      <div className="col-6 ">$ {brokerFee != "" ? brokerFee : "0.00"}</div>
                      <div className="col-6 fw-bold">Effective Date</div>
                      <div className="col-6 ">{moment(effectiveDate[selectedSubPolicy]).format("YYYY-MM-DD")}</div>

                      <div className="col-6 fw-bold">Term</div>
                      <div className="col-6 ">{term}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="mt-3 p-3 border rounded bg-light">
              <div className="row g-2">
                <div className="col-md-6">
                  <div className="row ">
                    {showCarrierDropDown ? (
                      <div className="col-10  position-relative ">
                        <span className="fw-bold">
                          {" "}
                          Carrier :{" "}
                          <i
                            title="Edit"
                            className="fa fa-pencil text-secondary show-cursor position-absolute end-0 me-2 text-16"
                            onClick={onCarrierEditIconClick}
                          />
                        </span>
                        {nowCertsClientsByIdLoading ?
                          <PolicyLoadingSkeleton />
                          :
                          <>
                            {carrier != undefined && carrier != null && carrier.length > 0 ?
                              <>
                                <div className="text-12">
                                  {carrier[0].description} <br />
                                  {carrier[0].address1} <br />
                                  {carrier[0].city}, {carrier[0].state}, {carrier[0].zipCode}
                                </div>
                                <span className="text-10 alert alert-warning p-1">* Carrier can be changed by clicking the edit icon on top right. </span>
                              </>
                              : "No Carrier Found"
                            }
                          </>
                        }
                      </div>
                    ) : (
                      <div className="form-group col-10 py-1  position-relative   ">
                        <label className="mb-1">
                          Carrier Name<span className="text-danger "> *</span>
                          <i
                            title="Close"
                            className="fa fa-close text-danger show-cursor position-absolute end-0 me-2 text-16"
                            onClick={onCarrierEditIconClick}
                          />
                        </label>{" "}
                        <div className="form-group col-12 mb-2 position-relative">
                          <AtkTextField
                            className="input-field policydetails"
                            type="text"
                            id="text"
                            value={carrierSearch.length > 0 ? carrierSearch[0] : ""}
                            onChange={onCarrierNameChange}
                            placeholder={
                              carrierValue[0] != undefined &&
                                carrierValue[0] != null &&
                                carrierValue[0].description != undefined &&
                                carrierValue[0].description != null &&
                                carrierValue[0].description.trim() != ""
                                ? carrierValue[0].description
                                : "Search and select carrier name..."
                            }
                            autoComplete="off"
                          />
                          {carrierSearch[0] != undefined && carrierSearch[0] != null && carrierSearch.length > 0 && (
                            <>
                              {carrierList.length > 0 ? (
                                <div id="hs-client-search" className="canopy-clientselector-dropdown w-100">
                                  {carrierList.map((item: any) => (
                                    <div
                                      className="show-cursor w-100"
                                      onClick={(e) => {
                                        onCarrierselect(item, e);
                                      }}
                                    >
                                      <a href="#" className="carrier-link">
                                        {item.description}
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <>
                                  {carrierSearch[0].length > 0 ? (
                                    <div
                                      style={{
                                        background: "#ddd",
                                      }}
                                      className="text-center text-12 w-100 p-2 "
                                    >
                                      <span className="">Oops Carrier name not found</span>
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          )}
                          <span className="text-12 text-danger">{carrierNameError}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row ">
                    <div className="form-group col-10 py-1  position-relative   ">
                      <label className="mb-1">General Agency</label>{" "}
                      {nowCertsClientsByIdLoading ?
                        <PolicyLoadingSkeleton />
                        :
                        <div className="form-group col-12 mb-2 position-relative">
                          <AtkTextField
                            className="input-field"
                            type="text"
                            id="text"
                            value={gaName}
                            // onChange={onGeneralAgencySearchChange}
                            placeholder="Enter general agency name"
                            autoComplete="off"
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {nowCertsClientsByIdLoading ?
                null
                :
                <div className="col-12 text-center mt-5 mb-3 text-14">
                  {directBillLoading ?
                    <div className="d-inline-flex align-items-center justify-content-center me-4">
                      <AtkLoader /> &nbsp;&nbsp; Generating link...
                    </div>
                    :
                    <AtkButton label="Show Direct Bill (Easy2Quote)***" className="show-direct-btn w-auto me-3" onClick={capitalSubmitClick} />
                  }
                  <AtkButton label="Pay Monthly" className=" pay-monthly-btn w-auto border px-3" onClick={onPaymonthlyClick} />
                </div>
              }
            </div>
            <div className="row bg-light text-center p-2">
              {nowCertsClientsByIdLoading ?
                null
                :
                <div className="col-12">
                  <span>With this option, CPF will collect the down payment and each monthly payment.</span><br />
                  <span>The agency will receive their commission similar to how Direct Billed policies work.</span>
                </div>
              }
            </div>
          </>
        )
        }
      </div>

    </div>
  );
}
