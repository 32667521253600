import React, { FC, useEffect, useState, useRef } from "react";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import CommonValues, { Logout } from "../../../common/utils";
import axios from "axios";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
export default function CapitalSetting(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [capitalLoading, setCapitalLoading] = useState(false);
  const [deleteCapitalLoading, setDeleteCapitalLoading] = useState(false);
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageSearchCode, setErrorMessageSearchCode] = useState("");
  const [capitalUsername, setCapitalUsername] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [capitalPassword, setCapitalPassword] = useState("");
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const toast: any = useRef("");
  const [passwordType, setPasswordType] = useState("password");
  const [preferredVendorLoading, setPreferredVendorLoading] = useState(false);
  const hostname = window.location.hostname;

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getCapitalCreds();
  }, []);
  const getCapitalCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setCapitalUsername("");
          setSearchCode("");
          setCapitalPassword("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);

          setCapitalUsername(response.data.username);
          setSearchCode(response.data.searchCode);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setCapitalPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the Capital Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the Capital Setting details" });
        }
        setLoading(false);
      });
  };
  // const capitalCheckValidation = () => {
  //   let formIsValid = true;
  //   setErrorMessageUsername("");
  //   setErrorMessagePassword("");
  //   setErrorMessageSearchCode("");

  //   if (capitalUsername == "" || capitalUsername == null) {
  //     formIsValid = false;
  //     setErrorMessageUsername("Please enter user name");
  //   }
  //   if (capitalPassword == "" || capitalPassword == null) {
  //     formIsValid = false;
  //     setErrorMessagePassword("Please enter password");
  //   }

  //   return formIsValid;
  // };
  const onUpdateCapitalClick = () => {
    // if (capitalCheckValidation()) {
      setCapitalLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Username: capitalUsername,
        Password: capitalPassword,
        SearchCode: searchCode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setCapitalLoading(false);
          toast.current.show({ severity: "success", summary: 'Success', detail: "Capital Settings updated successfully", life: 3000 });
          getCapitalCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error", summary: 'Error',

                detail: "It look likes you are not registered with Capital, Please register first",
              });
            } else {
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the Capital Settings" });
            }
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the Capital Settings" });
          }
          setCapitalLoading(false);
        });
    // }
  };
  const onDeleteCapitalClick = () => {
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setDeleteCapitalLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteCapitalLoading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "Capital Settings deleted successfully", life: 3000 });
        getCapitalCreds();
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the Capital Settings" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the Capital Settings" });
        }
        setDeleteCapitalLoading(false);
      });
  };
  const onKeyUpdateCapitalClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateCapitalClick();
    }
  };
  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setCapitalUsername(e.target.value);
    setSearchCode(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setCapitalPassword(e.target.value);
  };
  const onSearchCodeChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setSearchCode(e.target.value);
  };
  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const insertPreferredVendor = (vendorId: any, isChecked: any) => {
    setPreferredVendorLoading(true);
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      ProviderId: vendorId,
      IsChecked: !isChecked,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        // toast.current.show({ severity: "success", summary: 'Success', detail: "Capital Settings updated successfully", life: 3000 });
        props.getVendorListFunction();
        setPreferredVendorLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPreferredVendorLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the preferred vendor" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the preferred vendor" });
        }
      });
  };
  return (
    <>
    <Toast ref={toast} />

    <div className="row mx-auto">
      <div className="col-12">
        <div className="pf-setting-col">
          <h4 className="pf-setting-title">
            Capital Premium Financing
            {hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2 || hostname == process.env.REACT_APP_Momentum_Url3 || hostname == process.env.REACT_APP_Momentum_Url4  ? (
              <>
                {preferredVendorLoading ? (
                  <AtkLoader></AtkLoader>
                ) : (
                  <input
                    type="checkbox"
                    value=""
                    checked={props.capitalPremiumFinanceVendor}
                    onChange={
                      props.capitalPremiumFinanceVendor == false ? () => insertPreferredVendor(2, props.capitalPremiumFinanceVendor) : undefined
                    }
                    className="show-cursor bundle-checkbox "
                  />
                )}
              </>
            ) : null}
          </h4>

          <div
            className={
              loading
                ? "row pf-setting-inputs  mx-auto div-blur-loading d-flex align-items-center"
                : "row pf-setting-inputs  mx-auto d-flex align-items-center "
            }
          >
            <>
              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label ">
                  User Name
                </label>
              </div>

              <div className={errorMessageUsername.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                <AtkTextField
                  type="text"
                  id="username"
                  name="capitalUsername"
                  onChange={onUsernameChange}
                  value={capitalUsername}
                  onKeyDown={onKeyUpdateCapitalClick}
                  className="input-field"
                  placeholder="Enter user name"
                />
                <span className="text-danger text-12 error-message">{errorMessageUsername}</span>
              </div>

              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">
                  Password
                </label>
              </div>

              <div className={errorMessagePassword.length > 0 ? "col-8 mt-2 validation-div position-relative" : "col-8 mt-2 position-relative"}>
                <AtkTextField
                  type={passwordType}
                  id="password"
                  name="capitalPassword"
                  onChange={onPasswordChange}
                  value={capitalPassword}
                  onKeyDown={onKeyUpdateCapitalClick}
                  className="input-field"
                  placeholder="Enter password"
                  autoComplete="new-password"
                />
                <span className="eye-icon" onClick={onShowPassword}>
                  {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                </span>
              </div>
              {errorMessagePassword.length > 0 ? (
                <>
                  <div className="col-4  "></div>
                  <div className=" col-8 ">
                    <span className="text-danger text-12">{errorMessagePassword}</span>
                  </div>
                </>
              ) : null}
              <div className="col-4 d-flex align-items-center mt-2  mb-2">
                <label className="form-label">Search Code</label>
              </div>

              <div className={errorMessageSearchCode.length > 0 ? "col-8 validation-div mb-2 mt-2" : "col-8 mt-2 mb-2"}>
                <AtkTextField
                  type="text"
                  id="ownerSearchCode"
                  name="ownerSearchCode"
                  onChange={onSearchCodeChange}
                  value={searchCode}
                  onKeyDown={onKeyUpdateCapitalClick}
                  className="input-field"
                  placeholder="Enter search code"
                />
              </div>
              <div className="col-12 mb-2">
                {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using Momentum Credentials" /> : null}
              </div>
            </>
          </div>
          <div className="row mx-auto  setting-btn-position ">
            <div className="col-4 mt-3 mb-1"></div>
            <div className="col-4 mt-3 mb-1">
              {deleteCapitalLoading ? (
                <AtkLoader></AtkLoader>
              ) : (
                <AtkButton label="Reset" onClick={onDeleteCapitalClick} className="pf-primary-btn  w-100" />
              )}
            </div>
            <div className="col-4 mt-3 mb-1">
              {capitalLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onUpdateCapitalClick} className="pf-primary-btn  w-100" />}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}