import SectionMessage from "@atlaskit/section-message";
import TextField from "@atlaskit/textfield";
import axios from "axios";
import moment from "moment";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import SmartScanMiddleHeader from "./SmartScanMiddleHeader";
import SmartScanSidebar from "./SmartScanSidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import BackgroundVideo from "../../common/BackgroundVideo";
import SmartScanDataExtractPage from "./SmartScanDataExtractPage";
import { VirtualScrollerPassThroughMethodOptions } from "primereact/virtualscroller";
import { json } from "stream/consumers";
import BackIcon from "../../assets/images/Back Button.svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function SmartScanLandingPage(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [documentType, setDocumentType] = useState("");
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");
  const [errorMessageDocumentType, setErrorMessageDocumentType] = useState("");
  const [showHideCancel, setShowHideCancel] = useState(false);
  const [activeTab, setActiveTab] = useState("Insured");
  const [allCarrierNames, setallCarrierNames] = useState<string[]>([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [fein, setFein] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyPremium, setPolicyPremium] = useState("");
  const [carrierName, setCarrierName] = useState<string>("");
  const [lineOfBusiness, setLineOfBusiness] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [naicsCode, setNaicsCode] = useState("");
  const [sicCode, setSicCode] = useState("");
  const [eachAccident, setEachAccident] = useState("");
  const [diseasePolicyLimit, setDiseasePolicyLimit] = useState("");
  const [diseaseEachEmployee, setDiseaseEachEmployee] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [insuredFirstNameError, setInsuredFirstNameError] = useState("");
  const [insuredLastNameError, setInsuredLastNameError] = useState("");
  const [insuredEmailError, setInsuredEmailError] = useState("");
  const [insuredPhoneNumberError, setInsuredPhoneNumberError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessDbaError, setBusinessDbaError] = useState("");
  const [businessFeinError, setBusinessFeinError] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState("");
  const [mailingAddressLine1Error, setmailingAddressLine1Error] = useState("");
  const [mailingCityError, setMailingCityError] = useState("");
  const [mailingStateError, setMailingStateError] = useState("");
  const [mailingZipCodeError, setMailingZipCodeError] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");
  const [policyPremiumError, setPolicyPremiumError] = useState("");
  const [carrierNameError, setCarrierNameError] = useState("");
  const [lineOfBusinessError, setLineOfBusinessError] = useState("");
  const [quoteNumberError, setQuoteNumberError] = useState("");
  const [effectiveDateError, setEffectiveDateError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [termError, setTermError] = useState("");
  const [term, setTerm] = useState("");

  const [carrierNameLoading, setcarrierNameLoading] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [ncURL, setNcURL] = useState("");
  const [toggleSelectErrorMsg, setToggleSelectErrorMsg] = useState("");
  const [createProspectLoading, setCreateProspectLoading] = useState(false);

  const [submissionId, setSubmissionId] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [showUploadAgain, setShowUploadAgain] = useState(false);
  const [isBusinessEmail, setIsBusinessEmail] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showExtractedData, setShowExtractedData] = useState(false);
  const [insuredType, setInsuredType] = useState("Commercial");
  const [fees, setFees] = useState("0");
  const [taxes, setTaxes] = useState("0");
  const [coverageLimitDetails, setCoverageLimitDetails] = useState([]);

  let options1 = [
    { value: "Acord125", label: "Acord 125" },
    { value: "Acord126", label: "Acord 126" },
    { value: "Acord130", label: "Acord 130" },
    { value: "DecPage", label: "Dec Page" },
    { value: "QuoteLetter", label: "Quote Letter" },
  ];
  useEffect(() => {
    const token = CommonValues.GetToken();
    getCurrentBalance();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);

  const getCurrentBalance = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const OnChangeDocumentType = (value: any) => {
    if (value == "Select Document Type") {
      setDocumentType("");
    } else {
      setDocumentType(value);
    }
  };
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      toast.current.show({
        severity: "warn",
        detail: "Please select PDF files only.",
        life: 3000,
      });
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Multiple files cannot be uploaded at the same time; please upload a single file.",
          life: 3000,
        });
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };
  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
  };
  const createUploadRequest = () => {
    setLoading(true);

    if (uploadFile.length == 0) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Please select at least one file to upload.",
        life: 3000,
      });
      setLoading(false);
      return;
    }
    if (documentType.length == 0) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Please select document type.",
        life: 3000,
      });
      setLoading(false);

      return;
    }

    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadFile[0].file);
    data.append("documentType", documentType);
    let token = CommonValues.GetToken();

    const selectedOption = {
      SelectProspect: props.prospectOrInsuredSelect == "Prospect" ? true : false,
      SelectInsured: props.prospectOrInsuredSelect == "Insured" ? true : false,
      CreateNewClient: props.createInsured,
      UseExistingInsured: props.isExistingInsuredSelected == true ? true : false,
      UseExistingProspect: props.isExistingProspectSelected == true ? true : false,
      CreateQuote: props.createQuote,
      CreateNewPolicy: props.createNewPolicy,
      CreateNewProspect: props.createProspect,
      ClientName: props.clientName,
      InsuredId: props.insuredDatabaseId,
    };
    data.append("selectedOption", JSON.stringify(selectedOption));
    var config = {
      method: "post",
      url: `${documentType === "Acord125" || documentType === "Acord130" || documentType === "DecPage" || documentType === "QuoteLetter" ?
        `${process.env.REACT_APP_AcordScan_Url}/accord_scan` :
        `${process.env.REACT_APP_DataFabric_Url}/api/OCR/nowcertssmartscan`
        }`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (response.status === 200) {
          if (response.data.success) {
            setIsFileUpload(true);
            setShowHideCancel(true);
            setShowExtractedData(true);
            const acordFormResponse =
              documentType == "DecPage" || documentType == "QuoteLetter"
                ? JSON.parse(response.data.acordFormResponse)
                : response.data.acordFormResponse;
            setSubmissionId(acordFormResponse.submissionId);
            if (documentType == "Acord125") {
              bindAccordResponseData(acordFormResponse);
            } else if (documentType == "Acord130") {
              bindAccordResponseData(acordFormResponse);
            } else if (documentType == "DecPage") {
              bindDecResponseData(acordFormResponse);
            } else if (documentType == "Acord126") {
              bindAccord126Model(acordFormResponse);
            } else if (documentType == "QuoteLetter") {
              bindDecResponseData(acordFormResponse);
            }
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else if (!response.data.success && response.data.errorMessage === "User does not have enough credits for Acord Scan.") {
            setLoading(false);
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: response.data.errorMessage,
              life: 3000,
            });
          }
        }else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong.",
            life: 3000,
          });
          setLoading(false);
        }
        getCurrentBalance();
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if(error.response.status == 503){
          toast.current.show({
            severity: "warn",
            summary: "Warn",
            detail: error.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while creating the request.",
            life: 3000,
          });
          setLoading(false);
        }
      });
  };
  const bindAccordResponseData = (uploadFileResponse: any) => {
    let responseJson = JSON.parse(uploadFileResponse);
    let incomingState = responseJson.state;
    setSubmissionId(responseJson.submissionId);
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (responseJson.fileUrl != "" && responseJson.fileUrl != null) {
      setUploadPdfUrl(responseJson.fileUrl);
    }

    if (responseJson.firstName != "" && responseJson.firstName != null) {
      setFirstName(responseJson.firstName);
      setInsuredFirstNameError("");
    } else {
      setFirstName("");
    }

    if (responseJson.lastName != "" && responseJson.lastName != null) {
      setLastName(responseJson.lastName);
      setInsuredLastNameError("");
    } else {
      setLastName("");
    }

    if (responseJson.businessName != "" && responseJson.businessName != null) {
      setBusinessName(responseJson.businessName);
      setBusinessNameError("");
    } else {
      setBusinessName(responseJson.firstName + " " + responseJson.lastName);
    }

    if (responseJson.address != "" && responseJson.address != null) {
      setAddressLine1(responseJson.address);
    } else {
      setAddressLine1("");
    }

    if (responseJson.city != "" && responseJson.city != null) {
      setCity(responseJson.city);
    } else {
      setCity("");
    }

    if (responseJson.state != "" && responseJson.state != null) {
      setState(responseJson.state);
    } else {
      setState("");
    }

    if (responseJson.zipCode != "" && responseJson.zipCode != null) {
      setZipCode(responseJson.zipCode);
    } else {
      setZipCode("");
    }

    if (responseJson.policyNumber != "" && responseJson.policyNumber != null) {
      setPolicyNumber(responseJson.policyNumber);
    } else {
      setPolicyNumber("");
    }

    if (responseJson.policyPremium != "" && responseJson.policyPremium != null) {
      setPolicyPremium(responseJson.policyPremium.replace("$", "").replace(",", ""));
    } else {
      setPolicyPremium("0");
    }

    if (responseJson.carrierName != "" && responseJson.carrierName != null) {
      setCarrierName(responseJson.carrierName);
    } else {
      setCarrierName("");
    }
    getNCCarrierList(responseJson.carrierName);

    if (responseJson.effectiveDate != "" && responseJson.effectiveDate != null) {
      setEffectiveDate(responseJson.effectiveDate);
    } else {
      setEffectiveDate("");
    }

    if (responseJson.expirationDate != "" && responseJson.expirationDate != null) {
      setExpirationDate(responseJson.expirationDate);
    }
    else { setExpirationDate(""); }

    if (responseJson.linesOfBusiness != "" && responseJson.linesOfBusiness != null) {
      setLineOfBusiness(responseJson.linesOfBusiness);
    } else {
      setLineOfBusiness("");
    }

    if (responseJson.naicsCode != "" && responseJson.naicsCode != null) {
      setNaicsCode(responseJson.naicsCode);
    } else {
      setNaicsCode("");
    }

    if (responseJson.phoneNumber != "" && responseJson.phoneNumber != null) {
      setPhoneNumber(responseJson.phoneNumber);
    } else {
      setPhoneNumber("");
    }

    if (responseJson.email != "" && responseJson.email != null) {
      setEmail(responseJson.email);
    }
    else {
      setEmail("");
    }

    if (responseJson.fees != "" && responseJson.fees != null) {
      setFees(responseJson.fees);
    } else {
      setFees("0");
    }

    if (responseJson.taxes != "" && responseJson.taxes != null) {
      setTaxes(responseJson.taxes);
    } else {
      setTaxes("0");
    }

    if (responseJson.coverageLimitDetails != "" && responseJson.coverageLimitDetails != null) {
      setCoverageLimitDetails(responseJson.coverageLimitDetails);
    }
    else {
      setCoverageLimitDetails([]);
    }
  };

  const bindDecResponseData = (uploadFileResponse: any) => {
    let responseJson = uploadFileResponse;
    let incomingState = responseJson.state;
    setSubmissionId(responseJson.submissionId);
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (responseJson.fileUrl != "" && responseJson.fileUrl != null) {
      setUploadPdfUrl(responseJson.fileUrl);
    }

    if (responseJson.firstName != "" && responseJson.firstName != null) {
      setFirstName(responseJson.firstName);
      setInsuredFirstNameError("");
    } else {
      setFirstName("");
    }

    if (responseJson.lastName != "" && responseJson.lastName != null) {
      setLastName(responseJson.lastName);
      setInsuredLastNameError("");
    } else {
      setLastName("");
    }

    if (responseJson.businessName != "" && responseJson.businessName != null) {
      setBusinessName(responseJson.businessName);
      setBusinessNameError("");
    } else {
      setBusinessName(responseJson.firstName + " " + responseJson.lastName);
    }

    if (responseJson.address != "" && responseJson.address != null) {
      setAddressLine1(responseJson.address);
    } else {
      setAddressLine1("");
    }

    if (responseJson.city != "" && responseJson.city != null) {
      setCity(responseJson.city);
    } else {
      setCity("");
    }

    if (state.code != "" && state.code != null) {
      setState(state.code);
    } else {
      setState("");
    }

    if (responseJson.zipCode != "" && responseJson.zipCode != null) {
      setZipCode(responseJson.zipCode);
    } else {
      setZipCode("");
    }

    if (responseJson.policyNumber != "" && responseJson.policyNumber != null) {
      setPolicyNumber(responseJson.policyNumber);
    } else {
      setPolicyNumber("");
    }
    if (responseJson.policyPremium != "" && responseJson.policyPremium != null) {
      const policyPremiumValue = responseJson.policyPremium.replace(/[^0-9.]/g, "").replace("$", "").replace(",", "");
      setPolicyPremium(policyPremiumValue);
    } else {
      setPolicyPremium("0");
    }

    if (responseJson.carrierName != "" && responseJson.carrierName != null) {
      setCarrierName(responseJson.carrierName);
    } else {
      setCarrierName("");
    }
    getNCCarrierList(responseJson.carrierName);

    if (responseJson.effectiveDate != "" && responseJson.effectiveDate != null) {
      let effectiveDate=responseJson.effectiveDate.replace(/[^0-9/]/g, "")
      setEffectiveDate(effectiveDate);
    } else {
      setEffectiveDate("");
    }

    if (responseJson.expirationDate != "" && responseJson.expirationDate != null) {
      let expirationDate=responseJson.expirationDate.replace(/[^0-9/]/g, "")
      setExpirationDate(expirationDate);
    }
    else { setExpirationDate(""); }

    if (responseJson.linesOfBusiness != "" && responseJson.linesOfBusiness != null) {
      const value = responseJson.linesOfBusiness.split(/,|and/i)[0].trim();
      setLineOfBusiness(value);
    } else {
      setLineOfBusiness("");
    }

    if (responseJson.naicsCode != "" && responseJson.naicsCode != null) {
      setNaicsCode(responseJson.naicsCode);
    } else {
      setNaicsCode("");
    }

    if (responseJson.phoneNumber != "" && responseJson.phoneNumber != null) {
      setPhoneNumber(responseJson.phoneNumber);
    } else {
      setPhoneNumber("");
    }

    if (responseJson.email != "" && responseJson.email != null) {
      setEmail(responseJson.email);
    }
    else {
      setEmail("");
    }

    if (responseJson.fees != "" && responseJson.fees != null) {
      let fee=responseJson.fees.replace(/[^0-9.]/g, "").replace("$", "").replace(",", "")
        setFees(fee);
      } else {
      setFees("0");
    }

    if (responseJson.taxes != "" && responseJson.taxes != null) {
      let taxes=responseJson.taxes.replace(/[^0-9.]/g, "").replace("$", "").replace(",", "")
      setTaxes(taxes)
    } else {
      setTaxes("0");
    }
    if (responseJson.coverageLimitDetails != "" && responseJson.coverageLimitDetails != null) {
      setCoverageLimitDetails(responseJson.coverageLimitDetails);
    }
    else {
      setCoverageLimitDetails([]);
    }
  };


  const bindAccord126Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.businessName != "" && uploadFileResponse.businessName != null) {
      setBusinessName(uploadFileResponse.businessName);
      setBusinessNameError("");
    } else {
      setBusinessName(uploadFileResponse.firstName + " " + uploadFileResponse.lastName);
    }
    if (uploadFileResponse.firstName != "" && uploadFileResponse.firstName != null) {
      setFirstName(uploadFileResponse.firstName);
      setInsuredFirstNameError("");
    }
    if (uploadFileResponse.lastName != "" && uploadFileResponse.lastName != null) {
      setLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }
    setEmail(uploadFileResponse.email);
    setInsuredEmailError("");
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setFein(uploadFileResponse.fein);
    }
    if (uploadFileResponse.fees != "" && uploadFileResponse.fees != null) {
      setFees(uploadFileResponse.fees);
    }

    setTaxes(uploadFileResponse.taxes != "" && uploadFileResponse.taxes != null ? uploadFileResponse.taxes : "");

    if (uploadFileResponse.addressLine1 != "" && uploadFileResponse.addressLine1 != null) {
      setAddressLine1(uploadFileResponse.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (uploadFileResponse.zipCode != "" && uploadFileResponse.zipCode != null) {
      setZipCode(uploadFileResponse.zipCode);
      setMailingZipCodeError("");
    }
    if (props.policyDetails == "Use Existing Policy") {
      setPolicyNumber(props.existingPolicyId);
      setPolicyNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setPolicyNumber(uploadFileResponse.policyNumber);
        setPolicyNumberError("");
      }
    }

    if (uploadFileResponse.policyPremium != "" && uploadFileResponse.policyPremium != null) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    if (uploadFileResponse.carrierName != "" && uploadFileResponse.carrierName != null) {
      setCarrierName(uploadFileResponse.carrierName);
    }
    getNCCarrierList(uploadFileResponse.carrierName);
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.expirationDate != "" && uploadFileResponse.expirationDate != null) {
      setExpirationDate(uploadFileResponse.expirationDate);
      setExpiryDateError("");
    }
    // if (uploadFileResponse.lineOfBusiness != "" && uploadFileResponse.lineOfBusiness != null) {
    //   setLineOfBusiness(uploadFileResponse.lineOfBusiness);
    //   setLineOfBusinessError("");
    // }
    setLineOfBusiness("General Liability");
  };
  const binAccord130Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.businessName != "" && uploadFileResponse.businessName != null) {
      setBusinessName(uploadFileResponse.businessName);
      setBusinessNameError("");
    } else {
      setBusinessName(uploadFileResponse.firstName + " " + uploadFileResponse.lastName);
    }
    if (uploadFileResponse.firstName != "" && uploadFileResponse.firstName != null) {
      setFirstName(uploadFileResponse.firstName);
      setInsuredFirstNameError("");
    }
    if (uploadFileResponse.lastName != "" && uploadFileResponse.lastName != null) {
      setLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }

    setEmail(uploadFileResponse.email);
    setInsuredEmailError("");

    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setFein(uploadFileResponse.fein);
    }

    if (uploadFileResponse.addressLine1 != "" && uploadFileResponse.addressLine1 != null) {
      setAddressLine1(uploadFileResponse.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (uploadFileResponse.zipCode != "" && uploadFileResponse.zipCode != null) {
      setZipCode(uploadFileResponse.zipCode);
      setMailingZipCodeError("");
    }
    if (props.policyDetails == "Use Existing Policy") {
      setPolicyNumber(props.existingPolicyId);
      setPolicyNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setPolicyNumber(uploadFileResponse.policyNumber);
        setPolicyNumberError("");
      }
    }

    if (uploadFileResponse.policyPremium != "" && uploadFileResponse.policyPremium != null) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    if (uploadFileResponse.carrierName != "" && uploadFileResponse.carrierName != null) {
      setCarrierName(uploadFileResponse.carrierName);
    }
    getNCCarrierList(uploadFileResponse.carrierName);
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.expirationDate != "" && uploadFileResponse.expirationDate != null) {
      setExpirationDate(uploadFileResponse.expirationDate);
      setExpiryDateError("");
    }
    if (uploadFileResponse.lineOfBusiness != "" && uploadFileResponse.lineOfBusiness != null) {
      setLineOfBusiness(uploadFileResponse.lineOfBusiness);
      setLineOfBusinessError("");
    }
    if (uploadFileResponse.fees != "" && uploadFileResponse.fees != null) {
      setFees(uploadFileResponse.fees);
    }
    setTaxes(uploadFileResponse.taxes != "" && uploadFileResponse.taxes != null ? uploadFileResponse.taxes : "");
  };
  const bindDecPageModel = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.businessName != "" && uploadFileResponse.businessName != null) {
      setBusinessName(uploadFileResponse.businessName);
      setBusinessNameError("");
    } else {
      setBusinessName(uploadFileResponse.firstName + " " + uploadFileResponse.lastName);
    }
    if (uploadFileResponse.firstName != "" && uploadFileResponse.firstName != null) {
      setFirstName(uploadFileResponse.firstName);
      setInsuredFirstNameError("");
    }
    if (uploadFileResponse.lastName != "" && uploadFileResponse.lastName != null) {
      setLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }

    setEmail(uploadFileResponse.email);
    setInsuredEmailError("");

    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setFein(uploadFileResponse.fein);
    }

    if (uploadFileResponse.addressLine1 != "" && uploadFileResponse.addressLine1 != null) {
      setAddressLine1(uploadFileResponse.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (uploadFileResponse.zipCode != "" && uploadFileResponse.zipCode != null) {
      setZipCode(uploadFileResponse.zipCode);
      setMailingZipCodeError("");
    }
    if (props.policyDetails == "Use Existing Policy") {
      setPolicyNumber(props.existingPolicyId);
      setPolicyNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setPolicyNumber(uploadFileResponse.policyNumber);
        setPolicyNumberError("");
      }
    }

    if (uploadFileResponse.policyPremium != "" && uploadFileResponse.policyPremium != null) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    if (uploadFileResponse.carrierName != "" && uploadFileResponse.carrierName != null) {
      setCarrierName(uploadFileResponse.carrierName);
    }
    getNCCarrierList(uploadFileResponse.carrierName);
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.expirationDate != "" && uploadFileResponse.expirationDate != null) {
      setExpirationDate(uploadFileResponse.expirationDate);
      setExpiryDateError("");
    }
    if (uploadFileResponse.lineOfBusiness != "" && uploadFileResponse.lineOfBusiness != null) {
      setLineOfBusiness(uploadFileResponse.lineOfBusiness);
      setLineOfBusinessError("");
    }
    if (uploadFileResponse.quoteNumber != "" && uploadFileResponse.quoteNumber != null) {
      setQuoteNumber(uploadFileResponse.quoteNumber);
      setQuoteNumberError("");
    }
    if (uploadFileResponse.fees != "" && uploadFileResponse.fees != null) {
      setFees(uploadFileResponse.fees);
    }
    setTaxes(uploadFileResponse.taxes != "" && uploadFileResponse.taxes != null ? uploadFileResponse.taxes : "0");
  };
  const getNCCarrierList = (carrierName: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    setcarrierNameLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/getnowcertcarrierlist?carrierName=${carrierName}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setallCarrierNames(response.data.allCarrierNames);
        if (response.data.matchedCarrierName != "" && response.data.matchedCarrierName != "null" && response.data.matchedCarrierName != undefined) {
          let matchingcarriername: any = response.data.matchedCarrierName;
          if (matchingcarriername !== undefined) {
            if (!allCarrierNames.includes(matchingcarriername)) {
              setallCarrierNames((allCarrierNames) => [...allCarrierNames, matchingcarriername]);
            }
          }
          setCarrierName(matchingcarriername);
        }
        setcarrierNameLoading(false);
      })
      .catch((error: any) => {
        setcarrierNameLoading(false);
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while creating the request.",
            life: 3000,
          });
        }
      });
  };
  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
    setShowHideCancel(true);
  };
  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };
  const onChangeInsuredFirstName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setFirstName(Value);
    setInsuredFirstNameError("");
  };

  const onChangeInsuredLastName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setLastName(Value);
    setInsuredLastNameError("");
  };
  const onChangeBusinessFein = (value: any) => {
    const Value = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (Value.test(value)) {
      if (value.length > 9) {
        return;
      }
      setFein(value);
    }
  };

  const onChangeAgencyMailingAddress1 = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setAddressLine1(Value);
    setmailingAddressLine1Error("");
  };
  const onChangeAgencyMailingCity = (value: any) => {
    const Value = value.slice(0, 255);
    setCity(Value);
    setMailingCityError("");
  };
  const onChangeAgencyMailingState = (value: any) => {
    setState(value);
    setMailingStateError("");
  };
  const onChangeAgencyMailingZip = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length > 5) {
        return;
      }
      setZipCode(value);
      setMailingZipCodeError("");
    }
  };
  const onChangePolicyNumber = (value: any) => {
    const Value = value.slice(0, 255);
    if (props.policyDetails == "Use Existing Policy") {
      return;
    } else {
      setPolicyNumber(Value);
    }
    setPolicyNumberError("");
  };
  const onChangePolicyPremium = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setPolicyPremium(value);
      setPolicyPremiumError("");
    }
  };
  const onChangeFees = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setFees(value);
    }
  };
  const onChangeTaxes = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setTaxes(value);
    }
  };
  const onTermChange = (e: any) => {
    if (/^\d*$/.test(e.target.value)) {
      setTerm(e.target.value);
    }
  };
  const onChangeCarrierName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    if (value == "") {
      setCarrierName("");
    } else {
      setCarrierName(Value);
      setCarrierNameError("");
    }
  };
  const onChangeInsuredType = (value: any) => {
    setInsuredType(value);
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setBusinessNameError("");
  };
  const onChangeLineOfBusiness = (value: any) => {
    const Value = value.slice(0, 255);
    setLineOfBusiness(Value);
    setLineOfBusinessError("");
  };
  const onChangeEmail = (value: any) => {
    const limitedValue = value.slice(0, 64);
    setEmail(limitedValue);
    setBusinessEmailError("");
  };
  const onChangePhoneNumber = (value: any) => {
    const inputValue = value;
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const limitedValue = numericValue.slice(0, 10);
    setPhoneNumber(limitedValue);
    setInsuredPhoneNumberError("");
  };
  const onChangeQuoteNumber = (value: any) => {
    const Value = value.slice(0, 255);
    setQuoteNumber(Value);
    setQuoteNumberError("");
  };
  const onChangeEffectiveDate = (value: any) => {
    setEffectiveDate(value);
    setEffectiveDateError("");
  };

  const createExpiryDate = (value: string) => {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
      console.error("Invalid date format. Expected 'MM/dd/yyyy'. Got:", value);
      return "Invalid date format";
    }

    const [month, day, year] = value.split("/").map((val) => parseInt(val, 10));

    const result = new Date(year, month - 1, day);

    if (isNaN(result.getTime())) {
      console.error("Invalid Date Object:", result);
      return "Invalid date";
    }

    return result.toISOString().split("T")[0];
  };

  const onChangeExpiryDate = (value: any) => {
    setExpirationDate(value);
    setExpiryDateError("");
  };

  const onChangeBusinessName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setBusinessName(Value);
    setBusinessNameError("");
  };
  const formatPhoneNumber = (phoneNumber: string) => {
    const numericValue = phoneNumber.replace(/\D/g, "");
    const phoneNumberLength = numericValue.length;
    if (phoneNumberLength <= 3) {
      return numericValue;
    } else if (phoneNumberLength <= 6) {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
    }
  };

  const onOpenClick = () => {
    window.open(ncURL, "_blank");
  };

  const uploadAgain = () => {
    setActiveTab("Insured");
    setShowExtractedData(false);
    setIsFileUpload(false);
    setEmail("");
    setFirstName("");
    setLastName("");
    setPolicyNumber("");
    setPolicyPremium("");
    setCarrierName("");
    setLineOfBusiness("");
    setExpirationDate("");
    setEffectiveDate("");
    setBusinessName("");
    setFein("");
    setAddressLine1("");
    setCity("");
    setState("");
    setZipCode("");
    setUploadFile([]);
    setFileName("");
    setFileNameTitle("");
    setDocumentType("");
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setNcURL("");
    setHideSubmitButton(true);
    setEachAccident("");
    setDiseaseEachEmployee("");
    setDiseasePolicyLimit("");
    setSicCode("");
    setQuoteNumber("");
    setQuoteNumberError("");
    setTerm("");
    setShowUploadAgain(false);
  };
  const Allstate = [
    { name: "Alaska", code: "AK" },
    { name: "Alabama", code: "AL" },
    { name: "Arkansas", code: "AR" },
    { name: "Arizona", code: "AZ" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "DistrictofColumbia", code: "DC" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Iowa", code: "IA" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Massachusetts", code: "MA" },
    { name: "Maryland", code: "MD" },
    { name: "Maine", code: "ME" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Missouri", code: "MO" },
    { name: "Mississippi", code: "MS" },
    { name: "Montana", code: "MT" },
    { name: "NorthCarolina", code: "NC" },
    { name: "NorthDakota", code: "ND" },
    { name: "Nebraska", code: "NE" },
    { name: "NewHampshire", code: "NH" },
    { name: "NewJersey", code: "NJ" },
    { name: "NewMexico", code: "NM" },
    { name: "Nevada", code: "NV" },
    { name: "NewYork", code: "NY" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "RhodeIsland", code: "RI" },
    { name: "SouthCarolina", code: "SC" },
    { name: "SouthDakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Virginia", code: "VA" },
    { name: "Vermont", code: "VT" },
    { name: "Washington", code: "WA" },
    { name: "Wisconsin", code: "WI" },
    { name: "WestVirginia", code: "WV" },
    { name: "Wyoming", code: "WY" },
  ];

  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="smartscan-main-page">
        <Header pagename="smart-scan" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <SmartScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SmartScanSidebar pagename="smart-scan" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  p-0">
                    <div className="row d-flex   justify-content-end scan-search-row mx-auto ">
                      {" "}
                      <div className="col-9 d-flex  align-items-center">
                        <img src={BackIcon} className="show-cursor me-2" height={20} width={20} title="Back" onClick={props.onBackClick} />

                        {props.prospectDetails != "" && props.prospectDetails != null ? (
                          <>
                            <span className=" me-3">
                              <label>
                                <b>Prospect: </b> &nbsp;
                              </label>
                              {props.prospectDetails}
                            </span>
                            {props.quoteDetails != "" ? (
                              <span>
                                <label>
                                  <b>Quote: </b> &nbsp;
                                </label>
                                {props.quoteDetails}
                              </span>
                            ) : null}
                          </>
                        ) : props.insuredDetails != "" && props.insuredDetails != null ? (
                          <>
                            <span className=" me-3">
                              <label>
                                <b>Insured: </b> &nbsp;
                              </label>
                              {props.insuredDetails}
                            </span>
                            {props.quoteDetailsForInsured != "" ? (
                              <span>
                                <label>
                                  <b>Quote: </b> &nbsp;
                                </label>
                                {props.quoteDetailsForInsured} &nbsp;
                              </span>
                            ) : null}
                            <span>
                              <label>
                                <b>Policy: </b> &nbsp;
                              </label>
                              {props.policyDetails}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="col-3 d-flex justify-content-end ">
                        <div className="balance-span">
                          <label>Current Balance</label>
                          {currentBalanceLoading ? (
                            <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                              <AtkLoader />
                            </span>
                          ) : (
                            <span className=" d-block w-auto ">${currentBalance}</span>
                          )}
                          <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-12 main-content-row mx-auto overflow-auto">
        <div className="col-lg-10 col-md-12 col-sm-12 " style={{ width: "100%" }}>
          <div className="row m-3 ">
                      {isFileUpload ? (
                        <div className="col">
                          <iframe
                            src={uploadPdfUrl}
                            style={{
                              overflow: "scroll",
                              height: "100%",
                              width: "100%",
                            }}
                          ></iframe>
                        </div>
                      ) : (
                        <>
                          <div
                            className="uploadNowcertscoi my-3"
                            onDrop={(e) => {
                              onDropHandler(e);
                            }}
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="row w-100 ">
                              <div className="col-12 smart-scan-acord-dropdown pb-0">
                                <select className="smart-scan-acord-dropdown" onChange={(e) => OnChangeDocumentType(e.target.value)}>
                                  <option>Select Document Type</option>
                                  {options1.map((item: any) => (
                                    <option key={item.value} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-12 mt-1 mb-2 text-center">
                                {errorMessageDocumentType.length == 0 ? null : (
                                  <span className="text-danger text-12">{errorMessageDocumentType}</span>
                                )}
                              </div>

                              {uploadFile.length > 0 ? (
                                <div className="col-12">
                                  {uploadFile.map((file: any, index: any) => (
                                    <div className="show-pdf-icon">
                                      <div className="scan-file-name">
                                        <div className="preview-icon">
                                          <i className="fa fa-file-pdf" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                        </div>
                                        <div>
                                          <div title={fileNameTitle}>{fileName}</div>
                                          <div>
                                            {(parseInt(file.size) / 1000).toFixed(0)}
                                            KB
                                          </div>
                                        </div>
                                      </div>
                                      {uploadComplete ? (
                                        <div>
                                          <span className="uploaded">Uploaded</span>
                                        </div>
                                      ) : (
                                        uploadingFiles == false && (
                                          <div onClick={() => removeFileFromList()} className="show-cursor">
                                            <img src={RemoveIcon} />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <>
                                  <>
                                    <div className="row" style={{ textAlign: "center" }}>
                                      <div className="col-12">
                                        <img src={PfdIcon} alt="" width="70" />
                                      </div>

                                      <div className="col-12 mt-4">
                                        <div className="col-12">
                                          <span className="drag-and-drop">Drag & Drop file here</span>
                                        </div>
                                        <div className="col-12 my-2">
                                          <span
                                            style={{
                                              color: "black",
                                              fontSize: "large",
                                            }}
                                          >
                                            or
                                          </span>
                                        </div>
                                        <label className="mb-0 show-cursor">
                                          <span className="browse">Browse PDF</span>
                                          <input
                                            type="file"
                                            name="filefield"
                                            accept=".pdf"
                                            id="bulkFileUploader"
                                            style={{
                                              visibility: "hidden",
                                              display: "none",
                                            }}
                                            onChange={(e) => {
                                              onFileSelectHandler(e.target.files);
                                            }}
                                          />
                                        </label>
                                      </div>
                                      <div className="coi-info mt-3">
                                        <span className="text-14 mt-2 info">
                                          Supported document types: Acord 125, Acord 126 , Acord 130 , Dec Page and Quote Letter.
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                </>
                              )}

                              <div className="col-12 text-center">
                                {isfileuploaded ? null : (
                                  <>
                                    {uploadingFiles == false &&
                                      incompleteRequestData == false &&
                                      uploadingRetry == false &&
                                      uploadFile.length > 0 && (
                                        <div
                                          className="d-flex justify-content-center"
                                          style={{
                                            width: "100%",
                                            padding: "20px",
                                          }}
                                        >
                                          {loading == false ? (
                                            <>
                                              <AtkButtonIconBefore
                                                label="Upload"
                                                className="pf-secondary-btn"
                                                onClick={() => {
                                                  createUploadRequest();
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <div className="d-flex justify-content-center align-items-center p-2">
                                              <AtkLoader></AtkLoader>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>

                              <div className="col-2"></div>
                              <div className="col-8 text-12 mt-3">
                                <AtkMessage
                                  appearance="information"
                                  messageText={
                                    "This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."
                                  }
                                />
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        </>
                      )}
                      {showExtractedData ?
                        <div
                          className="col p-2 ms-2"
                          style={{
                            border: "1px solid darkgrey",
                            borderRadius: "30px",
                            minHeight: "66vh",
                          }}
                        >
                          <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                            <div className="col-12 d-flex align-items-center justify-content-start">

                              <span
                                className={activeTab === "Insured" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                onClick={() => {
                                  handleTabClick("Insured");
                                }}
                              >
                                Insured Details
                              </span>
                              {documentType == "DecPage" || documentType == "QuoteLetter" ?
                                <span
                                  className={activeTab === "CoverageLimit" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                  onClick={() => {
                                    handleTabClick("CoverageLimit");
                                  }}
                                >
                                  Coverage Limit Details
                                </span>
                                : null}
                            </div>
                          </div>
                          {activeTab === "Insured" ? (
                            <SmartScanDataExtractPage
                              firstName={firstName}
                              onChangeInsuredFirstName={onChangeInsuredFirstName}
                              lastName={lastName}
                              onChangeInsuredLastName={onChangeInsuredLastName}
                              policyNumber={policyNumber}
                              onChangePolicyNumber={onChangePolicyNumber}
                              policyPremium={policyPremium}
                              onChangePolicyPremium={onChangePolicyPremium}
                              carrierNameLoading={carrierNameLoading}
                              carrierName={carrierName}
                              onChangeCarrierName={onChangeCarrierName}
                              allCarrierNames={allCarrierNames}
                              lineOfBusiness={lineOfBusiness}
                              onChangeLineOfBusiness={onChangeLineOfBusiness}
                              phoneNumber={phoneNumber}
                              onChangePhoneNumber={onChangePhoneNumber}
                              email={email}
                              onChangeEmail={onChangeEmail}
                              quoteNumber={quoteNumber}
                              onChangeQuoteNumber={onChangeQuoteNumber}
                              effectiveDate={effectiveDate}
                              onChangeEffectiveDate={onChangeEffectiveDate}
                              expirationDate={expirationDate}
                              onChangeExpiryDate={onChangeExpiryDate}
                              businessName={businessName}
                              onChangeBusinessName={onChangeBusinessName}
                              addressLine1={addressLine1}
                              onChangeAgencyMailingAddress1={onChangeAgencyMailingAddress1}
                              city={city}
                              onChangeAgencyMailingCity={onChangeAgencyMailingCity}
                              state={state}
                              onChangeAgencyMailingState={onChangeAgencyMailingState}
                              Allstate={Allstate}
                              submissionId={submissionId}
                              zipCode={zipCode}
                              onChangeAgencyMailingZip={onChangeAgencyMailingZip}
                              onChangeInsuredType={onChangeInsuredType}
                              insuredType={insuredType}
                              ncURL={ncURL}
                              onOpenClick={onOpenClick}
                              uploadAgain={uploadAgain}
                              onFeedbackClick={onFeedbackClick}
                              feedbackRef={feedbackRef}
                              createInsured={props.createInsured}
                              createProspect={props.createProspect}
                              documentType={documentType}
                              showUploadAgain={showUploadAgain}
                              onSubmitLoader={onSubmitLoader}
                              createQuote={props.createQuote}
                              quoteDetails={props.quoteDetails}
                              insuredFirstNameError={insuredFirstNameError}
                              setInsuredFirstNameError={setInsuredFirstNameError}
                              insuredLastNameError={insuredLastNameError}
                              setInsuredLastNameError={setInsuredLastNameError}
                              insuredEmailError={insuredEmailError}
                              setInsuredEmailError={setInsuredEmailError}
                              policyNumberError={policyNumberError}
                              setPolicyNumberError={setPolicyNumberError}
                              policyPremiumError={policyPremiumError}
                              setPolicyPremiumError={setPolicyPremiumError}
                              carrierNameError={carrierNameError}
                              setCarrierNameError={setCarrierNameError}
                              lineOfBusinessError={lineOfBusinessError}
                              setLineOfBusinessError={setLineOfBusinessError}
                              effectiveDateError={effectiveDateError}
                              setEffectiveDateError={setEffectiveDateError}
                              expiryDateError={expiryDateError}
                              setExpiryDateError={setExpiryDateError}
                              businessNameError={businessNameError}
                              setBusinessNameError={setBusinessNameError}
                              quoteNumberError={quoteNumberError}
                              setQuoteNumberError={setQuoteNumberError}
                              mailingAddressLine1Error={mailingAddressLine1Error}
                              setmailingAddressLine1Error={setmailingAddressLine1Error}
                              mailingCityError={mailingCityError}
                              setMailingCityError={setMailingCityError}
                              mailingStateError={mailingStateError}
                              setMailingStateError={setMailingStateError}
                              mailingZipCodeError={mailingZipCodeError}
                              setMailingZipCodeError={setMailingZipCodeError}
                              prospectDetails={props.prospectDetails}
                              fees={fees}
                              onChangeFees={onChangeFees}
                              taxes={taxes}
                              onChangeTaxes={onChangeTaxes}
                              createQuoteForInsured={props.createQuoteForInsured}
                              createNewPolicyForInsured={props.createNewPolicyForInsured}
                              createExistingPolicyForInsured={props.createExistingPolicyForInsured}
                              existingPolicyId={props.existingPolicyId}
                              insuredDatabaseId={props.insuredDatabaseId}
                              prospectDatabaseId={props.prospectDatabaseId}
                              isUpdateInNowCerts={false}
                              createNewPolicy={props.createNewPolicy}
                              fromHistory={false}
                              coverageLimitDetails={coverageLimitDetails} />
                          ) : null}
                          {documentType == "DecPage" || documentType == "QuoteLetter" ?
                            <>
                              {activeTab === "CoverageLimit" ? (
                                <div className="card mt-2">
                                  <div className="col-12  history-table p-3 rounded-bottom">
                                    {coverageLimitDetails.map((item: any, index: number) => (
                                          <div key={index}>
                                            {item.generalLiability == "" || item.generalLiability == undefined ?
                                              null
                                              : <>
                                              <span className="text-12 fw-bold">General liability</span>
                                              <div className="col-12   w-100 p-0" key={index}>
                                                <div className="history-expanded-block ">
                                                  <div className="row mx-auto">
                                                    <div className="row ">
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit damage to rented premises</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.generalLiability.limitDamageToRentedPremises == undefined ? "" : item.generalLiability.limitDamageToRentedPremises}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit each occurrence</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.generalLiability.limitEachOccurrence}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit general aggregate</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.generalLiability.limitGeneralAggregate}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit med exp</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.generalLiability.limitMedExp}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-ite</div>ms-center fw-bold ">
                                                        <span>Limit personal and injury</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.generalLiability.limitPersonalAndAdvInjury}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit products comp aggregate</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.generalLiability.limitProductsCompOpAggregate}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>}

                                            {/* {item.autoMobileLiability == "" || item.autoMobileLiability == undefined ?
                                              null
                                              : <>
                                              <span className="text-12 fw-bold">Auto Mobile Liability </span>
                                              <div className="col-12   w-100 p-0">
                                                <div className="history-expanded-block ">
                                                  <div className="row mx-auto">
                                                    <div className="row ">
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit Combined Single</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitCombinedSingle}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit bodily injury per person</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitBodilyInjuryPerPerson}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit bodily injury per accident</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitBodilyInjuryPerAccident}
                                                        </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit property damage</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.autoMobileLiability.limitPropertyDamage}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>} */}


                                            {item.WorkerCompensationAndEmployersLiability == "" || item.WorkerCompensationAndEmployersLiability == undefined ?
                                              null
                                              : <>
                                              <span className="text-12 fw-bold">Worker Compensation Liability </span>
                                              <div className="col-12   w-100 p-0">
                                                <div className="history-expanded-block ">
                                                  <div className="row mx-auto">
                                                    <div className="row ">
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit each accident</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.WorkerCompensationAndEmployersLiability.limitEachAccident}
                                                        </span>
                                                      </div>

                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Limit EA accident</span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.WorkerCompensationAndEmployersLiability.limitEAEmployee}
                                                        </span>
                                                      </div>

                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                                                        <span>Policy limit </span>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                                                        <span>
                                                          {item.WorkerCompensationAndEmployersLiability.limitPolicy}
                                                        </span>
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>}
                                          </div>
                                        ))}

                                  </div>
                                </div>

                              ) : null}
                            </>
                            : null}
                        </div>
                        : null}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
