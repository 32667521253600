import React, { FC, useEffect, useState, useRef } from "react";
import Header from '../../common/Header'
import BackgroundVideo from '../../common/BackgroundVideo'
import NcLogo from "../../assets/images/momemntumpurple.webp";
import { Toast } from 'primereact/toast'
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import axios from "axios";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { Guid } from "js-guid";


export default function BotAuthentication() {
    const toast: any = useRef("");
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        validateToolboxToken();
    }, []);

    const validateToolboxToken = () => {
        setLoading(true);
        var token: any = searchParams.get("token");

        let config = {
            method: 'get',
            url: `${process.env.REACT_APP_AUTHAPI}/api/account/validatetoolboxtoken?token=${token}`,
            headers: {}
        };

        axios.request(config)
            .then((response: any) => {
                setLoading(false);

                CommonValues.SetValues(
                    response.data.token,
                    response.data.tenantId,
                    response.data.userName,
                    response.data.userId,
                    response.data.companyName,
                    response.data.roleId,
                    response.data.avatarName,
                    response.data.firstName,
                    "",
                    response.data.iconURL,
                    response.data.hasGIGMarket,
                    response.data.slugURL,
                    response.data.headerText
                );

                navigate(`/support-bot/${Guid.newGuid()}`);
            })
            .catch((error: any) => {
                setLoading(false);
                toast.current.show({
                    severity: "info",
                    detail:
                        "Unknown error occured while validating the token.",
                    life: 3000,
                });
            });

    };
    return (
        <div className="momentum-sso-page">
            <Toast ref={toast} />
            <Header pagename="momentum-sso-page" />

            <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
                <BackgroundVideo />
                <div className="login-box ">
                    <div className="row mx-auto d-flex align-items-center">
                        <div className="col-12 text-center  nowcerts-loader">
                            {loading ? (
                                <>
                                    <div className=" authenticating-block-pf text-center">
                                        <img src={NcLogo} height="60px" />
                                        <div className="mt-3 mb-3">
                                            <span className="">Authenticating</span>
                                        </div>
                                        <div className="d-flex justify-content-center nowcerts-sso-loader">
                                            <AtkLoader />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className=" d-flex align-items-center justify-content-center" style={{ minHeight: "100px" }}>
                                    <span className="not-authorize-text">You are not authorized</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* {embeded == "true" ? null : <Footer />} */}
        </div>
    )
}
