import { Toast } from "primereact/toast";
import { useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import BillingSidebar from "./BillingSidebar";

import { Elements } from "@stripe/react-stripe-js";
import InjectedStripePaymentCredits from "./AddPaymentCredits";
import { loadStripe } from "@stripe/stripe-js";
import BillingMiddleHeader from "./BillingMiddleHeader";
import BackgroundVideo from "../common/BackgroundVideo";

export default function BuyCredis() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const hostname = window.location.hostname;
  useEffect(() => {
     if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  return (
    <>
      <Toast ref={toast} />
      <div className="buy-credits-main-page">
        <Header pagename="buy-credits" />
        <div className="content-wrapper ">
          <BackgroundVideo/>
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <BillingMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <BillingSidebar pagename="buy-credits" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row   d-flex  align-items-end text-12 position-relative">
                  <div className="col-12">
                    <Elements stripe={stripePromise}>
                      <InjectedStripePaymentCredits />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
