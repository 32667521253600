import Header from "../common/Header";
import { Toast } from "primereact/toast";

import axios from "axios";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AtkButton from "../AtlaskitControls/AtkButton";
import CommonValues from "../common/utils";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Dialog } from "primereact/dialog";
import { isValid } from "date-fns";
import BackgroundVideo from "../common/BackgroundVideo";
const hostname = window.location.hostname;

export default function GodMode() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [search, setSearch] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [supportloading, setSupportLoading] = useState(false);
  const [addCreditsLoading, setAddCreditsLoading] = useState(false);
  const [allusersLoading, setAllUsersLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showAddCreditsPop, setShowAddCreditsPop] = useState(false);
  const [cookies, setCookie] = useCookies(["igtoken", "NCToken", "tenantId", "RoleId", "userName"]);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [creditsValue, setCreditsValue] = useState(0);
  const [userId, setUserId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [creditAmountError, setCreditAmountError] = useState("");

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      setAllUsersLoading(true);
    }
    if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 || hostname === process.env.REACT_APP_Momentum_Url3 || hostname === process.env.REACT_APP_Momentum_Url4) {
      setTimeout(() => {
        getAllUsers(true);
      }, 10);
    } else {
      setTimeout(() => {
        getAllUsers(false);
      }, 10);
    }
  }, [search]);

  const getAllUsers = (ismomemntum: boolean) => {
    // setAllUsersLoading(true);
    var data = {
      ismomemntum: ismomemntum,
      search: search,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTHAPI}/getallsdkuser`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setAllUsersLoading(false);
        setUsers(response.data);
      })
      .catch((error: any) => {
        setAllUsersLoading(false);
        if (error.response.status == 401) {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while SupportLogin", life: 3000 });
          navigate("/");
          CommonValues.Logout(navigate);
        }
      });
  };
  const onClientSearchChange = (e: any) => {
    setSearch(e.target.value);
  };
  const handleRowSelect = (e: any) => {
    setWorkEmail(e.value.workEmail);
  };
  const Supportlogin = (rowData: any) => {
    setWorkEmail(rowData.workEmail);
    setSupportLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/impersonate?email=` + rowData.workEmail,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        setSupportLoading(false);
        if (response.data.token == "") {
          let errorMessage = "";
          errorMessage = "Oops... something went wrong. Please try again later.";
          toast.current.show({ severity: "error", summary: 'Error', detail: { errorMessage }, life: 3000 });
        } else {
          toast.current.show({ severity: "success", summary: 'Success', detail: "Support login Done Successfully!", life: 3000 });

          setCookie("igtoken", localStorage.getItem("igtoken"));
          setCookie("NCToken", localStorage.getItem("NCToken"));
          setCookie("tenantId", localStorage.getItem("tenantId"));
          setCookie("RoleId", localStorage.getItem("RoleId"));
          setCookie("userName", localStorage.getItem("userName"));

          CommonValues.SetValues(
            response.data.token,
            response.data.tenantId,
            response.data.userName,
            response.data.userId,
            response.data.companyName,
            response.data.roleId,
            response.data.avatarName,
            response.data.firstName,
            "",
            response.data.iconURL,
            response.data.hasGIGMarket,
            response.data.slugURL,
            response.data.headerText
          );
          setTimeout(() => {
            navigate("/startuppage");
          }, 1000);
        }
      })
      .catch((error: any) => {
        setSupportLoading(false);
        if (error.response.status == 401) {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while SupportLogin", life: 3000 });
          navigate("/");
          CommonValues.Logout(navigate);
        }
      });
  };

  const AddCreditsClick = (rowData: any) => {
    setUserId(rowData.userId);
    setCompanyId(rowData.companyId);
    setCurrentBalance(rowData.currentBalance.toFixed(2));
    setShowAddCreditsPop(true);
  };

  const onchangeCreditsValue = (value: any) => {
    setCreditsValue(value);
  };

  const AddCredits = () => {
    if (checkCreditsValidation()) {
      setAddCreditsLoading(true);
      var data = {
        userId: userId,
        companyId: companyId,
        amountInDollar: creditsValue,
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/freecredits`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          if (response.data.success == true) {
            toast.current.show({ severity: "success", summary: 'Success', detail: "Free credits Added successfully", life: 3000 });

            if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 || hostname === process.env.REACT_APP_Momentum_Url3 || hostname === process.env.REACT_APP_Momentum_Url4) {
              setTimeout(() => {
                getAllUsers(true);
                setShowAddCreditsPop(false);
                setCreditsValue(0);
              }, 10);
            } else {
              setTimeout(() => {
                getAllUsers(false);
                setShowAddCreditsPop(false);
                setCreditsValue(0);
              }, 10);
            }
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: response.data.ErrorMessage, life: 3000 });
          }
          setAddCreditsLoading(false);
        })
        .catch((error: any) => {
          setAddCreditsLoading(false);
          if (error.response.status == 401) {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while add free credits", life: 3000 });
            navigate("/");
            CommonValues.Logout(navigate);
          }
        });
    }
  };

  const checkCreditsValidation = () => {
    let isValid = true;
    setCreditAmountError("");
    if (creditsValue == 0 || creditsValue == undefined) {
      setCreditAmountError("Please enter credit amount");
      isValid = false;
    }

    if (creditsValue > 20) {
      setCreditAmountError("Free credits cannot be greater than 20"); //toast.current.show({ severity: "error", summary: 'Error', detail: "Free credits can not add greater than 20", life: 3000 });
      isValid = false;
    }
    return isValid;
  };
  const currentBalanceTemplate = (rowData: any) => {
    return <span>{rowData.currentBalance.toFixed(2)}</span>;
  };
  return (
    <div className="admin-main-page new-quote-main-page">
      <Toast ref={toast} />
      <Header />
      <div className="content-wrapper">
        <BackgroundVideo/>
        <div className="row  wrapper-main-row mx-auto wrapper-below-div">
          <div className="row main-content-row mx-auto">
            <div className="col-lg-12 col-md-12 col-sm-12 main-content-col ">
              <div className="row client-search-row position-relative ">
                <div className="col-lg-4 col-md-6 col-sm-6 col-10 p-0 my-1 client-search-input-col position-relative mx-auto">
                  <div className="search-bar ">
                    <AtkTextField
                      type="text"
                      id="text"
                      className="search-area "
                      name="clientName"
                      onChange={onClientSearchChange}
                      value={search}
                      //   onKeyDown={onKeyClick}
                      placeholder={"Enter user name"}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-6 col-10 p-0 my-1 client-search-input-col position-relative mx-auto"></div>
              </div>
              <div className="row ">
                {allusersLoading ? (
                  <div className="d-flex justify-content-center align-items-center mt-2 fix-width-support ">
                    <AtkLoader></AtkLoader>
                  </div>
                ) : (
                  <div className="col-12  history-table p-3 rounded-bottom">
                    <DataTable
                      value={users}
                      paginator
                      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                      rows={8}
                      selectionMode="single"
                      onSelectionChange={(e) => {
                        handleRowSelect(e);
                      }}
                    >
                      <Column header="Full Name" field="fullName" sortable></Column>
                      <Column header="Work Email" field="workEmail" sortable></Column>
                      <Column header="Company Name" field="companyName" sortable></Column>
                      <Column header="Current Balance" field="currentBalance" body={currentBalanceTemplate} sortable></Column>
                      <Column
                        header="Action"
                        body={(rowData: any) => {
                          return supportloading && workEmail == rowData.workEmail ? (
                            // <div className="d-flex justify-content-center align-items-center mt-2">
                            <AtkLoader></AtkLoader>
                          ) : (
                            // </div>
                            <AtkButton label="Support Login" className="pf-primary-btn w-auto show-cursor" onClick={() => Supportlogin(rowData)} />
                          );
                        }}
                      ></Column>
                      <Column
                        header="Credits"
                        body={(rowData: any) => {
                          return (
                            <AtkButton label="Add Credits" className="pf-primary-btn w-auto show-cursor" onClick={() => AddCreditsClick(rowData)} />
                          );
                        }}
                      ></Column>
                    </DataTable>
                  </div>
                )}

                {showAddCreditsPop ? (
                  <Dialog
                    visible={showAddCreditsPop}
                    position={"center"}
                    draggable={false}
                    resizable={false}
                    onHide={() => {
                      setShowAddCreditsPop(false);
                    }}
                    style={{ width: "30vw", height: "30vh" }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <label>
                          <b>Current Balance</b>
                        </label>
                        {currentBalanceLoading ? (
                          <AtkLoader />
                        ) : (
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <AtkTextField className="input-field form-control p-0" type="text" id="text" value={currentBalance} isDisabled={true} />
                          </div>
                        )}
                      </div>
                      <div className="mt-2"></div>
                      <div className="col-12">
                        <label>
                          <b>Add Credits</b>
                        </label>
                        <div className={creditAmountError.length > 0 ? "input-group validation-div" : "input-group"}>
                          <span className="input-group-text">$</span>
                          <AtkTextField
                            className="input-field form-control p-0"
                            type="number"
                            id="number"
                            value={creditsValue}
                            onChange={(e) => {
                              onchangeCreditsValue(e.target.value);
                            }}
                          />
                        </div>
                        <span className="text-danger text-12">{creditAmountError}</span>
                      </div>
                      <div className="mt-2"></div>
                      <div className="col-12" style={{ display: "flex", justifyContent: "right" }}>
                        {addCreditsLoading ? (
                          <AtkLoader />
                        ) : (
                          <AtkButton
                            label="Add Credits"
                            className="pf-primary-btn w-auto show-cursor"
                            onClick={() => {
                              AddCredits();
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Dialog>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
