import { Skeleton } from 'primereact/skeleton'
import React from 'react'

export default function PolicyLoadingSkeleton() {
    return (
        <div>
            <div className="col-12">
                <Skeleton className="mb-2"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="70%" className="mb-2 d-block"></Skeleton>
            </div>

            <div className="col-12">
                <Skeleton width="40%" className="mb-2 d-block"></Skeleton>
            </div>
            <div className="col-12">
                <Skeleton width="20%" className="mb-2 d-block"></Skeleton>
            </div>
        </div>
    )
}
