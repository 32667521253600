import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import DriverLicenseScanMiddleHeader from "./DriverLicenseScanMiddleHeader";
import DriverLicenseScanSidebar from "./DriverLicenseScanSidebar";
import BackgroundVideo from "../../common/BackgroundVideo";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import axios from "axios";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import feedbackImg from "../../assets/images/feedback.svg";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import DriverLicenseScanDataExtractPage from "./DriverLicenseScanDataExtractPage";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../../AtlaskitControls/AtkButton";

export default function DriverLicenseScanHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [driverLicenseScanHistoryData, setDriverLicenseScanHistoryData] = useState<any>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [drivingLicenseNumber, setDrivingLicenseNumber] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [state, setState] = useState("");
  const [addressOfLicenseHolder, setAddressOfLicenseHolder] = useState("");
  const [drivingLicenseClass, setDrivingLicenseClass] = useState("");
  const [endorsements, setEndorsements] = useState("");
  const [restrictions, setRestrictions] = useState("");
  const [sex, setSex] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [isUpdateInNowCerts, setUpdateInNowCerts] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [clientName, setClientName] = useState("");
  const [insuredClientId, setInsuredClientId] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isUploadAgain, setIsUploadAgain] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    getDriverLicenseScanHistory();
  }, []);

  const getDriverLicenseScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDriverLicenseScanHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting driver scan history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" title="Feedback" onClick={(e) => onShowFeedbackPopup(e, rowData)} />;
  };

  const actionTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );
  };
  const updateInAMSStatus = (rowData: any) => {
    return rowData.isUpdateInNowcerts === true ? (
      <span className="">Yes</span>
    ) : rowData.isUpdateInNowcerts === false ? (
      <span className="">No</span>
    ) : null;
  };
  const insuredNameAction = (rowData: any) => {
    const selectionOptionsJson = JSON.parse(rowData.selectionOptionsJson);
    return rowData.isUpdateInNowcerts != null ? <span className="">{selectionOptionsJson.clientName}</span> : null;
  };

  const onPreviewClick = (rowData: any) => {
    setTransactionId(rowData.transactionId);
    setUpdateInNowCerts(rowData.isUpdateInNowcerts);
    if (rowData.isUpdateInNowcerts == true) {
      const responseJson = JSON.parse(rowData.submittedRequestJson);
      setFirstName(responseJson.firstName);
      setLastName(responseJson.lastName);
      setDateOfBirth(responseJson.dateOfBirth);
      setDrivingLicenseNumber(responseJson.drivingLicenseNumber);
      setIssueDate(responseJson.issueDate);
      setExpirationDate(responseJson.expirationDate);
      setAddressLine1(responseJson.addressLine1);
      setCity(responseJson.city);
      setState(responseJson.state);
      setZipCode(responseJson.zipCode);
      setAddressOfLicenseHolder(responseJson.addressOfLicenseHolder);
      setDrivingLicenseClass(responseJson.drivingLicenseClass);
      setEndorsements(responseJson.endorsements);
      setRestrictions(responseJson.restrictions);
      setSex(responseJson.sex);
      setHeight(responseJson.height);
      setWeight(responseJson.weight);
      setEyeColor(responseJson.eyeColor);
      setHairColor(responseJson.hairColor);
      setShowPopUp(true);
    } else {
      const responseJson = JSON.parse(rowData.responseJson);
      const selectionOptionJson = JSON.parse(rowData.selectionOptionsJson);
      const height = responseJson.heightFeet + "." + responseJson.heightInch;
      setFileName(rowData.fileName);
      setFirstName(responseJson.firstName);
      setLastName(responseJson.lastName);
      setDateOfBirth(responseJson.dateOfBirth);
      setDrivingLicenseNumber(responseJson.drivingLicenseNumber);
      setIssueDate(responseJson.issueDate);
      setExpirationDate(responseJson.expirationDate);
      setAddressLine1(responseJson.addressLine1);
      setCity(responseJson.city);
      setState(responseJson.state);
      setZipCode(responseJson.zipCode);
      setAddressOfLicenseHolder(responseJson.addressOfLicenseHolder);
      setDrivingLicenseClass(responseJson.drivingLicenseClass);
      setEndorsements(responseJson.endorsements);
      setRestrictions(responseJson.restrictions);
      setSex(responseJson.sex);
      setHeight(height);
      setWeight(responseJson.weight);
      setEyeColor(responseJson.eyeColor);
      setHairColor(responseJson.hairColor);
      setShowPopUp(true);
      setInsuredClientId(selectionOptionJson.insuredClientId);
      setClientName(selectionOptionJson.clientName);
    }

    const token = CommonValues.GetToken();
    var data = {
      transactionId: rowData.transactionId,
      fileName: rowData.fileName,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setFileUrl(response.data.fileUrl);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while preview details ", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
    setPreviewFileLoading(false);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setFileUrl("");
    getDriverLicenseScanHistory();
  };

  const onChangeFirstName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setFirstName(Value);
  };

  const onChangeLastName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setLastName(Value);
    setFirstNameErrorMessage("");
  };

  const onChangeDateOfBirth = (value: any) => {
    setDateOfBirth(value);
  };

  const onChangeDrivingLicenseNumber = (value: any) => {
    setDrivingLicenseNumber(value);
  };

  const onChangeIssueDate = (value: any) => {
    setIssueDate(value);
  };

  const onChangeExpirationDate = (value: any) => {
    setExpirationDate(value);
  };

  const onChangeState = (value: any) => {
    setState(value);
  };

  const onChangeDrivingLicenseClass = (value: any) => {
    setDrivingLicenseClass(value);
  };

  const onChangeEndorsements = (value: any) => {
    setEndorsements(value);
  };

  const onChangeRestrictions = (value: any) => {
    setRestrictions(value);
  };

  const onChangeSex = (value: any) => {
    setSex(value);
  };

  const onChangeHeight = (value: any) => {
    setHeight(value);
  };

  const onChangeWeight = (value: any) => {
    setWeight(value);
  };

  const onChangeEyeColor = (value: any) => {
    setEyeColor(value);
  };

  const onChangeHairColor = (value: any) => {
    setHairColor(value);
  };

  const onChangeAddressLine1 = (value: any) => {
    setAddressLine1(value);
  };

  const onChangeCity = (value: any) => {
    setCity(value);
  };

  const onChangeZipCode = (value: any) => {
    setZipCode(value);
  };
  const checkValidation = () => {
    let isValid = true;
    if (firstName == "" || firstName == null || firstName == "None") {
      isValid = false;
      setFirstNameErrorMessage("Please enter first name.");
    }
    return isValid;
  };
  const OnSubmit = () => {
    if (checkValidation()) {
      addDriverData(insuredClientId);
      downloadFileFromAzure();
    }
  };
  const addDriverData = (insuredDatabaseId: any) => {
    setSubmitLoading(true);
    let date = issueDate.split("/");
    let requestDataList = {
      insured_database_id: insuredDatabaseId,
      first_name: firstName,
      last_name: lastName,
      insured_commercial_name: clientName,
      license_number: drivingLicenseNumber,
      driver_license_class: drivingLicenseClass,
      date_of_birth: dateOfBirth,
      gender: sex,
      license_state: state,
      termination_date: expirationDate,
      cdl_date: date[0],
      cdl_year: date[2],
      license_year: date[2],
      active: true,
    };
    const data = JSON.stringify(requestDataList);

    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/adddriver?&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Driver data updated in NowCerts", life: 3000 });
        setSubmitLoading(false);
        updateDrivingLicenseScanHistory();
        setIsUploadAgain(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Bad Request",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while adding single. driver data in nowcerts",
              life: 3000,
            });
          }
        }
        setSubmitLoading(false);
      });
  };

  const updateDrivingLicenseScanHistory = () => {
    let token = CommonValues.GetToken();
    const submittedRequestJson = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      drivingLicenseNumber: drivingLicenseNumber,
      issueDate: issueDate,
      expirationDate: expirationDate,
      addressLine1: addressLine1,
      city: city,
      state: state,
      zipCode: zipCode,
      drivingLicenseClass: drivingLicenseClass,
      endorsements: endorsements,
      restrictions: restrictions,
      sex: sex,
      height: height,
      weight: weight,
      eyeColor: eyeColor,
      hairColor: hairColor,
    };
    const selectionOptionsJson = {
      useExistingInsured: true,
      insuredClientId: insuredClientId,
      clientName: clientName,
    };
    var data = {
      TransactionId: transactionId,
      submittedRequestJson: JSON.stringify(submittedRequestJson),
      selectionOptionsJson: JSON.stringify(selectionOptionsJson),
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/updatehistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while driver license scan updating the request.",
            life: 3000,
          });
        }
      });
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setTransactionId(rowData.transactionId);
      onSaveFeedback(rowData);
    }
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/ratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setDriverLicenseScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setDriverLicenseScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
  };

  const downloadFileFromAzure = () => {
    let token = CommonValues.GetToken();
    axios({
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/downloadfile?fileName=${fileName}&transactionId=${transactionId}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        const file = new Blob([response.data], { type: response.data.type });
        const fileReader = new FileReader();

        fileReader.onload = function (evt) {
          var binary = "";
          var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          sendToNowCertsAMSAPI(btoa(binary));
        };
        fileReader.readAsArrayBuffer(file);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while saving file to AMS ";
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };

  const sendToNowCertsAMSAPI = (base64String: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = JSON.stringify({
      TransactionId: transactionId,
      FileName: fileName,
      NCPolicyId: "",
      NCInsuredId: insuredClientId,
      FileData: base64String,
      FolderName: "Driver License",
      NowCertsToken: nowCertsToken,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/upload`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while saving file to AMS ";
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while saving file to AMS", life: 3000 });
          }
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <DriverLicenseScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <DriverLicenseScanSidebar pagename="driver-license-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    <div className="row mb-3">
                      <div className="col-6">
                        <span className="p-input-icon-left w-50">
                          <i className="fa fa-search grid-search-icon" />
                          <AtkTextField
                            type="text"
                            id="txtfirstName"
                            name="globalFilter"
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            value={globalFilter}
                            className="input-field"
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                      <div className="col-6 text-end">
                        <AtkButton label="Refresh" onClick={getDriverLicenseScanHistory} className=" pf-primary-btn w-auto" />
                      </div>
                    </div>
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable
                        value={driverLicenseScanHistoryData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        globalFilter={globalFilter}
                        emptyMessage="No data available."
                      >
                        <Column
                          header="Submitted Date"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="justify-">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          field="created"
                          sortable
                          style={{ width: "12%" }}
                        ></Column>
                        <Column header="File Name" field="fileName" sortable></Column>
                        <Column header="Client Name" body={insuredNameAction} sortable></Column>
                        <Column header="Update In AMS" body={updateInAMSStatus} sortable></Column>
                        <Column header="Status" body={statusTemplate} style={{ width: "10%" }} sortable></Column>
                        <Column header="Action" body={actionTemplate} className="text-center" style={{ width: "12%" }}></Column>
                        <Column header="Feedback" body={feedbackTemplate} className="text-center" style={{ width: "5%" }}></Column>
                      </DataTable>
                    )}

                    <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                      {previewFileLoading ? (
                        <div className="d-flex align-items-center justify-content-center overflow-hidden">
                          <AtkLoader />
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-6 border-end">
                            <b>Uploaded Image</b>
                            <iframe src={fileUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                          </div>
                          <div className="col-6">
                            <div className="row d-flex justify-content-end">
                              <div className="col-4"></div>
                              <div className="col-4"></div>
                              <div className="col-4">
                                {isUpdateInNowCerts == true ? (
                                  <>
                                    <span className="text-16 fw-bold">Update In AMS</span>
                                    <AtkTextField type="text" id="text" className="" name="clientName" value="Yes" isDisabled={true} />
                                  </>
                                ) : (
                                  <>
                                    <span className="text-16 fw-bold">Client Name</span>
                                    <AtkTextField type="text" id="text" className="" name="clientName" value={clientName} isDisabled={true} />
                                  </>
                                )}
                              </div>
                            </div>
                            <DriverLicenseScanDataExtractPage
                              firstName={firstName}
                              onChangeFirstName={onChangeFirstName}
                              firstNameErrorMessage={firstNameErrorMessage}
                              lastName={lastName}
                              onChangeLastName={onChangeLastName}
                              dateOfBirth={dateOfBirth}
                              onChangeDateOfBirth={onChangeDateOfBirth}
                              drivingLicenseNumber={drivingLicenseNumber}
                              onChangeDrivingLicenseNumber={onChangeDrivingLicenseNumber}
                              issueDate={issueDate}
                              onChangeIssueDate={onChangeIssueDate}
                              expirationDate={expirationDate}
                              onChangeExpirationDate={onChangeExpirationDate}
                              state={state}
                              onChangeState={onChangeState}
                              addressLine1={addressLine1}
                              onChangeAddressLine1={onChangeAddressLine1}
                              city={city}
                              onChangeCity={onChangeCity}
                              zipCode={zipCode}
                              onChangeZipCode={onChangeZipCode}
                              drivingLicenseClass={drivingLicenseClass}
                              onChangeDrivingLicenseClass={onChangeDrivingLicenseClass}
                              endorsements={endorsements}
                              onChangeEndorsements={onChangeEndorsements}
                              restrictions={restrictions}
                              onChangeRestrictions={onChangeRestrictions}
                              sex={sex}
                              onChangeSex={onChangeSex}
                              height={height}
                              onChangeHeight={onChangeHeight}
                              weight={weight}
                              onChangeWeight={onChangeWeight}
                              eyeColor={eyeColor}
                              onChangeEyeColor={onChangeEyeColor}
                              hairColor={hairColor}
                              onChangeHairColor={onChangeHairColor}
                              formHistoryPage={true}
                              isUpdateInNowCerts={isUpdateInNowCerts}
                              onSubmit={OnSubmit}
                              isUploadAgain={isUploadAgain}
                              onUploadAgain={onHidePopupClicked}
                              submitLoading={submitLoading}
                            />
                          </div>
                        </div>
                      )}
                    </Dialog>

                    <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                      <div className="row mx-auto d-flex align-items-center mb-2">
                        <div className="col-12 feedback-header">
                          <h4>Feedback</h4>
                        </div>
                        <div className={"form-group col-12 mb-2 text-12"}>
                          <textarea
                            name="feedback"
                            onChange={(e) => onFeedbackChange(e.target.value)}
                            value={feedBackData.feedback}
                            className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                            rows={4}
                            placeholder="Enter your feedback here"
                          />
                          <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                        </div>
                        <div className="col-4 d-flex justify-content-evenly">
                          <i
                            className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                                   ${feedBackData.isLiked == true ? " active" : " "}`}
                            aria-hidden="true"
                            onClick={(e) => onThumbsUpClick(feedBackData)}
                          ></i>
                          <i
                            className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                    ${feedBackData.isLiked == false ? " active" : " "}`}
                            aria-hidden="true"
                            onClick={(e) => onThumbDownClick(feedBackData)}
                          ></i>
                        </div>
                        <div className="col-4 text-end p-2 pe-0  ">
                          <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                        </div>
                        <div className="col-4 text-end p-2  ">
                          {transactionId == feedBackData.transactionId && feedbackLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <AtkLoader />
                            </div>
                          ) : (
                            <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                          )}
                        </div>
                      </div>
                    </OverlayPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
