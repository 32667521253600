import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DriverLicenseScanMiddleHeader from "./DriverLicenseScanMiddleHeader";
import DriverLicenseScanSidebar from "./DriverLicenseScanSidebar";
import Header from "../../common/Header";
import BackgroundVideo from "../../common/BackgroundVideo";
import { RadioButton } from "primereact/radiobutton";
import Toggle from "@atlaskit/toggle";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Toast } from "primereact/toast";
import DriverLicenseScanLandingPage from "./DriverLicenseScanLandingPage";

export default function DriverLicenseScanOptionSelectionPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast: any = useRef("");
  const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;
  const [createInsured, setCreateInsured] = useState(false);
  const [existingInsured, setExistingInsured] = useState(true);
  const [clientName, setClientName] = useState("");
  const [insuredSearch, setInsuredSearch] = useState("");
  const [insuredClientId, setInsuredClientId] = useState("");
  const [insuredClientNameError, setInsuredClientNameError] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(false);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [showDLFileUploadPage, setShowDLFileUploadPage] = useState(false);
  const [clientNameErrorMessage,setClientNameErrorMessage]=useState("");
 const hostname = window.location.hostname;
  useEffect(() => {
     if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    if (insuredSearch.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(insuredSearch);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [insuredSearch]);

  const handleClientForInsured = () => {
    // setCreateInsured(!createInsured)
    setExistingInsured(!existingInsured);
  };
  const onClientSelectForInsured = (client: any) => {
    setInsuredSearch("");
    setClientName(client.name);
    setInsuredClientId(client.databaseId);
    setInsuredClientNameError("");
  };
  const onClientSearchChangeForInsured = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setInsuredSearch(value);
    setClientNameErrorMessage("");
  };

  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the insureds data.Please try again later",
                life: 3000,
              });
            }
          }
        }
        setSearchClientLoading(false);
      });
  };

  const onNextButtonClick = () => {
    if(checkValidation()){
    setShowDLFileUploadPage(true);
    }
  };

  const checkValidation=()=>{
    let isValid=true;
    if(clientName==null || clientName=="")
    {
      isValid=false
      setClientNameErrorMessage("Please select client name.");
    }
    return isValid
  }

  const onBackButtonClick = () => {
    setShowDLFileUploadPage(false);
    setClientName("");
    setCreateInsured(false);
    setExistingInsured(true);
  };
  return (
    <>
      <div className="driver-License-scan-main-page">
        <Header pagename="driver-License-scan" NavigatedFromChatBot={navigatedFromChatBot} />
        <div className="content-wrapper">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <DriverLicenseScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <DriverLicenseScanSidebar pagename="driver-license-scan" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                {showDLFileUploadPage ? (
                  <>
                    <DriverLicenseScanLandingPage
                      clientName={clientName}
                      insuredClientId={insuredClientId}
                      onBackButtonClick={onBackButtonClick}
                    />
                  </>
                ) : (
                  <div className="row  d-flex  align-items-end text-12 position-relative">
                    <div className="col-12  p-0">
                      <div className="row mx-auto d-flex  align-items-center " style={{ backgroundColor: "#f6f6f6" }}>
                        <div className="col-10">
                          <div className="row scan-search-row mx-auto ">
                          <div className="col-3 position-relative mb-2 p-0">
                                <p className="text-16 fw-bold mb-1 ">Select Client </p>

                                <div className={`search-bar flex-column ${clientNameErrorMessage.length > 0 ? " validation-div " : " "}`}>
                                  <AtkTextField
                                    type="text"
                                    id="client-name"
                                    className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 `}
                                    name="insuredSearch"
                                    onChange={(e) => {
                                      onClientSearchChangeForInsured(e.target.value);
                                    }}
                                    value={insuredSearch}
                                    placeholder={clientName.length > 0 ? clientName : "Enter client name"}
                                  />
                                </div>
                                {insuredSearch.replaceAll(" ", "").length > 0 && (
                                  <div id="client-search" className=" position-absolute  ">
                                    {searchClientLoading ? (
                                      <div className="d-flex justify-content-center my-2 client-loader-div">
                                        <AtkLoader />
                                      </div>
                                    ) : searchClientList.length == 0 ? (
                                      <div style={{ padding: "5px", background: "white" }}>
                                        <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                      </div>
                                    ) : (
                                      searchClientList.map((item: any) => (
                                        <div
                                          style={{ cursor: "pointer", width: "100%" }}
                                          onClick={() => {
                                            onClientSelectForInsured(item);
                                          }}
                                        >
                                          <a
                                            href="#"
                                            style={{
                                              color: "black",
                                              width: "100%",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item.name}
                                          </a>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                )}
                                <span className="text-danger text-12">{clientNameErrorMessage}</span>
                              </div>
                          </div>
                        </div>
                        <div className="col-2 text-end ">
                          <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-100 " />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
