import { Carousel } from "primereact/carousel";
import { useRef } from "react";
import PFLogo from ".././assets/images/PF_Logo 1.svg";
import OutlookLogo from ".././assets/images/Outlook.svg";
import LeadxLogo from "../assets/images/LeadX 2 1.svg";
import MacLogo from "../assets/images/ac 1 1.svg";
import RateLogo from "../assets/images/rate 2 1.svg";
import { useNavigate } from "react-router-dom";

export default function OurTools() {
  const platformRef = useRef(null);
  const navigate = useNavigate();
  const onNewQuoteClick = () => {
    // isVisitedWidget("PremFi");
    navigate("/premiumfinance");
  };
  const onMacClick = () => {
    window.open("https://staging.fusionnowcrm.com/now-certs-auth", "_blank");
  };
  const onRateClick = () => {
    window.open(process.env.REACT_APP_Rate_UI_Url, "_blank");
  };
  const onLeadxClick = () => {
    window.open("https://leadx.fusionnowcrm.com/signin", "_blank");
  };
  const platforms = [
    {
      name: "PremFi",
      description: "Seamless premium finance integration and management",
      logo: PFLogo,
      color: "text-primary",
      onClick: onNewQuoteClick,
    },
    {
      name: "LeadX",
      description: "Customized Solutions for Leads Management",
      logo: LeadxLogo,
      color: "text-warning",
      onClick: onLeadxClick,
    },

    {
      name: "Momentum Automation Center",
      description: "Fusion Automation Center - Put Your Pipeline on Autopilot",
      logo: MacLogo,
      color: "text-info",
      onClick: onMacClick,
    },
    {
      name: "Rate",
      description: "Fast, Reliable Quoting and Binding All Within Your AMS",
      logo: RateLogo,
      color: "text-success",
      onClick: onRateClick,
    },
    {
      name: "Outlook",
      description: "Coming Soon",
      logo: OutlookLogo,
      color: "text-info",
      // onClick: onNewQuoteClick,
    },
    {
      name: "PremFi",
      description: "Seamless premium finance integration and management",
      logo: PFLogo,
      color: "text-primary",
      onClick: onNewQuoteClick,
    },
    {
      name: "LeadX",
      description: "Customized Solutions for Leads Management",
      logo: LeadxLogo,
      color: "text-warning",
      onClick: onLeadxClick,
    },

    {
      name: "Momentum Automation Center",
      description: "Fusion Automation Center - Put Your Pipeline on Autopilot",
      logo: MacLogo,
      color: "text-info",
      onClick: onMacClick,
    },
    {
      name: "Rate",
      description: "Fast, Reliable Quoting and Binding All Within Your AMS",
      logo: RateLogo,
      color: "text-success",
      onClick: onRateClick,
    },
  ];
  const platformTemplate = (platform: any) => {
    return (
      <div className={`mx-2 ${platform.description === "Coming Soon" ? " " : "show-cursor"}`}>
        <div
          className="card border-0 shadow-sm p-3 text-center platform-hover-shadow "
          onClick={platform.onClick}
          style={{
            minHeight: "210px",
          }}
        >
          <div
            className="rounded-circle mx-auto d-flex align-items-center justify-content-center"
            style={{
              padding: "5px",
            }}
          >
            <img src={platform.logo} height={48} width={55}   />
          </div>
          <h6 className={`mt-3 ${platform.color}`}>{platform.name}</h6>
          {platform.description === "Coming Soon" ? (
            <p className="text-warning our-platform-description">{platform.description}</p>
          ) : (
            <p className="text-muted our-platform-description">{platform.description}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="container-fluid py-5 ms-3 mb-4"
      style={{
        background: "linear-gradient(135deg, #381574, #a200ff)",
        borderRadius: "20px",
      }}
    >
      <div className=" text-center text-white mb-3">
        <h2 className="fw-bold mb-4">Our Platform</h2>

        <Carousel
          className="platform-custom-carousel"
          ref={platformRef}
          value={platforms}
          numVisible={4}
          numScroll={1}
          circular
          autoplayInterval={3000}
          itemTemplate={platformTemplate}
          responsiveOptions={[
            { breakpoint: "1024px", numVisible: 3, numScroll: 1 },
            { breakpoint: "768px", numVisible: 2, numScroll: 1 },
            { breakpoint: "560px", numVisible: 1, numScroll: 1 },
          ]}
        />
      </div>
    </div>
  );
}
