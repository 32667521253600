import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/newquote.png";
export default function SonantSidebar(props: any) {
  const navigate = useNavigate();

  const onSonantClick = () => {
    navigate("/sonant");
  };
  const onSonantHistoryClick = () => {
    navigate("/sonanthistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename === "sonant" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onSonantClick}
        >
          <img alt="" src={NewIcon} className={props.pagename === "sonant" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">Sonant</span>{" "}
        </span>

        <span
          className={props.pagename === "sonant-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onSonantHistoryClick}
        >
          <img  alt="" src={HistoryIcon} className={props.pagename === "sonant-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
