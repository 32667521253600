import PolicyCompareIcon from "../assets/images/PolicyCopmarison.svg";
import PolicySummaryIcon from "../assets/images/PolicySummaryReport.svg";

export default function PolicyComparisonMiddleHeader(props: any) {
  console.log(props);

  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={localStorage.getItem("PolicyPageStatus") === "summary" ? PolicySummaryIcon : PolicyCompareIcon} className="" width={30} alt=""/>
          <span className="middle-header-title">Policy {localStorage.getItem("PolicyPageStatus")}</span>
        </div>
      </div>{" "}
    </div>
  );
}
