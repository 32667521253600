import axios from "axios";
import DOMPurify from "dompurify";
import { saveAs } from "file-saver";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import Plot from "react-plotly.js";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import DownloadCSVIcon from "../../assets/images/csv-download.svg";
import DownloadXMLIcon from "../../assets/images/xml-download.svg";
import SupportLoader from "../../common/SupportLoader";
import CommonValues from "../../common/utils";
import DriverLicenseScanDataExtractPage from "../DriverLicenseScan/DriverLicenseScanDataExtractPage";
import InvoiceScanDataExtractPage from "../InvoiceScan/InvoiceScanDataExtractPage";

interface AnswerProps {
  chatResponse: ChatResponse;
  index: number;
  chatBotEmbeded: boolean;
  // sendQuestion: (question: string) => void;
  onFeedbackSubmit: (chatId: string, feedBack: string) => void;
  onFeedbackChange: (chatId: string, feedBack: string) => void;
  toggleFeedbackVisibility: (chatId: string) => void;
  onCopyResponseClick: (copiedData: string) => void;
  onThumbsUpClick: (chatId: string, like: boolean, disLike: boolean) => void;
  onThumbsDownClick: (chatId: string, like: boolean, disLike: boolean) => void;
  showImageSidebar: (index: number, isHidden: boolean) => void;
  type?: string;
}

interface ChatResponse {
  conversationId: string;
  companyId: number;
  response: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
  ai_tool: string;
  insured_id: string;
  type?: string;
  policy_id: string;
}

export default function MomentumGPTAnswer({
  chatResponse,
  index,
  chatBotEmbeded,
  // sendQuestion,
  onFeedbackSubmit,
  onFeedbackChange,
  toggleFeedbackVisibility,
  onCopyResponseClick,
  onThumbsUpClick,
  onThumbsDownClick,
  showImageSidebar,
  type,
}: AnswerProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showComissionPopUp, setShowComissionPopUp] = useState(false);
  const [showDriverLicensePopUp, setShowDriverLicensePopUp] = useState(false);
  const [csvFileData, setCsvFileData] = useState([]);
  const [importCommissionLoading, setImportCommissionLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [conversationLoading, setConversationLoading] = useState(false);
  const [driverDetails, setDriverDetails] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isUpdateInNowCerts, setIsUpdateInNowCerts] = useState(false);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [insuredSearch, setInsuredSearch] = useState("");
  const [clientName, setClientName] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(false);
  const [insuredClientNameError, setInsuredClientNameError] = useState("");
  const [showAiCallAdvanceOptionPopup, setShowAiCallAdvanceOptionPopup] =
    useState(false);

  const [showInvoiceAdvancedOptions, setShowInvoiceAdvancedOptions] =
    useState(false);
  const [joinCallLoading, setJoinCallLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceDueDate, setInvoiceDueDate] = useState("");
  const [billedToName, setBilledToName] = useState("");
  const [billedToAddress, setBilledToAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [soldToName, setSoldToName] = useState("");
  const [soldToAddress, setSoldToAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [producer, setProducer] = useState("");
  const [bookLocation, setBookLocation] = useState("");
  const [totalAmountDue, setTotalAmountDue] = useState("");
  const [totalGrossAmount, setTotalGrossAmount] = useState("");
  const [totalBrokerCommission, setTotalBrokerCommission] = useState("");
  const [paymentAddress, setPaymentAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [insuredName, setInsuredName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [paymentURL, setPaymentURL] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [invoiceLineItems, setInvoiceLineItems] = useState([]);
  const [transactionId, setTransactionId] = useState("");

  const toast: any = useRef("");
  const features = [
    {
      icon: "fa-clock",
      title: "24/7 Availability",
      description:
        "Enable 24/7 personalized and consistent service with zero waiting times for your clients.",
    },
    {
      icon: "fa-random",
      title: "Transfer Calls",
      description:
        "Direct to human agents as needed. Instantly connects customers to your team or third parties.",
    },
    {
      icon: "fa-file-alt",
      title: "Quote Intaking",
      description:
        "Make quote intaking an automatic process, from a call to quote details in your AMS or rater.",
    },
    {
      icon: "fa-question-circle",
      title: "Servicing Inquiries",
      description:
        "Collect information relative with requests from customers and third parties.",
    },
    {
      icon: "fa-calendar-check",
      title: "Appointment Scheduling",
      description:
        "Automatically schedules appointments, fully integrated with your calendar.",
    },
    {
      icon: "fa-sticky-note",
      title: "Post Call Notes",
      description:
        "Summarizes calls and extracts relevant data and automatically updates your systems.",
    },
    {
      icon: "fa-language",
      title: "Multilingual",
      description:
        "Speaks and understands multiple languages, even in the same call.",
    },
    {
      icon: "fa-magic",
      title: "Tailored to your Agency",
      description:
        "Consistent client experience across calls, tailored to your agency guidelines.",
    },
  ];
  useEffect(() => {
    if (insuredSearch.replaceAll(" ", "") == "") {
      return;
    }
    const searchTimeout = setTimeout(() => {
      getNowCertsClients(insuredSearch);
    }, 10);
    return () => clearTimeout(searchTimeout);
  }, [insuredSearch]);

  const parseAnswerToHtml = (answer: string) => {
    let parsedAnswer = answer.trim();

    return parsedAnswer;
  };
  const parsedAnswer = useMemo(
    () => parseAnswerToHtml(chatResponse.response),
    [chatResponse]
  );
  let sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer);
  let followUpQuestions: string[] = [];
  if (sanitizedAnswerHtml.includes("$$FollowupQuestions$$")) {
    const answerParts = sanitizedAnswerHtml.split("$$FollowupQuestions$$");
    sanitizedAnswerHtml = answerParts[0];
    let followUpPart = answerParts[1].split("$$FollowupQuestionsEND$$")[0];
    followUpQuestions = followUpPart
      ? followUpPart
          .trim()
          .split("\n")
          .filter((q) => q)
      : [];
  } else if (chatResponse.response.includes("$$PlotlyDataStart$$")) {
    let splitedAnswer = chatResponse.response.split("$$PlotlyDataStart$$");
    sanitizedAnswerHtml = splitedAnswer[0];
  } else if (chatResponse.response.includes("$$INSUREDCLIENTIDSTART$$")) {
    let splitedAnswer = chatResponse.response.split("$$INSUREDCLIENTIDSTART$$");
    sanitizedAnswerHtml = splitedAnswer[0];
  } else {
    const answerParts = sanitizedAnswerHtml.split("FollowupQuestions");
    sanitizedAnswerHtml = answerParts[0];
    followUpQuestions = answerParts[1]
      ? answerParts[1]
          .trim()
          .split("\n")
          .filter((q: any) => q)
      : [];
  }
  const onSourceClick = (source: string) => {
    window.open(source, "_blank");
  };
  const onFollowupQuestionClick = (followupQuestion: any) => {
    // sendQuestion(followupQuestion.substring(3));
  };
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (
        fileExtension?.[1].toLowerCase().includes("pdf") ||
        fileExtension?.[1].toLowerCase().includes("png") ||
        fileExtension?.[1].toLowerCase().includes("jpeg") ||
        fileExtension?.[1].toLowerCase().includes("jpg")
      ) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const onAdvanceOptionClicked = (response: any) => {
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncmomentumgpt/nc-momentum-gpt-chat-history/${response.chatId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let chat = response.data.ncMomentumGPTHistoryConversation;
        let toolType = "";
        let invoiceData: any;
        if (chat.aI_Tool !== "") {
          toolType = chat.aI_Tool;
          invoiceData = JSON.parse(
            chat.serializedJSONResponse.replace(/'/g, '"')
          );
        }

        switch (toolType) {
          case "DRIVING-LICENSE-SCAN":
            setPdfUrl(response.data.pdfUrl);
            setShowDriverLicensePopUp(true);
            setDriverDetails(
              JSON.parse(chat.serializedJSONResponse.replace(/'/g, '"'))
            );
            break;
          case "COMMISSION-SCAN":
            setPdfUrl(response.data.pdfUrl);
            setCsvFileData(
              JSON.parse(chat.serializedJSONResponse.replace(/'/g, '"'))
            );
            setShowComissionPopUp(true);
            break;
          case "QUOTE-PROPOSAL-SCAN":
            break;
          case "INVOICE-SCAN":
            setPdfUrl(response.data.pdfUrl);
            setInvoiceNumber(invoiceData.invoiceNumber);
            setInvoiceDate(invoiceData.invoiceDate);
            setInvoiceDueDate(invoiceData.invoiceDueDate);
            setBilledToName(invoiceData.billedToName);
            setBilledToAddress(invoiceData.billedToAddress);
            setSoldToName(invoiceData.soldToName);
            setSoldToAddress(invoiceData.soldToAddress);
            setProducer(invoiceData.producer);
            setBookLocation(invoiceData.bookLocation);
            setTotalAmountDue(invoiceData.totalAmountDue);
            setTotalGrossAmount(invoiceData.totalGrossAmount);
            setTotalBrokerCommission(invoiceData.totalBrokerCommission);
            setPaymentAddress(invoiceData.paymentAddress);
            setInsuredName(invoiceData.insuredName);
            setPolicyNumber(invoiceData.policyNumber);
            setPaymentURL(response.data.paymentURL);
            setInsuranceCompany(invoiceData.insuranceCompany);
            setInvoiceLineItems(invoiceData.invoiceLineItems);
            setShowInvoiceAdvancedOptions(true);
            break;
          default:
            break;
        }
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };
  const onAiAdvanceOptionClicked = () => {
    setShowAiCallAdvanceOptionPopup(true);
  };
  const onHidePopupClicked = () => {
    setShowComissionPopUp(false);
    setShowDriverLicensePopUp(false);
    setShowAiCallAdvanceOptionPopup(false);
    setShowInvoiceAdvancedOptions(false);
    setPdfUrl("");
  };
  const onJoinCallClick = () => {
    setJoinCallLoading(true);
    var token = CommonValues.GetToken();
    var data = {
      AgentId: localStorage.getItem("NowcertsAgentId"),
      AgentEmail: localStorage.getItem("userName"),
      ServiceId: 13,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/request-subscription`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setJoinCallLoading(false);
        setShowAiCallAdvanceOptionPopup(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Subscription request submitted successfully",
          life: 3000,
        });
      })
      .catch((error: any) => {
        setJoinCallLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while subscribing ai call",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while subscribing ai call",
            life: 3000,
          });
        }
      });
  };

  const onDownloadClick = (documentType: string) => {
    let url = window.location.href;
    let conversationId = url.split("/")[4];
    const token = CommonValues.GetToken();

    axios({
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/document/${documentType}/${conversationId}`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        if (documentType === "csv") {
          saveAs(response.data, "csvFile.csv");
        } else if (documentType === "xml") {
          saveAs(response.data, "xmlFile.xml");
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting  history details",
              life: 3000,
            });
          }
        }
      });
  };
  const onUploadCommissionToAMSClicked = () => {
    setImportCommissionLoading(true);
    let url = window.location.href;
    let conversationId = url.split("/")[4];
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/upload/${conversationId}?token=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };

    axios(config)
      .then((response: any) => {
        window.open(response.data.redirectURL, "_blank");
        setImportCommissionLoading(false);
      })
      .catch((error: any) => {
        setImportCommissionLoading(false);
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while uploading the file.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
  };
  const onSubmitAddDriver = () => {
    if (
      chatResponse.insured_id == undefined ||
      chatResponse.insured_id == null ||
      chatResponse.insured_id == ""
    ) {
      setInsuredClientNameError("Please select client");
      return;
    }
    setSubmitLoading(true);
    let date = driverDetails.IssueDate.split("/");
    let requestDataList = {
      insured_database_id: chatResponse.insured_id,
      first_name: driverDetails.FirstName,
      last_name: driverDetails.LastName,
      insured_commercial_name: "",
      license_number: driverDetails.DrivingLicenseNumber,
      driver_license_class: driverDetails.DrivingLicenseClass,
      date_of_birth: driverDetails.DateOfBirth,
      gender: driverDetails.Sex,
      license_state: driverDetails.AddressOfLicenseState,
      termination_date: driverDetails.ExpirationDate,
      cdl_date: date[0],
      cdl_year: date[2],
      license_year: date[2],
      active: true,
    };
    const data = JSON.stringify(requestDataList);

    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addsingledriver?&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Driver data updated in NowCerts",
          life: 3000,
        });
        setSubmitLoading(false);
        setIsUpdateInNowCerts(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Bad Request",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail:
                "Unknown error while adding single. driver data in nowcerts",
              life: 3000,
            });
          }
        }
        setSubmitLoading(false);
      });
  };
  const onClientSearchChangeForInsured = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setInsuredSearch(value);
  };
  const getNowCertsClients = (value: any) => {
    setSearchClientLoading(true);
    var data = {
      SearchValue: value,
    };
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${value}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        setSearchClientLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail:
                  "Unknown error while loading the insureds data.Please try again later",
                life: 3000,
              });
            }
          }
        }
      });
  };
  const onClientSelectForInsured = (client: any) => {
    setInsuredSearch("");
    setInsuredClientNameError("");
    setClientName(client.name);
    chatResponse.insured_id = client.databaseId;
  };
  const onChangeInvoiceNumber = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z1-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInvoiceNumber(Value);
  };

  const onChangeInvoiceDate = (value: any) => {
    setInvoiceDate(value);
  };

  const onChangeInvoiceDueDate = (value: any) => {
    setInvoiceDueDate(value);
  };

  const onChangeTotalDueAmount = (value: any) => {
    setTotalAmountDue(value);
  };

  const onChangeTotalGrossAmount = (value: any) => {
    setTotalGrossAmount(value);
  };
  const onChangeInsuredName = (value: any) => {
    setInsuredName(value);
  };
  const onChangePolicyNumber = (value: any) => {
    setPolicyNumber(value);
  };
  const performTextToAction = (textToAction: string) => {
    if (textToAction === "INSURED_DETAILS") {
      window.open(
        `${window.location.ancestorOrigins[0]}/AMSINS/Insureds/Insert?MomentumUrl=/Insureds/List`,
        "_blank"
      );
    } else if (textToAction === "PROSPECT_DETAILS") {
      window.open(
        `${window.location.ancestorOrigins[0]}/AMSINS/Prospects/Insert?MomentumUrl=/Prospects/List`,
        "_blank"
      );
    } else if (textToAction === "POLICY_DETAILS") {
      window.open(
        `${window.location.ancestorOrigins[0]}/Policies/Insert.aspx?MomentumUrl=/Policies/List`,
        "_blank"
      );
    }
  };
  return (
    <div className="mx-2">
      <Toast ref={toast} />
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        className="answer-block"
      >
        {sanitizedAnswerHtml}
      </ReactMarkdown>
      {chatResponse.plotlyData.data !== "" &&
        (chatBotEmbeded ? (
          <Plot
            data={chatResponse.plotlyData.data}
            layout={chatResponse.plotlyData.layout}
            style={{ width: "100%", height: "450px" }}
          />
        ) : (
          <Plot
            data={chatResponse.plotlyData.data}
            layout={chatResponse.plotlyData.layout}
          />
        ))}
      {chatResponse.imageSidebar && (
        <Sidebar
          visible={chatResponse.imageSidebar}
          position="right"
          onHide={() => showImageSidebar(index, false)}
          style={{ width: "30%" }}
        >
          {chatResponse.imageURLs.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  overflowY: "auto",
                  paddingBottom: "20px",
                }}
              >
                {chatResponse.imageURLs.map((image, i) => (
                  <>
                  {image.toLowerCase().includes("pdf") ? (
                    <iframe src={image} height="100%" width="100%" />
                  ) : (
                    <img
                      alt="NowCerts Support Center"
                      src={image}
                      height="auto"
                      width="100%"
                    />
                  )}
                </>
              ))}
            </div>
            <div style={{ textAlign: "center", padding: "10px", borderTop: "1px solid #ddd" }}>
              <AtkButton label="Close" onClick={() => showImageSidebar(index, false)} />
            </div>
          </div>
        )}
        </Sidebar>
      )}
      {chatResponse.textToAction !== "" &&
        (chatResponse.textToAction === "INSURED_DETAILS" ||
          chatResponse.textToAction === "PROSPECT_DETAILS" ||
          chatResponse.textToAction === "POLICY_DETAILS") && (
          <div
            className="sources-box me-3 "
            onClick={() => performTextToAction(chatResponse.textToAction)}
          >
            <span className="source-wrap d-flex">
              <span className="namebox">
                Go to add{" "}
                {`${
                  chatResponse.textToAction === "INSURED_DETAILS"
                    ? "insured"
                    : chatResponse.textToAction === "PROSPECT_DETAILS"
                    ? "prospect"
                    : chatResponse.textToAction === "POLICY_DETAILS"
                    ? "policy"
                    : ""
                }`}
              </span>
            </span>
          </div>
        )}
      {chatResponse.imageURLs.length > 0 && !chatResponse.responseStreaming && (
        <>
          <p className="text-14">
            <b>Attachment:</b>
          </p>
          {chatResponse.imageURLs.length > 0 && (
            <>
              <div
                className="sources-box me-3 "
                onClick={() => showImageSidebar(index, true)}
              >
                <span className="source-wrap d-flex">
                  <span className="namebox">Documents</span>
                </span>
              </div>

              {(chatResponse.ai_tool === "INVOICE-SCAN" ||
                chatResponse.ai_tool === "COMMISSION-SCAN" ||
                chatResponse.ai_tool === "DRIVING-LICENSE-SCAN" ||
                chatResponse.ai_tool === "ACORD-DECPAGES-SCAN-1" ) && (
                <div
                  className="sources-box me-3 "
                  onClick={() => onAdvanceOptionClicked(chatResponse)}
                >
                  <span className="source-wrap d-flex">
                    <span className="namebox">Advance Options</span>
                  </span>
                </div>
              )}
            </>
          )}
          {chatResponse.videoURLs.length > 0 &&
            chatResponse.videoURLs.map((source, i) => (
              <div
                key={i}
                className="sources-box me-3 "
                onClick={() => onSourceClick(source)}
              >
                <span className="source-wrap d-flex">
                  <span className="namebox">Video {i + 1}</span>
                </span>
              </div>
            ))}
          {chatResponse.sources.map((source, i) => (
            <div
              key={i}
              className="sources-box me-3 "
              onClick={() => onSourceClick(source)}
            >
              <span className="source-wrap d-flex">
                <span className="namebox">Article {i + 1}</span>
              </span>
            </div>
          ))}
        </>
      )}
      <div className="icon-box mb-2">
        {/* <i
          className={chatResponse.like ? "fa fa-thumbs-up active" : "fa fa-thumbs-up"}
          aria-hidden="true"
          title="Like"
          onClick={() => onThumbsUpClick(chatResponse.chatId, !chatResponse.like, false)}
        ></i> */}
        {/* <i
          className={chatResponse.disLike ? "dislike fa fa-thumbs-down active" : "fa fa-thumbs-down"}
          aria-hidden="true"
          title="DisLike"
          onClick={() => onThumbsDownClick(chatResponse.chatId, false, !chatResponse.disLike)}
        ></i> */}
        <span
          className={`show-cursor answer-feedback-icon-box ${
            chatResponse.like ? "active" : ""
          }`}
          title="Like"
          onClick={() =>
            onThumbsUpClick(chatResponse.chatId, !chatResponse.like, false)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={chatResponse.like ? "#5d5d5d" : "none"}
            stroke={chatResponse.like ? "#5d5d5d" : "currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M7 10v12" />
            <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
          </svg>
        </span>
        <span
          className="show-cursor answer-feedback-icon-box"
          title="Dislike"
          onClick={() =>
            onThumbsDownClick(chatResponse.chatId, false, !chatResponse.disLike)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={chatResponse.disLike ? "#5d5d5d" : "none"}
            stroke={chatResponse.disLike ? "#5d5d5d" : "currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M17 14V2" />
            <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
          </svg>
        </span>
        <span
          className="show-cursor answer-feedback-icon-box"
          title="Copy"
          onClick={() => onCopyResponseClick(sanitizedAnswerHtml)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
            <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
          </svg>
        </span>
        <span
          className="show-cursor answer-feedback-icon-box"
          title="Feedback"
          onClick={() => toggleFeedbackVisibility(chatResponse.chatId)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
          </svg>
        </span>
        {type == "AICall" ? (
          <div
            className="sources-box me-3 ms-1"
            onClick={onAiAdvanceOptionClicked}
          >
            <span className="source-wrap d-flex">
              <span className="namebox">Advance Options</span>
            </span>
          </div>
        ) : null}
        {chatResponse.showFeedback && (
          <div className="feedback-box mb-3 text-14">
            <div className="row d-flex align-items-center">
              <div className="col-10">
                <h6 className="text-14">Your feedback will improve this product.</h6>
              </div>
              <div className="col-2 d-flex align-items-center justify-content-end">
                <i
                  className="fa fa-close mt-0 mb-2"
                  aria-hidden="true"
                  title="Close"
                  onClick={() => toggleFeedbackVisibility(chatResponse.chatId)}
                ></i>
              </div>
              <div className="">
                <textarea
                  name="feedback"
                  onChange={(e) =>
                    onFeedbackChange(chatResponse.chatId, e.target.value)
                  }
                  value={chatResponse.feedback}
                  className="form-control mb-3 text-14 "
                  rows={4}
                  placeholder="Your feedback..."
                />
              </div>

              {chatResponse.feedbackLoading ? (
                <div className="col-12 d-flex justify-content-end">
                  <SupportLoader />
                </div>
              ) : (
                <div className="col-12 text-end">
                  <AtkButton
                    label="Submit"
                    onClick={() =>
                      onFeedbackSubmit(
                        chatResponse.chatId,
                        chatResponse.feedback
                      )
                    }
                    className="ai-feedback-btn w-auto"
                  />
                </div>
              )}
            </div>{" "}
          </div>
        )}
      </div>
      {followUpQuestions.length > 0 && (
        <>
          <h6>Follow-up Questions:</h6>

          {followUpQuestions.map((question, i) => (
            <span
              key={i}
              className="followup-que"
              onClick={() => onFollowupQuestionClick(question)}
            >
              {question}
            </span>
          ))}
        </>
      )}{" "}
      {chatResponse.responseStreaming && (
        <div className="d-flex justify-content-center">
          <SupportLoader />
        </div>
      )}
      <Dialog
        header=""
        visible={showComissionPopUp}
        style={{ width: "80vw", height: "90vh" }}
        onHide={onHidePopupClicked}
        draggable={false}
      >
        <div className="row">
          <div
            className="col-12"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <img
              src={DownloadCSVIcon}
              title="Download CSV"
              className="me-2 show-cursor"
              onClick={() => {
                onDownloadClick("csv");
              }}
            />
            <img
              src={DownloadXMLIcon}
              title="Download XML"
              className="me-2 show-cursor"
              onClick={() => {
                onDownloadClick("xml");
              }}
            />
            {importCommissionLoading ? (
              <AtkLoader />
            ) : (
              <AtkButton
                label="Import Commissions"
                onClick={onUploadCommissionToAMSClicked}
                className=" pf-primary-btn w-auto"
              />
            )}
          </div>

          <div className="col-6 border-end">
            <b>Uploaded PDF</b>
            <iframe
              src={pdfUrl}
              style={{ overflow: "scroll", height: "75vh", width: "100%" }}
              className="mt-2"
            ></iframe>
          </div>
          <div className="col-6 history-table">
            <b>Extracted data</b>
            {csvFileData.length > 0 ? (
              <div className="mt-2">
                <DataTable
                  value={csvFileData}
                  paginator
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                  rows={10}
                >
                  <Column header="Insured" field="Insured"></Column>
                  <Column header="Policy Number" field="Policy_Number"></Column>
                  <Column
                    header="Effective Date"
                    field="Policy_Effective_Date"
                  ></Column>
                  <Column
                    header="Transaction Date"
                    field="Transaction_Date"
                  ></Column>
                  <Column
                    header="Transaction Type"
                    field="Transaction_Type"
                  ></Column>
                  <Column header="Premium" field="Premium"></Column>
                  <Column
                    header="Commission Rate"
                    field="Commission_Rate"
                  ></Column>
                  <Column
                    header="Commission Amount"
                    field="Commission_Amount"
                  ></Column>
                </DataTable>
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
      <Dialog
        header=""
        visible={showDriverLicensePopUp}
        style={{ width: "80vw", height: "90vh" }}
        onHide={onHidePopupClicked}
        draggable={false}
      >
        {/* {previewFileLoading ? ( */}
        {false ? (
          <div className="d-flex align-items-center justify-content-center overflow-hidden">
            <AtkLoader />
          </div>
        ) : (
          <div className="row">
            <div className="col-6 border-end">
              <b>Uploaded Image</b>
              <iframe
                src={pdfUrl}
                style={{ overflow: "scroll", height: "75vh", width: "100%" }}
                className="mt-2"
              ></iframe>
            </div>
            <div className="col-6">
              <DriverLicenseScanDataExtractPage
                firstName={driverDetails.FirstName}
                onChangeFirstName={() => {}}
                firstNameErrorMessage=""
                lastName={driverDetails.LastName}
                onChangeLastName={() => {}}
                dateOfBirth={driverDetails.DateOfBirth}
                onChangeDateOfBirth={() => {}}
                drivingLicenseNumber={driverDetails.DrivingLicenseNumber}
                onChangeDrivingLicenseNumber={() => {}}
                issueDate={driverDetails.IssueDate}
                onChangeIssueDate={() => {}}
                expirationDate={driverDetails.ExpirationDate}
                onChangeExpirationDate={() => {}}
                state={driverDetails.AddressOfLicenseState}
                onChangeState={() => {}}
                addressLine1={driverDetails.AddressOfLicenseLine1}
                onChangeAddressLine1={() => {}}
                city={driverDetails.AddressOfLicenseCity}
                onChangeCity={() => {}}
                zipCode={driverDetails.AddressOfLicenseZipCode}
                onChangeZipCode={() => {}}
                drivingLicenseClass={driverDetails.DrivingLicenseClass}
                onChangeDrivingLicenseClass={() => {}}
                endorsements={driverDetails.Endorsements}
                onChangeEndorsements={() => {}}
                restrictions={driverDetails.Restrictions}
                onChangeRestrictions={() => {}}
                sex={driverDetails.Sex}
                onChangeSex={() => {}}
                height={driverDetails["Height-Feet"]}
                onChangeHeight={() => {}}
                weight={driverDetails.Weight}
                onChangeWeight={() => {}}
                eyeColor={driverDetails.EyeColor}
                onChangeEyeColor={() => {}}
                hairColor={driverDetails.HairColor}
                onChangeHairColor={() => {}}
                // formHistoryPage={true}
                isUpdateInNowCerts={isUpdateInNowCerts}
                onSubmit={onSubmitAddDriver}
                // isUploadAgain={isUploadAgain}
                // onUploadAgain={onHidePopupClicked}
                searchClientLoading={searchClientLoading}
                submitLoading={submitLoading}
                onClientSearchChangeForInsured={onClientSearchChangeForInsured}
                insuredSearch={insuredSearch}
                searchClientList={searchClientList}
                clientName={clientName}
                onClientSelectForInsured={onClientSelectForInsured}
                insuredId={chatResponse.insured_id}
                showSearchClient={true}
                insuredClientNameError={insuredClientNameError}
              />
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        header=""
        visible={showAiCallAdvanceOptionPopup}
        style={{ width: "60vw" }}
        onHide={onHidePopupClicked}
        draggable={false}
      >
        <div className="row">
          {features.map((feature, index) => (
            <div className="col-4 mb-3" key={index}>
              <div className="d-flex align-items-start">
                <i className={`fa ${feature.icon} fs-4 me-3`}></i>
                <div>
                  <h6 className="fw-bold">{feature.title}</h6>
                  <p className=" mb-0 text-14">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-end mt-2">
            {joinCallLoading ? (
              <div className="d-flex justify-content-end">
                <SupportLoader />
              </div>
            ) : (
              <AtkButton
                label="Join Waiting List"
                onClick={onJoinCallClick}
                className="pf-primary-btn w-auto"
              />
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        header=""
        visible={showInvoiceAdvancedOptions}
        style={{ width: "80vw", height: "90vh" }}
        onHide={onHidePopupClicked}
        draggable={false}
      >
        <div className="row">
          <div className="col-6 border-end">
            <b>Uploaded Image</b>
            <iframe
              src={pdfUrl}
              style={{
                overflow: "scroll",
                height: "100%",
                width: "100%",
              }}
            ></iframe>
          </div>
          <div className="col-6 history-table">
            <InvoiceScanDataExtractPage
              selectedPolicyId={chatResponse.policy_id}
              insuredClientId={chatResponse.insured_id}
              clientName={clientName}
              invoiceNumber={invoiceNumber}
              onChangeInvoiceNumber={onChangeInvoiceNumber}
              invoiceDate={invoiceDate}
              onChangeInvoiceDate={onChangeInvoiceDate}
              invoiceDueDate={invoiceDueDate}
              onChangeInvoiceDueDate={onChangeInvoiceDueDate}
              billedToName={billedToName}
              billedToAddress={billedToAddress}
              soldToName={soldToName}
              soldToAddress={soldToAddress}
              producer={producer}
              bookLocation={bookLocation}
              totalAmountDue={totalAmountDue}
              onChangeTotalDueAmount={onChangeTotalDueAmount}
              totalGrossAmount={totalGrossAmount}
              onChangeTotalGrossAmount={onChangeTotalGrossAmount}
              totalBrokerCommission={totalBrokerCommission}
              paymentAddress={paymentAddress}
              insuredName={insuredName}
              onChangeInsuredName={onChangeInsuredName}
              policyNumber={policyNumber}
              onChangePolicyNumber={onChangePolicyNumber}
              paymentURL={paymentURL}
              insuranceCompany={insuranceCompany}
              invoiceLineItems={invoiceLineItems}
              transactionId={transactionId}
              fromHistoryPage={true}
              isUpdateInNowCerts={isUpdateInNowCerts}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

// export default MomentumGPTAnswer;