    import RateIcon from "../assets/images/rate.png";
    
    export default function AppetiteMiddleHeader() {
      return (
        <div className="row middle-header-row ps-3">
          <div className="col-12">
            <div className="middle-header-col active ms-2" style={{padding:"17px 8px"}}>
              <img src={RateIcon} className="" width={70} alt=""/>
              <span className="middle-header-title">Rate Risk Appetite</span>
            </div>
          </div>{" "}
        </div>
      )
    }
    