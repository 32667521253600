import React from "react";
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/newquote.png";
export default function DriverLicenseScanSidebar(props: any) {
  const navigate = useNavigate();

  const onDriverLicenseScanClick = () => {
    navigate("/driverlicensescan");
  };
  const onDriverLicenseScanHistoryClick = () => {
    navigate("/driverlicensescanhistory");
  };

  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "driver-license-scan" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onDriverLicenseScanClick}
        >
          <img src={NewIcon} className={props.pagename == "driver-license-scan" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">New Driver License Scan</span>{" "}
        </span>

        <span
          className={props.pagename == "driver-license-scan-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onDriverLicenseScanHistoryClick}
        >
          <img src={HistoryIcon} className={props.pagename == "driver-license-scan-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
