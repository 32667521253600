
export default function FinchMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src="https://mintlify.s3.us-west-1.amazonaws.com/finch/logo/logo-black.svg" className="landing-page-img" alt=""/>
        </div>
      </div>{" "}
    </div>
  );
}
