import React, { useState } from "react";
import Header from "../common/Header";
import AtkLoader from "../AtlaskitControls/AtkLoader";

import { useLocation, useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import axios from "axios";
import BackgroundVideo from "../common/BackgroundVideo";

const LinkUser = () => {
  const [linksuccess, setLinksuccess] = useState(false);
  const [linkprocess, setLinkprocess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const access_token = location.state && location.state.access_token;

  const onLinkClick = () => {
    setLinkprocess(true);
    setLinksuccess(true);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTHAPI}/linkuser?`,
      headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${access_token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLinkprocess(false);
        localStorage.setItem("igtoken", response.data.token);
        localStorage.setItem("tenantId", response.data.tenantId);   
        localStorage.setItem("userName", response.data.userName);    
        navigate("/startuppage");
      })
      .catch((error: any) => {
        setLinkprocess(false);
        let errorMessage;
        if (error.response !== null) {
          errorMessage = "Unknown error while link process.";
        } else {
          errorMessage = "Unknown error while link process.";
        }
        setErrorMessage(errorMessage);
      });
  };
  return (
    <div className="linkuser-page">
      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
       <BackgroundVideo/>
        <div className="login-box ">
          <div className="row mx-auto d-flex align-items-center">
            <div className="col-12 text-center">
              <h2> Account Linking</h2>
              {linksuccess !== true ? (
                <>
                  <p className="welcome-text">
                    It looks like you have another account with the same email address. We recommend you link these accounts.
                  </p>
                  <AtkButton label="Continue To Link Account" onClick={onLinkClick} className="pf-primary-btn w-50 mt-2" />
                </>
              ) : null}
              {linkprocess === true ? (
                <>
                  <AtkLoader />
                </>
              ) : null}

              {errorMessage !== "" ? (
                <div className="col-12 mt-2">
                  <AtkMessage appearance="error" messageText={errorMessage} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default LinkUser;
