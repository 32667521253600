import React from "react";
import SmartScanSidebar from "./InvoiceScanSidebar";
import SmartScanMiddleHeader from "./InvoiceScanMiddleHeader";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import CommonValues from "../../common/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import moment from "moment";
import AtkButton from "../../AtlaskitControls/AtkButton";
import feedbackImg from "../../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import TextField from "@atlaskit/textfield";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackgroundVideo from "../../common/BackgroundVideo";
import InvoiceScanDataExtractPage from "./InvoiceScanDataExtractPage";
import InvoiceScanMiddleHeader from "./InvoiceScanMiddleHeader";
import InvoiceScanSidebar from "./InvoiceScanSidebar";

export default function InvoiceScanHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [invoiceScanHistoryData, setInvoiceScanHistoryData] = useState<any>([]);
  const [submissionId, setSubmissionId] = useState();
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [extractedData, setExtractedData] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("Insured");
  const [globalFilter, setGlobalFilter] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [isUpdateInNowCerts, setIsUpdateInNowCerts] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceDueDate, setInvoiceDueDate] = useState("");
  const [billedToName, setBilledToName] = useState("");
  const [billedToAddress, setBilledToAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [soldToName, setSoldToName] = useState("");
  const [soldToAddress, setSoldToAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [producer, setProducer] = useState("");
  const [bookLocation, setBookLocation] = useState("");
  const [totalAmountDue, setTotalAmountDue] = useState("");
  const [totalGrossAmount, setTotalGrossAmount] = useState("");
  const [totalBrokerCommission, setTotalBrokerCommission] = useState("");
  const [paymentAddress, setPaymentAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [insuredName, setInsuredName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [paymentURL, setPaymentURL] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [invoiceLineItems, setInvoiceLineItems] = useState([]);
  const [showInvoiceScanDataExtractPage, setShowInvoiceScanDataExtractPage] = useState(false);
  const [insuredClientId, setInsuredClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [selectedPolicyId, setSelectedPolicyId] = useState("");

  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getInvoiceScanHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getInvoiceScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setInvoiceScanHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting invoice scan history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };

  const onPreviewClick = (rowData: any) => {
    setTransactionId(rowData.transactionId);
    const selectionOptionJson = JSON.parse(rowData.selectionOptionsJson);
    setIsUpdateInNowCerts(rowData.isUpdateInNowcerts);
    if (rowData.isUpdateInNowcerts == true) {
      const responseJson = JSON.parse(rowData.submittedRequestJson);
      setInvoiceNumber(responseJson.invoiceNumber);
      setInvoiceDate(responseJson.invoiceDate);
      setInvoiceDueDate(responseJson.invoiceDueDate);
      setTotalAmountDue(responseJson.totalAmountDue);
      setTotalGrossAmount(responseJson.totalGrossAmount);
      setInsuredName(responseJson.insuredName);
      setInvoiceLineItems(responseJson.invoiceLineItems);
      setShowInvoiceScanDataExtractPage(true);
      setTransactionId(rowData.transactionId);
      setPolicyNumber(
        responseJson.policyNumber == null || responseJson.policyNumber == "" ? selectionOptionJson.policyNumber : responseJson.policyNumber
      );
      setShowPopUp(true);
    } else {
      const responseJson = JSON.parse(rowData.responseJson);
      setInvoiceNumber(responseJson.invoiceNumber);
      setInvoiceDate(responseJson.invoiceDate);
      setInvoiceDueDate(responseJson.invoiceDueDate);
      setTotalAmountDue(responseJson.totalAmountDue);
      setTotalGrossAmount(responseJson.totalGrossAmount);
      setInsuredName(responseJson.insuredName);
      setPolicyNumber(
        responseJson.policyNumber == null || responseJson.policyNumber == "" ? selectionOptionJson.policyNumber : responseJson.policyNumber
      );
      setInvoiceLineItems(responseJson.invoiceLineItems);
      setShowInvoiceScanDataExtractPage(true);
      setTransactionId(rowData.transactionId);
      setShowPopUp(true);
      setInsuredClientId(selectionOptionJson.insuredClientId);
      setClientName(selectionOptionJson.clientName);
      setSelectedPolicyId(selectionOptionJson.selectedPolicyId);
    }

    const token = CommonValues.GetToken();
    var data = {
      transactionId: rowData.transactionId,
      fileName: rowData.fileName,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setFileUrl(response.data.fileUrl);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while preview details ", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
    setPreviewFileLoading(false);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setInvoiceScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setInvoiceScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIInvoiceScan/ratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const actionTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <>
        <img src={PreviewIcon} height={20} title="Preview" onClick={() => onPreviewClick(rowData)} className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
    getInvoiceScanHistory();
  };
  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };
  const updateInAMSStatus = (rowData: any) => {
    return rowData.isUpdateInNowcerts === true ? (
      <span className="">Yes</span>
    ) : rowData.isUpdateInNowcerts === false ? (
      <span className="">No</span>
    ) : null;
  };
  const insuredNameAction = (rowData: any) => {
    const selectionOptionsJson = JSON.parse(rowData.selectionOptionsJson);
    return rowData.isUpdateInNowcerts != null ? <span className="">{selectionOptionsJson.clientName}</span> : null;
  };

  const onUploadAgain = () => {
    setShowPopUp(false);
    getInvoiceScanHistory();
  };

  const onChangeInvoiceNumber = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z1-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInvoiceNumber(Value);
  };

  const onChangeInvoiceDate = (value: any) => {
    setInvoiceDate(value);
  };

  const onChangeInvoiceDueDate = (value: any) => {
    setInvoiceDueDate(value);
  };

  const onChangeTotalDueAmount = (value: any) => {
    setTotalAmountDue(value);
  };

  const onChangeTotalGrossAmount = (value: any) => {
    setTotalGrossAmount(value);
  };
  const onChangeInsuredName = (value: any) => {
    setInsuredName(value);
  };
  const onChangePolicyNumber = (value: any) => {
    setPolicyNumber(value);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="invoice-scan-history-main-page">
        <Header pagename="invoice-scan-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div ">
            <div className="col-12">
              <InvoiceScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <InvoiceScanSidebar pagename="invoice-scan-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    <div className="row mb-3">
                      <div className="col-6">
                        <span className="p-input-icon-left w-50">
                          <i className="fa fa-search grid-search-icon" />
                          <AtkTextField
                            type="text"
                            id="txtfirstName"
                            name="globalFilter"
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            value={globalFilter}
                            className="input-field"
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                      <div className="col-6 text-end">
                        <AtkButton label="Refresh" onClick={getInvoiceScanHistory} className=" pf-primary-btn w-auto" />
                      </div>
                    </div>
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable
                        value={invoiceScanHistoryData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        globalFilter={globalFilter}
                        emptyMessage="No data available."
                      >
                        <Column
                          header="Submitted Date"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="justify-">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          field="created"
                          sortable
                          style={{ width: "12%" }}
                        ></Column>
                        <Column header="File Name" field="fileName" sortable></Column>
                        <Column header="Client Name" body={insuredNameAction} sortable></Column>
                        <Column header="Update In AMS" body={updateInAMSStatus} sortable></Column>
                        <Column header="Status" body={statusTemplate} style={{ width: "10%" }} sortable></Column>
                        <Column header="Action" body={actionTemplate} style={{ width: "12%" }}></Column>
                        <Column header="Feedback" body={feedbackTemplate} className="text-center" style={{ width: "5%" }}></Column>
                      </DataTable>
                    )}

                    <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                      {previewFileLoading ? (
                        <div className="d-flex align-items-center justify-content-center overflow-hidden">
                          <AtkLoader />
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-6 border-end">
                            <b>Uploaded Image</b>
                            <iframe
                              src={fileUrl}
                              style={{
                                overflow: "scroll",
                                height: "100%",
                                width: "100%",
                            }}
                            ></iframe>
                          </div>
                          <div className="col-6 history-table">
                            <div className="row d-flex justify-content-end">
                              <div className="col-4"></div>
                              <div className="col-4"></div>
                              <div className="col-4">
                                {isUpdateInNowCerts == true ? (
                                  <>
                                    <span className="text-16 fw-bold">Update In AMS</span>
                                    <AtkTextField type="text" id="text" className="" name="clientName" value="Yes" isDisabled={true} />
                                  </>
                                ) : (
                                  <>
                                    <span className="text-16 fw-bold">Client Name</span>
                                    <AtkTextField type="text" id="text" className="" name="clientName" value={clientName} isDisabled={true} />
                                  </>
                                )}
                              </div>
                            </div>
                            <InvoiceScanDataExtractPage
                              selectedPolicyId={selectedPolicyId}
                              insuredClientId={insuredClientId}
                              clientName={clientName}
                              invoiceNumber={invoiceNumber}
                              onChangeInvoiceNumber={onChangeInvoiceNumber}
                              invoiceDate={invoiceDate}
                              onChangeInvoiceDate={onChangeInvoiceDate}
                              invoiceDueDate={invoiceDueDate}
                              onChangeInvoiceDueDate={onChangeInvoiceDueDate}
                              billedToName={billedToName}
                              billedToAddress={billedToAddress}
                              soldToName={soldToName}
                              soldToAddress={soldToAddress}
                              producer={producer}
                              bookLocation={bookLocation}
                              totalAmountDue={totalAmountDue}
                              onChangeTotalDueAmount={onChangeTotalDueAmount}
                              totalGrossAmount={totalGrossAmount}
                              onChangeTotalGrossAmount={onChangeTotalGrossAmount}
                              totalBrokerCommission={totalBrokerCommission}
                              paymentAddress={paymentAddress}
                              insuredName={insuredName}
                              onChangeInsuredName={onChangeInsuredName}
                              policyNumber={policyNumber}
                              onChangePolicyNumber={onChangePolicyNumber}
                              paymentURL={paymentURL}
                              insuranceCompany={insuranceCompany}
                              invoiceLineItems={invoiceLineItems}
                              transactionId={transactionId}
                              fromHistoryPage={true}
                              isUpdateInNowCerts={isUpdateInNowCerts}
                              onUploadAgain={onUploadAgain}
                            />
                          </div>
                        </div>
                      )}
                    </Dialog>

                    <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                      <div className="row mx-auto d-flex align-items-center mb-2">
                        <div className="col-12 feedback-header">
                          <h4>Feedback</h4>
                        </div>
                        <div className={"form-group col-12 mb-2 text-12"}>
                          <textarea
                            name="feedback"
                            onChange={(e) => onFeedbackChange(e.target.value)}
                            value={feedBackData.feedback}
                            className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                            rows={4}
                            placeholder="Enter your feedback here"
                          />
                          <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                        </div>
                        <div className="col-4 d-flex justify-content-evenly">
                          <i
                            className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                                                                           ${feedBackData.isLiked == true ? " active" : " "}`}
                            aria-hidden="true"
                            onClick={(e) => onThumbsUpClick(feedBackData)}
                          ></i>
                          <i
                            className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                                                            ${feedBackData.isLiked == false ? " active" : " "}`}
                            aria-hidden="true"
                            onClick={(e) => onThumbDownClick(feedBackData)}
                          ></i>
                        </div>
                        <div className="col-4 text-end p-2 pe-0  ">
                          <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                        </div>
                        <div className="col-4 text-end p-2  ">
                          {transactionId == feedBackData.transactionId && feedbackLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <AtkLoader />
                            </div>
                          ) : (
                            <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                          )}
                        </div>
                      </div>
                    </OverlayPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
