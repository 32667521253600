import { InputSwitch } from "primereact/inputswitch";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import SmartScanMiddleHeader from "./InvoiceScanMiddleHeader";
import SmartScanSidebar from "./InvoiceScanSidebar";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import AtkButton from "../../AtlaskitControls/AtkButton";
import InvoiceScanLandingPage from "./InvoiceScanLandingPage";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import AtkCheckbox from "../../AtlaskitControls/AtkCheckbox";
import { RadioButton } from "primereact/radiobutton";
import Toggle from "@atlaskit/toggle";
import BackgroundVideo from "../../common/BackgroundVideo";
import { Card } from "primereact/card";
import { DataView } from "primereact/dataview";
import InvoiceScanMiddleHeader from "./InvoiceScanMiddleHeader";
import InvoiceScanSidebar from "./InvoiceScanSidebar";
import { set } from "lodash";

export default function InvoiceScanMainpage() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast: any = useRef("");
  const [createInsured, setCreateInsured] = useState(false);
  const [existingInsured, setExistingInsured] = useState(true);
  const [clientName, setClientName] = useState("");
  const [insuredSearch, setInsuredSearch] = useState("");
  const [insuredClientId, setInsuredClientId] = useState("");
  const [insuredClientNameError, setInsuredClientNameError] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(false);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
  const [showInVoiceFileUploadPage, setShowInVoiceFileUploadPage] = useState(false)
  const [showPolicyList, setShowPolicyList] = useState(false);
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [policyNumber, setPolicyNumber] = useState("");
  const [lineOfBusinessName, setLineOfBusinessName] = useState("");
  const [policyLoading,setPolicyLoading]=useState(false);
  const [rows, setRows] = useState(3);
  const [first, setFirst] = useState(0);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<{ id: any; index: any; code: any; clientName: any }[]>([]);
  const [selectedPolicyDatabaseId,setSelectedPolicyDatabaseId]=useState("");
  const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;
  const hostname = window.location.hostname;
  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    if (insuredSearch.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (
      CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id
    ) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(insuredSearch);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [insuredSearch]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getNowCertsPoliciesList = (insuredId: string) => {
    setPolicyLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        setPolicyLoading(false);
        setNowCertPolicyData(response.data);
        setShowPolicyList(true);
      })
      .catch((error) => {
        setPolicyLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage =
              "Unknown error while loading the insureds data.Please try again later.";
          }
        }
      });
  };

  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the insureds data.Please try again later",
                life: 3000,
              });
            }
          }
        }
        setSearchClientLoading(false);
      });
  };
  // const onClientSelect = (client: any) => {
  //   setClientName(client.name);
  //   setInsuredClientId(client.clientId);
  //   setShowPolicyList(true);
  //   getNowCertsPoliciesList(client.clientId)
  // };

  const onClientSelectForInsured = (client: any) => {
    setInsuredSearch("");
    setClientName(client.name);
    setInsuredClientId(client.databaseId);
    setInsuredClientNameError("");
    getNowCertsPoliciesList(client.databaseId)
  };

  const onClientSearchChangeForInsured = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setInsuredSearch(value);
    setClientNameErrorMessage("");
  };

  const currentPremiumBody = (rowData: any) => {
    return (
      <>
        {rowData.currentPremium != "" &&
          rowData.currentPremium != null &&
          rowData.currentPremium != 0
          ? rowData.currentPremium
          : rowData.quotedPremium}
      </>
    );
  };

  const onSelectMultiplePolicies = (rowData: any, index: any) => {
    return (
      <div className="checkbox">
        <label>
        <input
            type="checkbox"
            value=""
            checked={Array.isArray(selectedPolicyIds) && selectedPolicyIds.some((item) => item.id === rowData.databaseId)}
            onChange={() => onSelectedMultiplePolicyChange(rowData, index)}
            className="show-cursor bundle-checkbox"
          />
        </label>
      </div>
    );
  };

  const onSelectedMultiplePolicyChange = (rowData: any, index: any) => {
    setSelectedPolicyDatabaseId(rowData.databaseId);
    let code = "";
    if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
      code = rowData.linesOfBusiness[0];
    }
    setPolicyNumber(rowData.policyNumber);
    setLineOfBusinessName(code);
    let setIndex: number;

    if (window.innerWidth > 767) {
      setIndex = index.rowIndex;
    } else {
      setIndex = index;
    }
    setSelectedPolicyIds([
      {
        id: rowData.databaseId,
        index: setIndex,
        code: code,
        clientName: rowData.clientName,
      },
    ]);

  };

  const handleRowSelect = (e: any) => {
    setSelectedPolicyIds(e.value);
  };

  const onNextButtonClick=()=>{
    if(checkValidation()){
    setShowInVoiceFileUploadPage(true);
    setShowPolicyList(false)
    }
  }
  const onBackButtonClick=()=>{
    setShowInVoiceFileUploadPage(false);
    setClientName("");
    setPolicyNumber("");
    setSelectedPolicyIds([]);
  }
  const checkValidation=()=>{
    let isValid=true;
    if(clientName==null || clientName=="")
      {
        isValid=false
        setClientNameErrorMessage("Please select client name.");
        return isValid;
      }
    if(selectedPolicyDatabaseId =="" || selectedPolicyDatabaseId==null){
      isValid=false;
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select a policy to proceed.",
          life: 3000,
        });
        return isValid;
    }
    return isValid;
  }
  return (
    <>
      <div className="invoice-scan-main-page">
        <Toast ref={toast} />
        <Header pagename="invoice-scan" NavigatedFromChatBot={navigatedFromChatBot} />
        <div className="content-wrapper">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <InvoiceScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <InvoiceScanSidebar pagename="invoice-scan" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                {showInVoiceFileUploadPage ? (
                  <>
                    <InvoiceScanLandingPage
                     clientName={clientName}
                     policyNumber={policyNumber}
                     insuredClientId={insuredClientId}
                     selectedPolicyId={selectedPolicyDatabaseId}
                     onBackButtonClick={onBackButtonClick}
                    />
                  </>
                ) : (
                  <div className="row  d-flex  align-items-end text-12 position-relative">
                    <div className="col-12  p-0">
                      <div className="row mx-auto d-flex  align-items-center " style={{ backgroundColor: "#f6f6f6" }}>
                        <div className="col-10">
                          <div className="row scan-search-row mx-auto ">
                            <div className="col-3 position-relative mb-2 p-0">
                              <p className="text-16 fw-bold mb-1 ">Select Client </p>

                              <div className={`search-bar flex-column ${clientNameErrorMessage.length > 0 ? " validation-div " : " "}`}>
                                <AtkTextField
                                  type="text"
                                  id="client-name"
                                  className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 `}
                                  name="insuredSearch"
                                  onChange={(e) => {
                                    onClientSearchChangeForInsured(e.target.value);
                                  }}
                                  value={insuredSearch}
                                  placeholder={clientName.length > 0 ? clientName : "Enter client name"}
                                />
                              </div>
                              {insuredSearch.replaceAll(" ", "").length > 0 && (
                                <div id="client-search" className=" position-absolute  ">
                                  {searchClientLoading ? (
                                    <div className="d-flex justify-content-center my-2 client-loader-div">
                                      <AtkLoader />
                                    </div>
                                  ) : searchClientList.length == 0 ? (
                                    <div style={{ padding: "5px", background: "white" }}>
                                      <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                    </div>
                                  ) : (
                                    searchClientList.map((item: any) => (
                                      <div
                                        style={{ cursor: "pointer", width: "100%" }}
                                        onClick={() => {
                                          onClientSelectForInsured(item);
                                        }}
                                      >
                                        <a
                                          href="#"
                                          style={{
                                            color: "black",
                                            width: "100%",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item.name}
                                        </a>
                                      </div>
                                    ))
                                  )}
                                </div>
                              )}
                              <span className="text-danger text-12">{clientNameErrorMessage}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 text-end ">
                          <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-100 " />
                        </div>
                      </div>
                    </div>
                  </div>
                )}


              {policyLoading ?
               <>
                <div className="d-flex justify-content-center my-2 client-loader-div">
                                      <AtkLoader />
                                    </div>
               </>:<>
                {showPolicyList ? 
                  <div className="row p-2 mx-auto history-table mt-2">
                    <DataTable
                      value={nowCertPolicyData}
                      paginator
                      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                      rows={10}
                      selectionMode="single"
                      selection={selectedPolicyIds}
                     onSelectionChange={(e) => {
                      handleRowSelect(e);
                    }}
                    >
                      <Column
                        header=""
                        field=""
                        body={(rowData, rowIndex) => onSelectMultiplePolicies(rowData, rowIndex)}
                        bodyClassName="hide-cursor"
                      ></Column>
                      <Column
                        header="Title"
                        field="linesOfBusiness[0]"
                        body={(rowData: any) => {
                          if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
                            return rowData.linesOfBusiness[0];
                          }
                        }}
                        bodyClassName="hide-cursor"
                      ></Column>
                      <Column
                        header="Effective Date"
                        field="effectiveDate"
                        body={(rowData: any) => {
                          if (rowData.effectiveDate != null || rowData.effectiveDate !== "") {
                            return moment.utc(rowData.effectiveDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                          }
                        }}
                        bodyClassName="hide-cursor"
                        sortable
                      ></Column>
                      <Column header="Policy Number" field="policyNumber" sortable bodyClassName="hide-cursor"></Column>
                      <Column
                        header="Total Premium"
                        field="totalPremium"
                        body={(rowData) => <span>${rowData.totalPremium}</span>}
                        sortable
                        bodyClassName="hide-cursor"
                      ></Column>
                    </DataTable>
                  </div>
                 : (
                  null
                )}
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
