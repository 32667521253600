import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/NewQuote_B 1.svg";

export default function PolicyComparisonSidebar(props: any) {
  const navigate = useNavigate();

  const onPolicyCompareClick = () => {
    if (localStorage.getItem("PolicyPageStatus") === "summary") {
      navigate("/policycomparison/summary");
    } else if (localStorage.getItem("PolicyPageStatus") === "compare") {
      navigate("/policycomparison/compare");
    } else {
      navigate("/policycomparison");
    }
  };
  const onPolicyCompareHistoryClick = () => {
    navigate("/policycomparisonhistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename === "policycompare-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onPolicyCompareClick}
        >
          <img src={NewIcon} className={props.pagename === "policycompare-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt="" />
          <span className="sidebar-menu-title">New Policy {localStorage.getItem("PolicyPageStatus")}</span>{" "}
        </span>
        <span
          className={props.pagename === "policycompare-page-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onPolicyCompareHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename === "policycompare-page-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} alt=""
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
