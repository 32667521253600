import Logo from "../../assets/images/Momentum Logo M.png";

export default function MiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={Logo} className="landing-page-img" />
          <span className="middle-header-title">AI Assistance</span>
        </div>
      </div>{" "}
    </div>
  );
}