import React, { FC, useEffect, useState, useRef } from "react";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import CommonValues, { Logout } from "../../../common/utils";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function PeoplesSetting(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [peoplesPassword, setPeoplesPassword] = useState("");
  const [peopleUsername, setPeopleUsername] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const toast: any = useRef("");
  const [passwordType, setPasswordType] = useState("password");
  const [preferredVendorLoading, setPreferredVendorLoading] = useState(false);
  const hostname = window.location.hostname;

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getPeopleCreds();
  }, []);
  const getPeopleCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setPeoplesPassword("");
          setPeopleUsername("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setLoading(false);
          setPeopleUsername(response.data.username);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setPeoplesPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the Peoples premium finance setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the Peoples premium finance setting details" });
        }
        setLoading(false);
      });
  };

  const getPeoplepfDetailsForLogin = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplesportal`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the peoples Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the peoples Setting details" });
        }
        setLoginLoading(false);
      });
  };
  const onLoginClick = () => {
    getPeoplepfDetailsForLogin();
  };

  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setPeopleUsername(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setPeoplesPassword(e.target.value);
  };
  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onKeyUpdatePeopleClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateButtonClick();
    }
  };
  const CheckValidation = () => {
    let formIsValid = true;
    setErrorMessageUsername("");
    setErrorMessagePassword("");

    if (peopleUsername == "" || peopleUsername == null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter user name");
    }
    if (peoplesPassword == "" || peoplesPassword == null) {
      formIsValid = false;
      setErrorMessagePassword("Please enter password");
    }

    return formIsValid;
  };
  const onRegisterClick = () => {
    window.open("https://www.peoplesbancorp.com/media/user/premium-finance/2023_1_PPF_Agency%20Profile.pdf", "_blank");
  };

  const onUpdateButtonClick = () => {
    if (CheckValidation()) {
      setUpdateLoading(true);
      let token = CommonValues.GetToken();

      var data = JSON.stringify({
        Username: peopleUsername,
        Password: peoplesPassword,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplecreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setUpdateLoading(false);
          toast.current.show({ severity: "success", summary: 'Success', detail: "Peoples Premium Finance Settings updated successfully", life: 3000 });
          getPeopleCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error", summary: 'Error',
                detail: "It look likes you are not registered with Peoples Premium Finance, Please register first.",
              });
            } else {
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the Peoples Premium Finance Settings" });
            }
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the Peoples Premium Finance Settings" });
          }
          setUpdateLoading(false);
        });
    }
  };
  const onDeleteButtonClick = () => {
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setDeleteLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteLoading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "Peoples Premium Finance Settings deleted successfully", life: 3000 });
        getPeopleCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the Peoples Premium Finance Settings" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the Peoples Premium Finance Settings" });
        }
        setDeleteLoading(false);
      });
  };
  const insertPreferredVendor = (vendorId: any, isChecked: any) => {
    setPreferredVendorLoading(true);
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      ProviderId: vendorId,
      IsChecked: !isChecked,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        // toast.current.show({ severity: "success", summary: 'Success', detail: "Capital Settings updated successfully", life: 3000 });
        props.getVendorListFunction();
        setPreferredVendorLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPreferredVendorLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the preferred vendor" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the preferred vendor" });
        }
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="row mx-auto">
        <div className="col-12">
          <div className="pf-setting-col">
            <h4 className="pf-setting-title">
              Peoples Premium Finance
              {hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2 || hostname == process.env.REACT_APP_Momentum_Url3 || hostname == process.env.REACT_APP_Momentum_Url4 ? (
                <>
                  {preferredVendorLoading ? (
                    <AtkLoader></AtkLoader>
                  ) : (
                    <input
                      type="checkbox"
                      value=""
                      checked={props.peoplesPremiumFinanceVendor}
                      onChange={() => insertPreferredVendor(5, props.peoplesPremiumFinanceVendor)}
                      className="show-cursor bundle-checkbox "
                    />
                  )}
                </>
              ) : null}
            </h4>

            <div
              className={
                loading
                  ? "row pf-setting-inputs  mx-auto div-blur-loading d-flex align-items-center"
                  : "row pf-setting-inputs  mx-auto  d-flex align-items-center"
              }
            >
              <>
                <div className="col-4 d-flex align-items-center ">
                  <label className="form-label">
                    User Name
                    <span className="text-danger"> *</span>
                  </label>
                </div>
                <div className={errorMessageUsername.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                  <AtkTextField
                    type="text"
                    id="username"
                    name="peopleUsername"
                    onChange={onUsernameChange}
                    value={peopleUsername}
                    onKeyDown={onKeyUpdatePeopleClick}
                    className="input-field"
                    placeholder="Enter user name"
                  />
                  <span className="text-danger text-12 error-message">{errorMessageUsername}</span>
                </div>

                <div className="col-4 d-flex align-items-center mt-2 mb-2">
                  <label className="form-label">
                    Password
                    <span className="text-danger "> *</span>
                  </label>
                </div>
                <div
                  className={errorMessagePassword.length > 0 ? "col-8 mt-2 validation-div  position-relative" : "col-8 mt-2 mb-2 position-relative"}
                >
                  <AtkTextField
                    type={passwordType}
                    id="password"
                    name="peoplesPassword"
                    onChange={onPasswordChange}
                    value={peoplesPassword}
                    onKeyDown={onKeyUpdatePeopleClick}
                    className="input-field"
                    placeholder="Enter password"
                    autoComplete="new-password"
                  />
                  <span className="eye-icon" onClick={onShowPassword}>
                    {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                  </span>
                </div>
                {errorMessagePassword.length > 0 ? (
                  <>
                    <div className="col-4  mb-2"></div>
                    <div className=" col-8  mb-2">
                      <span className="text-danger text-12">{errorMessagePassword}</span>
                    </div>
                  </>
                ) : null}
                <div className="col-12 mb-2 ">
                  {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using Momentum Credentials" /> : null}
                </div>
              </>
            </div>
            <div className="row mx-auto  setting-btn-position">
              <div className="col-4 mt-3 mb-1">
                {deleteLoading ? (
                  <AtkLoader></AtkLoader>
                ) : (
                  <AtkButton label="Reset" onClick={onDeleteButtonClick} className="pf-primary-btn  w-100" />
                )}
              </div>
              <div className="col-4 mt-3 mb-1">
                {updateLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onUpdateButtonClick} className="pf-primary-btn  w-100" />}
              </div>
              <div className="col-4 mt-3 mb-1">
                <AtkButton label="Register" onClick={onRegisterClick} className="pf-primary-btn  w-100" />
              </div>
              <div className="col-12 mt-2  mb-2">
                {loginLoading ? (
                  <>
                    <AtkLoader></AtkLoader>
                  </>
                ) : (
                  <AtkButton label="Login" onClick={onLoginClick} className="pf-secondary-btn  w-100" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
