import React from "react";
import MomentumAISidebar from "./MomentumAISidebar";
import AIMenuFooter from "./AIMenuFooter";

export default function DataDiscoveryLandingPage() {
  return (
    <div className="ai-wrapper min-vh-100 ">
      <div className="ai-wrapper-container position-relative z-1">
        <div className="row g-4 mx-auto">
          <div className="col-2 px-0 border-end" style={{  paddingTop: "2%" }}>
            <MomentumAISidebar pagename="ai-data-discovery" />
          </div>
          <div className="col-10 px-0" style={{ paddingTop: "2%" }}>
          <div className="bg-white p-3 d-flex flex-column h-100">
          <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-wrap justify-content-center gap-2">
                  <h4 style={{ color: "#381574" }}>Coming Soon...</h4>
                </div>
              </div>{" "}
              <AIMenuFooter pagename="ai-data-discovery" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
