import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TurndownService from "turndown";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import copyImg from "../../assets/images/copy.png";
import BackgroundVideo from "../../common/BackgroundVideo";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import QuoteProposalHistorySidebar from "../QuoteProposal/QuoteProposalHistorySidebar";
import QuoteProposalMiddleHeader from "../QuoteProposal/QuoteProposalMiddleHeader";

export default function QuoteProposalHistory() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [historyData, setHistoryData] = useState<any>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [csvFileData, setCsvFileData] = useState<any>([]);
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [htmlUrl, setHTMLUrl] = useState("");
  const hostname = window.location.hostname;
  const [quoteProposalMarkdown, setQuoteProposalMarkdown] = useState("");
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname == process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    getHistoryDetails();
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getHistoryDetails = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCQuoteProposal/nc-quote-proposal-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting  history details",
              life: 3000,
            });
          }
        }
        setHistoryLoading(false);
      });
  };
  const CopyToClipBoardClicked = () => {
    navigator.clipboard
      .writeText(quoteProposalMarkdown)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Copied to clipboard",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to copy",
          life: 3000,
        });
      });
  };
  const actionTemplate = (rowData: any) => {
    return rowData.status === 2 ? (
      <>
        <div className="history-action-column">
          <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="me-2 show-cursor" />
        </div>
      </>
    ) : null;
  };

  const onPreviewClick = (rowData: any) => {
    const transactionId = rowData.transactionId;
    const fileName = rowData.quoteProposalHtml;
    setFileName(fileName);
    setTransactionId(transactionId);
    setShowPopUp(true);
    setPreviewFileLoading(true);
    const token = CommonValues.GetToken();
    var data = {
      transactionId: rowData.transactionId,
      quoteApplicationPdf: rowData.quoteApplicationPdf,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCQuoteProposal/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.pdfURL);
        setHTMLUrl(response.data.htmlURL);

        // const turndownService = new TurndownService();
        // setQuoteProposalMarkdown(
        //   turndownService.turndown(response.data.htmlContent)
        // );
        setQuoteProposalMarkdown(response.data.htmlContent);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting  history details",
              life: 3000,
            });
          }
        }
        setPreviewFileLoading(false);
      });
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
    setHTMLUrl("");
    setCsvFileData([]);
  };
  const statusTemplate = (rowData: any) => {
    return rowData.status === 0 ? (
      <b className="text-warning"> Submitted</b>
    ) : rowData.status === 1 ? (
      <b className="text-info"> In Progress</b>
    ) : rowData.status === 2 ? (
      <b className="text-success"> Done</b>
    ) : rowData.status === 3 ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <QuoteProposalMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <QuoteProposalHistorySidebar pagename="commission-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    <div className="row mb-3">
                      <div className="col-6">
                        <span className="p-input-icon-left w-50">
                          <i className="fa fa-search grid-search-icon" />
                          <AtkTextField
                            type="text"
                            id="txtfirstName"
                            name="globalFilter"
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            value={globalFilter}
                            className="input-field"
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                      <div className="col-6 text-end">
                        <AtkButton label="Refresh" onClick={getHistoryDetails} className=" pf-primary-btn w-auto" />
                      </div>
                    </div>
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable
                        value={historyData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        globalFilter={globalFilter}
                        emptyMessage="No data available."
                      >
                        <Column header="File Name" field="quoteApplicationPdf" sortable></Column>
                        <Column
                          header="Submitted Date"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="justify-">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          field="created"
                          sortable
                          style={{ width: "12%" }}
                        ></Column>
                        <Column header="Status" field="status" body={statusTemplate} style={{ width: "10%" }} sortable></Column>
                        <Column header="Action" body={actionTemplate} style={{ width: "12%" }}></Column>
                      </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
        {previewFileLoading ? (
          <div className="d-flex align-items-center justify-content-center overflow-hidden">
            <AtkLoader />
          </div>
        ) : (
          <div className="row">
            <div className="col-12" style={{ display: "flex", justifyContent: "right" }}>
              <img
                src={copyImg}
                title="Copy insured smart form url"
                onClick={CopyToClipBoardClicked}
                className="copy-icon d-inline-block mx-2"
                alt="Copy url"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col-md-6 border-end">
              <b>Uploaded PDF</b>
              <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
            </div>
            <div className="col-md-6">
              <b>Quote Proposal Template</b>
              <iframe src={htmlUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
            </div>
          </div>
        )}
      </Dialog>
      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              // onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
              aria-hidden="true"
              // onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
              aria-hidden="true"
              // onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton
              label="Cancel"
              // onClick={onHideFeedbackPopup}
              className="pf-secondary-btn w-auto"
            />
          </div>
          <div className="col-4 text-end p-2  ">
            {transactionId == feedBackData.transactionId && feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton
                label="Submit"
                // onClick={() => onSubmitFeedbackClick(feedBackData)}
                className="pf-secondary-btn w-auto "
              />
            )}
          </div>
        </div>
      </OverlayPanel>
    </>
  );
}
