import axios from "axios";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import PfdIcon from "../../assets/images/file.png";
import ImageIcon from "../../assets/images/ImageIcon.png"
import PlusSign from "../../assets/images/PlusSing1.webp"
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import CommonValues from "../../common/utils";
import { OverlayPanel } from "primereact/overlaypanel";
import InvoiceScanDataExtractPage from "./InvoiceScanDataExtractPage";
import BackIcon from "../../assets/images/Back Button.svg";

export default function InvoiceScanLandingPage(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [showUploadAgain, setShowUploadAgain] = useState(false);
  const [showExtractedData, setShowExtractedData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceDueDate, setInvoiceDueDate] = useState("");
  const [billedToName, setBilledToName] = useState("");
  const [billedToAddress, setBilledToAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [soldToName, setSoldToName] = useState("");
  const [soldToAddress, setSoldToAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [producer, setProducer] = useState("");
  const [bookLocation, setBookLocation] = useState("");
  const [totalAmountDue, setTotalAmountDue] = useState("");
  const [totalGrossAmount, setTotalGrossAmount] = useState("");
  const [totalBrokerCommission, setTotalBrokerCommission] = useState("");
  const [paymentAddress, setPaymentAddress] = useState({
    addressLine: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [insuredName, setInsuredName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [paymentURL, setPaymentURL] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [invoiceLineItems, setInvoiceLineItems] = useState([]);
  const [transactionId, setTransactionId] = useState("");




  useEffect(() => {
    const token = CommonValues.GetToken();
    getCurrentBalance();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);

  const getCurrentBalance = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf") || fileExtension?.[1].toLowerCase().includes("jpg")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      toast.current.show({
        severity: "warn",
        detail: "Please select PDF files only.",
        life: 3000,
      });
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        toast.current.show({
          severity: "error", summary: 'Error',
          detail: "Multiple files cannot be uploaded at the same time; please upload a single file.",
          life: 3000,
        });
      }
      setUploadFile(allFiles);
    }
  };
  const removeFileFromList = () => {
    setUploadFile([]);
  };
  const createUploadRequest = () => {
    setLoading(true);

    var FormData = require("form-data");
    var data = new FormData();
    data.append("Invoice", uploadFile[0].file);
    const selectionOptionsJson = {
      useExistingInsured: true,
      insuredClientId: props.insuredClientId,
      clientName: props.clientName,
      policyNumber: props.policyNumber,
      selectedPolicyId: props.selectedPolicyId,
    }
    data.append("selectedOption", JSON.stringify(selectionOptionsJson));
    let token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_PolicyAgent_Url}/scan_invoice`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setInvoiceNumber(response.data.invoiceResponse.invoiceNumber);
        setInvoiceDate(response.data.invoiceResponse.invoiceDate);
        setInvoiceDueDate(response.data.invoiceResponse.invoiceDueDate);
        setBilledToName(response.data.invoiceResponse.billedToName);
        setBilledToAddress(response.data.invoiceResponse.billedToAddress);
        setSoldToName(response.data.invoiceResponse.soldToName);
        setSoldToAddress(response.data.invoiceResponse.soldToAddress);
        setProducer(response.data.invoiceResponse.producer);
        setBookLocation(response.data.invoiceResponse.bookLocation);
        setTotalAmountDue(response.data.invoiceResponse.totalAmountDue);
        setTotalGrossAmount(response.data.invoiceResponse.totalGrossAmount);
        setTotalBrokerCommission(response.data.invoiceResponse.totalBrokerCommission);
        setPaymentAddress(response.data.invoiceResponse.paymentAddress);
        setInsuredName(response.data.invoiceResponse.insuredName);
        setPolicyNumber(response.data.invoiceResponse.policyNumber == "" || response.data.invoiceResponse.policyNumber == null ? props.policyNumber : response.data.invoiceResponse.policyNumber);
        setPaymentURL(response.data.paymentURL);
        setInsuranceCompany(response.data.invoiceResponse.insuranceCompany);
        setInvoiceLineItems(response.data.invoiceResponse.invoiceLineItems);
        setShowExtractedData(true);
        setIsFileUpload(true);
        setUploadPdfUrl(response.data.fileUrl);
        setTransactionId(response.data.transactionId);
        getCurrentBalance();
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Error while creating the request.",
            life: 3000,
          });
          setLoading(false);
        }
      });
  };

  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
  };

  const onUploadAgain = () => {
    setShowExtractedData(false);
    setUploadFile([]);
    setUploadPdfUrl("");
    props.onBackButtonClick();
  }

  const onChangeInvoiceNumber = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z1-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInvoiceNumber(Value);
  };

  const onChangeInvoiceDate = (value: any) => {
    setInvoiceDate(value);
  };

  const onChangeInvoiceDueDate = (value: any) => {
    setInvoiceDueDate(value);
  };

  const onChangeTotalDueAmount = (value: any) => {
    setTotalAmountDue(value);
  };

  const onChangeTotalGrossAmount = (value: any) => {
    setTotalGrossAmount(value);
  };
  const onChangeInsuredName = (value: any) => {
    setInsuredName(value);
  }
  const onChangePolicyNumber = (value: any) => {
    setPolicyNumber(value);
  }


  return (
    <>
      <Toast ref={toast} />
      <div className="row  d-flex  align-items-end text-12 position-relative">
        <div className="col-12  p-0">
          <div className="row d-flex justify-content-end scan-search-row mx-auto ">
            {" "}
            <div className="col-10 d-flex  align-items-center">

              <span>
                {" "}
                <img src={BackIcon} className="show-cursor me-3" height={20} width={20} title="Back" onClick={props.onBackButtonClick} />
              </span>
              <span className=" me-3">
                <label>
                  <b>Client Name: </b> &nbsp;
                </label>
                {props.clientName}
              </span>
              <span className=" me-3">
                <label>
                  <b>Policy Number </b> &nbsp;
                </label>
                {props.policyNumber}
              </span>

            </div>
            <div className="col-2">
              <div className="balance-span">
                {" "}
                <label>Current Balance</label>
                {currentBalanceLoading ? (
                  <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                    <AtkLoader />
                  </span>
                ) : (
                  <span className=" d-block w-auto ">${currentBalance}</span>
                )}
                <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row  d-flex justify-content-end align-items-center text-12 position-relative mx-auto mt-2">
        <div className="col-12">
          <div className="row m-3">
            {isFileUpload ? (
              <div className="col border-end">
                <iframe
                  src={uploadPdfUrl}
                  style={{
                    overflow: "scroll",
                    height: "100%",
                    width: "100%",
                }}
                ></iframe>
                {/* <img className="invoice-img" src={uploadPdfUrl} /> */}
              </div>
            ) : (
              <>
                <div
                  className="uploadNowcertscoi my-3"
                  onDrop={(e) => {
                    onDropHandler(e);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row w-100 ">
                    {uploadFile.length > 0 ? (
                      <div className="col-12">
                        {uploadFile.map((file: any, index: any) => (
                          <div className="show-pdf-icon">
                            <div className="scan-file-name">
                              <div className="preview-icon">
                                <img src={PreviewIcon} />
                              </div>
                              <div>
                                <div title={fileNameTitle}>{fileName}</div>
                                <div>
                                  {(parseInt(file.size) / 1000).toFixed(0)}
                                  KB
                                </div>
                              </div>
                            </div>
                            <div onClick={() => removeFileFromList()} className="show-cursor">
                              <img src={RemoveIcon} title="Remove file" />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <>
                          <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-12">
                              <img src={PfdIcon} alt="" width="70" />
                              <img src={PlusSign} alt="" width="70" />
                              <img src={ImageIcon} alt="" width="70" />
                            </div>

                            <div className="col-12 mt-4">
                              <div className="col-12">
                                <span className="drag-and-drop">Drag & Drop file here</span>
                              </div>
                              <div className="col-12 my-2">
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "large",
                                  }}
                                >
                                  or
                                </span>
                              </div>
                              <label className="mb-0 show-cursor">
                                <span className="browse">Browse PDF</span>
                                <input
                                  type="file"
                                  name="filefield"
                                  accept="image/*"
                                  id="bulkFileUploader"
                                  style={{ visibility: "hidden", display: "none" }}
                                  onChange={(e) => {
                                    onFileSelectHandler(e.target.files);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </>
                      </>
                    )}
                    <div className="col-12 text-center">
                      {uploadFile.length > 0 && (
                        <div className="d-flex justify-content-center" style={{ width: "100%", padding: "20px" }}>
                          {loading == false ? (
                            <>
                              <AtkButtonIconBefore
                                label="Upload"
                                className="pf-secondary-btn"
                                onClick={() => {
                                  createUploadRequest();
                                }}
                                title="Upload file"
                              />
                            </>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center p-2">
                              <AtkLoader></AtkLoader>
                            </div>
                          )}
                        </div>
                      )}
                      {errorMessage.length > 0 ? (
                        <div
                          className="d-flex justify-content-center Acord25-upload-file-error"
                          style={{ width: "100%", padding: "20px 20px 0px" }}
                        >
                          <AtkMessage appearance="error" messageText={errorMessage} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}

            {showExtractedData ?
              <>
                <InvoiceScanDataExtractPage
                  selectedPolicyId={props.selectedPolicyId}
                  insuredClientId={props.insuredClientId}
                  clientName={props.clientName}
                  invoiceNumber={invoiceNumber}
                  onChangeInvoiceNumber={onChangeInvoiceNumber}
                  invoiceDate={invoiceDate}
                  onChangeInvoiceDate={onChangeInvoiceDate}
                  invoiceDueDate={invoiceDueDate}
                  onChangeInvoiceDueDate={onChangeInvoiceDueDate}
                  billedToName={billedToName}
                  billedToAddress={billedToAddress}
                  soldToName={soldToName}
                  soldToAddress={soldToAddress}
                  producer={producer}
                  bookLocation={bookLocation}
                  totalAmountDue={totalAmountDue}
                  onChangeTotalDueAmount={onChangeTotalDueAmount}
                  totalGrossAmount={totalGrossAmount}
                  onChangeTotalGrossAmount={onChangeTotalGrossAmount}
                  totalBrokerCommission={totalBrokerCommission}
                  paymentAddress={paymentAddress}
                  insuredName={insuredName}
                  onChangeInsuredName={onChangeInsuredName}
                  policyNumber={policyNumber == "" ? props.policyNumber : policyNumber}
                  onChangePolicyNumber={onChangePolicyNumber}
                  paymentURL={paymentURL}
                  insuranceCompany={insuranceCompany}
                  invoiceLineItems={invoiceLineItems}
                  transactionId={transactionId}
                  fromHistoryPage={false}
                  onUploadAgain={onUploadAgain}
                />
              </>
              : null}
          </div>
        </div>
      </div>

    </>
  );
}
