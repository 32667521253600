import HomeOwnerMiddleHeader from "./HomeOwnerMiddleHeader";
import HomeOwnersSidebar from "./HomeOwnersSidebar";
import Header from "../../common/Header";
import Toggle from "@atlaskit/toggle";
import { useEffect, useRef, useState } from "react";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AddNewInsuredAndProperty from "./AddNewInsuredAndProperty";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Toast } from "primereact/toast";
import HomeOwnersMainPage from "./HomeOwnersMainPage";
import BackgroundVideo from "../../common/BackgroundVideo";
import BackIcon from "../../assets/images/Back Button.svg";

export default function HomeOwnerLandingPage(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [isExistingClientSelected, setIsExistingClientSelected] = useState(true);
  const [isNewClientSelected, setIsNewClientSelected] = useState(false);
  const [isExistingPropertySelected, setIsExistingPropertySelected] = useState(true);
  const [isAddNewPropertySelected, setIsAddNewPropertySelected] = useState(false);
  const [disabledIsExistingSelected, setDisabledIsExistingSelected] = useState(false);
  const [showHomeOwnerMainPage, setShowHomeOwnerMainPage] = useState(false);
  const [showExistClientAndAddNewProperty, setShowExistClientAndAddNewProperty] = useState(false);
  const [showNewClientAndAddNewProperty, setShowNewClientAndAddNewProperty] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [stateErrorMessage, setStateErrorMessage] = useState("");
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("");

  const [search, setSearch] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [showResponse, setShowResponse] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");
  const [selectedPropertyError, setSelectedPropertyError] = useState("");
  const [insuredDatabaseId, setInsuredDataBaseId] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [propertylist, setPropertylist] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [propertyListLoading, setPropertyListLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [yearBuilt, setYearBuilt] = useState("");
  const [constructionType, setConstructionType] = useState("");
  const [Occupancy, setOccupancy] = useState("");
  const [residenceType, setResidenceType] = useState("");
  const [usageType, setUsageType] = useState("");
  const [room, setRoom] = useState("");
  const [families, setFamilies] = useState("");
  const [marketValue, setMarketValue] = useState("");
  const [replacementCost, setReplacementCost] = useState("");
  const [totalLivingArea, setTotalLivingArea] = useState("");
  const [firePlaces, setFirePlaces] = useState("");
  const [rating, setRating] = useState("");
  const [roofMaterial, setRoodMaterial] = useState("");
  const [primaryHeat, setPrimaryHeat] = useState("");
  const [foundation, setFoundation] = useState("");
  const [loading, setLoading] = useState(false);
  const [showHomeOwnerResponsePage, setShowHomeOwnerResponsePage] = useState(false);
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("");
  const [ncURL, setNcURL] = useState("");
  const [clientId, setClientId] = useState("");
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [buildingId, setBuildingId] = useState("");
  const hostname = window.location.hostname;
  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2  && hostname !==process.env.REACT_APP_Momentum_Url3 && hostname !== process.env.REACT_APP_Momentum_Url4) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
  });

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  useEffect(() => {
    getCardDetails();
  }, []);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            // toast.current.show({
            //   severity: "error", summary: 'Error',
            //   detail: "Unknown error while loading the insureds data.Please try again later.",
            //   life: 3000,
            // });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };
  const onClientHandleChange = (e: any, selectedName: any) => {
    if (selectedName == "useExisting" && e.target.checked == true) {
      setIsExistingClientSelected(!isExistingClientSelected);
      setIsNewClientSelected(!isNewClientSelected);
      setDisabledIsExistingSelected(!disabledIsExistingSelected);
    } else {
      setIsExistingClientSelected(!isExistingClientSelected);
      setIsNewClientSelected(!isNewClientSelected);
      setDisabledIsExistingSelected(!disabledIsExistingSelected);
      setIsExistingPropertySelected(false);
    }
  };

  const onPropertyHandleChange = (e: any, selectedName: any) => {
    if (selectedName == "useExistingProperty" && e.target.checked == true) {
      setIsExistingPropertySelected(!isExistingPropertySelected);
      setIsAddNewPropertySelected(!isAddNewPropertySelected);
    } else {
      if (disabledIsExistingSelected == true) {
        setIsAddNewPropertySelected(true);
      } else if (selectedName == "addNewProperty" && e.target.checked == true) {
        setIsExistingPropertySelected(false);
        setIsAddNewPropertySelected(e.target.checked);
      } else {
        setIsExistingPropertySelected(!isExistingPropertySelected);
        setIsAddNewPropertySelected(!isAddNewPropertySelected);
      }
    }
  };

  const onNextButton = () => {
    if (isExistingClientSelected == true && isExistingPropertySelected == true) {
      setShowHomeOwnerMainPage(true);
    }
    if (isExistingClientSelected == true && isAddNewPropertySelected == true) {
      setShowExistClientAndAddNewProperty(true);
      setShowHomeOwnerMainPage(true);
      setShowNewClientAndAddNewProperty(false);
    }
    if (isExistingClientSelected == true && isExistingPropertySelected == true) {
      setIsExistingClientSelected(true);
      setShowNewClientAndAddNewProperty(true);
    }
    if (isNewClientSelected == true && isAddNewPropertySelected == true) {
      setShowHomeOwnerMainPage(true);
      setIsExistingClientSelected(false);
      setShowNewClientAndAddNewProperty(false);
      setShowExistClientAndAddNewProperty(true);
    }
  };

  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };

  const onClientSelect = (client: any) => {
    setSearch("");
    setClientName(client.name);
    setClientId(client.databaseId);
    setClientNameError("");
    if (isAddNewPropertySelected != true) {
      getNowCertsClientsById(client.databaseId);
    }
  };

  const getNowCertsClientsById = (clientId: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${clientId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          setCity(insuredInfo[0].city);
          setAddressLine1(insuredInfo[0].addressLine1);
          setPhoneNumber(insuredInfo[0].phone);
          setState(insuredInfo[0].stateNameOrAbbreviation);
          setZipCode(insuredInfo[0].zipCode);

          getInsuredProperties(insuredInfo[0].databaseId);
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            // toast.current.show({
            //   severity: "error", summary: 'Error',
            //   detail: "Error while getting insured data.",
            //   life: 3000,
            // });
          }
        }
      });
  };

  const getInsuredProperties = (insuredId: any) => {
    setPropertyListLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";

    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/propertylist?ncToken=${nowCertsToken}&insuredId=${insuredId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response: any) => {
        let list: any = [];
        response.data.forEach((item: any) => {
          list.push({
            id: item.id,
            property: item.propertyUse + " - " + item.addressLine1 + ", " + item.city + ", " + item.zip,
            address: item.addressLine1,
            city: item.city,
            province: item.state,
            postalCode: item.zip,
          });
        });
        setPropertylist(list);
        setPropertyListLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPropertyListLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            // toast.current.show({
            //   severity: "error", summary: 'Error',
            //   detail: "Error while getting insured property data.",
            //   life: 3000,
            // });
          }
        }
      });
  };
  const onBackButtonClick = () => {
    setIsExistingClientSelected(true);
    setIsExistingPropertySelected(true);
    setIsNewClientSelected(false);
    setIsAddNewPropertySelected(false);
    setDisabledIsExistingSelected(false);
    setIsNewClientSelected(false);
    setIsAddNewPropertySelected(false);
    setShowHomeOwnerMainPage(false);
    setClientNameError("");
    setSelectedPropertyError("");
    setClientName("");
    setSelectedProperty("");
    setAddressErrorMessage("");
    setCityErrorMessage("");
    setStateErrorMessage("");
    setZipCodeErrorMessage("");
    setShowExistClientAndAddNewProperty(false);
    setShowHomeOwnerResponsePage(false);
    setZipCode("");
    setCity("");
    setAddressLine1("");
    setState("");
  };

  const checkValidation = () => {
    setClientNameError("");
    setSelectedPropertyError("");
    let formIsValid = true;
    // if (createNewClientSelected == false && isExistingClientSelected == false) {
    //   setClientActionError("Please select client action");
    //   formIsValid = false;
    // }
    if (isExistingClientSelected == true) {
      if (clientName == "" || clientName == null) {
        setClientNameError("Please select client name");
        formIsValid = false;
      }
    }
    if (isExistingClientSelected == true && isExistingPropertySelected == true) {
      if (selectedProperty == "" || selectedProperty == null) {
        setSelectedPropertyError("Please select property");
        formIsValid = false;
      }
      if (addressLine1 == "" || addressLine1 == null) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Please add address line1 in NowCerts",
          life: 3000,
        });
        formIsValid = false;
      }
      if (city == "" || city == null) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Please add city in NowCerts",
          life: 3000,
        });
        formIsValid = false;
      }
      if (state == "" || state == null) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Please add state in NowCerts",
          life: 3000,
        });
        formIsValid = false;
      }
      if (zipCode == "" || zipCode == null) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Please add zipCode in NowCerts",
          life: 3000,
        });
        formIsValid = false;
      } else {
        const zipCodeRegex = /^\d+$/;
        if (!zipCodeRegex.test(zipCode)) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "Zip code must be exactly five digit please update the zip code in NowCerts.",
            life: 3000,
          });
          formIsValid = false;
        }
      }
    }

    if (isExistingClientSelected == true && isAddNewPropertySelected == true) {
      if (addressLine1 == "" || addressLine1 == null) {
        setAddressErrorMessage("Please enter address line1.");
        formIsValid = false;
      }
      if (city == "" || city == null) {
        setCityErrorMessage("Please enter city.");
        formIsValid = false;
      }
      if (state == "" || state == null || state == "Select state") {
        setStateErrorMessage("Please enter state.");
        formIsValid = false;
      }
      if (zipCode == "" || zipCode == null) {
        setZipCodeErrorMessage("Please enter zip code.");
        formIsValid = false;
      } else {
        const zipCodeRegex = /^\d+$/;
        if (!zipCodeRegex.test(zipCode)) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "Please enter zip code must be exactly five digit.",
            life: 3000,
          });
          formIsValid = false;
        }
      }
    }
    if (isNewClientSelected == true) {
      if (clientName == "" || clientName == null) {
        setClientNameError("Please select client name");
        formIsValid = false;
      }
    }
    return formIsValid;
  };

  const onChangeReplacementCost = (value: any) => {
    setReplacementCost(value);
  };
  const onChangeTotalLivingArea = (value: any) => {
    setTotalLivingArea(value);
  };
  const onChangeFirePlaces = (value: any) => {
    setFirePlaces(value);
  };
  const onChangeRating = (value: any) => {
    setRating(value);
  };

  const onChangeRoofMaterial = (value: any) => {
    setRoodMaterial(value);
  };
  const onChangePrimaryHeat = (value: any) => {
    setRoodMaterial(value);
  };
  const onChangeMarketValue = (value: any) => {
    setMarketValue(value);
  };
  const onChangeYearBuild = (value: any) => {
    setYearBuilt(value);
  };
  const onChangeRoomCount = (value: any) => {
    setRoom(value);
  };
  const onChangeFamilies = (value: any) => {
    setFamilies(value);
  };
  const onChangeUsageType = (value: any) => {
    setUsageType(value);
  };
  const onChangeResidenceType = (value: any) => {
    setResidenceType(value);
  };
  const onChangeOccupancy = (value: any) => {
    setOccupancy(value);
  };
  const onChangeConstructionType = (value: any) => {
    setConstructionType(value);
  };
  const onChangesClientName = (value: any) => {
    setClientNameError("");
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setClientName(Value);
  };
  const onChangesAddress = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setAddressLine1(value);
    setAddressErrorMessage("");
  };
  const onChangesCity = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setCity(value);
    setCityErrorMessage("");
  };
  const onChangesState = (value: any) => {
    setStateErrorMessage("");
    setState(value);
  };
  const onChangesZipCode = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length > 9) {
        return;
      }
      setZipCode(value);
    }
    setZipCodeErrorMessage("");
  };

  const onPropertySelected = (e: any) => {
    if (e.value.province.includes("Canada:")) {
      let stateName = e.value.province.split(":")[1];
      CanadaStateList.map((item: any) => {
        if (item.name === stateName) {
          setState(item.code);
        }
      });
    } else {
      Allstate.map((item: any) => {
        if (item.name === e.value.province) {
          setState(item.code);
        }
      });
    }
    setSelectedProperty(e.value);
    setAddressLine1(e.value.address);
    setCity(e.value.city);
    setCountry("US");
    setZipCode(e.value.postalCode);
    setBuildingId(e.value.id);
    setSelectedPropertyError("");
  };

  const onGetHomeOwnersDataClicked = async () => {
    if (checkValidation()) {
      if (isAddNewPropertySelected == true && isNewClientSelected == true) {
        let insuredDatabaseId = await createNewInsured();
        addNewProperty(insuredDatabaseId);
      } else if (isExistingClientSelected == true && isAddNewPropertySelected == true) {
        addNewProperty(clientId);
      } else {
        getHomeOwnerResponse(true);
      }
    }
  };

  const addNewProperty = (insuredDatabaseId: any) => {
    setShowExistClientAndAddNewProperty(false);
    setLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
      addressLine1: addressLine1,
      City: city,
      State: state,
      zipCode: zipCode,
    };
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addproperty?ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (response.data.status == "1") {
          setBuildingId(response.data.databaseId);
          getHomeOwnerResponse(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error);
        setPropertyListLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Error while adding insured property data.",
              life: 3000,
            });
          }
        }
      });
  };
  const getHomeOwnerResponse = (isLoading: any) => {
    if (isLoading == true) {
      setLoading(true);
    }
    var token = CommonValues.GetToken();
    var data = {
      Country: country,
      Address: addressLine1,
      City: city,
      Province: state,
      PostalCode: parseInt(zipCode),
      InsuredName: clientName,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/HomeOwners/search`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        setLoading(false);

        if (response.data.success == true && response.data.errorMessage == "Data Not Found") {
          setShowHomeOwnerResponsePage(true);
          setShowResponse(false);
        } else if (response.data.success == true) {
          setShowHomeOwnerResponsePage(true);
          let homeOwnersResponse = response.data.responseJson;
          setConstructionType(homeOwnersResponse.constructionType);
          setOccupancy(homeOwnersResponse.occupancy);
          setResidenceType(homeOwnersResponse.residenceType);
          setUsageType(homeOwnersResponse.usageType);
          setYearBuilt(homeOwnersResponse.yearBuild);
          setRoom(homeOwnersResponse.room);
          setFamilies(homeOwnersResponse.families);
          setMarketValue(homeOwnersResponse.marketValue);
          setReplacementCost(homeOwnersResponse.replacementCost);
          setTotalLivingArea(homeOwnersResponse.totalLivingArea);
          setFirePlaces(homeOwnersResponse.fireplaces);
          setRating(homeOwnersResponse.rating);
          setRoodMaterial(homeOwnersResponse.roofMaterial);
          setPrimaryHeat(homeOwnersResponse.primaryHeat);
          setFoundation(homeOwnersResponse.foundation);
          setShowResponse(true);
        } else if (response.data.success == false) {
          setShowHomeOwnerResponsePage(false);
          setShowResponse(false);
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: response.data.errorMessage,
            life: 3000,
          });
        }
        getCardDetails();
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting home owners details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting home owners details",
            life: 3000,
          });
        }
      });
  };

  const createNewInsured = async () => {
    setLoading(true);
    setNcURL("");
    try {
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: clientName,
        firstName: "",
        lastName: "",
        dba: "",
        addressLine1: addressLine1,
        addressLine2: "",
        state: state,
        city: city,
        zipCode: zipCode,
        eMail: "",
        cellPhone: "",
        fein: "",
        type:1
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertinsured?submissionId=${"hdtgfy-474dofi-854754f-ufifu"}&nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.database_id;
      setInsuredDataBaseId(response.data.database_id);
      setNcURL(response.data.newInsuredLunchUrl);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Insured has been created successfully in NowCerts",
        life: 3000,
      });
      return insuredInfo;
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.errors,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Please correct NowCerts username and password.",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error while creating insured inside the NowCerts.",
          life: 3000,
        });
      }
      let insuredInfo = "";
      return insuredInfo;
    }
  };

  const stateDropdownClicked = (value: any) => {
    setState(value);
    setStateErrorMessage("");
  };

  const onSubmitClick = () => {
    setOnSubmitLoading(true);
    var token = CommonValues.GetToken();
    let ncToken = CommonValues.GetNCToken();
    var data = {
      databaseId: insuredDatabaseId == null || insuredDatabaseId == "" ? clientId : insuredDatabaseId,
      buildingId: buildingId,
      insuredDatabaseId: insuredDatabaseId == null || insuredDatabaseId == "" ? clientId : insuredDatabaseId,
      constructionTypeOtherIndicator: true,
      constructionTypeOtherCode: constructionType,
      sidingOtherUsageIndicator: true,
      sidingOtherUsageDescription: usageType,
      otherOccupancyIndicator: true,
      otherOccupancyDescription: Occupancy,
      residenceTypeOtherIndicator: true,
      residenceTypeOtherDescription: residenceType,
      constructionRoofMaterialCode: roofMaterial,
      constructionBuiltYear: yearBuilt,
      marketValueAmount: marketValue,
      replacementCostAmount: replacementCost,
      totalLivingArea: totalLivingArea,
      roomCount: room,
      familyCount: families,
      residentialStructureFireplaceChimneyCount: "0",
      residentialStructureFireplaceHearthCount: "0",
      residentialStructureFireplacePrefabricatedCount: "0",
      residentialStructureFireplaceWoodStoveInsertCount: "0",
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addaccordeighty?ncToken=${ncToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response.data.status == "1") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Data updated in NowCerts.",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
        setOnSubmitLoading(false);
      })
      .catch((error: any) => {
        setOnSubmitLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while submitting home owners details in NowCerts.",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting home owners details",
            life: 3000,
          });
        }
      });
  };

  const CanadaStateList = [
    { name: "Alberta", code: "AB" },
    { name: "British Columbia", code: "BC" },
    { name: "Manitoba", code: "MB" },
    { name: "New Brunswick", code: "NB" },
    { name: "Newfoundland", code: "NF" },
    { name: "Northwest Territories", code: "NT" },
    { name: "Nova Scotia", code: "NS" },
    { name: "Nunavut", code: "NU" },
    { name: "Ontario", code: "ON" },
    { name: "Prince Edward Island", code: "PE" },
    { name: "Quebec", code: "PQ" },
    { name: "Saskatchewan", code: "SK" },
    { name: "Yukon", code: "YT" },
  ];
  const Allstate = [
    { name: "Alaska", code: "AK" },
    { name: "Alabama", code: "AL" },
    { name: "Arkansas", code: "AR" },
    { name: "Arizona", code: "AZ" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "DistrictofColumbia", code: "DC" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Iowa", code: "IA" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Massachusetts", code: "MA" },
    { name: "Maryland", code: "MD" },
    { name: "Maine", code: "ME" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Missouri", code: "MO" },
    { name: "Mississippi", code: "MS" },
    { name: "Montana", code: "MT" },
    { name: "NorthCarolina", code: "NC" },
    { name: "NorthDakota", code: "ND" },
    { name: "Nebraska", code: "NE" },
    { name: "NewHampshire", code: "NH" },
    { name: "NewJersey", code: "NJ" },
    { name: "NewMexico", code: "NM" },
    { name: "Nevada", code: "NV" },
    { name: "NewYork", code: "NY" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "RhodeIsland", code: "RI" },
    { name: "SouthCarolina", code: "SC" },
    { name: "SouthDakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Virginia", code: "VA" },
    { name: "Vermont", code: "VT" },
    { name: "Washington", code: "WA" },
    { name: "Wisconsin", code: "WI" },
    { name: "WestVirginia", code: "WV" },
    { name: "Wyoming", code: "WY" },
  ];
  return (
    <>
      <Toast ref={toast} />
      <div className="homeowner-main-page">
        <Header pagename="homeowner-landing-page" />
        <div className="content-wrapper">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <HomeOwnerMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto ">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <HomeOwnersSidebar pagename="homeowner-landing-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row   d-flex  align-items-end text-12 position-relative">
                  <div className="col-12 p-0">
                    <div className="row scan-search-row mx-auto d-flex  align-items-center">
                      {showHomeOwnerMainPage == true ? (
                        <>
                          <div className="col-10">
                            <div className="row">
                              <div className="col-1 mt-4 d-flex align-items-center">
                              <img src={BackIcon} className="show-cursor" height={20} width={20} title="Back" onClick={onBackButtonClick} />

                                {/* <AtkButton label="Back" onClick={onBackButtonClick} className="policy-back-btn w-auto" /> */}
                              </div>
                              {isExistingClientSelected == true ? (
                                <div className={`col-4 position-relative  p-0 ${clientNameError.length > 0 ? "validation-div" : ""}`}>
                                  <p className="text-14 fw-bold mb-1 ">Select Client </p>

                                  <div className={`search-bar flex-column `}>
                                    {/* ${clientNameError.length > 0 ? " validation-div " : " "}`}> */}
                                    <AtkTextField
                                      type="text"
                                      id="client-name"
                                      className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 input-field `}
                                      name="search"
                                      onChange={(e) => {
                                        onClientSearchChange(e.target.value);
                                      }}
                                      value={search}
                                      placeholder={clientName.length > 0 ? clientName : "Enter client name"}
                                      // isDisabled={selectedProspect !== "useExisting"}
                                    />
                                  </div>
                                  {search.replaceAll(" ", "").length > 0 && (
                                    <div id="client-search" className=" position-absolute  ">
                                      {searchClientLoading ? (
                                        <div className="d-flex justify-content-center my-2 client-loader-div">
                                          <AtkLoader />
                                        </div>
                                      ) : searchClientList.length == 0 ? (
                                        <div style={{ padding: "5px", background: "white" }}>
                                          <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                        </div>
                                      ) : (
                                        searchClientList.map((item: any) => (
                                          <div
                                            style={{ cursor: "pointer", width: "100%" }}
                                            onClick={() => {
                                              onClientSelect(item);
                                            }}
                                          >
                                            <a
                                              href="#"
                                              style={{
                                                color: "black",
                                                width: "100%",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item.name}
                                            </a>
                                          </div>
                                        ))
                                      )}
                                    </div>
                                  )}
                                  <span className="text-danger text-12">{clientNameError}</span>
                                </div>
                              ) : null}

                              {showNewClientAndAddNewProperty ? (
                                <>
                                  <div className={`col-4 ${selectedPropertyError.length > 0 ? "validation-div" : ""}`}>
                                    <div className="row text-14 ">
                                      <p className="text-14 fw-bold mb-1">Select Property</p>
                                      <div className="col-12">
                                        <div className="d-flex align-items-center">
                                          {propertyListLoading ? (
                                            <div className="d-flex justify-content-center my-2">
                                              <AtkLoader />
                                            </div>
                                          ) : (
                                            <Dropdown
                                              className="finance-company-dropdown input-field"
                                              placeholder="Select property"
                                              options={propertylist}
                                              optionLabel="property"
                                              onChange={onPropertySelected}
                                              value={selectedProperty}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <span className="text-danger text-12 ">{selectedPropertyError}</span>
                                  </div>
                                  <div className="col-3 mt-4">
                                    <AtkButtonIconBefore
                                      label="Get Home Owners Data"
                                      className="pf-secondary-btn"
                                      onClick={onGetHomeOwnersDataClicked}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-2 text-center balance-span">
                            <label>Current Balance</label>
                            {currentBalanceLoading ? (
                              <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                                <AtkLoader />
                              </span>
                            ) : (
                              <span className=" d-block w-auto ">${currentBalance}</span>
                            )}
                            <small className="d-block w-auto text-danger mt-0">100 cent will be charged per usage</small>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-10">
                            <div className="row">
                              <div className="col-md-2 col-sm-3 col-lg-3 mb-3">
                                <div className="row text-12 mb-2 ">
                                  <p className="text-16 fw-bold">Client</p>
                                  <div className="col-12">
                                    <div className="p-field-checkbox  d-flex align-items-center">
                                      <label htmlFor="useExisting">Use Existing</label>
                                      <Toggle
                                        id="useExisting"
                                        isChecked={isExistingClientSelected}
                                        onChange={(e) => {
                                          onClientHandleChange(e, "useExisting");
                                        }}
                                      />
                                    </div>
                                    <div className="p-field-checkbox d-flex align-items-center">
                                      <label htmlFor="createNew">Create New</label>
                                      <Toggle
                                        id="createNew"
                                        isChecked={isNewClientSelected}
                                        onChange={(e) => {
                                          onClientHandleChange(e, "createNew");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 col-sm-3 col-lg-3 mb-3">
                                <div className="row text-12 mb-2">
                                  <p className="text-16 fw-bold">Property</p>

                                  <div className="col-12">
                                    <div className="p-field-checkbox d-flex align-items-center">
                                      <label htmlFor="useExistingProperty">Use Existing Property</label>
                                      <Toggle
                                        id="useExistingProperty"
                                        isChecked={isExistingPropertySelected}
                                        onChange={(e) => {
                                          onPropertyHandleChange(e, "useExistingProperty");
                                        }}
                                        isDisabled={disabledIsExistingSelected}
                                      />
                                    </div>
                                    <div className="p-field-checkbox  d-flex align-items-center">
                                      <label htmlFor="addNewProperty">Add New Property</label>
                                      <Toggle
                                        id="addNewProperty"
                                        isChecked={isAddNewPropertySelected}
                                        onChange={(e) => {
                                          onPropertyHandleChange(e, "addNewProperty");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 text-end">
                            <AtkButton label="Next" className="pf-secondary-btn w-100 " onClick={onNextButton} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {showExistClientAndAddNewProperty ? (
                  <>
                    <AddNewInsuredAndProperty
                      isExistingClientSelected={isExistingClientSelected}
                      isAddNewPropertySelected={isAddNewPropertySelected}
                      isNewClientSelected={isNewClientSelected}
                      addressLine1={addressLine1}
                      city={city}
                      state={state}
                      zipCode={zipCode}
                      stateDropdownClicked={stateDropdownClicked}
                      onChangesClientName={onChangesClientName}
                      onChangesAddress={onChangesAddress}
                      onChangesCity={onChangesCity}
                      onChangesZipCode={onChangesZipCode}
                      addressErrorMessage={addressErrorMessage}
                      cityErrorMessage={cityErrorMessage}
                      stateErrorMessage={stateErrorMessage}
                      zipCodeErrorMessage={zipCodeErrorMessage}
                      clientNameError={clientNameError}
                      onGetHomeOwnersDataClicked={onGetHomeOwnersDataClicked}
                    />
                  </>
                ) : null}

                {loading ? (
                  <div className="d-flex justify-content-center my-2 client-loader-div">
                    <AtkLoader />
                  </div>
                ) : (
                  <>
                    {showHomeOwnerResponsePage ? (
                      <HomeOwnersMainPage
                        constructionType={constructionType}
                        onChangeConstructionType={onChangeConstructionType}
                        occupancy={Occupancy}
                        onChangeOccupancy={onChangeOccupancy}
                        residenceType={residenceType}
                        onChangeResidenceType={onChangeResidenceType}
                        usageType={usageType}
                        onChangeUsageType={onChangeUsageType}
                        yearBuild={yearBuilt}
                        onChangeYearBuild={onChangeYearBuild}
                        room={room}
                        onChangeRoomCount={onChangeRoomCount}
                        families={families}
                        onChangeFamilies={onChangeFamilies}
                        marketValue={marketValue}
                        onChangeMarketValue={onChangeMarketValue}
                        replacementCost={replacementCost}
                        onChangeReplacementCost={onChangeReplacementCost}
                        totalLivingArea={totalLivingArea}
                        onChangeTotalLivingArea={onChangeTotalLivingArea}
                        firePlaces={firePlaces}
                        onChangeFirePlaces={onChangeFirePlaces}
                        rating={rating}
                        onChangeRating={onChangeRating}
                        roofMaterial={roofMaterial}
                        onChangeRoofMaterial={onChangeRoofMaterial}
                        primaryHeat={primaryHeat}
                        onChangePrimaryHeat={onChangePrimaryHeat}
                        foundation={foundation}
                        onSubmitLoading={onSubmitLoading}
                        onSubmitClick={onSubmitClick}
                        showResponse={showResponse}
                      />
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
