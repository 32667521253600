import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Guid } from "js-guid";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import SupportLoader from "../common/SupportLoader";
import moment from "moment";

export default function MomentumAISidebar(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");

  const [recentHistory, setRecentHistory] = useState<any>([]);
  const [conversationLoading, setConversationLoading] = useState(false);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getAllRecentHistory();
  }, []);

  useEffect(() => {
    if (props.latestQuestion && !recentHistory.some((item: any) => item.conversationId === props.latestQuestion.conversationId)) {
      setRecentHistory((prevrecentHistory: any) => [props.latestQuestion, ...prevrecentHistory]);
    }
  }, [props.latestQuestion]);

  const getAllRecentHistory = () => {
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/monentum-ai-recent-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setRecentHistory(response.data);
        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting recent history",
            life: 3000,
          });
        }
      });
  };
  const onNewConversionlick = () => {
    const pageRoutes: Record<string, string> = {
      "ai-support-assistant": `/ai-support-assistant/${Guid.newGuid()}`,
      "ai-scans": `/ai-scans/${Guid.newGuid()}`,
      "ai-call-assistant": `/ai-call-assistant/${Guid.newGuid()}`,
      "ai-data-discovery": "/datadiscovery",
      "ai-report-assistant": "/report-assistant",
      "ai-coi-assistant": `/ai-coi-assistant/${Guid.newGuid()}`,
    };

    const route = pageRoutes[props.pagename] || "/";
    navigate(route);
  };
  const onHistoryConversionClicked = (history: any) => {
    navigate(`/${history.type}/${history.conversationId}`);
  };
  const pageIcons: Record<string, string> = {
    "ai-support-assistant": "fa fa-life-ring",
    "ai-scans": "fa fa-robot",
    "ai-call-assistant": "fa fa-phone",
    "ai-data-discovery": "fa fa-database",
    "ai-report-assistant": "fa fa-chart-bar",
  };
  const pageTitles: Record<string, string> = {
    "ai-support-assistant": "Support Assistant",
    "ai-scans": "AI-Powered Scans",
    "ai-call-assistant": " Calls by AI Agent",
    "ai-data-discovery": "Data Discovery",
    "ai-report-assistant": "Report Assistant",
  };
  const iconClass = pageIcons[props.pagename] || "";
  const title = pageTitles[props.pagename] || "";
  return (
    <div className=" p-3 " style={{ minHeight: "95vh" }}>
      <Toast ref={toast} />
      <div className="overflow-auto " style={{ maxHeight: "100vh" }}>
        {/* <div className="mb-3 rounded  d-flex align-items-center" style={{ color: "#381574" }}>
          <span className="d-flex flex-row align-items-center">
            {iconClass && <i className={`me-2 ${iconClass}`}></i>}
            <h6 className="mb-0">{title}</h6>
          </span>
        </div> */}
        <div className="ai-sidebar-menu mb-2 ai-border-radius p-3 d-flex align-items-center border me-1 bg-white" onClick={onNewConversionlick}>
          <i className="fa fa-plus me-2" />
          <span>
            <h6 className="mb-0 text-14">New Chat</h6>
            {/* <p className="text-muted mb-0 text-10">Start fresh with Momentum AI</p> */}
          </span>
        </div>

        <div className="mb-3 rounded  d-flex align-items-center mt-3">
          <span className="d-flex flex-row align-items-center">
            <h6 className="text-xs mb-0">RECENT CONVERSATIONS</h6>
          </span>
        </div>

        {conversationLoading ? (
          <div style={{ height: "70vh", overflowY: "auto" }} className="d-flex justify-content-center align-items-center">
            <SupportLoader />
          </div>
        ) : (
          <div style={{ height: "70vh", overflowY: "auto" }}>
            {recentHistory.map((history: any) => {
              return (
                <div
                  className="ai-sidebar-menu mb-3  px-3 py-2 d-flex align-items-center me-2"
                  onClick={() => onHistoryConversionClicked(history)}
                  title={history.question}
                >
                  {history.type == "ai-support-assistant" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="rgba(33, 37, 41, 0.75)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-headphones me-2"
                    >
                      <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3"></path>
                    </svg>
                  ) : history.type == "ai-scans" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="rgba(33, 37, 41, 0.75)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-search me-2"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <path d="m21 21-4.3-4.3"></path>
                    </svg>
                  ) : history.type == "ai-call-assistant" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="rgba(33, 37, 41, 0.75)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-search me-2"
                    >
                      <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z"></path>
                    </svg>
                  ) : history.type == "ai-coi-assistant" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="rgba(33, 37, 41, 0.75)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-search me-2"
                    >
                      <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                    </svg>
                  ) : null}

                  <span>
                    <span className="mb-0 text-sm text-muted fw-0 d-block">
                      {history.question.length > 25 ? history.question.substring(0, 25) + " ..." : history.question}
                    </span>
                    <p className="text-muted mb-0 text-10">{moment(moment.utc(history.created)).local().format("MM-DD-YYYY HH:mm")}</p>
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
