import SonantLogo from "../assets/images/sonant.png";

export default function SonantMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
    <div className="col-12">
      <div className="coi-scan-middle-header-col active ms-2">
        <img src={SonantLogo} className="landing-page-img" alt=""/>
        <span className="middle-header-title">Sonant</span>
      </div>
    </div>{" "}
  </div>
  )
}
